import { PlayerFilter } from '../_models/player-filter.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Team } from '../_models/team';
import { User } from '../_models/user.model';
import { AuthenticationService } from '../_services/authentication.service';
import { PlayerManagementService } from '../_services/player-management.service';
import { SharedService } from '../_services/shared.service';
import saveAs from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportPlayersComponent } from '../import-players/import-players.component';
import { ToastService } from "../_services/toast.service";

@Component({
  selector: 'app-player-management',
  templateUrl: './player-management.component.html',
  styleUrls: ['./player-management.component.scss'],
})
export class PlayerManagementComponent implements OnInit, OnDestroy {
  title = 'Player Management';
  subTitle = '';
  loadingGamesPlayedCount = true;
  loadingAverageScore = true;
  loadingAverageRating = true;
  loadingTotalConnectionsCount = true;
  loadingPlayers = true;
  showGames = { import: false, export: false, duration: true };
  totalGamesPlayed = 0;
  totalGamesPlayerDifference = 0;

  totalConnections = 0;
  totalConnectionsDifference = 0;

  averageScore = 0;
  averageScoreDifference = 0;

  averageRating = 0;
  averageRatingDifference = 0;

  players: any = [];
  playersPage = 1;
  playersLength = 10;
  totalPlayersCount = 0;

  env;

  user: User;

  durationType: number = 2; // AllTime = 1, LastWeek = 2, Last30Days = 3

  sort = 3; // by default sort by Average correct answer
  sortByAscending = false;

  filter: PlayerFilter = new PlayerFilter();

  filterOptions = {
    producers: [],
    varietals: [],
    regions: [],
    sommeliers: [],
  };

  destroyed$ = new Subject<void>();
  destoryCompanySubs$ = new Subject<void>();
  destorySubs$ = new Subject<void>();

  team: Team = undefined;

  previousFilterPayload: PlayerFilter = new PlayerFilter();

  exporting = false;
  userIsAdmin = false;

  isPremium = false;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private playerManagementService: PlayerManagementService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.env = environment;
    this.team = this.authService.getDefaultCompany();
    this.filter.companyId = this.team?.companyId;
    this.filter.durationType = 2;
    const root =
      document.compatMode === 'BackCompat'
        ? document.body
        : document.documentElement;
    this.playersLength = Math.floor((root.clientHeight - 300) / 70);
    this.user = this.authService.getUser();
    delete this.filter.playerId;
    this.init();

    this.authService
      .getUserCompany$()
      .pipe(takeUntil(this.destoryCompanySubs$))
      .subscribe(async (m) => {
        if (m) {
          this.destroyed$.next();
          this.team = m;
          this.filter.companyId = this.team?.companyId;
          this.userIsAdmin =
            this.authService.isAdmin() ||
            this.team.value === 0 ||
            this.team.value === 1;
        }
      });

    this.isPremium = this.sharedService.activeSubscription
      ? !this.sharedService.activeSubscription.isFree
      : false;

    this.showGames.import = this.isPremium;
    this.showGames.export = this.isPremium;

    this.authService._subscription$
      .pipe(takeUntil(this.destorySubs$))
      .subscribe((t) => {
        this.isPremium = t ? !t.isFree : false;

        this.showGames.import = this.isPremium;
        this.showGames.export = this.isPremium;
      });

    if (this.team) {
      this.userIsAdmin =
        this.authService.isAdmin() ||
        this.team.value === 0 ||
        this.team.value === 1;
    }
  }

  init() {
    this.playerManagementService
      .getFilterOptions(this.team?.companyId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (r) => (this.filterOptions = r.data),
        (_) => {}
      );

    this.load();
  }

  load(isDurationChanged: boolean = false) {
    const appliedFilters = JSON.parse(localStorage.getItem('filters'));
    if (appliedFilters) {
      this.filter = appliedFilters;
    }
    delete this.filter.playerId;
    this.getTotalGamesPlayedCount(isDurationChanged);
    this.getAverageScore(isDurationChanged);
    this.getAverageRating(isDurationChanged);
    this.getTotalConnections(isDurationChanged);
    this.getPlayers(false, isDurationChanged);
  }

  changeDuration(value: number) {
    const isDurationChanged: boolean = this.filter.durationType !== value;
    this.filter.durationType = value;
    localStorage.setItem('filters', JSON.stringify(this.filter));
    this.destroyed$.next();
    this.load(isDurationChanged);
  }

  getTotalGamesPlayedCount(isDurationChanged: boolean = false) {
    this.loadingGamesPlayedCount = true;
    this.playerManagementService
      .getTotalGamesPlayedCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingGamesPlayedCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalGamesPlayed = m.data;
        this.totalGamesPlayerDifference = 0;
        this.totalGamesPlayerDifference = m.percentage;
      });
  }

  getAverageScore(isDurationChanged: boolean = false) {
    this.loadingAverageScore = true;
    this.playerManagementService
      .getAverageScore(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingAverageScore = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.averageScore = m.data;
        this.averageScoreDifference = 0;
        this.averageScoreDifference = m.percentage;
      });
  }

  getAverageRating(isDurationChanged: boolean = false) {
    this.loadingAverageRating = true;
    this.playerManagementService
      .getAverageRating(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingAverageRating = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.averageRating = m.data;
        this.averageRatingDifference = 0;
        this.averageRatingDifference = m.percentage;
      });
  }

  getTotalConnections(isDurationChanged: boolean = false) {
    this.loadingTotalConnectionsCount = true;
    this.playerManagementService
      .getTotalConnections(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalConnectionsCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalConnections = m.data;
        this.totalConnectionsDifference = 0;
        this.totalConnectionsDifference = m.percentage;
      });
  }

  getPlayers(append = false, isDurationChanged: boolean = false) {
    this.loadingPlayers = true;
    if (!append) {
      this.players = [];
      this.playersPage = 1;
    }

    const model = { ...this.generateFilterPayload(isDurationChanged) };
    model['page'] = this.playersPage;
    model['count'] = this.playersLength;
    model['sortBy'] = this.sort;
    model['isSortByAscending'] = this.sortByAscending;

    this.playerManagementService
      .getPlayers(model)
      .pipe(
        finalize(() => (this.loadingPlayers = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m: any) => {
        this.totalPlayersCount = m.totalCount;
        if (append) {
          this.players.push(...m.data);
        } else {
          this.players = m.data;
        }
        this.subTitle = `You have ${this.totalPlayersCount} players.`;
      });
  }

  generateFilterPayload(isDurationChanged: boolean) {
    if (isDurationChanged) {
      this.previousFilterPayload.durationType = this.filter.durationType;
      return this.previousFilterPayload;
    }

    this.previousFilterPayload = { ...this.filter };
    if (!this.previousFilterPayload.vintageFrom)
      this.previousFilterPayload.vintageFrom = 0;
    if (!this.previousFilterPayload.vintageTo)
      this.previousFilterPayload.vintageTo = 0;

    return this.previousFilterPayload;
  }

  applyPlayerAnalyticsSort(sort) {
    if (this.sort === sort) {
      this.sortByAscending = !this.sortByAscending;
    } else {
      this.sort = sort;
      this.sortByAscending = false;
    }
    this.getPlayers();
  }

  getInitials(fullName: string) {
    if (!fullName) return '';

    const names = fullName.split(' ');
    let intials = names[0][0].toUpperCase();

    if (names.length > 1) {
      intials += names[1][0].toUpperCase();
    }

    return intials;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destoryCompanySubs$.next();
    this.destorySubs$.next();
  }

  onScroll() {
    if (
      !this.loadingPlayers &&
      this.totalPlayersCount !== this.players.length
    ) {
      this.playersPage++;
      this.getPlayers(true, false);
    }
  }

  // export() {
  //   if (this.exporting) return;
  //   const appliedFilters = JSON.parse(localStorage.getItem('filters'));
  //   if (appliedFilters) {
  //     this.filter = appliedFilters;
  //   }

  //   this.exporting = true;
  //   this.playerManagementService
  //     .export(this.filter)
  //     .pipe(finalize(() => (this.exporting = false)))
  //     .subscribe(
  //       (blob) => {
  //         saveAs(blob, 'players.csv');
  //       },
  //       (error) => console.log(error)
  //     );
  // }

  export() {
    const companyId = this.team?.companyId;
      if (companyId) {
          this.playerManagementService.fetchExportUrl(companyId).subscribe({
              next: (response) => {
                  // Check if the response includes the URL
                  if (response && response.data) {
                      // Use the URL from the response for redirection
                      window.location.href = response.data;
                  } else {
                      // Handle the case where the URL is not returned
                      this.toastService.showError('No import URL returned from the server.');
                  }
              },
              error: (error) => {
                  // Handle any errors, such as network issues or server errors
                  this.toastService.showError('Error fetching import URL.');
                  console.error('Error fetching import URL:', error);
              }
          });
      } else {
          this.toastService.showError('Company ID is undefined.');
          console.error('Company ID is undefined.');
      }
  }

  importPlayers() {
    this.modalService
      .open(ImportPlayersComponent, {
        backdrop: 'static',
      })
      .result.then((result) => {});
  }

  getSortingClass(sortColumn: number) {
    if (this.sort !== sortColumn) {
      return 'ni-arrow-long-up';
    } else if (this.sort === sortColumn && !this.sortByAscending) {
      return 'ni-arrow-long-up text-dark';
    } else if (this.sort === sortColumn && this.sortByAscending) {
      return 'ni-arrow-long-down text-dark';
    }
  }
}

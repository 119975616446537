import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GameService } from '../_services/game.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-generate-qr-code',
  templateUrl: './generate-qr-code.component.html',
  styleUrls: ['./generate-qr-code.component.scss']
})
export class GenerateQrCodeComponent implements OnInit {

  @Input() code: string;
  index = 0;
  blobs = [];
  loading = false;
  isDownloaded = false;

  horizontalQRHref = '';
  squareQRHref = '';
  digitalQRHref = '';
  verticalQRHref = '';
  template_url: string = '';


  constructor(private gameService: GameService, private modal: NgbActiveModal, private toastService: ToastService, private domeSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loading = true;
    this.template_url = environment.pwaUrl + '/scan/' + this.code;
    var horizontalQRRequest = this.gameService.downloadQRCode(this.code, "1");
    var squareQRRequest = this.gameService.downloadQRCode(this.code, "0");
    var digitalQRRequest = this.gameService.downloadQRCode(this.code, "2");
    var verticalQRRequest = this.gameService.downloadQRCode(this.code, "4");

    forkJoin([horizontalQRRequest, squareQRRequest, digitalQRRequest, verticalQRRequest])
      .pipe(finalize(() => this.loading = false)).subscribe(results => {
        results.forEach(result => this.blobs.push(result));
        this.horizontalQRHref = window.URL.createObjectURL(this.blobs[0]);
        this.squareQRHref = window.URL.createObjectURL(this.blobs[1]);
        this.digitalQRHref = window.URL.createObjectURL(this.blobs[2]);
        this.verticalQRHref = window.URL.createObjectURL(this.blobs[3]);
      });
  }

  close = () => this.modal.close();

  download() {
    this.isDownloaded = true;
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(this.blobs[this.index]);
    downloadLink.setAttribute('download', `QR-${this.code}`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  copyToClipboard() {
    window.navigator.clipboard.writeText(this.template_url);
    this.toastService.showSuccess('Link has been copied to clipboard');
  }

  // getImageUrl() {
  //   return window.URL.createObjectURL(this.blobs[this.index]);
  // }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../_services/shared.service';
import { getIcon, WineService } from '../_services/wine.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recommendation-request-model',
  templateUrl: './recommendation-request-model.component.html',
  styleUrls: ['./recommendation-request-model.component.scss'],
})
export class RecommendationRequestModelComponent implements OnInit {
  @Input() companyId: string;
  @Input() recommendationId: string;
  @Input() playerName: string;
  @Input() requiredType: string;
  @Input() wineName: string;
  @Input() picture: string;
  @Input() wineTypes: any[];
  title = 'Make a recommendation';
  name = '';
  wineType: any;
  showList = true;
  showWine = false;
  isSubmit = true;
  isConfirm = false;
  isFinal = false;
  env;

  constructor(
    public activeModal: NgbActiveModal,
    private wineService: WineService
  ) { }

  ngOnInit(): void {
    this.env = environment;
  }

  setWineType(type: any): void {
    if (type) {
      this.title = 'Somm Request';
      this.showWine = true;
      this.wineType = type;
    } else {
      this.showWine = false;
    }
  }
  flagName(name: string): string {
    return SharedService.flagName(name);
  }
  getWineIcon(color: string): string {
    return getIcon(color);
  }
  confirm(): void {
    this.isFinal = true;
    this.isConfirm = true;
    this.showList = false;
    this.isSubmit = false;
    this.title = 'Make this a default?';
  }
  onOpen(): void {
    this.title = 'Somm Request';
  }

  submit(val: number): void {
    this.wineService
      .respondRecommendation(this.recommendationId, this.wineType?.id, val)
      .subscribe((res) => {
        if (res) {
          this.activeModal.close('success');
        }
      });
  }

}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../_constants/global.constants';
import { Company } from '../_models/company.model';
import { Flavor, Note } from '../_models/note.model';
import { Wine } from '../_models/wine.model';
import { AuthenticationService } from '../_services/authentication.service';
import { ConfirmationDialogService } from '../_services/confirmation-dialog.service';
import { GameService } from '../_services/game.service';
import { ToastService } from '../_services/toast.service';
import countries from 'src/assets/data/countries.json';

declare var $: any;

@Component({
  selector: 'app-game-create',
  templateUrl: './game-create.component.html',
  styleUrls: ['./game-create.component.scss'],
})
export class GameCreateComponent implements OnInit, OnDestroy {
  @Input() note: Note;
  @Input() companyId = '';
  @Input() sommId = '';
  @Input() isEditMode = false;
  @Input() template = undefined;
  @Input() buyLink = '';
  wineAttributes = GlobalConstants.wineAttributes;

  selectedAttributes = [];

  flavorPlaceholders = ['Pineapple', 'Peach', 'Raspberry', 'Cherry'];

  company: Company;

  isHovering: boolean;
  wineImage: File;
  wineImageSource: any;
  isLoading = false;
  isOak = false;
  isFormModified = false;

  @ViewChild('wineForm', { static: true }) wineForm: NgForm;
  @ViewChild('tastingForm', { static: true }) tastingForm: NgForm;
  @ViewChild('notesForm', { static: true }) notesForm: NgForm;

  destroy$: Subject<any> = new Subject();

  aroma = [...GlobalConstants.aroma];
  body = [...GlobalConstants.body];
  acidity = [...GlobalConstants.acidity];
  tannins = [...GlobalConstants.tannins];
  sugar = [...GlobalConstants.sugar];
  oak = [...GlobalConstants.oak];

  sommNoteOptions = [
    { text: 'N/A', value: 0 },
    { text: 'Top 10%', value: 100 },
    { text: 'Top 25%', value: 75 },
    { text: 'Average', value: 50 },
    { text: 'Below Average', value: 25 },
  ];

  isflavorInputError = false;
  isflavorSelectError = false;

  description = '';

  searchingProducer = false;
  searchingWine = false;
  searchingVarietals = false;
  searchingWineRegions = false;
  searchingWineCountries = false;
  searchingAppellation = false;

  countries = countries;

  supportedFileTypes: Array<string> = ['png', 'jpg', 'jpeg'];

  constructor(
    private activeModel: NgbActiveModal,
    public authService: AuthenticationService,
    private gameService: GameService,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    if (!this.note) {
      this.initialize();
    } else {
      if (this.note && this.note.oak) this.isOak = true;
      this.description = this.note.description
        ? this.note.description
        : this.note.wine.description;
      this.wineImageSource = this.note.wine?.image
        ? environment.cdn + 'wines/' + this.note.wine.image
        : '';

      console.log(this.note.wine.attributes);
      if (this.note.wine.attributes.length > 0)
        this.selectedAttributes = this.note.wine.attributes
          .filter((x) => x.enabled)
          .map((m) => m.value);
    }

    if (!this.companyId) {
      this.companyId = this.authService.getDefaultCompany().companyId;
      this.authService
        .getUserCompany$()
        .pipe(takeUntil(this.destroy$))
        .subscribe((m) => {
          if (m) {
            this.companyId = m.companyId;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initialize() {
    this.note = new Note();
    this.note.wine = new Wine();
    this.note.flavors = new Array<Flavor>();

    const flavor0 = new Flavor();
    flavor0.selected = false;
    flavor0.value = '';
    this.note.flavors.push(flavor0);

    const flavor1 = new Flavor();
    flavor1.selected = false;
    flavor1.value = '';
    this.note.flavors.push(flavor1);

    const flavor2 = new Flavor();
    flavor2.selected = false;
    flavor2.value = '';
    this.note.flavors.push(flavor2);

    const flavor3 = new Flavor();
    flavor3.selected = false;
    flavor3.value = '';
    this.note.flavors.push(flavor3);

    this.note.wine.producer = '';
    this.note.wine.name = '';
    this.note.wine.variety = '';
    this.note.wine.vintage = undefined;
    this.note.wine.country = '';
    this.note.wine.link = '';

    this.note.body = GlobalConstants.body[0];
    this.note.aroma = GlobalConstants.aroma[0];
    this.note.acidity = GlobalConstants.acidity[0];
    this.note.sugar = GlobalConstants.sugar[0];
    this.note.tannins = GlobalConstants.tannins[0];
    this.note.oak = GlobalConstants.oak[0];
  }

  toggleHover = (event: boolean) => (this.isHovering = event);

  onFilePicked($event) {
    if ($event.target.files[0]) {
      if (
        this.supportedFileTypes.indexOf(
          $event.target.files[0].type.split('/')[1]
        ) < 0
      )
        return;

      this.setWineImage($event.target.files[0]);
    }
  }

  onDrop(files: FileList) {
    if (files.length > 0) {
      const file: File = files[0];
      if (this.supportedFileTypes.indexOf(file.type.split('/')[1]) < 0) return;
      this.setWineImage(file);
    }
  }

  setWineImage(file: File) {
    this.wineImage = file;
    const reader = new FileReader();
    reader.onload = (e) => (this.wineImageSource = reader.result);
    reader.readAsDataURL(this.wineImage);
  }

  onRemove() {
    this.wineImage = undefined;
    this.wineImageSource = undefined;
  }

  changeSelection = (type: string, value: string) => (this.note[type] = value);

  close = () => {
    if (this.isFormModified) {
      this.confirmationDialogService
        .confirm(
          'Confirmation',
          'You have unsaved changes, are you sure you want to cancel it?',
          'Yes',
          'No',
          true
        )
        .then((success) => {
          if (success) {
            this.activeModel.close();
          }
        })
        .catch(() => {});
    } else {
      this.activeModel.close();
    }
  };

  formModified(value: boolean) {
    if (!this.isFormModified) {
      if (value) this.isFormModified = true;
    }
    return true;
  }

  capitalizeFirstLetter(value: string) {
    const words = value.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
  }

  getSelectionBtnClass(type: string, value: string) {
    return {
      btn: true,
      'btn-primary': this.note[type] === value,
      'btn-outline-primary': this.note[type] !== value,
    };
  }

  goto = (tabId) => $(tabId).click();

  submitWineInfo(tabId) {
    if (this.wineImage) {
      this.goto(tabId);
    }
  }

  get isWineFormValid() {
    return this.wineForm && this.wineForm.valid;
  }

  get isTastingFormValid() {
    return this.tastingForm && this.tastingForm.valid;
  }

  get isNotesFormValid() {
    return this.notesForm && this.notesForm.valid;
  }

  get areFormsValid() {
    return (
      this.isWineFormValid && this.isTastingFormValid && this.notesForm.valid
    );
  }

  searchProducers: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingProducer = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findProducerNamesByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingProducer = false))
          );
        } else {
          return of([]).pipe(finalize(() => (this.searchingProducer = false)));
        }
      }),
      finalize(() => (this.searchingProducer = false)),
      catchError(() => {
        return [];
      })
    );

  searchWines: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingWine = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineNamesByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingWine = false))
          );
        } else {
          return of([]).pipe(finalize(() => (this.searchingWine = false)));
        }
      }),
      finalize(() => (this.searchingWine = false)),
      catchError(() => {
        return [];
      })
    );

  searchVarietals: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingVarietals = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findVarietalsByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingVarietals = false))
          );
        } else {
          return of([]).pipe(finalize(() => (this.searchingVarietals = false)));
        }
      }),
      finalize(() => (this.searchingVarietals = false)),
      catchError(() => {
        return [];
      })
    );

  searchWineRegions: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingWineRegions = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineRegionsByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingWineRegions = false))
          );
        } else {
          return of([]).pipe(
            finalize(() => (this.searchingWineRegions = false))
          );
        }
      }),
      finalize(() => (this.searchingWineRegions = false)),
      catchError(() => {
        return [];
      })
    );

  searchWineCountries: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingWineCountries = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineCountriesByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingWineCountries = false))
          );
        } else {
          return of([]).pipe(
            finalize(() => (this.searchingWineCountries = false))
          );
        }
      }),
      finalize(() => (this.searchingWineCountries = false)),
      catchError(() => {
        return [];
      })
    );

  searchAppellation: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingAppellation = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineAppellationByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingAppellation = false))
          );
        } else {
          return of([]).pipe(
            finalize(() => (this.searchingAppellation = false))
          );
        }
      }),
      finalize(() => (this.searchingAppellation = false)),
      catchError(() => {
        return [];
      })
    );

  createGame() {
    if (!this.isLoading) {
      this.isLoading = true;
      let payload: any = { ...this.note };
      payload['wine']['image'] = this.wineImage;
      payload['companyId'] = this.companyId;

      if (this.isOak) {
        payload['tannins'] = '';
      } else {
        payload['oak'] = '';
      }

      payload['buyLink'] = this.buyLink;

      const attributes = [...this.wineAttributes];

      if (this.selectedAttributes.length > 0) {
        attributes.forEach((x) => {
          x.enabled = this.selectedAttributes.indexOf(x.value) > -1;
        });
      }

      this.note.wine.attributes = attributes;

      if (!this.template) {
        this.gameService
          .create(payload)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe((m) => {
            if (m.success) {
              this.activeModel.close({ createGame: true });
            } else {
              this.toastService.showError(m.message);
            }
          });
      } else {
        console.log(payload);
        this.gameService
          .update(this.template.id, payload)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe((m) => {
            if (m.success) {
              this.activeModel.close({ createGame: true });
            } else {
              this.toastService.showError(m.message);
            }
          });
      }
    }
  }

  onFlavorChecked($event, index) {
    this.note.flavors.forEach((x) => (x.selected = false));
    this.note.flavors[index].selected = $event.target.checked;
  }

  validate() {
    this.isflavorInputError = this.isflavorSelectError = false;
    if (this.note.flavors.some((x) => x.value.trim() === '')) {
      this.isflavorInputError = true;
      return false;
    } else if (!this.note.flavors.some((x) => x.selected)) {
      this.isflavorSelectError = true;
      return false;
    }

    return true;
  }

  get mailToContent() {
    const user = this.authService.getUser();
    const formattedBody = `User ${user.fullName} ( ${user.id} ) is requesting to change wine color for following details \n\nSommId # ${this.template.sommId} - ${this.template.sommelier.fullName} \n\nWine Details\nId # ${this.note.wine.id}\nProducer # ${this.note.wine.producer}\nName # ${this.note.wine.name}\nExisting Color: ${this.note.wine.color}\n\n`;
    var mailToLink =
      'mailto:support@sommsays.co?subject=Wine Color Change Request (WineId ' +
      this.note.wine.id +
      ')&body=' +
      encodeURIComponent(formattedBody);
    return mailToLink;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { TokenType } from '@angular/compiler/src/ml_parser/lexer';
import { PaymentService } from '../_services/payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  PaymentMethodID;

  planId = '';

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  plans: any;
  result: any;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private stripeService: StripeService,
    private paymentService: PaymentService
  ) {}

  async ngOnInit() {
    const ret: any = await this.paymentService.getPlans().toPromise();
    this.plans = [...ret.plans];
  }

  extraData: any;
  cardCaptureReady = 0;
  invalidError = false;
  cardDetailsFilledOut = false;

  onStripeInvalid(error: Error) {
    console.log('Validation Error', error);
  }

  onStripeError(error: Error) {
    console.error('Stripe error', error);
  }

  // setPaymentMethod(token: stripe.paymentMethod.PaymentMethod) {
  //   console.log('Stripe Payment Method', token);
  // }

  // setStripeToken(token: stripe.Token) {
  //   console.log('Stripe Token', token);
  // }

  // setStripeSource(source: stripe.Source) {
  //   console.log('Stripe Source', source);
  // }

  async getCustomerId(): Promise<string> {
    let customerId = '';
    const idRet: any = await this.paymentService
      .getStripeCustomerId()
      .toPromise();
    console.log(idRet);
    if (!this.card.element) return;

    if (idRet && idRet.customerId) {
      customerId = idRet.customerId;
    } else {
      const stripeTokenRet = await this.stripeService
        .createToken(this.card.element)
        .toPromise();

      const n: any = await this.paymentService
        .getCustomerId(stripeTokenRet.token.id as string)
        .toPromise();

      customerId = n?.customerId;
    }

    return customerId;
  }

  async subscribe() {
    if (!this.planId) return;

    let customerId = await this.getCustomerId();
    const pack = {
      planId: this.planId,
      customerId: customerId,
    };

    const k = await this.paymentService.subscribe(pack).toPromise();
    this.result = k;
  }

  async charge() {
    let customerId = await this.getCustomerId();
    const pack = {
      amount: 5,
      currency: 'usd',
      description: 'testing the charge section ' + new Date().toISOString(),
      customerId: customerId,
    };

    const k = await this.paymentService.charge(pack).toPromise();
    this.result = k;
  }
}

import { CMSService } from './../_services/cms.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eulae',
  templateUrl: './eulae.component.html',
  styleUrls: ['./eulae.component.scss']
})
export class EulaeComponent implements OnInit {

  content: string = '';

  constructor(
    private cms: CMSService
  ) { }

  ngOnInit() {
    this.cms.getEULA().subscribe((ret) => {
      this.content = ret.items[0].data.content.iv;
    });
  }

}

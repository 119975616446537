import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appButtonSpinner]'
})
export class ButtonSpinnerDirective implements OnInit, OnChanges {

  @Input() loadingState: boolean;
  @Input() text: string;

  constructor(private elem: ElementRef) {
    if (this.elem.nativeElement.innerText) {
      this.text = this.elem.nativeElement.innerText;
    }
  }

  ngOnInit(): void {
    if (this.loadingState) {
      this.elem.nativeElement.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"> <span class="sr-only">Loading...</span></div>';
    }
  }

  ngOnChanges(changes) {
    this.loadingState = changes.loadingState?.currentValue;
    if (this.loadingState) {
      this.elem.nativeElement.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"> <span class="sr-only">Loading...</span></div>';
    } else {
      if (this.text) {
        this.elem.nativeElement.innerText = this.text;
      }
    }
  }
}
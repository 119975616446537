import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CdnService {
  wine = () => environment.cdn + "wines";
  profile = () => environment.cdn + "profiles";
}

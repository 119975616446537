<app-shell-light>
    <div class="nk-content ">
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <app-global-filters [title]="title" [subTitle]="subTitle" [filter]="filter"
                        (createGame)="createGame()" [filterOptions]="filterOptions" [showGames]="showGames"
                        (duration)="changeDuration($event)" (load)="search()" (export)="export()">
                    </app-global-filters>
                    <div class="row g-gs mt-07">
                        <!-- People Played -->
                        <div class="col-md-4">
                            <div class="card card-bordered card-full top-card">
                                <div class="card-inner">
                                    <!-- <img src="../../assets/icons/icons8-omnichannel.svg" class="icn"> -->
                                    <div class="card-title-group align-start mb-0">
                                        <div class="card-title">
                                            <h6 class="subtitle">People Played</h6>
                                        </div>
                                        <div class="card-tools">
                                            <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                data-placement="left" title="Total number of players have played"></em>
                                        </div>
                                    </div>
                                    <div class="card-amount">
                                        <div *ngIf="loadingTotalConnectionsCount"
                                            class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span></div>

                                        <div class="w-100 d-flex justify-content-between"
                                            *ngIf="!loadingTotalConnectionsCount">
                                            <span class="amount">{{ totalConnections }}</span>
                                            <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                <span
                                                    class="{{ totalConnectionsDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                                                        totalConnectionsDifference > -1 ? '+' : ''}}
                                                    {{ totalConnectionsDifference }} %</span>
                                                <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Buy Now Clicks -->
                        <div class="col-md-4">
                            <div class="card card-bordered card-full top-card">
                                <div class="card-inner">
                                    <!-- <img src="../../assets/icons/icon-buylink.svg" class="icn"> -->
                                    <div class="card-title-group align-start mb-0">
                                        <div class="card-title">
                                            <h6 class="subtitle">Buy Now Clicks</h6>
                                        </div>
                                        <div class="card-tools">
                                            <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                data-placement="left" title="Total buy now clicks"></em>
                                        </div>
                                    </div>
                                    <div class="card-amount">
                                        <div *ngIf="loadingBuyNowClickCount" class="spinner-border spinner-border-sm"
                                            role="status"> <span class="sr-only">Loading...</span></div>

                                        <div class="w-100 d-flex justify-content-between"
                                            *ngIf="!loadingBuyNowClickCount">
                                            <span class="amount">{{ totalBuyNowClicks }}</span>
                                            <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                <span class="{{ totalBuyNowClicksDifference < 0 ? 'text-danger' : 'text-success' }}
                                                        fw-medium">{{
                                                        totalBuyNowClicksDifference > -1 ? '+' : ''}}
                                                    {{ totalBuyNowClicksDifference }} %</span>
                                                <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Followers -->
                        <div class="col-md-4">
                            <div class="card card-bordered card-full top-card">
                                <div class="card-inner">
                                    <!-- <img src="../../assets/icons/icons8-staff.svg" class="icn"> -->
                                    <div class="card-title-group align-start mb-0">
                                        <div class="card-title">
                                            <h6 class="subtitle">Followers</h6>
                                        </div>
                                        <div class="card-tools">
                                            <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                data-placement="left" title="Total number of followers"></em>
                                        </div>
                                    </div>
                                    <div class="card-amount">
                                        <div *ngIf="loadingTotalFollowersCount" class="spinner-border spinner-border-sm"
                                            role="status"> <span class="sr-only">Loading...</span></div>
                                        <span *ngIf="!loadingTotalFollowersCount" class="amount">{{ totalFollowers
                                                }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Games Played -->
                        <div class="col-md-4">
                            <div class="card card-bordered card-full top-card">
                                <div class="card-inner">
                                    <!-- <img src="../../assets/icons/icons8-champagne.svg" class="icn"> -->
                                    <div class="card-title-group align-start mb-0">
                                        <div class="card-title">
                                            <h6 class="subtitle">Games Played</h6>
                                        </div>
                                        <div class="card-tools">
                                            <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                data-placement="left"
                                                title="Total number of games have been played"></em>
                                        </div>
                                    </div>
                                    <div class="card-amount">
                                        <div *ngIf="loadingTotalGamesPlayedCount"
                                            class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span></div>
                                        <div class="w-100 d-flex justify-content-between"
                                            *ngIf="!loadingTotalGamesPlayedCount">
                                            <span class="amount">{{ totalGamesPlayed }}</span>
                                            <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                <span
                                                    class="{{ totalGamesPlayerDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                                                        totalGamesPlayerDifference > -1 ? '+' : ''}}
                                                    {{ totalGamesPlayerDifference }} %</span>
                                                <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Recommendations Requested -->
                        <div class="col-md-4">
                            <div class="card card-bordered card-full top-card">
                                <div class="card-inner">
                                    <!-- <img src="../../assets/icons/icon-recommendation.svg" class="icn"> -->
                                    <div class="card-title-group align-start mb-0">
                                        <div class="card-title">
                                            <h6 class="subtitle">Rec Requested</h6>
                                        </div>
                                        <div class="card-tools">
                                            <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                data-placement="left"
                                                title="Total number of recommendation request received and responded"></em>
                                        </div>
                                    </div>
                                    <div class="card-amount">
                                        <div *ngIf="loadingRecommendationsCount"
                                            class="spinner-border spinner-border-sm" role="status"> <span
                                                class="sr-only">Loading...</span></div>
                                        <div class="w-100 d-flex justify-content-between"
                                            *ngIf="!loadingRecommendationsCount">
                                            <span class="amount">{{ totalRecommendationReceived }}</span>
                                            <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                <span class="{{ totalRecommendationReceivedDifference < 0 ? 'text-danger' : 'text-success' }}
                                                            fw-medium">{{
                                                        totalRecommendationReceivedDifference > -1 ? '+' : ''}}
                                                    {{ totalRecommendationReceivedDifference }} %</span>
                                                <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Recommendations Fulfilled -->
                        <div class="col-md-4">
                            <div class="card card-bordered card-full top-card">
                                <div class="card-inner">
                                    <!-- <img src="../../assets/icons/icon-recommendation.svg" class="icn"> -->
                                    <div class="card-title-group align-start mb-0">
                                        <div class="card-title">
                                            <h6 class="subtitle">Rec Fulfilled</h6>
                                        </div>
                                        <div class="card-tools">
                                            <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                data-placement="left"
                                                title="Total number of recommendation request received and responded"></em>
                                        </div>
                                    </div>
                                    <div class="card-amount">
                                        <div *ngIf="loadingRecommendationsRespondedCount"
                                            class="spinner-border spinner-border-sm" role="status"> <span
                                                class="sr-only">Loading...</span></div>
                                        <div class="w-100 d-flex justify-content-between"
                                            *ngIf="!loadingRecommendationsRespondedCount">
                                            <span class="amount">{{ totalRecommendationsResonded }}</span>
                                            <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                <span class="{{ totalRecommendationReceivedDifference < 0 ? 'text-danger' : 'text-success' }}
                                                            fw-medium">{{
                                                        totalRecommendationsResondedDifference > -1 ? '+' : ''}}
                                                    {{ totalRecommendationsResondedDifference }} %</span>
                                                <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class=" col-12 card card-bordered card-full mt-4">
                            <div class="card-inner p-0">
                                <div class="nk-tb-list nk-tb-orders d-none d-md-table">
                                    <div class="nk-tb-item nk-tb-head">
                                        <div class="nk-tb-col tb-col-md">
                                            <span class="ml-2">Wine Details</span>
                                        </div>
                                        <div class="nk-tb-col tb-col-sm" (click)="applySort(1)">
                                            <span>Games</span>
                                            <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                [ngClass]="getSortClass(1)">
                                            </em>
                                        </div>
                                        <div class="nk-tb-col tb-col-sm" (click)="applySort(2)">
                                            <span>Players</span>
                                            <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                [ngClass]="getSortClass(2)">
                                            </em>
                                        </div>
                                        <div class="nk-tb-col tb-col-sm" (click)="applySort(3)">
                                            <span>Avg Rating</span>
                                            <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                [ngClass]="getSortClass(3)">
                                            </em>
                                        </div>
                                        <div class="nk-tb-col tb-col-sm" (click)="applySort(4)">
                                            <span>GTP Diff</span>
                                            <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                [ngClass]="getSortClass(4)">
                                            </em>
                                        </div>
                                        <div class="nk-tb-col tb-col-sm"><span>Sommelier</span></div>
                                        <div class="nk-tb-col tb-col-sm"><span>Rec. Status</span></div>
                                        <div class="nk-tb-col tb-col-sm" (click)="applySort(5)">
                                            <span>Rec. Fulfilled</span>
                                            <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                [ngClass]="getSortClass(5)">
                                            </em>
                                        </div>
                                        <div class="nk-tb-col tb-col-sm" (click)="applySort(6)">
                                            <span>Rec. Rating</span>
                                            <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                [ngClass]="getSortClass(6)">
                                            </em>
                                        </div>
                                        <div class="nk-tb-col tb-col-sm" (click)="applySort(7)">
                                            <span>Buy Clicks</span>
                                            <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                [ngClass]="getSortClass(7)">
                                            </em></div>
                                        <div class="nk-tb-col tb-col-sm"></div>
                                    </div>

                                    <div class="nk-tb-item selectable" *ngFor="let template of templates">
                                        <div class="nk-tb-col tb-col-md" (click)="open(template.id)">
                                            <div class="user-card">
                                                <div class="wine-image mr-1">
                                                    <ng-conatiner *ngIf="template.wine?.image; else defaultWineImage">
                                                        <img [src]="getWineImageUrl(template.wine?.image)" class="m-1">
                                                    </ng-conatiner>
                                                    <ng-template #defaultWineImage>
                                                        <img src="../../assets/temp/barrelX_riesling.jpg" class="m-1">
                                                    </ng-template>

                                                </div>
                                                <div class="user-name">
                                                    <span class="tb-lead wine-info">{{ template.wine?.producer }} - {{
                                                    template.wine?.vintage }}</span>
                                                    <span class="tb-lead wine-name">{{ template.wine?.name }}</span>
                                                    <span class="tb-lead wine-meta">
                                                        <img [src]="getWineIcon(template.wine?.color)" class="grape">
                                                        {{ template.wine?.variety }}
                                                        <img src="../../assets/flags/{{ getCountryFlag(template.wine?.country) }}.svg"
                                                            class="flag">
                                                        {{ getWineOrigin(template.wine) }}
                                                    </span>
                                                    <span class="tb-lead my-1 wine-meta">
                                                        <img [src]="getBuyItNowIcon(template.haveBuyLink)" class="wr">
                                                        Buy It Now
                                                        <img [src]="getFoodPairingIcon(template.haveFoodPairing)"
                                                            class="wr ml-1">
                                                        Food Pairing
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        <span class="head-m"> {{ template.gamesCount }}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        <span class="head-m"> {{ template.players }}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        <span class="head-m"> {{ template.rating }}%</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        <span class="head-m"> {{ template.gtp }}%</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        {{ template.sommName}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center flex-column">
                                                        <div class="rec-status" *ngIf="
                                                        template.recommendation?.pending < 1 &&
                                                            template.recommendation?.completed > 0">
                                                            <img src="../../assets/icons/icons8-waiter-green.svg">
                                                            <span>Complete({{template.recommendation?.completed}})</span>
                                                        </div>
                                                        <div class="rec-status default" *ngIf="template.recommendation?.pending < 1 &&
                                                        template.recommendation?.completed < 1">
                                                            <img src="../../assets/icons/icons8-waiter-green.svg">
                                                            <span>None</span>
                                                        </div>
                                                        <div class="rec-status pending"
                                                            *ngIf="template.recommendation?.pending > 0">
                                                            <img src="../../assets/icons/icons8-waiter-grey.svg">
                                                            <span>Pending({{template.recommendation?.pending}})</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        {{ template.recommendation?.completed}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        {{ template.recommendation?.rating}}%
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm" (click)="open(template.id)">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        {{ template.buyClicks}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="nk-tb-col tb-col-sm">
                                            <table>
                                                <tr>
                                                    <td class="center">
                                                        <btn class="btn success btn-outline-primary btn-round btn-sm btn-special"
                                                            (click)="generateQR(template.code)">
                                                            <div>Normal QR</div>
                                                        </btn>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="center">
                                                        <btn class="btn btn-outline-primary btn-round btn-sm btn-special"
                                                            (click)="createInstance(template.id)">
                                                            <div>Event QR</div>
                                                        </btn>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="center">
                                                        <btn class="btn btn-outline-primary btn-round btn-sm btn-special"
                                                            (click)="selectTemplate(template.id)">
                                                            <div>Edit Game</div>
                                                        </btn>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="nk-tb-list nk-tb-orders d-block d-md-none">
                                    <div class="nk-tb-item selectable col-12 d-flex flex-column py-2"
                                        *ngFor="let template of templates; let odd = odd" [ngClass]="{'bg-grey': odd }">
                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                <span>Name</span>
                                            </div>
                                            <div class="user-card col-8 pl-0">
                                                <div class="wine-image mr-1">
                                                    <ng-conatiner *ngIf="template.wine?.image; else defaultWineImage">
                                                        <img [src]="getWineImageUrl(template.wine?.image)" class="m-1">
                                                    </ng-conatiner>
                                                    <ng-template #defaultWineImage>
                                                        <img src="../../assets/temp/barrelX_riesling.jpg" class="m-1">
                                                    </ng-template>

                                                </div>
                                                <div class="user-name">
                                                    <span class="tb-lead wine-info">{{ template.wine?.producer }} - {{
                                                    template.wine?.vintage }}</span>
                                                    <span class="tb-lead wine-name">{{ template.wine?.name }}</span>
                                                    <span class="tb-lead wine-meta">
                                                        <img [src]="getWineIcon(template.wine?.color)" class="grape">
                                                        {{
                                                    template.wine?.variety}}
                                                    </span>
                                                    <span class="tb-lead wine-meta">
                                                        <img src="../../assets/flags/{{ getCountryFlag(template.wine?.country) }}.svg"
                                                            class="flag">
                                                        {{ getWineOrigin(template.wine) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Games
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.gamesCount }}</span>
                                            </div>
                                        </div>

                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Players
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.players}}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Avg. Rating
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.rating }}%
                                                </span>
                                            </div>
                                        </div>


                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                GTP Diff
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.gtp}}%
                                                </span>
                                            </div>
                                        </div>


                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Sommelier
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.sommName}}</span>
                                            </div>
                                        </div>

                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Rec. Status
                                            </div>
                                            <div class="col-8 pl-0">
                                                <div class="flex-column">
                                                    <div class="rec-status" *ngIf="
                                                        template.recommendation?.pending < 1">
                                                        <img src="../../assets/icons/icons8-waiter-green.svg">
                                                        <span>Complete({{template.recommendation?.completed}})</span>
                                                    </div>
                                                    <div class="rec-status default" *ngIf="template.recommendation?.pending < 1 &&
                                                        template.recommendation?.completed < 1">
                                                        <img src="../../assets/icons/icons8-waiter-green.svg">
                                                        <span>Default({{template.recommendation?.default}})</span>
                                                    </div>
                                                    <div class="rec-status pending"
                                                        *ngIf="template.recommendation?.pending > 0">
                                                        <img src="../../assets/icons/icons8-waiter-grey.svg">
                                                        <span>Pending({{template.recommendation?.pending}})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Rec. Fulfilled
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.recommendation?.completed }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Rec. Rating
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.recommendation?.rating }}%
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row" (click)="open(template.id)">
                                            <div class="col-4 pr-0">
                                                Buy Clicks
                                            </div>
                                            <div class="col-8 pl-0">
                                                <span class="fs-13px fw-medium text-dark">
                                                    {{ template.buyClicks }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                Actions
                                            </div>
                                            <div class="col-8 pl-0">
                                                <ul>
                                                    <li>
                                                        <a class="fs-13px fw-medium text-success"
                                                            (click)="generateQR(template.code)">
                                                            Normal QR
                                                            <em
                                                                class="icon ni ni-chevron-right fs-14px fw-bold super-action"></em>
                                                        </a>
                                                    </li>

                                                    <li class="mt-1">
                                                        <a class="fs-13px fw-medium text-success"
                                                            (click)="createInstance(template.id)">
                                                            Event QR
                                                            <em
                                                                class="icon ni ni-chevron-right fs-14px fw-bold super-action"></em>
                                                        </a>
                                                    </li>


                                                    <li class="mt-1">
                                                        <a class="fs-13px fw-medium text-success"
                                                            (click)="selectTemplate(template.id)">
                                                            Edit Game
                                                            <em
                                                                class="icon ni ni-chevron-right fs-14px fw-bold super-action"></em>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <!-- <div class="row" (click)="open(template.id)">
                                        <table class="wine-stats col-12">
                                            <tr>
                                                <td><img src="../../assets/icons/icons8-user-groups.svg" class="grape">
                                                    Players <span class="value">{{ template.players }}</span></td>
                                                <td><img src="../../assets/icons/icons8-game-controller.svg"
                                                        class="grape"> Games <span class="value">{{
                                                        template.instances }}</span></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Code<span class="value ml-1">{{ template.code }}</span></td>
                                                <td><img src="../../assets/icons/icons8-prize.svg" class="grape"> Avg
                                                    Pref. <span class="value">{{ template.averagePreference |
                                                        number: '1.0-0' }}%</span></td>
                                            </tr>
                                        </table>
                                    </div> -->

                                    </div>
                                </div>

                                <div class="text-center empty"
                                    *ngIf="templates && templates.length === 0 && loading === false">
                                    You have no game templates yet<br>
                                    <a (click)="createGame()"
                                        [ngClass]="{'disabled': !userCompany.isGameCreationAllowed }"
                                        routerLinkActive="router-link-active" class="btn btn-super mt-3">
                                        <em class="icon ni ni-plus"></em> <span>Create Game</span>
                                    </a>
                                </div>

                                <div class="text-center empty" *ngIf="loading === true">
                                    <div class="spinner-border spinner-border-sm" role="status"> <span
                                            class="sr-only">Loading...</span></div>
                                </div>


                                <div class="col-12 py-2">
                                    <ngb-pagination *ngIf="!loading" [(page)]="filter.page" [(pageSize)]="filter.length"
                                        [rotate]="true" [ellipses]="true" [boundaryLinks]="true"
                                        [collectionSize]="totalCount" (pageChange)="loadPage($event)">
                                    </ngb-pagination>
                                </div>


                            </div>
                            <!-- <div class="card-inner-sm border-top text-center d-sm-none">
                            <a href="#" class="btn btn-link btn-block">See History</a>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-shell-light>
import { UserService } from './user.service';
import { SharedService } from 'src/app/_services/shared.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/* Firebase Start */
import { EventService } from './event.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { EventModel } from '../_models/eventModel';
/* Firebase End */

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private unsubscribe$: Subject<any> = new Subject<any>();

  httpOptions = {};

  collectionRef: AngularFirestoreCollection<EventModel>;
  statCollectionRef: AngularFirestoreCollection<EventModel>;
  gameLobbyCollectionRef: AngularFirestoreCollection<EventModel>;
  todo: Observable<EventModel>;
  called = false;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private firestore: AngularFirestore,
    private af: AngularFireModule,
    private eventService: EventService,
    private userService: UserService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.sharedService.token,
      }),
    };
  }

  private getToken(): Observable<any> {
    const formData: FormData = new FormData();
    return this.http
      .post(
        environment.apiURL + '/firebase/get-token',
        formData,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          const ret = response;
          if (ret) {
            return ret;
          } else {
            console.log('error');
          }
        })
      );
  }

  async authenticate() {
    var defaultAuth = firebase.auth();
    const ret = await this.getToken().toPromise();
    console.log('GET TOKEN', ret);
    await defaultAuth.signInWithCustomToken(ret.data);
    console.log('LOGGED IN');
  }

  watchWineGuessPriceChange(id: string) {
    this.statCollectionRef = this.firestore.collection('wineGuessedPrice');
    this.statCollectionRef
      .doc(id)
      .valueChanges()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        console.log('Update from Firebase', x);
        this.eventService.dispatchEvent({ type: 'STATS_UPDATED', payload: x });
      });
  }

  watchStats(id: string) {
    this.statCollectionRef = this.firestore.collection('wineStats');
    this.statCollectionRef
      .doc(id)
      .valueChanges()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        console.log('Update from Firebase', x);
        this.eventService.dispatchEvent({ type: 'STATS_UPDATED', payload: x });
      });
  }

  unWatch() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logOut() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  }
}

import { Company } from "./company.model";
import { User } from "./user.model";

export class Team {
    id: string;
    companyId: string;
    company: Company;
    userId: string;
    user: User;
    title: string;
    value: number;
    isDefault: boolean;
    valueAsString: string;
    isGameCreationAllowed: boolean;
    isPublicProfile: boolean;
    isActive: boolean;
    createdOn: boolean;
    hasAcceptedInvite: boolean;
    name: string;
    email: string;
}
<app-shell-light>
    <div class="nk-content">
        <ng-container *ngIf="!isTemplateLoading; else showLoadingSection">
            <div class="container-fluid" *ngIf="template">
                <div class="nk-content-inner">
                    <div class="nk-content-body">


                        <div class="row g-gs">
                            <div class="col-lg-12 col-xxl-12">
                                <div class="card card-bordered h-100">
                                    <div class="card-inner">
                                        <div class="row g-gs">
                                            <div class="user-card">
                                                <div class="wine-image">
                                                    <ng-conatiner *ngIf="template.wine?.image; else defaultWineImage">
                                                        <img src="{{env.cdn}}wines/{{template.wine?.image}}">
                                                    </ng-conatiner>
                                                    <ng-template #defaultWineImage>
                                                        <img src="../../assets/temp/barrelX_riesling.jpg">
                                                    </ng-template>
                                                </div>
                                                <div class="user-name ml-2">
                                                    <span class="tb-lead wine-info">{{ template.wine.producer }} - {{
                                                        template.wine.vintage }}</span>
                                                    <br>
                                                    <span class="tb-lead wine-name">{{ template.wine.name }}</span>
                                                    <br>
                                                    <span class="tb-lead wine-meta">
                                                        <img [src]="getWineIcon(template.wine?.color)" class="grape">
                                                        {{ template.wine.variety}}
                                                        <img src="../../assets/flags/{{ flagName(template.wine.country) }}.svg"
                                                            class="flag">
                                                        {{ getWineOrigin(template.wine) }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="toggle-wrap nk-block-tools-toggle align-end">
                                                <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1"
                                                    data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                                                <div class="toggle-expand-content" data-content="pageMenu">
                                                    <ul class="nk-block-tools g-3">
                                                        <li><a [routerLink]="['/games']" class="btn btn-super"><span>Go
                                                                    to Dashboard</span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-xxl-12">
                                        <div class="tabs">
                                            <div class="tab" [ngClass]="{'selected': selectedTab == 'summary'}"
                                                (click)="selectTab('summary')">Summary</div>
                                            <div class="tab" [ngClass]="{'selected': selectedTab == 'stats'}"
                                                (click)="selectTab('stats')">Realtime Stats</div>
                                            <div class="tab" [ngClass]="{'selected': selectedTab == 'detail'}"
                                                (click)="selectTab('detail')">Wine Details</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="nk-block mt-4">
                            <div class="row g-gs" *ngIf="selectedTab == 'summary'">
                                <div class="col-md-3">
                                    <div class="card card-bordered card-full top-card">
                                        <div class="card-inner">
                                            <div class="card-title-group align-start mb-0">
                                                <img src="../../assets/icons/icons8-champagne.svg" class="icn">
                                                <div class="card-title">
                                                    <div class="subtitle">Connections</div>
                                                    <div class="amount">{{uniquePlayerCount}}</div>
                                                </div>
                                                <div class="stats">
                                                    <div class="percent"></div>
                                                    <div class="days"></div>
                                                </div>
                                                <div class="card-tools">
                                                    <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                        data-placement="left" title="Total Deposited"></em>
                                                </div>
                                            </div>
                                            <!-- <div class="card-amount">
                                                <div *ngIf="uniquePlayerCount < 0"
                                                    class="spinner-border spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span *ngIf="uniquePlayerCount > -1" class="amount">
                                                    {{ uniquePlayerCount }}
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card card-bordered card-full top-card">
                                        <div class="card-inner">
                                            <div class="card-title-group align-start mb-0">
                                                <img src="../../assets/icons/icons8-champagne.svg" class="icn">
                                                <div class="card-title">
                                                    <div class="subtitle">Games</div>
                                                    <div class="amount">{{totalGamesCount}}</div>
                                                </div>
                                                <div class="stats">
                                                    <div class="percent"></div>
                                                    <div class="days"></div>
                                                </div>
                                                <div class="card-tools">
                                                    <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                        data-placement="left" title="Total Deposited"></em>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card card-bordered card-full top-card">
                                        <div class="card-inner">
                                            <div class="card-title-group align-start mb-0">
                                                <img src="../../assets/icons/icons8-champagne.svg" class="icn">
                                                <div class="card-title">
                                                    <div class="subtitle">Avg Score</div>
                                                    <div class="amount">{{correctAverageAnswer}}</div>
                                                </div>
                                                <div class="stats">
                                                    <div class="percent"></div>
                                                    <div class="days"></div>
                                                </div>
                                                <div class="card-tools">
                                                    <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                        data-placement="left" title="Total Deposited"></em>
                                                </div>
                                            </div>
                                            <!-- <div class="card-amount">
                                                <span class="amount fs-25px">{{ averageTime }}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card card-bordered card-full top-card">
                                        <div class="card-inner">
                                            <div class="card-title-group align-start mb-0">
                                                <img src="../../assets/icons/icons8-champagne.svg" class="icn">
                                                <div class="card-title">
                                                    <div class="subtitle">Avg Rating</div>
                                                    <div class="amount">{{averageRating}}</div>
                                                </div>
                                                <div class="stats">
                                                    <div class="percent"></div>
                                                    <div class="days"></div>
                                                </div>
                                                <div class="card-tools">
                                                    <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                        data-placement="left" title="Total Deposited"></em>
                                                </div>
                                            </div>
                                            <!-- <div class="card-amount">
                                                <div *ngIf="correctAverageAnswer < 0"
                                                    class="spinner-border spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span *ngIf="correctAverageAnswer > -1" class="amount">
                                                    {{ correctAverageAnswer | number:'1.0-0'}}%
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-xxl-12">

                                    <div class="card card-bordered card-full top-card">
                                        <div class="card-inner">
                                            <div class="card-title-group fs">
                                                <div class="card-title w-100">
                                                    <h5 class="title">Preference Summary</h5>
                                                    <div>See how your customer's rate their experience. Dark purple means higher preference.</div>
                                                </div>
                                            </div>
                                            <div class="w-100 py-4 text-center" *ngIf="loadingInstances">
                                                <div class="spinner-border spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <div class="pgGroup"
                                                *ngFor="let ps  of sortCharRatingChange(preferenceSummary)">
                                                <p class="groupName">{{ps?.feelName}}</p>
                                                <div class="pg">
                                                    <div class="progress-row" *ngFor="let p  of ps?.property">
                                                        <span class="label"
                                                            [ngClass]="{'green-tick':p.propertyName == ps.correctProperty }">
                                                            <span style="margin-right:10px"
                                                                *ngIf="p.propertyName == ps.correctProperty "><img
                                                                    src="../../assets/icons/icons8-tick.svg"></span>{{p.propertyName}}
                                                        </span>
                                                        <div class="progress-bar-no-data" ngbTooltip="0"
                                                            *ngIf="!(+p.val1 + +p.val2 + +p.val3)">
                                                        </div>
                                                        <div [style.width]="'100%'">
                                                            <div class="progress">
                                                                <span class="progress-bar-pink"
                                                                    style="background-color:#C636B2;" role="progressbar"
                                                                    [ngbTooltip]="p.val1 | number: '1.0-2'"
                                                                    [style.width]="getInnerBarWidth(p.val1,p)"></span>
                                                                <span class="progress-bar-light-pink" role="progressbar"
                                                                    [ngbTooltip]="p.val2 | number: '1.0-2'"
                                                                    [style.width]="getInnerBarWidth(p.val2,p)"></span>
                                                                <span class="progress-bar-light-grey-remaining"
                                                                    [ngbTooltip]="p.val3 | number: '1.0-2'"
                                                                    role="progressbar"
                                                                    [style.width]="getInnerBarWidth(p.val3,p)"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                            <div class="pgGroup" *ngIf="preferenceSummary?.length">
                                                <p class="groupName"></p>
                                                <div class="pg">
                                                    <div class="progress-row">
                                                        <span class="label">
                                                            <span style="margin-right:10px"></span></span>
                                                        <div class="grid-val">
                                                            <div class="val" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10]">
                                                                {{i*(max/10)|number:'1.0-1'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                            <div class="col-md-01 legend mv-5 center-align"
                                                *ngIf="preferenceSummary.length > 0">
                                                <div class="flex">
                                                    <div class="indicator" style="background-color: #C636B2;"></div>
                                                    <p class="title">Top 10%</p>
                                                </div>
                                                <div class="flex">
                                                    <div class="indicator" style="background-color: #EA8AD8;"></div>
                                                    <p class="title">Top 25%</p>
                                                </div>
                                                <div class="flex">
                                                    <div class="indicator" style="background-color: #F2F2F7;"></div>
                                                    <p class="title">Not Preferred</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-xxl-12">
                                    <div class="card card-bordered card-full">
                                        <div class="card-inner">
                                            <div class="card-title-group">
                                                <div class="card-title w-100">
                                                    <h6 class="title">Feedback Summary</h6>
                                                    <div>See what your customers are requesting for their next-best-bottle.</div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row g-gs">
                                                <div class="col-md-0" *ngFor="let fs of feedbackSummary;let i =index">
                                                    <div class="card card-bordered card-full top-card">
                                                        <div class="feedback-card">
                                                            <div class="top">
                                                                <div class="icon-outer"
                                                                    [style.background]="feedBackColor[i].background">
                                                                    <img [src]="'../../assets/icons/'+feedBackColor[i].icon"
                                                                        class="icon-inner">
                                                                </div>
                                                                <h6 class="subtitle"
                                                                    [style.color]="feedBackColor[i].color">
                                                                    {{fs?.feelName}}</h6>
                                                            </div>

                                                            <div class="bottom">
                                                                <div class="fs-row">
                                                                    <img src="../../assets/icons/icon-8-up.svg"
                                                                        class="more-icon">
                                                                    <div class="more">More</div>
                                                                    <div class="number">{{fs?.more}}</div>
                                                                </div>
                                                                <div class="fs-row">
                                                                    <img src="../../assets/icons/icon-8-down.svg"
                                                                        class="more-icon">
                                                                    <div class="more">Less</div>
                                                                    <div class="number">{{fs?.less}}</div>
                                                                </div>
                                                                <div class="fs-row">
                                                                    <div class="total">Total</div>
                                                                    <div class="number">{{fs?.more + fs?.less}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-100 py-4 text-center" *ngIf="loadingInstances">
                                            <div class="spinner-border spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="w-100 py-3 text-center"
                                            *ngIf="!loadingInstances && instances.length < 1">
                                            <span>No records found</span>
                                        </div>
                                        <div class="search-results" class="search-results" infiniteScroll
                                            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                                            (scrolled)="onScroll()"></div>
                                    </div>
                                </div>
                            </div>



                            <div class="row g-gs" *ngIf="selectedTab == 'stats'">
                                <div class="col-12">
                                    <div class="card card-bordered card-full">
                                        <div class="card-inner">
                                            <div class="row">

                                                <div class="col-xl-6">
                                                    <div class="card-title">
                                                        <h5>Instances</h5>
                                                    </div>
                                                    <div class="serachbar">
                                                        <input type="text" placeholder="Select Instance"
                                                            class="instance">
                                                        <select class="select">
                                                            <option selected>Newest First</option>
                                                        </select>
                                                    </div>
                                                    <div class="divider"></div>
                                                    <div class="nk-tb-list nk-tb-customers">
                                                        <div class="nk-tb-item nk-tb-head">
                                                            <div class="nk-tb-col pl-2"><span>Name</span></div>
                                                            <div class="nk-tb-col tb-col-sm">
                                                                <span>Enterprise</span>
                                                            </div>
                                                            <div class="nk-tb-col pl-2"><span>Date</span></div>


                                                            <div class="nk-tb-col"><span>Time</span></div>
                                                            <div class="nk-tb-col tb-col-sm">
                                                                <span>Player</span>
                                                            </div>
                                                            <div class="nk-tb-col"><span>Pref</span></div>

                                                            <div class="nk-tb-col"><span>&nbsp;</span></div>
                                                        </div>
                                                        <div class="nk-tb-item selectable border-bottom"
                                                            (click)="select(index)" [class]="{'selected':
                                                        selectedIndex === index }"
                                                            *ngFor="let instance of displayInstances; let index = index">
                                                            <div class="nk-tb-col pl-2">
                                                                <div class="user-card">
                                                                    <div class="user-name">
                                                                        <div class="fullname">{{
                                                                            instance?.user?.firstName |
                                                                            titlecase}} {{ instance?.user?.lastName |
                                                                            titlecase}}
                                                                        </div>
                                                                        <div class="username">
                                                                            {{ instance?.user?.username }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="nk-tb-col data-item-num-0">
                                                                {{ instance.enterprise }}
                                                            </div>

                                                            <div class="nk-tb-col">
                                                                {{ instance.createdOn | date: 'dd/MM/yyyy' }}
                                                            </div>

                                                            <div class="nk-tb-col data-item-num-0">
                                                                {{ formatAverageTime(instance.averageTime) }}
                                                            </div>

                                                            <div class="nk-tb-col data-item-num-0">
                                                                {{ instance.totalParticipants }}
                                                            </div>
                                                            <div class="nk-tb-col data-item-num-0">
                                                                {{ instance.averagePereference | number:'1.0-0' }}%
                                                            </div>
                                                            <ng-container
                                                                *ngIf="instance?.ownerCreated; else emptyActionContainer">
                                                                <div class="nk-tb-col d-flex justify-content-end"
                                                                    style="padding-right:0; padding-top: 10px;">
                                                                    <img src="../../assets/icons/icons8-return.svg"
                                                                        (click)="updateInstance(instance)" class="icn"
                                                                        data-toggle="tooltip" data-placement="left"
                                                                        title="Update instance">
                                                                    <img (click)="generateQR(instance.code)"
                                                                        data-toggle="tooltip" data-placement="left"
                                                                        title="Generate QR"
                                                                        src="../../assets/icons/icons8-qr-code.svg"
                                                                        class="icn cursor-pointer">
                                                                    <!-- <img src="../../assets/icons/icons8-menu-vertical.svg" class="icn"> -->
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #emptyActionContainer>
                                                                <div class="nk-tb-col data-item-num-0">&nbsp;</div>
                                                            </ng-template>


                                                        </div>
                                                    </div>
                                                    <div class="w-100 text-center my-5">
                                                        <div *ngIf="loadingInstances"
                                                            class="spinner-border spinner-border-lg" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                    <p *ngIf="!loadingInstances && displayInstances?.length < 0"
                                                        class="w-100  text-center text-dark">
                                                        No
                                                        games found</p>

                                                </div>
                                                <div class="col-xl-6 col-xxl-6 border-left">
                                                    <ng-container *ngIf="selectedInstance; else emptyInstanceStats">
                                                        <div class="card-title mt-1200 card-title-stats">
                                                            <h4 class="live-stats">Live Stats</h4>
                                                            <div class="badge">View Game</div>
                                                        </div>
                                                        <div class="divider"></div>
                                                        <div class="row">
                                                            <div class="col-12 col-md-12 mt-3">
                                                                <h6 class="mt-2">Sommelier</h6>
                                                                <div class="somm-info d-flex mt-2">
                                                                    <div class="user-picture">
                                                                        <img class="rounded-circle user-image"
                                                                            src="{{env.cdn}}profiles/{{selectedInstance.note.user?.picture}}">
                                                                    </div>
                                                                    <div class="d-flex flex-column"
                                                                        style="margin-left: 16px;">
                                                                        <span
                                                                            class="fw-bold fs-14px text-dark">{{selectedInstance.note.user?.fullName
                                                                            | titlecase}} <span><img
                                                                                    src="../../assets/icons/icons8-green-tick.svg"></span></span>
                                                                        <span class="fs-13px"
                                                                            *ngIf="selectedInstance.user?.displayCertificationLevel">
                                                                            {{selectedInstance.note.user?.displayCertificationLevel}}
                                                                        </span>
                                                                        <span class="fs-13px">
                                                                            {{selectedInstance.note.user?.email}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="view-game">
                                                                        <div class="badge">View</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12">
                                                                <hr>
                                                            </div>

                                                            <div class="col-12 mt-3">
                                                                <h6>Game Summary</h6>
                                                            </div>

                                                            <div class="w-100">
                                                                <div class="d-flex flex-column flex-sm-row w-100 mt-3">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="stats-box">
                                                                            <div class="title">
                                                                                {{
                                                                                formatAverageTime(selectedInstance.averageTime)
                                                                                }}
                                                                            </div>
                                                                            <div class="sub-title">Avg Duration</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 mt-md-0 col-md-4">
                                                                        <div class="stats-box">
                                                                            <div class="title">
                                                                                {{selectedInstance.totalParticipants}}
                                                                            </div>
                                                                            <div class="sub-title">Total Players</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 mt-md-0 col-md-4">
                                                                        <div class="stats-box">
                                                                            <div class="title">
                                                                                {{ selectedInstance.lastSeen |
                                                                                date:'MM-dd-yyyy' }}
                                                                            </div>
                                                                            <div class="sub-title">Last Played</div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <ng-container *ngIf="!isLoadingStats; else loadingStats">
                                                                <div class="col-12 mt-5">
                                                                    <h6>Rating Summary</h6>
                                                                </div>

                                                                <div class="d-flex w-100 mt-3">

                                                                    <div
                                                                        class="col-12 col-md-12 d-flex flex-column flex-sm-row">
                                                                        <div class="d-flex flex-column">
                                                                            <div class="d-flex align-items-center mb-1">
                                                                                <div class="pgb-title">
                                                                                    Top 10%
                                                                                </div>
                                                                                <div
                                                                                    class="ngb-progress-bar-container pt-1">
                                                                                    <ngb-progressbar
                                                                                        [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 100)}"
                                                                                        type="secondary"
                                                                                        [value]="getOptionPercentage('explicitPreference', '100')"
                                                                                        [max]="100">
                                                                                    </ngb-progressbar>
                                                                                </div>
                                                                                <div class="pgb-value">
                                                                                    {{
                                                                                    getOptionPercentage('explicitPreference',
                                                                                    '100') }}%
                                                                                </div>
                                                                            </div>
                                                                            <div class="d-flex align-items-center mb-1">
                                                                                <div class="pgb-title">
                                                                                    Top 25%
                                                                                </div>
                                                                                <div
                                                                                    class="ngb-progress-bar-container pt-1">
                                                                                    <ngb-progressbar
                                                                                        [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 75)}"
                                                                                        type="secondary"
                                                                                        [value]="getOptionPercentage('explicitPreference', '75')"
                                                                                        [max]="100">
                                                                                    </ngb-progressbar>
                                                                                </div>
                                                                                <div class="pgb-value">
                                                                                    {{
                                                                                    getOptionPercentage('explicitPreference',
                                                                                    '75') }}%
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="d-flex  align-items-center mb-1">
                                                                                <div class="pgb-title">
                                                                                    Average
                                                                                </div>
                                                                                <div
                                                                                    class="ngb-progress-bar-container pt-1">
                                                                                    <ngb-progressbar
                                                                                        [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 50)}"
                                                                                        type="secondary"
                                                                                        [value]="getOptionPercentage('explicitPreference', '50')"
                                                                                        [max]="100">
                                                                                    </ngb-progressbar>
                                                                                </div>
                                                                                <div class="pgb-value">
                                                                                    {{
                                                                                    getOptionPercentage('explicitPreference',
                                                                                    '50') }}%
                                                                                </div>
                                                                            </div>
                                                                            <div class="d-flex align-items-center mb-1">
                                                                                <div class="pgb-title">
                                                                                    Not for me
                                                                                </div>
                                                                                <div
                                                                                    class="ngb-progress-bar-container pt-1">
                                                                                    <ngb-progressbar
                                                                                        [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 25)}"
                                                                                        type="secondary"
                                                                                        [value]="getOptionPercentage('explicitPreference', '25')"
                                                                                        [max]="100">
                                                                                    </ngb-progressbar>
                                                                                </div>
                                                                                <div class="pgb-value">
                                                                                    {{
                                                                                    getOptionPercentage('explicitPreference',
                                                                                    '25') }}%
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div class="stats-box border-0 ml-0 ml-sm-3">
                                                                            <h4 class="rating-summary-stats-header">Avg
                                                                                Rating
                                                                            </h4>
                                                                            <div class="rating-summary-stats-title">
                                                                                {{getAverageRatingBySelecttion()}}%
                                                                            </div>
                                                                            <div class="sub-title">
                                                                                {{selectedWineStats.totalCount}} players
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 mt-5">
                                                                    <h6>Results By Category</h6>
                                                                </div>
                                                                <div class="col-12 mt-3">
                                                                    Jump to <button class="category"
                                                                        *ngFor="let f of flaverTypes"
                                                                        (click)="jumpTo(f)">{{f}}</button>
                                                                </div>
                                                                <div class="col-12 w-100 mt-5">
                                                                    <div class="col-12">

                                                                        <div class="flex pb-4" id="Aroma">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="flex-column">
                                                                                    <div
                                                                                        style="font-weight: bold; color: #1c2128;">
                                                                                        Aroma</div>
                                                                                    <div class="players-remaining">
                                                                                        {{
                                                                                        selectedWineStats.unAnsweredAromaPlayers
                                                                                        }}
                                                                                        remaining
                                                                                    </div>
                                                                                    <div *ngIf="remainingVisibleState.aroma"
                                                                                        @ngIfAnimation>
                                                                                        <ng-container *ngFor="let participant of
                                                                                    selectedWineStats.remainingAromaPlayers;
                                                                                    let first =
                                                                                    first">
                                                                                            <div @easeInOut
                                                                                                [ngClass]="{'mt-1' : !first }">
                                                                                                <img class="remaining-palyer"
                                                                                                    src="{{cdn}}profiles/{{participant.picture}}">

                                                                                                <span
                                                                                                    class="ml-1 player-name">{{
                                                                                                    participant.name |
                                                                                                    titlecase }}</span>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                    <span
                                                                                        *ngIf="selectedWineStats.unAnsweredAromaPlayers >0"
                                                                                        class="show-hide cursor-pointer fs-12px"
                                                                                        (click)="
                                                                                                remainingVisibleState.aroma=!remainingVisibleState.aroma">
                                                                                        {{ remainingVisibleState.aroma ?
                                                                                        'Hide' : 'Show' }}
                                                                                    </span>

                                                                                </div>
                                                                                <div
                                                                                    class="d-flex show-more align-self-center">
                                                                                    Show more
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                        <div class="d-flex flex-column col-12  pl-0">
                                                                            <div class="d-flex align-items-start mb-1"
                                                                                *ngFor="let option of getOptions('aroma')">
                                                                                <div class="pgb-detail">
                                                                                    <div class="pgb-title"
                                                                                        [ngClass]="{ 'text-success': isSommSelection('aroma', option)}">
                                                                                        {{ option | titlecase }}
                                                                                    </div>
                                                                                    <div class="pgb-sub-value"
                                                                                        [ngClass]="{ 'text-success': isSommSelection('aroma', option)}">
                                                                                        {{ getOptionPercentage('aroma',
                                                                                        option) }}%
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="ngb-progress-bar-container pt-1 w-100">
                                                                                    <ngb-progressbar
                                                                                        [ngClass]="{'somm-progressbar': isSommSelection('aroma', option)}"
                                                                                        [type]="isSommSelection('aroma', option) ? 'success' : 'dark'"
                                                                                        [value]="getOptionPercentage('aroma', option)"
                                                                                        [max]="100">
                                                                                    </ngb-progressbar>
                                                                                    <div class="people" *ngFor="let participant of
                                                                                    getOptionUsers('aroma', option)">
                                                                                        <img
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">
                                                                                        <span>{{participant?.fullName}}</span>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 w-100 mt-3">
                                                                    <div class="col-12">

                                                                        <div class="flex" id="Body">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="d-flex"
                                                                                    style="font-weight: bold; color: #1c2128;">
                                                                                    Body
                                                                                </div>
                                                                                <button class="d-flex arrow-updown "
                                                                                    (click)="body.toggle()"
                                                                                    [attr.aria-expanded]="!isCollapsed.body">
                                                                                    <img
                                                                                        src="../../assets/icons/icons8-arrow-down.svg">
                                                                                </button>
                                                                            </div>


                                                                            <div class="players-remaining">
                                                                                {{
                                                                                selectedWineStats.unAnsweredBodyPlayers
                                                                                }}
                                                                                remaining
                                                                            </div>
                                                                            <div *ngIf="remainingVisibleState.body"
                                                                                @ngIfAnimation>
                                                                                <ng-container *ngFor="let participant of selectedWineStats.remainingBodyPlayers;
                                                                        let first =first">
                                                                                    <div @easeInOut
                                                                                        [ngClass]="{'mt-1' : !first }">
                                                                                        <img class="remaining-palyer"
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">

                                                                                        <span
                                                                                            class="ml-1 player-name">{{
                                                                                            participant.name |
                                                                                            titlecase }}</span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <span
                                                                                *ngIf="selectedWineStats.unAnsweredBodyPlayers >0"
                                                                                class="show-hide cursor-pointer fs-12px"
                                                                                (click)="
                                                                                    remainingVisibleState.body=!remainingVisibleState.body">
                                                                                {{ remainingVisibleState.body ?
                                                                                'Hide' : 'Show' }}
                                                                            </span>

                                                                        </div>
                                                                        <div #body="ngbCollapse"
                                                                            [(ngbCollapse)]="isCollapsed.body">
                                                                            <div
                                                                                class="d-flex flex-column col-12  pl-0">
                                                                                <div class="d-flex align-items-start mb-1"
                                                                                    *ngFor="let option of getOptions('body')">
                                                                                    <div class="pgb-detail">
                                                                                        <div class="pgb-title"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('body', option)}">
                                                                                            {{ option | titlecase }}
                                                                                        </div>
                                                                                        <div class="pgb-sub-value"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('body', option)}">
                                                                                            {{
                                                                                            getOptionPercentage('body',
                                                                                            option) }}%
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="ngb-progress-bar-container pt-1 w-100">
                                                                                        <ngb-progressbar [ngClass]="{'somm-progressbar':
                                                                                    isSommSelection('body', option)}"
                                                                                            [type]="isSommSelection('body',
                                                                                    option) ? 'success' : 'dark'"
                                                                                            [value]="getOptionPercentage('body',
                                                                                    option)" [max]="100">
                                                                                        </ngb-progressbar>
                                                                                        <div class="people"
                                                                                            *ngFor="let participant of
                                                                                        getOptionUsers('body', option)">
                                                                                            <img
                                                                                                src="{{cdn}}profiles/{{participant.picture}}">
                                                                                            <span>{{participant?.fullName}}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 w-100 mt-3">
                                                                    <div class="col-12">

                                                                        <div class="flex" id="Acidity">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="d-flex"
                                                                                    style="font-weight: bold; color: #1c2128;">
                                                                                    Acidity
                                                                                </div>
                                                                                <button class="d-flex arrow-updown"
                                                                                    (click)="acidity.toggle()"
                                                                                    [attr.aria-expanded]="!isCollapsed.acidity">
                                                                                    <img
                                                                                        src="../../assets/icons/icons8-arrow-down.svg">
                                                                                </button>
                                                                            </div>

                                                                            <div class="players-remaining">
                                                                                {{
                                                                                selectedWineStats.unAnsweredAcidityPlayers
                                                                                }}
                                                                                remaining
                                                                            </div>
                                                                            <div *ngIf="remainingVisibleState.acidity"
                                                                                @ngIfAnimation>
                                                                                <ng-container *ngFor="let participant of selectedWineStats.remainingAcidityPlayers;
                                                                    let first =first">
                                                                                    <div @easeInOut
                                                                                        [ngClass]="{'mt-1' : !first }">
                                                                                        <img class="remaining-palyer"
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">

                                                                                        <span
                                                                                            class="ml-1 player-name">{{
                                                                                            participant.name |
                                                                                            titlecase }}</span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <span
                                                                                *ngIf="selectedWineStats.unAnsweredAcidityPlayers >0"
                                                                                class="show-hide cursor-pointer fs-12px"
                                                                                (click)="
                                                                                remainingVisibleState.acidity=!remainingVisibleState.acidity">
                                                                                {{ remainingVisibleState.acidity ?
                                                                                'Hide' : 'Show' }}
                                                                            </span>

                                                                        </div>
                                                                        <div #acidity="ngbCollapse"
                                                                            [(ngbCollapse)]="isCollapsed.acidity">
                                                                            <div
                                                                                class="d-flex flex-column col-12  pl-0">
                                                                                <div class="d-flex align-items-start mb-1"
                                                                                    *ngFor="let option of getOptions('acidity')">
                                                                                    <div class="pgb-detail">
                                                                                        <div class="pgb-title"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('acidity',
                                                                                    option)}">
                                                                                            {{ option | titlecase }}
                                                                                        </div>
                                                                                        <div class="pgb-sub-value"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('acidity',
                                                                                    option)}">
                                                                                            {{
                                                                                            getOptionPercentage('acidity',
                                                                                            option) }}%
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="ngb-progress-bar-container pt-1 w-100">
                                                                                        <ngb-progressbar
                                                                                            [ngClass]="{'somm-progressbar':
                                                                                    isSommSelection('acidity', option)}" [type]="isSommSelection('acidity',
                                                                                    option) ? 'success' : 'dark'"
                                                                                            [value]="getOptionPercentage('acidity',
                                                                                    option)" [max]="100">
                                                                                        </ngb-progressbar>
                                                                                        <div class="people" *ngFor="let participant of
                                                                                        getOptionUsers('acidity',
                                                                                        option)">
                                                                                            <img
                                                                                                src="{{cdn}}profiles/{{participant.picture}}">
                                                                                            <span>{{participant?.fullName}}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 w-100 mt-3">
                                                                    <div class="col-12">

                                                                        <div class="flex" id="Sugar">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="d-flex"
                                                                                    style="font-weight: bold; color: #1c2128;">
                                                                                    Sugar
                                                                                </div>
                                                                                <button class="d-flex arrow-updown"
                                                                                    (click)="sugar.toggle()"
                                                                                    [attr.aria-expanded]="!isCollapsed.sugar">
                                                                                    <img
                                                                                        src="../../assets/icons/icons8-arrow-down.svg">
                                                                                </button>
                                                                            </div>

                                                                            <div class="players-remaining">
                                                                                {{
                                                                                selectedWineStats.unAnsweredSugarPlayers
                                                                                }}
                                                                                remaining
                                                                            </div>
                                                                            <div *ngIf="remainingVisibleState.sugar"
                                                                                @ngIfAnimation>
                                                                                <ng-container *ngFor="let participant of selectedWineStats.remainingSugarPlayers;
                                                                    let first =first">
                                                                                    <div @easeInOut
                                                                                        [ngClass]="{'mt-1' : !first }">
                                                                                        <img class="remaining-palyer"
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">

                                                                                        <span
                                                                                            class="ml-1 player-name">{{
                                                                                            participant.name |
                                                                                            titlecase }}</span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <span
                                                                                *ngIf="selectedWineStats.unAnsweredSugarPlayers >0"
                                                                                class="show-hide cursor-pointer fs-12px"
                                                                                (click)="
                                                                                remainingVisibleState.sugar=!remainingVisibleState.sugar">
                                                                                {{ remainingVisibleState.sugar ?
                                                                                'Hide' : 'Show' }}
                                                                            </span>
                                                                        </div>
                                                                        <div #sugar="ngbCollapse"
                                                                            [(ngbCollapse)]="isCollapsed.sugar">
                                                                            <div
                                                                                class="d-flex flex-column col-12  pl-0">
                                                                                <div class="d-flex align-items-start mb-1"
                                                                                    *ngFor="let option of
                                                                              getOptions('sugar')">
                                                                                    <div class="pgb-detail">
                                                                                        <div class="pgb-title"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('sugar',
                                                                                    option)}">
                                                                                            {{ option | titlecase }}
                                                                                        </div>
                                                                                        <div class="pgb-sub-value"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('sugar',
                                                                                    option)}">
                                                                                            {{
                                                                                            getOptionPercentage('sugar',
                                                                                            option) }}%
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="ngb-progress-bar-container pt-1 w-100">
                                                                                        <ngb-progressbar [ngClass]="{'somm-progressbar':
                                                                                    isSommSelection('sugar', option)}"
                                                                                            [type]="isSommSelection('sugar',
                                                                                    option) ? 'success' : 'dark'"
                                                                                            [value]="getOptionPercentage('sugar',
                                                                                    option)" [max]="100">
                                                                                        </ngb-progressbar>
                                                                                        <div class="people" *ngFor="let participant of
                                                                                        getOptionUsers('sugar',
                                                                                        option)">
                                                                                            <img
                                                                                                src="{{cdn}}profiles/{{participant.picture}}">
                                                                                            <span>{{participant?.fullName}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 w-100 mt-3"
                                                                    *ngIf="selectedInstance.note.oak">
                                                                    <div class="col-12">

                                                                        <div class="flex" id="Oak">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="d-flex"
                                                                                    style="font-weight: bold; color: #1c2128;">
                                                                                    Oak
                                                                                </div>
                                                                                <button class="d-flex arrow-updown"
                                                                                    (click)="oak.toggle()"
                                                                                    [attr.aria-expanded]="!isCollapsed.oak">
                                                                                    <img
                                                                                        src="../../assets/icons/icons8-arrow-down.svg">
                                                                                </button>
                                                                            </div>

                                                                            <div class="players-remaining">
                                                                                {{
                                                                                selectedWineStats.unAnsweredOakPlayers
                                                                                }}
                                                                                remaining
                                                                            </div>
                                                                            <div *ngIf="remainingVisibleState.oak"
                                                                                @ngIfAnimation>
                                                                                <ng-container *ngFor="let participant of selectedWineStats.remainingOakPlayers;
                                                                    let first =first">
                                                                                    <div @easeInOut
                                                                                        [ngClass]="{'mt-1' : !first }">
                                                                                        <img class="remaining-palyer"
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">

                                                                                        <span
                                                                                            class="ml-1 player-name">{{
                                                                                            participant.name |
                                                                                            titlecase }}</span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <span
                                                                                *ngIf="selectedWineStats.unAnsweredOakPlayers >0"
                                                                                class="show-hide cursor-pointer fs-12px"
                                                                                (click)="
                                                                                remainingVisibleState.oak=!remainingVisibleState.oak">
                                                                                {{ remainingVisibleState.oak ?
                                                                                'Hide' : 'Show' }}
                                                                            </span>
                                                                        </div>
                                                                        <div #oak="ngbCollapse"
                                                                            [(ngbCollapse)]="isCollapsed.oak">
                                                                            <div
                                                                                class="d-flex flex-column col-12  pl-0">
                                                                                <div class="d-flex align-items-start mb-1"
                                                                                    *ngFor="let option of
                                                                              getOptions('oak')">
                                                                                    <div class="pgb-detail">
                                                                                        <div class="pgb-title"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('oak',
                                                                                    option)}">
                                                                                            {{ option | titlecase }}
                                                                                        </div>
                                                                                        <div class="pgb-sub-value"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('oak',
                                                                                    option)}">
                                                                                            {{
                                                                                            getOptionPercentage('oak',
                                                                                            option) }}%
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="ngb-progress-bar-container pt-1 w-100">
                                                                                        <ngb-progressbar [ngClass]="{'somm-progressbar':
                                                                                    isSommSelection('oak', option)}"
                                                                                            [type]="isSommSelection('oak',
                                                                                    option) ? 'success' : 'dark'"
                                                                                            [value]="getOptionPercentage('oak',
                                                                                    option)" [max]="100">
                                                                                        </ngb-progressbar>
                                                                                        <div class="people" *ngFor="let participant of
                                                                                        getOptionUsers('oak',
                                                                                        option)">
                                                                                            <img
                                                                                                src="{{cdn}}profiles/{{participant.picture}}">
                                                                                            <span>{{participant?.fullName}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 w-100 mt-3"
                                                                    *ngIf="selectedInstance.note.tannins">
                                                                    <div class="col-12">

                                                                        <div class="flex" id="Tannins">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="d-flex"
                                                                                    style="font-weight: bold; color: #1c2128;">
                                                                                    Tannins
                                                                                </div>
                                                                                <button class="d-flex arrow-updown"
                                                                                    (click)="tannins.toggle()"
                                                                                    [attr.aria-expanded]="!isCollapsed.tannins">
                                                                                    <img
                                                                                        src="../../assets/icons/icons8-arrow-down.svg">
                                                                                </button>
                                                                            </div>


                                                                            <div class="players-remaining">
                                                                                {{
                                                                                selectedWineStats.unAnsweredTanninsPlayers
                                                                                }}
                                                                                remaining
                                                                            </div>
                                                                            <div *ngIf="remainingVisibleState.tannins"
                                                                                @ngIfAnimation>
                                                                                <ng-container *ngFor="let participant of selectedWineStats.remainingTaninsPlayers;
                                                                    let first =first">
                                                                                    <div @easeInOut
                                                                                        [ngClass]="{'mt-1' : !first }">
                                                                                        <img class="remaining-palyer"
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">

                                                                                        <span
                                                                                            class="ml-1 player-name">{{
                                                                                            participant.name |
                                                                                            titlecase }}</span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <span
                                                                                *ngIf="selectedWineStats.unAnsweredTanninsPlayers >0"
                                                                                class="show-hide cursor-pointer fs-12px"
                                                                                (click)="
                                                                                remainingVisibleState.tannins=!remainingVisibleState.tannins">
                                                                                {{ remainingVisibleState.tannins ?
                                                                                'Hide' : 'Show' }}
                                                                            </span>

                                                                        </div>

                                                                        <div #tannins="ngbCollapse"
                                                                            [(ngbCollapse)]="isCollapsed.tannins">
                                                                            <div
                                                                                class="d-flex flex-column col-12  pl-0">
                                                                                <div class="d-flex align-items-start mb-1"
                                                                                    *ngFor="let option of
                                                                              getOptions('tannins')">
                                                                                    <div class="pgb-detail">
                                                                                        <div class="pgb-title"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('tannins',
                                                                                    option)}">
                                                                                            {{ option | titlecase }}
                                                                                        </div>
                                                                                        <div class="pgb-sub-value"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('tannins',
                                                                                    option)}">
                                                                                            {{
                                                                                            getOptionPercentage('tannins',
                                                                                            option) }}%
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="ngb-progress-bar-container pt-1 w-100">
                                                                                        <ngb-progressbar
                                                                                            [ngClass]="{'somm-progressbar':
                                                                                    isSommSelection('tannins', option)}" [type]="isSommSelection('tannins',
                                                                                    option) ? 'success' : 'dark'"
                                                                                            [value]="getOptionPercentage('tannins',
                                                                                    option)" [max]="100">
                                                                                        </ngb-progressbar>
                                                                                        <div class="people" *ngFor="let participant of
                                                                                        getOptionUsers('tannins',
                                                                                        option)">
                                                                                            <img
                                                                                                src="{{cdn}}profiles/{{participant.picture}}">
                                                                                            <span>{{participant?.fullName}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 w-100 mt-3">
                                                                    <div class="col-12">
                                                                        <div class="flex" id="Flavor">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="d-flex"
                                                                                    style="font-weight: bold; color: #1c2128;">
                                                                                    Flavor
                                                                                </div>
                                                                                <button class="d-flex arrow-updown"
                                                                                    (click)="flavor.toggle()"
                                                                                    [attr.aria-expanded]="!isCollapsed.flavor">
                                                                                    <img
                                                                                        src="../../assets/icons/icons8-arrow-down.svg">
                                                                                </button>
                                                                            </div>

                                                                            <div class="players-remaining">
                                                                                {{
                                                                                selectedWineStats.unAnsweredFlavorPlayers
                                                                                }}
                                                                                remaining
                                                                            </div>
                                                                            <div *ngIf="remainingVisibleState.flavor"
                                                                                @ngIfAnimation>
                                                                                <ng-container *ngFor="let participant of selectedWineStats.remainingFlavorPlayers;
                                                                    let first =first">
                                                                                    <div @easeInOut
                                                                                        [ngClass]="{'mt-1' : !first }">
                                                                                        <img class="remaining-palyer"
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">

                                                                                        <span
                                                                                            class="ml-1 player-name">{{
                                                                                            participant.name |
                                                                                            titlecase }}</span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <span
                                                                                *ngIf="selectedWineStats.unAnsweredFlavorPlayers >0"
                                                                                class="show-hide cursor-pointer fs-12px"
                                                                                (click)="
                                                                                remainingVisibleState.flavor=!remainingVisibleState.flavor">
                                                                                {{ remainingVisibleState.flavor ?
                                                                                'Hide' : 'Show' }}
                                                                            </span>
                                                                        </div>
                                                                        <div #flavor="ngbCollapse"
                                                                            [(ngbCollapse)]="isCollapsed.flavor">
                                                                            <div
                                                                                class="d-flex flex-column col-12  pl-0">
                                                                                <div class="d-flex align-items-start mb-1"
                                                                                    *ngFor="let option of getOptions('flavor')">

                                                                                    <div class="pgb-detail">
                                                                                        <div class="pgb-title"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('flavor',
                                                                                    option)}">
                                                                                            {{ option | titlecase }}
                                                                                        </div>
                                                                                        <div class="pgb-sub-value"
                                                                                            [ngClass]="{ 'text-success':
                                                                                    isSommSelection('flavor',
                                                                                    option)}">
                                                                                            {{
                                                                                            getOptionPercentage('flavor',
                                                                                            option) }}%
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="ngb-progress-bar-container pt-1 w-100">
                                                                                        <ngb-progressbar [ngClass]="{'somm-progressbar':
                                                                                    isSommSelection('flavor', option)}"
                                                                                            [type]="isSommSelection('flavor',
                                                                                    option) ? 'success' : 'dark'"
                                                                                            [value]="getOptionPercentage('flavor',
                                                                                    option)" [max]="100">
                                                                                        </ngb-progressbar>
                                                                                        <div class="people" *ngFor="let participant of
                                                                                        getOptionUsers('flavor',
                                                                                        option)">
                                                                                            <img
                                                                                                src="{{cdn}}profiles/{{participant.picture}}">
                                                                                            <span>{{participant?.fullName}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="pgb-value">
                                                                                        {{ getOptionPercentage('flavor',
                                                                                        option)
                                                                                        }}%
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 w-100 mt-3"
                                                                    *ngIf="selectedWineStats.winePriceStats?.totalGuesses > 0">
                                                                    <div class="col-12">
                                                                        <div class="flex" id="Guess the price">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div class="d-flex"
                                                                                    style="font-weight: bold; color: #1c2128;">
                                                                                    Guess the price
                                                                                </div>
                                                                                <button class="d-flex arrow-updown"
                                                                                    (click)="guessPrice.toggle()"
                                                                                    [attr.aria-expanded]="!isCollapsed.guessPrice">
                                                                                    <img
                                                                                        src="../../assets/icons/icons8-arrow-down.svg">
                                                                                </button>
                                                                            </div>

                                                                            <div class="players-remaining">
                                                                                {{
                                                                                selectedWineStats.winePriceStats.remainingGuesses
                                                                                }}
                                                                                remaining
                                                                            </div>
                                                                            <div *ngIf="remainingVisibleState.totalGuess"
                                                                                @ngIfAnimation>
                                                                                <ng-container *ngFor="let participant of selectedWineStats.winePriceStats.remainingUsers;
                                                                    let first =first">
                                                                                    <div @easeInOut
                                                                                        [ngClass]="{'mt-1' : !first }">
                                                                                        <img class="remaining-palyer"
                                                                                            src="{{cdn}}profiles/{{participant.picture}}">

                                                                                        <span
                                                                                            class="ml-1 player-name">{{
                                                                                            participant.name |
                                                                                            titlecase }}</span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <span
                                                                                *ngIf="selectedWineStats.remainingGuesses >0"
                                                                                class="show-hide cursor-pointer fs-12px"
                                                                                (click)="
                                                                                remainingVisibleState.totalGuess=!remainingVisibleState.totalGuess">
                                                                                {{ remainingVisibleState.totalGuess ?
                                                                                'Hide' : 'Show' }}
                                                                            </span>
                                                                        </div>
                                                                        <div #guessPrice="ngbCollapse"
                                                                            [(ngbCollapse)]="isCollapsed.guessPrice">
                                                                            <div
                                                                                class="d-flex flex-column col-12  pl-0">
                                                                                <div class="d-flex align-items-start mb-1"
                                                                                    *ngFor="let option of
                                                                                 selectedWineStats.winePriceStats.priceStats">
                                                                                    <div class="pgb-detail">
                                                                                        <div class="pgb-title">
                                                                                            ${{ option.priceUSD }}
                                                                                        </div>
                                                                                        <div class="pgb-sub-value text-danger"
                                                                                            [ngClass]="{'text-success':
                                                                                    option.isPositiveGuess}">
                                                                                            {{ option.totalGuess }}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="ngb-progress-bar-container pt-1 w-100">
                                                                                        <ngb-progressbar
                                                                                            [style.width.%]="option.guessedPercentage"
                                                                                            type="dark" [max]="100">
                                                                                        </ngb-progressbar>
                                                                                        <div class="people"
                                                                                            *ngFor="let participant of option.users">
                                                                                            <img
                                                                                                src="{{cdn}}profiles/{{participant.picture}}">
                                                                                            <span>{{participant?.fullName}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="pgb-value">

                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-template #loadingStats>
                                                                <div class="w-100 text-center my-5">
                                                                    <div class="spinner-border spinner-border-lg"
                                                                        role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #emptyInstanceStats>
                                                        <div
                                                            class="text-center h-100 d-flex justify-content-center align-items-center">
                                                            <p>Select an instance</p>
                                                        </div>
                                                    </ng-template>


                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row g-gs" *ngIf="selectedTab == 'detail'">
                                <div class="col-12">
                                    <div class="card card-bordered card-full">
                                        <div class="p-3 mb-0">
                                            <h5>Details</h5>
                                            <div>All the data we've collected about this wine.</div>
                                        </div>
                                        <div class="card-inner">
                                            <div class="row">
                                                <div class="col-12 col-sm-6 col-lg-3">
                                                    <div class="card card-bordered card-full">
                                                        <div class="card-header">
                                                            <h5> Somm Notes</h5>
                                                        </div>
                                                        <div class="card-inner">
                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    Overall
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Personal
                                                                            Rating
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{ getPercentageText(template.note?.rating)
                                                                            }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Notes</p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.note.description }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    Quality
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Variety
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{
                                                                            getPercentageText(template.note?.qualityOfVariety)
                                                                            }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Appellation
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{
                                                                            getPercentageText(template.note?.qualityOfAppellation)
                                                                            }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Value for
                                                                            price
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{
                                                                            getPercentageText(template.note?.valueForPrice)
                                                                            }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    Uniqueness
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Variety
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.note.variety ?
                                                                            template.note.variety : 'Unknown' }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Appellation
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.note.appellation ?
                                                                            template.note.appellation : 'Unknown' }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-12 col-sm-6 col-lg-3 mt-2 mt-md-0">
                                                    <div class="card card-bordered card-full">
                                                        <div class="card-header">
                                                            <h5> General </h5>
                                                        </div>
                                                        <div class="card-inner">
                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    General
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Name
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.wine.name }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Producer</p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.wine.producer }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">Color
                                                                                </p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.color | titlecase
                                                                                    }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">

                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">
                                                                                    Variety</p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.variety }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="d-flex justify-content-between">
                                                                        <div class="wine-section-content">
                                                                            <p class="wine-section-label">Vintage</p>
                                                                            <p class="wine-section-text">
                                                                                {{ template.wine.vintage > 0 ?
                                                                                template.wine.vintage : 'NV'}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="wine-section-content">
                                                                            <p class="wine-section-label">Sparkling</p>
                                                                            <p class="wine-section-text">
                                                                                {{ template.wine.sparkling ? 'Yes' :
                                                                                'No'}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    Grapes Used
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Variety
                                                                        </p>

                                                                        <ul>
                                                                            <li
                                                                                *ngFor="let grape of template.wine.grapes">
                                                                                {{ grape.name }} {{ grape.perc}}%
                                                                            </li>
                                                                        </ul>

                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    Region Of Origin
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Country
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.wine.country }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">
                                                                                    Appellation
                                                                                </p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.appellation }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">Region</p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.region }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    Attributes
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <ul class="list mt-1">
                                                                        <li class="pb-0" *ngFor="let attribute of getActiveAttributes(template.wine.attributes)
                                                                             ">
                                                                            {{ attribute.name }}
                                                                        </li>
                                                                    </ul>
                                                                    <p
                                                                        *ngIf="getActiveAttributes(template.wine.attributes).length < 1">
                                                                        Unknown
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-lg-3 mt-2 mt-lg-0">
                                                    <div class="card card-bordered card-full">
                                                        <div class="card-header">
                                                            <h5> Winemaking</h5>
                                                        </div>
                                                        <div class="card-inner">
                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading">
                                                                    Technical Data
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content"
                                                                        *ngIf="template.wine.phValue">
                                                                        <p class="wine-section-label">pH Value
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.wine.phValue }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-6"
                                                                            *ngIf="template.wine.alcoholPerc">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">Alcohol
                                                                                    Percentage
                                                                                </p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.alcoholPerc }}%
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6"
                                                                            *ngIf="template.wine.taValue">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">TA Value
                                                                                </p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.taValue }}%
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div class="wine-section-content"
                                                                        *ngIf="template.wine.brix">
                                                                        <p class="wine-section-label">
                                                                            Brix
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            {{ template.wine.brix }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-6"
                                                                            *ngIf="template.wine.sugarQuantity">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">
                                                                                    Sugar Quantity
                                                                                </p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.sugarQuantity }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6"
                                                                            *ngIf="template.wine.sugarPerc">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">Sugar
                                                                                    Percentage</p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.sugarPerc }}%
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail"
                                                                *ngIf="template.wine?.barrelTime || template.wine?.barrelMaterials.length">
                                                                <div class="wine-section-heading">
                                                                    Aging
                                                                </div>

                                                                <div class="wine-section-body">
                                                                    <div class="row">
                                                                        <div class="col-6"
                                                                            *ngIf="template.wine?.barrelTime">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">
                                                                                    Barrel Time
                                                                                </p>
                                                                                <p class="wine-section-text">
                                                                                    {{ template.wine.barrelTime }}
                                                                                    months
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6"
                                                                            *ngIf="template.wine?.barrelMaterials.length">
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-label">Barrel
                                                                                    Materials
                                                                                </p>
                                                                                <ul>
                                                                                    <li *ngFor="let material of
                                                                                 template.wine.barrelMaterials">
                                                                                        {{ material }}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail">
                                                                <div class="wine-section-heading"
                                                                    *ngIf="template.wine?.vineyards.length ||template.wine?.harvestDates.length">
                                                                    Viticulture
                                                                </div>

                                                                <div class="wine-section-content"
                                                                    *ngIf="template.wine?.vineyards.length">
                                                                    <p class="wine-section-label">
                                                                        Vineyards
                                                                    </p>
                                                                    <ul class="list mt-1">
                                                                        <li class="pb-0"
                                                                            *ngIf="!template.wine.vineyards.length">
                                                                            Unknown</li>
                                                                        <li class="pb-0" *ngFor="let vineyard of
                                                                        template.wine.vineyards">
                                                                            {{ vineyard }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="wine-section-content"
                                                                    *ngIf="template.wine?.harvestDates.length">
                                                                    <p class="wine-section-label">
                                                                        Harvest Date
                                                                    </p>
                                                                    <div class="wine-section-text">
                                                                        <p
                                                                            *ngFor="let dates of template.wine?.harvestDates">

                                                                        <div class="wine-section-content">
                                                                            <p class="wine-section-label">
                                                                                Harvest Date
                                                                            </p>
                                                                            <div class="wine-section-text">
                                                                                <p
                                                                                    *ngFor="let dates of template.wine?.harvestDates">

                                                                                    {{ dates.dateFrom | date: 'MM/dd' }}
                                                                                    -
                                                                                    {{ dates.dateTo | date: 'MM/dd' }}


                                                                                </p>

                                                                                <p *ngIf="!template.wine?.harvestDates">
                                                                                    Unknown
                                                                                </p>

                                                                                <!-- <span *ngIf="template.wine?.harvestDates">
                                                                            {{ template.wine.harvestDates }}
                                                                        </span>
                                                                        <span *ngIf="!template.wine?.harvestDates">
                                                                            Unknown
                                                                        </span> -->
                                                                                <!-- {{ template.wine.harvestDates ? template.wine.harvestDates : 'Unknown' }} -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="wine-section-detail"
                                                                    *ngIf="template.wine.vintners.length">
                                                                    <div class="wine-section-heading">
                                                                        Vintner
                                                                    </div>
                                                                    <div class="wine-section-body">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                <div class="wine-section-detail pt-1">
                                                                                    <div class="wine-section-label">
                                                                                        Winemaker
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <div class="wine-section-detail pt-1">
                                                                                    <div class="wine-section-label">
                                                                                        Title
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row" *ngFor="let vintner of
                                                                                 template.wine.vintners; let first =
                                                                                 first">

                                                                            <div class="col-6">
                                                                                <div class="wine-section-content">
                                                                                    <p class="wine-section-text">
                                                                                        {{ vintner.name }}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="wine-section-content">
                                                                                <p class="wine-section-text">
                                                                                    {{ vintner.title ? vintner.title :
                                                                                    'Unknown' }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="wine-section-detail"
                                                                    *ngIf="template.wine.wineBottles.length">
                                                                    <div class="wine-section-heading">
                                                                        Bottles
                                                                    </div>
                                                                    <div class="wine-section-body">
                                                                        <div class="row">
                                                                            <div class="col-6 pr-0">
                                                                                <div
                                                                                    class="wine-section-detail pt-1 pb-1">
                                                                                    <div class="wine-section-label">
                                                                                        Bottle Size
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6 pr-0">
                                                                                <div
                                                                                    class="wine-section-detail pt-1 pb-1">
                                                                                    <div class="wine-section-label">
                                                                                        Amount Produced
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row"
                                                                            *ngFor="let bottle of template.wine.wineBottles">
                                                                            <div class="col-6 pr-0">
                                                                                <p class="wine-section-text">
                                                                                    {{ bottle.size }}
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-6 pr-0">
                                                                                <p class="wine-section-text">
                                                                                    {{ bottle.qtyProduced }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-12 col-sm-6 col-lg-3 mt-2 mt-lg-0">
                                                    <div class="card card-bordered card-full mt-2 mt-md-0">
                                                        <div class="card-header">
                                                            <h5> Additional</h5>
                                                        </div>
                                                        <div class="card-inner">
                                                            <div class="wine-section-detail"
                                                                *ngIf="template.wine.description">
                                                                <div class="wine-section-heading">
                                                                    Notes
                                                                </div>
                                                                <div class="wine-section-body"
                                                                    *ngIf="template.wine.description">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Description
                                                                        </p>
                                                                        <p class="wine-section-text"
                                                                            [innerHtml]="template.wine.description">
                                                                        </p>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail"
                                                                *ngIf="template.wine?.foodPairing.length">
                                                                <div class="wine-section-heading">
                                                                    Food Pairing
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <ng-container
                                                                        *ngIf="template.wine.foodPairing; else emptyFoodPairing">
                                                                        <ul class="list mt-1">
                                                                            <li class="pb-0" *ngFor="let foodPair of
                                                                             template.wine.foodPairing">
                                                                                {{ foodPair }}
                                                                            </li>
                                                                        </ul>
                                                                    </ng-container>
                                                                    <ng-template #emptyFoodPairing>
                                                                        <p class="wine-section-text">
                                                                            Unknown
                                                                        </p>
                                                                    </ng-template>

                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail"
                                                                *ngIf="template.wine.importers.length||template.wine.distributors.length ||template.wine.averageValueUSD">
                                                                <div class="wine-section-heading">
                                                                    Economics
                                                                </div>
                                                                <div class="wine-section-body">
                                                                    <div class="wine-section-content"
                                                                        *ngIf="template.wine.importers.length">
                                                                        <p class="wine-section-label">Importers
                                                                        </p>
                                                                        <ng-container *ngIf="template.wine.importers; else
                                                                         emptyImporters">
                                                                            <ul class="list mt-1">
                                                                                <li class="pb-0" *ngFor="let importer of
                                                                             template.wine.importers">
                                                                                    {{ importer }}
                                                                                </li>
                                                                            </ul>
                                                                        </ng-container>
                                                                        <ng-template #emptyImporters>
                                                                            <p class="wine-section-text">
                                                                                Unknown
                                                                            </p>
                                                                        </ng-template>
                                                                    </div>

                                                                    <div class="wine-section-content"
                                                                        *ngIf="template.wine.distributors.length">
                                                                        <p class="wine-section-label">Distributors
                                                                        </p>
                                                                        <ng-container *ngIf="template.wine.distributors; else
                                                                         emptyDistributors">
                                                                            <ul class="list mt-1">
                                                                                <li class="pb-0" *ngFor="let distributor of
                                                                             template.wine.distributors">
                                                                                    {{ distributor }}
                                                                                </li>
                                                                            </ul>
                                                                        </ng-container>
                                                                        <ng-template #emptyDistributors>
                                                                            <p class="wine-section-text">
                                                                                Unknown
                                                                            </p>
                                                                        </ng-template>
                                                                    </div>

                                                                    <div class="wine-section-content"
                                                                        *ngIf="template.wine.averageValueUSD">
                                                                        <p class="wine-section-label">Retail Price
                                                                            (USD)
                                                                        </p>
                                                                        <p class="wine-section-text">
                                                                            ${{template.wine.averageValueUSD}}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="wine-section-detail"
                                                                *ngIf="template.buyLink||template.wine?.link">
                                                                <div class="wine-section-heading">
                                                                    Links

                                                                </div>
                                                                <div class="wine-section-body" *ngIf="template.buyLink">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Buy It Now
                                                                        </p>
                                                                        <a [href]="template.buyLink" target="_blank"
                                                                            class="wine-section-text text-primary">
                                                                            {{ getHostName(template.buyLink) }}
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="wine-section-body"
                                                                    *ngIf="template.wine?.link">
                                                                    <div class="wine-section-content">
                                                                        <p class="wine-section-label">Wine-Searcher
                                                                        </p>
                                                                        <a [href]="template.wine?.link" target="_blank"
                                                                            class="wine-section-text text-primary">
                                                                            {{getHostName(template.wine?.link) }}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-not-found *ngIf="!template"></app-not-found>
            </div>
        </ng-container>
        <ng-template #showLoadingSection>
            <div class="text-center loader">
                <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>
            </div>
        </ng-template>
    </div>
</app-shell-light>
export class WineFilter {
  companyId: string;
  durationType: number;
  producers: string[];
  varietals: string[];
  vintageFrom?: number;
  vintageTo?: number;
  regions: string[];
  somms: string[];
  ratings: number;
  sortBy: number;
  isSortByAscending: boolean;
  page: number;
  count: number;
  state: string;
  color: string;
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, distinct, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnterpriseSettingsUpdateComponent } from '../enterprise-settings-update/enterprise-settings-update.component';
import { ProfileUpdateComponent } from '../profile-update/profile-update.component';
import { AuthenticationService } from '../_services/authentication.service';
import { GeoService } from '../_services/geo.service';
import { ProfileService } from '../_services/profile.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  selectedTab = 'about';
  env = environment;
  pageSize = 8;

  loading = false;
  enterprisesLoading = false; // Enterprise
  templateLoading = false;

  enterprises: any;
  templates: any;
  user: any;

  aboutSectionPage = 1;
  aboutSectionWines = [];

  gameSectionWines: any;
  gameSectionPage = 1;

  filterWineColorOptions = [];
  filterRegionOptions = [];

  appliedWineColors = [];
  appliedRegions = [];

  sortByReleaseDate = true;

  constructor(
    private profileService: ProfileService,
    private userService: UserService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.enterprisesLoading = true;
    this.templateLoading = true;

    this.templates = [];
    this.enterprises = [];

    this.userService.get()
      .pipe(finalize(() => this.loading = false))
      .subscribe((d) => {
        this.user = d.data;
      });

    this.profileService.getUserEnterprises()
      .pipe(finalize(() => this.enterprisesLoading = false))
      .subscribe((d) => { this.enterprises = d.data; });

    this.profileService.getTemplatesByUser()
      .pipe(finalize(() => this.templateLoading = false))
      .subscribe((d) => {
        this.templates = d.data;
        this.aboutSectionWines = this.templates.map(x => x.note.wine);
        this.gameSectionWines = [...this.templates];

        this.aboutSectionWines.map(x => {
          const color = x.color.toLowerCase();
          if (this.filterWineColorOptions.indexOf(color) < 0) {
            this.filterWineColorOptions.push(color);
          }

          if (x?.region && this.filterRegionOptions.findIndex(a => a.region.toLowerCase() === x?.region?.toLowerCase()) < 0) {
            const country = x?.country;
            const count = this.aboutSectionWines.filter(k => k.region === x.region).length;
            this.filterRegionOptions.push({
              region: x.region,
              country: country,
              count: count
            });
          }

          return '';
        })
      });
  }

  selectTab(value: string) {
    this.selectedTab = value;
  }

  getWineOrigin(wine) {
    if (!wine) return '';

    let origin: string[] = [];

    if (wine.appellation && origin.indexOf(wine.appellation) < 0) origin.push(wine.appellation);
    if (wine.region && origin.indexOf(wine.region) < 0) origin.push(wine.region);
    if (wine.country && origin.indexOf(wine.country) < 0) origin.push(wine.country);

    return origin.join(", ");
  }

  getCountryFlagName(countryName: string) {
    if (countryName && countryName.toLowerCase() === 'united states' || countryName === 'united states of america' || countryName === 'usa') return 'USA';

    return countryName?.trim();
  }

  getLocation(city, country) {
    if (city && country) {
      return `${city}, ${country}`;
    } else if (city) {
      return `${city}`;
    } else if (country) {
      return `${country}`;
    } else {
      return 'Not available';
    }
  }

  openProfileEdit() {
    const modalRef = this.modalService.open(ProfileUpdateComponent);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.updated.subscribe((data) => {
      this.user = { ...data };
    })
  }

  toggleColorFilter(color: string) {
    const index = this.appliedWineColors.indexOf(color.toLowerCase());
    if (index > -1) {
      this.appliedWineColors.splice(index, 1);
    } else {
      this.appliedWineColors.push(color.toLowerCase());
    }

    this.refreshGames();
  }


  toggleRegionFilter(value: string) {
    const index = this.appliedRegions.indexOf(value.toLowerCase());

    if (index > -1) {
      this.appliedRegions.splice(index, 1);
    } else {
      this.appliedRegions.push(value.toLowerCase());
    }

    this.refreshGames();
  }

  refreshGames() {
    this.gameSectionWines = this.templates.filter(x => (this.appliedWineColors.length < 1 || this.appliedWineColors.indexOf(x?.note?.wine?.color?.toLowerCase()) > -1) &&
      (this.appliedRegions.length < 1 || this.appliedRegions.indexOf(x?.note?.wine?.region?.toLowerCase()) > -1));

    this.gameSectionWines = [...this.gameSectionWines.sort((first: any, second: any) => {
      if (this.sortByReleaseDate) {
        return new Date(second.createdOn).getTime() - new Date(first.createdOn).getTime();
      } else {
        return first?.instances > second?.instances ? -1 : 1;
      }
    })];
  }

}

<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Payment method</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>
            <form #cardDetaislForm="ngForm">

                <div class="text-danger" *ngIf="errorMessage">
                    <p class="mt-2">{{ errorMessage }}</p>
                </div>


                <div class="form-group">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="card-icon "> <img src="../../assets/icons/icons8-pro-check-box.svg"> Pro
                                plan</span>
                        </div>
                        <span class="form-control no-border gr"> $99/month</span>
                    </div>
                </div>

                <div class="divider"></div>

                <div class="form-group">
                    <label class="form-label">Name on card</label>
                    <div class="form-control-wrap">
                        <input type="text" placeholder="Olivia Rhye" [(ngModel)]="name" class="form-control"
                            name="name">
                    </div>
                </div>
                <ngx-stripe-card-group [elementsOptions]="elementsOptions">
                    <div class="form-group">
                        <label class="form-label">Card number</label>
                        <div class="form-control">
                            <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
                        </div>
                    </div>

                    <div class="d-flex justify-between">
                        <div class="form-group col-5 p-0">
                            <label class="form-label">Expiry</label>
                            <div class="form-control">
                                <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
                            </div>
                        </div>

                        <div class="form-group col-5 p-0">
                            <label class="form-label">CVC</label>
                            <div class="form-control">
                                <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
                            </div>
                        </div>
                    </div>

                </ngx-stripe-card-group>
                <div class="d-flex">
                    <img class="mr-1" src="../../assets/icons/icons8-lock.svg">
                    <span class="gr">Your payment info will be stored securely. <b>Terms and conditions</b>
                        apply.</span>
                </div>
            </form>
        </ng-container>
    </div>
    <div class="modal-footer">
        <div class="col-12 px-0 d-flex justify-between">
            <button [disabled]="isSaving" (click)="close()"
                class="col-5 btn-cancel btn cancel float-left">Cancel</button>
            <button type="button" (click)="onConfirm()" appButtonSpinner class="col-5 btn float-right btn-primary"
                [loadingState]="isSaving" text="Confirm"></button>
        </div>
    </div>
</div>
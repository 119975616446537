<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
  <div class="nk-app-root">
    <div class="nk-main ">
      <div class="nk-wrap ">
        <div class="nk-header nk-header-fixed is-light">
          <div class="container-fluid">
            <app-header></app-header>
          </div>
        </div>
        <app-side></app-side>
        <div class="nk-content ">
          <div class="container-fluid">
            <div class="nk-content-inner">
              <div class="nk-content-body">
                <div class="nk-block-head nk-block-head-sm">
                  <div class="nk-block-between">
                    <div class="nk-block-head-content">
                      <h3 class="nk-block-title page-title">Enterprise Settings</h3>
                      <div class="nk-block-des text-soft">
                        <p>Manage your enterprise.</p>
                      </div>
                    </div>
                    <div class="nk-block-head-content">
                      <div class="toggle-wrap nk-block-tools-toggle">
                        <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                        <div class="toggle-expand-content" data-content="pageMenu">
                          <ul class="nk-block-tools g-3">
                            <li>
                              <a class="lnk" [routerLink]="['/enterprise']"><img class="icon" src="../../assets/icons/icons8-name.svg" /> <span class="switch-profile">Back to Edit Enterprise Profile</span></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row g-gs">
                  <div class="col-lg-8 col-xxl-8">
                    <div class="card card-bordered h-100">
                      <div class="card-inner">
                        <div class="row g-gs">
                          <div class="col-lg-12 col-xxl-12 cntr">

                            <img src="../../assets/temp/tamura.png" class="p2" />

                            <div>Tamura’s Fine Wine & Liquors</div>
                            <div>Hawaii’s biggest wine retailer with 4 stores spanning across Oahu. We have the largest selection of wine and also some very popular poke.</div>
                            <div>
                              Honolulu, Hawaii www.tamuraswine.com
                            </div>
                          </div>
                          <div class="col-lg-12 col-xxl-12">
                            <ul class="nav nav-tabs nav-tabs-s2">
                              <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#games">Games</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#sommeliers">Sommeliers</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#contact">Contact</a>
                              </li>
                            </ul>
                            <div class="tab-content">
                              <div class="tab-pane active" id="games">
                                  <div class="nk-tb-item selectable" *ngFor="let number of [0,1,2,3,4,5]">
                                    <div class="nk-tb-col tb-col-md" (click)="selectTemplate()">
                                        <div class="user-card">
                                            <div class="wine-image">
                                                <img src="../../assets/temp/barrelX_riesling.jpg">
                                            </div>
                                            <div class="user-name">
                                                <span class="tb-lead wine-info">Paul Hobbs Winery -
                                                    2018</span>
                                                <span class="tb-lead wine-name">Paul Hobbs Russin River
                                                    Valley</span>
                                                <span class="tb-lead wine-meta">
                                                    <img src="../../assets/icons/icons8-grapes.svg"
                                                        class="grape"> Chardonnay
                                                    <img src="../../assets/flags/icons8-usa.svg"
                                                        class="flag"> Russian River Valley, California,
                                                    USA</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm data-item-num-0"
                                        (click)="selectTemplate()">
                                        95%
                                    </div>
                                    <div class="nk-tb-col tb-col-sm data-item-num-0">
                                        18
                                    </div>
                                </div>
                              </div>
                              <div class="tab-pane" id="sommeliers">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">First</th>
                                      <th scope="col">Last</th>
                                      <th scope="col">Handle</th>
                                      <th scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">1</th>
                                      <td>Mark</td>
                                      <td>Otto</td>
                                      <td>@mdo</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <th scope="row">2</th>
                                      <td>Jacob</td>
                                      <td>Thornton</td>
                                      <td>@fat</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <th scope="row">3</th>
                                      <td>Larry</td>
                                      <td>the Bird</td>
                                      <td>@twitter</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="tab-pane" id="contact">
                                <p>content</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</body>
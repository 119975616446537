<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            {{ isInstanceProcessed ? 'Download QR Code' : instanceId ? 'Update Event' : 'Create Event'}}</h5>
        <button type="button" (click)="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!isInstanceProcessed; else successInstanceCreated">
            <form #instanceForm="ngForm" (ngSubmit)="save()">
                <div class="col-md-12">
                    <div class="form-group mb-1">
                        <label class="form-label" for="fw-wine-link">When does this instance start?
                            {{ isTooltipSelected }}</label>
                        <div class="form-control-wrap">
                            <ul class="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                <li>
                                    <div class="custom-control custom-control-sm custom-radio custom-control-pro">
                                        <input type="radio" (change)="isStartNow = true" [checked]="isStartNow"
                                            class="custom-control-input" id="start-now" name="user-choose2">
                                        <label class="custom-control-label" for="start-now">Now</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-control-sm custom-radio custom-control-pro">
                                        <input type="radio" (change)="isStartNow = false" [checked]="!isStartNow"
                                            class="custom-control-input" id="start-later" name="user-choose2">
                                        <label class="custom-control-label" for="start-later">A later date</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ng-container *ngIf="!isStartNow">
                        <div class="form-group mb-1">
                            <label class="form-label fs-12px">Date</label>
                            <div class="form-control-wrap col-6 pl-0">
                                <input type="date" required #startDateInput="ngModel" [(ngModel)]="startDate"
                                    class="form-control" name="startDate">
                            </div>
                            <div *ngIf="instanceForm.submitted && startDateInput.invalid" class="invalid-feedback mt-1">
                                <div *ngIf="startDateInput.errors.required">This field is required.</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label fs-12px">Time</label>
                            <div class="form-control-wrap col-6 pl-0">
                                <input type="time" #startTimeInput="ngModel" required [(ngModel)]="startTime"
                                    class="form-control" name="startTime">
                            </div>
                            <div *ngIf="instanceForm.submitted && startTimeInput.invalid" class="invalid-feedback mt-1">
                                <div *ngIf="startTimeInput.errors.required">This field is required.</div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="col-md-12 mt-3">
                    <div class="form-group mb-1">
                        <label class="form-label" for="fw-wine-link">When does this instance end?</label>
                        <ul class="custom-control-group custom-control-vertical custom-control-stacked w-100">
                            <li>
                                <div class="custom-control custom-control-sm custom-radio custom-control-pro">
                                    <input type="radio" (change)="willExpire = false" [checked]="!willExpire"
                                        class="custom-control-input" id="user-choose-s1" name="user-choose">
                                    <label class="custom-control-label" for="user-choose-s1">Never</label>
                                </div>
                            </li>
                            <li>
                                <div class="custom-control custom-control-sm custom-radio custom-control-pro">
                                    <input type="radio" [checked]="willExpire" (change)="willExpire = true"
                                        class="custom-control-input" id="user-choose-s2" name="user-choose">
                                    <label class="custom-control-label" for="user-choose-s2">A specific date</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ng-container *ngIf="willExpire">
                        <div class="form-group mb-1">
                            <label class="form-label fs-12px">Date</label>
                            <div class="form-control-wrap col-6 pl-0">

                                <input type="date" #endTimeDate="ngModel" required [(ngModel)]="endDate"
                                    class="form-control" name="endDate">
                            </div>
                            <div *ngIf="instanceForm.submitted && endTimeInput.invalid" class="invalid-feedback mt-1">
                                <div *ngIf="endTimeDate.errors.required">This field is required.</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label fs-12px">Time</label>
                            <div class="form-control-wrap col-6 pl-0">
                                <input type="time" #endTimeInput="ngModel" required [(ngModel)]="endTime"
                                    class="form-control" name="endTime">
                            </div>
                            <div *ngIf="instanceForm.submitted && endTimeInput.invalid" class="invalid-feedback mt-1">
                                <div *ngIf="endTimeInput.errors.required">This field is required.</div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="col-md-12 mt-3">
                    <div class="form-group mb-1">
                        <label class="form-label" for="fw-wine-link">What is the Maximum Number of Players?</label>
                        <div class="form-control-wrap">
                            <ul class="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                <li>
                                    <div class="custom-control custom-control-sm custom-radio custom-control-pro">
                                        <input type="radio" (change)="isUnlimitedPlayers = true"
                                            [checked]="isUnlimitedPlayers" class="custom-control-input" id="unlimited"
                                            name="user-choose3">
                                        <label class="custom-control-label" for="unlimited">Unlimited</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-control-sm custom-radio custom-control-pro">
                                        <input type="radio" (change)="isUnlimitedPlayers = false"
                                            [checked]="!isUnlimitedPlayers" class="custom-control-input"
                                            id="specificNumberr" name="user-choose3">
                                        <label class="custom-control-label" for="specificNumberr">A specific
                                            number</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ng-container *ngIf="!isUnlimitedPlayers">
                        <div class="form-group">
                            <label class="form-label fs-12px">Set a player limit</label>
                            <div class="form-control-wrap col-6 pl-0">
                                <input type="number" required #maxParticipantsInput="ngModel"
                                    [(ngModel)]="maxParticipants" class="form-control" name="maxParticipants"
                                    placeholder="Provide players limit">
                            </div>
                            <div *ngIf="instanceForm.submitted && maxParticipantsInput.invalid"
                                class="invalid-feedback mt-1">
                                <div *ngIf="maxParticipantsInput.errors.required">This field is required.</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="form-group mt-3">
                    <div class="col-12">
                        <a *ngIf="!isSaving" data-dismiss="modal" (click)="closeModal()"
                            class="btn btn-light float-left">Cancel</a>
                        <button type="submit" appButtonSpinner class="btn btn-super float-right" text="Save"
                            [loadingState]="isSaving">Submit</button>
                        <!-- <a href="javascript:void(0);" class="btn btn-super" text="Submit">Submit</a> -->
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-template #successInstanceCreated>

            <ng-conatiner *ngIf="!loading; else showLoadingSection">
                <ul *ngIf="!isDownloaded">
                    <li>
                        <div class="custom-control custom-radio">
                            <input type="radio" id="horizontalQR" (click)="index = 0" checked name="customRadio"
                                class="custom-control-input">
                            <label class="custom-control-label" for="horizontalQR">Horizontal Print
                            </label>
                        </div>
                        <div class="horizontalQR-viewer" *ngIf="index == 0">
                            <img [src]="domeSanitizer.bypassSecurityTrustResourceUrl(horizontalQRHref)" />
                        </div>
                    </li>

                    <li>
                        <div class="custom-control custom-radio">
                            <input type="radio" id="squareQR" (click)="index = 1" name="customRadio"
                                class="custom-control-input">
                            <label class="custom-control-label" for="squareQR">Square Print</label>
                        </div>
                        <div class="horizontalQR-viewer" *ngIf="index == 1">
                            <img [src]="domeSanitizer.bypassSecurityTrustResourceUrl(squareQRHref)" />
                        </div>
                    </li>

                    <!-- <li>
                        <div class="custom-control custom-radio">
                            <input type="radio" id="digitalQR" (click)="index = 2" name="customRadio"
                                class="custom-control-input">
                            <label class="custom-control-label" for="digitalQR">Digital Print</label>
                        </div>
                        <div class="digitalQR-viewer" *ngIf="index == 2">
                            <img [src]="domeSanitizer.bypassSecurityTrustResourceUrl(digitalQRHref)" />
                        </div>
                    </li> -->

                    <li class="mt-3">
                        <div class="form-control-wrap">
                            <label class="form-label">Link</label>
                            <div class="input-group">
                                <input type="text" class="form-control" [value]="instance_url" readonly>
                                <div class="input-group-append">
                                    <button (click)="copyToClipboard()" class="btn btn-outline-primary btn-dim">
                                        <em class="icon ni ni-copy-fill"></em>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>

                </ul>



                <div *ngIf="isDownloaded">
                    <div class="nk-modal text-center mt-5">
                        <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 class="nk-modal-title mb-1">Success</h4>
                        <div class="nk-modal-text">
                            <div class="caption-text">
                                Your image is downloaded
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer justify-content-center border-0" *ngIf="!loading">
                    <ng-container *ngIf="!isDownloaded">
                        <button (click)="closeModal()" class="btn btn-lg btn-outline-dark mr-2">Cancel</button>
                        <button (click)="download()" class="btn btn-lg btn-primary">Download</button>
                    </ng-container>
                    <button *ngIf="isDownloaded" (click)="closeModal()"
                        class="btn btn-lg btn-primary mr-2">Done</button>
                </div>

            </ng-conatiner>

            <ng-template #showLoadingSection>
                <div class="text-center empty">
                    <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>

            <!-- <div class="nk-modal text-center mt-5">
                <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                <h4 class="nk-modal-title mb-1">Success</h4>
                <div class="nk-modal-text">
                    <div class="caption-text">
                        Your instance was created
                    </div>
                </div>
            </div>
            <div class="form-group mt-5">
                <div class="d-flex justify-content-center">
                    <a (click)="closeModal()" class="btn btn-super mr-3">Done</a>
                    <button (click)="generateQR()" class="btn btn-super">Share</button>
                     <a href="javascript:void(0);" class="btn btn-super" text="Submit">Submit</a> 
                </div>
            </div> -->
            <!-- <div class="d-flex justify-content-center">
                <ul>
                    <li>
                        <em class=""></em>
                    </li>
                    <li>Success</li>
                    <li>Your instance was created</li>
                </ul>
            </div> -->
        </ng-template>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerResponse } from '../_models/server-response';
import { Subscription } from '../_models/subscription.model';
import { ApiService } from './api.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    private apiService: ApiService,
    private sharedService: SharedService
  ) {}

  getStripCustomerId(businessId: string) {
    return this.apiService.get<ServerResponse<string>>(
      `Billing/business/${businessId}/get-stripe-customer-id`
    );
  }

  getEmailBucket(businessId: string) {
    return this.apiService.get<ServerResponse<any>>(
      `billing/business/${businessId}/get-active-email-bucket`
    );
  }

  getActiveSubscription(businessId: string) {
    return this.apiService
      .get<ServerResponse<Subscription>>(
        `Billing/business/${businessId}/get-active-subscription`
      )
      .pipe(
        map((res) => {
          this.sharedService.activeSubscription = res.data;
          return res;
        })
      );
  }

  getInvoices(businessId: string, page = 1, count = 10) {
    return this.apiService.get(
      `Billing/business/${businessId}/invoices?page=${page}&count=${count}`
    );
  }

  getCards(businessId: string) {
    return this.apiService.get(`Billing/business/${businessId}/get-cards`);
  }

  buyEmails(businessId: string, allowAutoEmailExtend: boolean) {
    const fd = new FormData();
    fd.append('allowAutoEmailExtend', allowAutoEmailExtend.toString());

    return this.apiService.post(
      `Billing/business/${businessId}/buy-more-emails`,
      fd
    );
  }

  createCustomerId(businessId: string, stripeToken: string) {
    const fd = new FormData();
    fd.append('stripeToken', stripeToken.trim());

    return this.apiService.post(
      `Billing/business/${businessId}/customer-id`,
      fd
    );
  }

  subscribe(
    businessId: string,
    planId: string
  ): Observable<ServerResponse<Subscription>> {
    return this.apiService
      .post(`Billing/business/${businessId}/subscribe/${planId}`, { planId })
      .pipe(
        map((res) => {
          this.sharedService.activeSubscription = res.data;
          return res;
        })
      );
  }

  updatePlan(
    businessId: string,
    identifier: string
  ): Observable<ServerResponse<Subscription>> {
    return this.apiService
      .post(`Billing/business/${businessId}/upgrade/${identifier}`)
      .pipe(
        map((res) => {
          this.sharedService.activeSubscription = res.data;
          return res;
        })
      );
  }
}

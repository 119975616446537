import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EditProducerComponent } from '../edit-producer/edit-producer.component';
import { GameCreateComponent } from '../game-create/game-create.component';
import { GameFilter } from '../_models/game-filter.model';
import { ProducerService } from '../_services/producer.service';

@Component({
  selector: 'app-producer',
  templateUrl: './producer.component.html',
  styleUrls: ['./producer.component.scss']
})
export class ProducerComponent implements OnInit {
  title = 'Admin/Producer Data';
  subTitle = 'Commodo interdum quam.';
  filter: GameFilter = new GameFilter();
  producers: any[] = [];
  loading = false;
  page = 1;
  count = 10;
  totalCount = 0;
  filterOptions = {
    producers: [],
    varietals: [],
    regions: [],
    sommeliers: [],
  };
  destroyed$ = new Subject<void>();
  env = environment;
  showGames = { game: true, duration: true };
  template: any;
  constructor(
    private modalService: NgbModal,
    private producerService: ProducerService,

  ) { }

  ngOnInit(): void {
    this.loadProducers();
  }


  loadProducers(): void {
    if (!this.loading) {
      this.loading = true;
      this.producers = [];
      this.producerService.getProducers(this.page, this.count).pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.destroyed$)
      ).subscribe(d => {
        if (d && d.data) {
          this.producers = d.data;
          this.totalCount = d.totalCount;
        }
      }
      );
    }
  }


  createGame(): void {
    const modelRef = this.modalService.open(GameCreateComponent, {
      backdrop: 'static',
    });

    modelRef.result.then((result) => {
      if (result) {
        this.loadProducers();
      }
    });
  }

  editProducer(producer: any): void {
    const modelRef = this.modalService.open(EditProducerComponent, {
      backdrop: 'static',

    });
    modelRef.componentInstance.producer = producer;
    modelRef.result.then((result) => {
      if (result) {
        this.loadProducers();
      }
    });
  }

  loadPage(ev: number): void {
    if (ev) {
      this.page = ev;
      localStorage.setItem('filters', JSON.stringify(this.filter));
      this.loadProducers();
    }
  }

  search(): void {
    const appliedFilters = JSON.parse(localStorage.getItem('filters'));
    if (appliedFilters) {
      this.filter = appliedFilters;
    }
    this.filter.page = 1;
    this.filter.length = 10;
    this.loadProducers();
  }
}

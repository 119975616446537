<app-shell-light>

  <div class="nk-content">
    <div class="container-fluid">
      <app-global-filters [title]="title" [subTitle]="subTitle" [filter]="filter" (duration)="changeDuration($event)"
        (createGame)="createGame()" [filterOptions]="filterOptions" (load)="load(false)" [showGames]="showGames">
      </app-global-filters>
    </div>
  </div>
  <div class="nk-content mt-n2">
    <div class="container-fluid">
      <div class="nk-content-inner">
        <div class="nk-content-body">
          <div class="nk-block">
            <div class="row g-gs ">
              <div class="col-lg-12 col-xxl-12 mb-14">
                <div class="card card-bordered h-100">
                  <div class="card-inner">
                    <div class="row g-gs">
                      <div class="col-lg-12 col-xxl-12 merchant">
                        <div class="profile-picture">
                          <img [src]="getPlayerPicture()" />
                        </div>
                        <img *ngIf="player.isKnownCustomer" class="known-customer"
                          src="../../assets/icons/customer.svg">
                        <div class="information">
                          <div class="name">{{ player.name | titlecase}}</div>
                          <div class="description">
                            {{ player.username }}
                            <span class="badge badge-light text-dark ml-3" *ngIf="player.hasFollowed">Follows You</span>
                          </div>
                          <div class="description">{{ player.email }}</div>
                        </div>

                        <div class="action" *ngIf="false">
                          <div>
                            <a routerLinkActive="router-link-active" class="btn btn-super follow">
                              <em class="icon ni ni-plus"></em>
                              <div>Follow</div>
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-lg-12 col-xxl-12">
                    <div class="tabs mt-3">
                      <div class="tab" [ngClass]="{'selected': selectedTab == 'history'}"
                        (click)="selectTab('history')">Game History</div>
                      <div class="tab" [ngClass]="{'selected': selectedTab == 'insights'}"
                        (click)="selectTab('insights')">Player Insights</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row g-gs">
              <div class="col-6 col-sm-6 col-md-3">
                <div class="card card-bordered card-full top-card">
                  <div class="card-inner">
                    <img src="../../assets/icons/icons8-omnichannel.svg" class="icn">
                    <div class="card-title-group align-start mb-0">
                      <div class="card-title">
                        <h6 class="subtitle">Connections</h6>
                      </div>
                      <div class="card-tools">
                        <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left"
                          title="Total Deposited"></em>
                      </div>
                    </div>
                    <div class="card-amount">
                      <div *ngIf="loadingTotalConnectionsCount" class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>

                      <div class="w-100 d-flex justify-content-between" *ngIf="!loadingTotalConnectionsCount">
                        <span class="amount">{{ totalConnections }}</span>
                        <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                          <span class="{{ totalConnectionsDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                            totalConnectionsDifference > -1 ? '+' : ''}}
                            {{ totalConnectionsDifference }}
                            %</span>
                          <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days' }}</small>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-3 col-xs-6">
                <div class="card card-bordered card-full top-card">
                  <div class="card-inner">
                    <img src="../../assets/icons/icons8-champagne.svg" class="icn">
                    <div class="card-title-group align-start mb-0">
                      <div class="card-title">
                        <h6 class="subtitle">Games</h6>
                      </div>
                      <div class="card-tools">
                        <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left"
                          title="Total Deposited"></em>
                      </div>
                    </div>
                    <div class="card-amount">
                      <div *ngIf="loadingGamesPlayedCount" class="spinner-border spinner-border-sm" role="status"> <span
                          class="sr-only">Loading...</span></div>
                      <div class="w-100 d-flex justify-content-between" *ngIf="!loadingGamesPlayedCount">
                        <span class="amount">{{ totalGamesPlayed }}</span>
                        <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                          <span class="{{ totalGamesPlayerDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                            totalGamesPlayerDifference > -1 ? '+' : ''}}
                            {{ totalGamesPlayerDifference }} %</span>
                          <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days' }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-3">
                <div class="card card-bordered card-full top-card">
                  <div class="card-inner">
                    <img src="../../assets/icons/grape.svg" class="icn">
                    <div class="card-title-group align-start mb-0">
                      <div class="card-title">
                        <h6 class="subtitle">Avg. Score</h6>
                      </div>
                      <div class="card-tools">
                        <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left"
                          title="Total Deposited"></em>
                      </div>
                    </div>
                    <div class="card-amount">
                      <div *ngIf="loadingAverageScore" class="spinner-border spinner-border-sm" role="status"> <span
                          class="sr-only">Loading...</span></div>

                      <div class="w-100 d-flex justify-content-between" *ngIf="!loadingAverageScore">
                        <span class="amount">{{ averageScore }}</span>
                        <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                          <span class="{{ averageScoreDifference < 0 ? 'text-danger' : 'text-success' }}
                                                        fw-medium">{{ averageScoreDifference > -1 ? '+' : ''}}
                            {{ averageScoreDifference }} %</span>
                          <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days' }}</small>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-3">
                <div class="card card-bordered card-full top-card">
                  <div class="card-inner">
                    <img src="../../assets/icons/star-rating.svg" class="icn">
                    <div class="card-title-group align-start mb-0">
                      <div class="card-title">
                        <h6 class="subtitle">Avg. Rating</h6>
                      </div>
                      <div class="card-tools">
                        <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left"
                          title="Total Deposited"></em>
                      </div>
                    </div>

                    <div class="card-amount">
                      <div *ngIf="loadingAverageRating" class="spinner-border spinner-border-sm" role="status"> <span
                          class="sr-only">Loading...</span></div>

                      <div class="w-100 d-flex justify-content-between" *ngIf="!loadingAverageRating">
                        <span class="amount">{{ averageRating }}</span>
                        <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                          <span class="{{ averageRatingDifference < 0 ? 'text-danger' : 'text-success' }}
                                                        fw-medium">{{ averageRatingDifference > -1 ? '+' : ''}}
                            {{ averageRatingDifference }} %</span>
                          <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days' }}</small>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <!-- ATTRIBUTE RATINGS -->
              <div class="col-xl-12 col-xxl-12" *ngIf="selectedTab == 'insights'">

                <div class="card card-bordered card-full top-card">
                  <div class="card-inner">
                    <div class="card-title-group fs">
                      <div class="card-title ml-2 mb-3 w-100 justify-between">
                        <h5 class="title">Attribute Rating</h5>
                        <div class="row col-4 justify-end">
                          <div>
                            <select class="select" (change)="getAttributeRatingFilterBY($event.target.value)">
                              <option value="" disabled selected hidden>Filter By</option>
                              <option *ngFor="let filter of attributeFilter" [value]="filter">{{filter}}</option>
                            </select>
                            <select class="select" (change)="sortByAttributeChange($event)">
                              <option selected value='1'>Games Played</option>
                              <option value='2'>Highest Rating</option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="w-100 py-4 text-center" *ngIf="attributeRatingLoading">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div *ngIf="!attributeRatingLoading">
                      <div class="pgGroup" *ngFor="let cr  of attributeRating">
                        <div class="pg">
                          <div class="progress-row">
                            <span class="prop-name">{{cr?.name}}</span>
                            <div class="progress grid">
                              <div class="progress-inner value" style="background-color:#D33F6A; min-width:55px;"
                                role="progressbar" [style.width]="100*(cr?.games/maxGames) +'%'">
                                {{cr?.games > 1 ? cr?.games+'Gms':cr?.games+'Gm'}}
                              </div>
                              <div class="progress-inner value" style="background-color:#D1D1D6;min-width:55px;"
                                role="progressbar" [style.width]="cr?.rating + '%'">
                                {{cr?.rating}}%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pgGroup" *ngIf="attributeRating?.length">
                        <div class="pg">
                          <div class="grid-percent" style="gap: 10px">
                            <span class="prop-name"></span>
                            <div class="grid-percent">
                              <div class="val" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10]">
                                {{i*(maxGames/10) |number:'1.0-1'}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-100 py-4 text-center" *ngIf="!attributeRating?.length && !attributeRatingLoading">
                      No Records Found
                    </div>
                    <div class="col-md-12 legend" *ngIf="attributeRating?.length">
                      <div class="flex">
                        <div class="indicator" style="background-color: #D33F6A;"></div>
                        <p class="title">Games Played</p>
                      </div>
                      <div class="flex">
                        <div class="indicator" style="background-color: #D1D1D6;"></div>
                        <p class="title">Preference</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- CHARACTERISTICS RATINGS -->
              <div class="col-xl-12 col-xxl-12" *ngIf="selectedTab == 'insights'">
                <div class="card card-bordered card-full top-card">
                  <div class="card-inner">
                    <div class="card-title-group fs">
                      <div class="card-title ml-2 mb-3 w-100 justify-between">
                        <h5 class="title">Characteristic Rating</h5>
                        <select [(ngModel)]="selectedCharacetristicRating"
                          (change)="onCharacteristicRatingChange(selectedCharacetristicRating)" class="select">
                          <option selected ngValue="somm">Somm Responses</option>
                          <option ngValue="player">Player Responses</option>
                        </select>
                      </div>
                    </div>
                    <div class="w-100 py-4 text-center" *ngIf="characetristicLoading">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div *ngIf="!characetristicLoading">
                      <div class="pgGroup border-bottom-light"
                        *ngFor="let ps  of sortCharRatingChange(selectedCharacetristicRatings)">
                        <div class="groupName">{{ps.feelName}}</div>
                        <div class="pg">
                          <div class="progress-row" *ngFor="let p  of ps.property">
                            <span class="prop-name"> {{p.propertyName}} </span>
                            <div class="progress-bar-no-data" ngbTooltip="0"
                              *ngIf="!(+p.val1 + +p.val2 + +p.val3 + +p.val4)"></div>
                            <div [style.width]="'100%'">
                              <div class="progress-charactristic">
                                <span class="progress-bar-pink" style="background-color:#8595E1;" role="progressbar"
                                  ngbTooltip="{{p.val1 |number:'1.0-2'}}"
                                  [style.width]="getInnerBarWidth(p.val1) + '%'"></span>
                                <span class="progress-bar-top25" role="progressbar"
                                  ngbTooltip="{{p.val2 |number:'1.0-2'}}"
                                  [style.width]="getInnerBarWidth(p.val2)+ '%'"></span>
                                <span class="progress-bar-average" ngbTooltip="{{p.val3 |number:'1.0-2'}}"
                                  role="progressbar" [style.width]="getInnerBarWidth(p.val3) + '%'"></span>
                                <span class="progress-bar-nope" ngbTooltip="{{p.val4 |number:'1.0-2'}}"
                                  role="progressbar" [style.width]="getInnerBarWidth(p.val4) + '%'"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pgGroup" *ngIf="selectedCharacetristicRatings?.length">
                        <div class="groupName"></div>
                        <div class="pg">
                          <div class="grid-percent">
                            <span class="prop-name" style="flex: 0 0 9.8%;"></span>
                            <div class="grid-percent">
                              <div class="val" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10]">
                                {{i*(maxBarLength/10)|number:'1.0-1'}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-100 py-4 text-center"
                      *ngIf="!selectedCharacetristicRatings?.length && !characetristicLoading">
                      No Records Found
                    </div>
                    <div class="col-md-12 legend" *ngIf="selectedCharacetristicRatings?.length">
                      <div class="flex">
                        <div class="indicator" style="background-color: #8595E1;"></div>
                        <p class="title">Top 10%</p>
                      </div>
                      <div class="flex">
                        <div class="indicator" style="background-color: #F0B98D;"></div>
                        <p class="title">Top 25%</p>
                      </div>
                      <div class="flex">
                        <div class="indicator" style="background-color: #D1D1D6;"></div>
                        <p class="title">Average</p>
                      </div>
                      <div class="flex">
                        <div class="indicator" style="background-color: #F2F2F7;"></div>
                        <p class="title">Nope</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-12" *ngIf="selectedTab == 'history'">
                <div class="card card-bordered card-full">
                  <div class="card-inner">
                    <div class="row">

                      <div class="col-xl-6 col-xxl-6">
                        <div class="card-title-group mb-2">
                          <div class="card-title card-title-sm">
                            <h4>History</h4>
                          </div>
                          <div class="card-tools" *ngIf="sequences && sequences.length > 0">
                            <!-- <div class="drodown">
                              <a class="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white"
                                data-bs-toggle="dropdown" aria-expanded="false">{{ recommendationStatus }}</a>
                              <div class="dropdown-menu dropdown-menu-end dropdown-menu-xs" style="">
                                <ul class="link-list-opt no-bdr">
                                  <li><span>All</span></li>
                                  <li><span>Pending</span></li>
                                  <li><span>Completed</span></li>
                                  <li><span>Defualt</span></li>
                                </ul>
                              </div>
                            </div> -->
                            <div class="drodown menu">
                              <a class="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white"
                                data-toggle="dropdown" aria-expanded="true">
                                {{ recommendationStatus }}
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <ul class="link-list-opt no-bdr">
                                  <li>
                                    <a (click)="filterByRecommendation('All')">
                                      <span>All</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a (click)="filterByRecommendation('Pending')">
                                      <span>Pending</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a (click)="filterByRecommendation('Completed')">
                                      <span>Completed</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a (click)="filterByRecommendation('Default')">
                                      <span>Default</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="card-title">
                          <h4>History</h4>
                        </div> -->
                        <ng-container *ngIf="displaySequences && displaySequences.length > 0; else empty">
                          <div class="row selectable border-bottom" [class]="{'selected': selectedIndex === index }"
                            *ngFor="let sequence of displaySequences | slice:
                            (page-1) * dataTablePageSize :
                            (page-1) *
                            dataTablePageSize+ dataTablePageSize; let index = index; let last = last"
                            (click)="select(index)">

                            <div class="nk-tb-col pl-0 w-100">

                              <div class="d-flex align-item-center">
                                <div class="wine-image">
                                  <ng-conatiner *ngIf="sequence.note.wine?.image; else defaultWineImage">
                                    <img src="{{env.cdn}}wines/{{sequence.note.wine?.image}}">
                                  </ng-conatiner>
                                  <ng-template #defaultWineImage>
                                    <img [src]="getPlayerPicture()">
                                  </ng-template>
                                </div>
                                <div class="d-flex flex-column ml-2">
                                  <span class="tb-lead wine-info">{{ sequence.note.wine.producer }}
                                    - {{ sequence.note.wine.vintage }}</span>
                                  <span class="tb-lead wine-name fw-bold fs-14px text-dark">{{ sequence.note.wine.name
                                    }}</span>
                                  <span class="tb-lead wine-meta">
                                    <img [src]="getWineIcon(sequence.note.wine?.color)" class="grape">
                                    {{ sequence.note.wine.variety}}
                                    <img src="../../assets/flags/{{getCountryFlag(sequence.note.wine.country)}}.svg"
                                      class="flag">
                                    {{ fetchWineOrigin(sequence.note.wine) }}
                                  </span>

                                  <div class="tb-lead f">
                                    <div class="badge badge-pill pill" [ngClass]="{
                                    'rating-badge-top-100' : sequence.result.explicitPreference === 100,
                                    'rating-badge-top-75' : sequence.result.explicitPreference === 75,
                                    'rating-badge-top-50' : sequence.result.explicitPreference === 50,
                                    'rating-badge-top-25' : sequence.result.explicitPreference === 25}">
                                      {{ getExplicityPreference(sequence.result.explicitPreference) }}
                                    </div>
                                    <div>
                                      <div *ngIf="sequence?.recommendation" class="rec-status"
                                        [ngClass]="{'pending': !sequence?.recommendation?.hasResponded}">
                                        <img *ngIf="sequence?.recommendation?.hasResponded"
                                          src="../../assets/icons/icons8-waiter-green.svg">
                                        <img *ngIf="!sequence?.recommendation?.hasResponded"
                                          src="../../assets/icons/icons8-waiter-grey.svg">
                                        <span *ngIf="!sequence?.recommendation?.hasResponded">Pending</span>
                                        <span *ngIf="sequence?.recommendation?.hasResponded">{{sequence?.recommendation?.isDefault
                                          ? 'Default': 'Complete'}}</span>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5">
                            <div class="col-12">
                              <ngb-pagination [collectionSize]="displaySequences.length" [(page)]="page"
                                [pageSize]="dataTablePageSize" [rotate]="true" [ellipses]="false"
                                [boundaryLinks]="true">
                              </ngb-pagination>
                            </div>
                          </div>
                        </ng-container>
                        <ng-template #empty>
                          <div class="w-100 text-center">
                            <div *ngIf="loadingPlayers" class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>

                          <p *ngIf="!loadingPlayers" class="w-100  text-center text-dark">No games found</p>
                        </ng-template>

                      </div>
                      <div class="col-xl-6 col-xxl-6 border-left" *ngIf="selectedSequence">
                        <div class="card-title mt-1200">
                          <h4>Player's Game Results</h4>
                        </div>

                        <div class="row" *ngIf="selectedSequence?.recommendation">
                          <div class="col-12">
                            <div class="mt-3">
                              <div class="d-flex align-item-center deafult-recomm"
                                [ngClass]="{'pending':!selectedSequence?.recommendation?.hasResponded, 'complete-rec':selectedSequence?.recommendation?.hasResponded && !selectedSequence?.recommendation?.isDefault}">
                                <img class="img"
                                  *ngIf="selectedSequence?.recommendation?.hasResponded && selectedSequence?.recommendation?.isDefault"
                                  src="../../assets/icons/icons8-waiter-green.svg">
                                <img class="img"
                                  *ngIf="!selectedSequence?.recommendation?.hasResponded || (selectedSequence?.recommendation?.hasResponded && !selectedSequence?.recommendation?.isDefault)"
                                  src="../../assets/icons/icons8-waiter-grey.svg">
                                <span *ngIf="!selectedSequence?.recommendation?.hasResponded">Pending
                                  Recommendation</span>
                                <span
                                  *ngIf="selectedSequence?.recommendation?.hasResponded && selectedSequence?.recommendation?.isDefault">
                                  Default Recommendation</span>
                                <span class="complete"
                                  *ngIf="selectedSequence?.recommendation?.hasResponded && !selectedSequence?.recommendation?.isDefault">Recommendation
                                  Complete</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-12">
                            <div class="d-flex align-item-center request p-2"
                              [ngClass]="{'pending-recomm':!selectedSequence?.recommendation?.hasResponded}">
                              <img class="profile-image" [src]="getPlayerPicture()">
                              <div class="d-flex flex-column justify-center pl-2 "
                                [ngClass]="{'w-50':!selectedSequence?.recommendation?.hasResponded}">
                                <span class="req">Request</span>
                                <span class="name">{{player?.name}} wants
                                  {{getRequestValue(selectedSequence?.recommendation?.requestedType,
                                  selectedSequence?.recommendation?.requestedValue)}}</span>
                              </div>
                              <div class="end" *ngIf="!selectedSequence?.recommendation?.hasResponded">
                                <div class="make-recomm" (click)="openRecommendation()">Make Recommendation</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-12" *ngIf="selectedSequence?.recommendation?.hasResponded">
                            <div class="d-flex align-item-center recomm p-2">
                              <img class="profile-image"
                                src="{{env.cdn}}wines/{{selectedSequence?.recommendation?.responseWine?.image}}">
                              <div class="d-flex flex-column justify-center pl-2 w-80">
                                <span class="req">Your recommendation</span>
                                <span class="name">{{selectedSequence?.recommendation?.responseWine?.name}}</span>
                                <span>This is this game’s default recommendation for
                                  {{getRequestValue(selectedSequence?.recommendation?.requestedType,
                                  selectedSequence?.recommendation?.requestedValue)}}</span>
                              </div>
                              <div class="end">
                                <div class="edit" (click)="openRecommendation()">Edit</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 mt-3">
                          <h6>Wine</h6>
                          <div class="wine-info mt-2">
                            <div class="d-flex align-item-center">
                              <div class="wine-image">
                                <ng-conatiner *ngIf="selectedSequence.note.wine?.image; else defaultWineImage">
                                  <img src="{{env.cdn}}wines/{{selectedSequence.note.wine?.image}}">
                                </ng-conatiner>
                                <ng-template #defaultWineImage>
                                  <img [src]="getPlayerPicture()">
                                </ng-template>
                              </div>
                              <div class="d-flex flex-column ml-2">
                                <span class="tb-lead wine-info">{{ selectedSequence.note.wine.producer }}
                                  - {{ selectedSequence.note.wine.vintage }}</span>
                                <span class="fw-bold fs-14px text-dark">{{ selectedSequence.note.wine.name
                                  }}</span>
                                <span class="tb-lead wine-meta">
                                  <img [src]="getWineIcon(selectedSequence.note.wine?.color)" class="grape">
                                  {{ selectedSequence.note.wine.variety}}
                                  <img
                                    src="../../assets/flags/{{getCountryFlag(selectedSequence.note.wine.country)}}.svg"
                                    class="flag">
                                  {{ fetchWineOrigin(selectedSequence.note.wine) }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-md-12 mt-3">
                          <h6 class="mt-2">Sommelier</h6>
                          <div class="somm-info d-flex mt-2">
                            <div class="user-picture">
                              <img class="rounded-circle user-image"
                                src="{{env.cdn}}profiles/{{selectedSequence.note.user?.picture}}">
                            </div>
                            <div class="d-flex flex-column" style="margin-left: 16px;">
                              <span class="fw-bold fs-14px text-dark">{{selectedSequence.note.user?.fullName |
                                titlecase}}</span>
                              <span class="fs-13px" *ngIf="selectedSequence.note.user?.displayCertificationLevel">
                                {{selectedSequence.note.user?.displayCertificationLevel}}
                              </span>
                              <span class="fs-13px">
                                {{selectedSequence.note.user?.email}}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <hr>
                        </div>

                        <ng-container *ngIf="!isLoadingStats; else loadingStats">
                          <div class="col-12 mt-3">
                            <h6>Game Summary</h6>
                          </div>

                          <div class="d-flex w-100 mt-3">
                            <div class="col-4 col-md-4">
                              <div class="stats-box">
                                <div class="title">{{ formatAverageTime(selectedSequence.completedIn) }}</div>
                                <div class="sub-title">Avg Duration</div>
                              </div>
                            </div>
                            <div class="col-4 col-md-4">
                              <div class="stats-box">
                                <div class="title">{{selectedWineStats.totalCount}}</div>
                                <div class="sub-title">Total Players</div>
                              </div>
                            </div>
                            <div class="col-4 col-md-4">
                              <div class="stats-box">
                                <div class="title">{{ selectedSequence.lastSeen | date:'MM-dd-yyyy' }}</div>
                                <div class="sub-title">Last Played</div>
                              </div>
                            </div>

                          </div>


                          <div class="col-12 mt-5">
                            <h6>Rating Summary</h6>
                          </div>

                          <div class="d-flex w-100 mt-3">

                            <div class="col-12 col-md-12 d-flex">
                              <div class="d-flex flex-column">
                                <div class="d-flex align-items-center mb-1">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.explicitPreference === 100"
                                      [src]="getPlayerPicture()">
                                    Top 10%
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar
                                      [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 100)}"
                                      [type]="isSommSelection('explicitPreference', 100) ? 'success' :
                                          isPlayerSelection('explicitPreference', 100) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('explicitPreference', '100')" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('explicitPreference', '100') }}%</div>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.explicitPreference === 75"
                                      [src]="getPlayerPicture()">Top 25%
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar
                                      [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 75)}"
                                      [type]="isSommSelection('explicitPreference', 75) ? 'success' :
                                        isPlayerSelection('explicitPreference', 75) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('explicitPreference', '75')" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('explicitPreference', '75') }}%</div>
                                </div>
                                <div class="d-flex  align-items-center mb-1">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.explicitPreference === 50"
                                      [src]="getPlayerPicture()">Average
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar
                                      [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 50)}"
                                      [type]="isSommSelection('explicitPreference', 50) ? 'success' :
                                        isPlayerSelection('explicitPreference', 50) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('explicitPreference', '50')" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('explicitPreference', '50') }}%</div>
                                </div>
                                <div class="d-flex  align-items-center mb-1">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.explicitPreference === 25"
                                      [src]="getPlayerPicture()"> Not for me
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar
                                      [ngClass]="{'somm-progressbar': isSommSelection('explicitPreference', 25)}"
                                      [type]="isSommSelection('explicitPreference', 25) ? 'success' :
                                         isPlayerSelection('explicitPreference', 25) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('explicitPreference', '25')" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('explicitPreference', '25') }}%</div>
                                </div>

                              </div>
                              <div class="stats-box border-0">
                                <h4 class="rating-summary-stats-header">Avg Rating</h4>
                                <div class="rating-summary-stats-title">{{getAverageRatingBySelecttion()}}%</div>
                                <div class="sub-title">{{selectedWineStats.totalCount}} players</div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-5">
                            <h6>Player's Experience</h6>
                          </div>

                          <div class="col-12 w-100 mt-3">
                            <div class="col-12">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                  Aroma
                                </div>
                                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                              </div>

                              <div class="d-flex flex-column col-12  pl-0">
                                <div class="d-flex align-items-center mb-1" *ngFor="let option of getOptions('aroma')">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.aroma === option"
                                      [src]="getPlayerPicture()">
                                    {{ option | titlecase }}
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar [ngClass]="{'somm-progressbar': isSommSelection('aroma', option)}"
                                      [type]="isSommSelection('aroma', option) ? 'success' :
                                        isPlayerSelection('aroma', option) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('aroma', option)" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('aroma', option) }}%</div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                  Body
                                </div>
                                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                              </div>

                              <div class="d-flex flex-column col-12 pl-0">
                                <div class="d-flex align-items-center mb-1" *ngFor="let option of getOptions('body')">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.body === option"
                                      [src]="getPlayerPicture()">
                                    {{ option | titlecase }}
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar [ngClass]="{'somm-progressbar': isSommSelection('body', option)}"
                                      [type]="isSommSelection('body', option) ? 'success' :
                                        isPlayerSelection('body', option) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('body', option)" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('body', option) }}%</div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                  Acidity
                                </div>
                                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                              </div>

                              <div class="d-flex flex-column col-12 pl-0">
                                <div class="d-flex align-items-center mb-1"
                                  *ngFor="let option of getOptions('acidity')">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.acidity === option"
                                      [src]="getPlayerPicture()">
                                    {{ option | titlecase }}
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar
                                      [ngClass]="{'somm-progressbar': isSommSelection('acidity', option)}" [type]="isSommSelection('acidity', option) ? 'success' :
                                        isPlayerSelection('acidity', option) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('acidity', option)" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('acidity', option) }}%</div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                  Sugar
                                </div>
                                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                              </div>

                              <div class="d-flex flex-column col-12 pl-0">
                                <div class="d-flex align-items-center mb-1" *ngFor="let option of getOptions('sugar')">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.sugar === option"
                                      [src]="getPlayerPicture()">
                                    {{ option | titlecase }}
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar [ngClass]="{'somm-progressbar': isSommSelection('sugar', option)}"
                                      [type]="isSommSelection('sugar', option) ? 'success' :
                                          isPlayerSelection('sugar', option) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('sugar', option)" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('sugar', option) }}%</div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12" *ngIf="selectedSequence.note.tannins">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                  Tannins
                                </div>
                                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                              </div>

                              <div class="d-flex flex-column col-12 pl-0">
                                <div class="d-flex align-items-center mb-1"
                                  *ngFor="let option of getOptions('tannins')">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.tannins === option"
                                      [src]="getPlayerPicture()">
                                    {{ option | titlecase }}
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar
                                      [ngClass]="{'somm-progressbar': isSommSelection('tannins', option)}" [type]="isSommSelection('tannins', option) ? 'success' :
                                         isPlayerSelection('tannins', option) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('tannins', option)" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('tannins', option) }}%</div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12" *ngIf="selectedSequence.note.oak">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                  Oak
                                </div>
                                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                              </div>

                              <div class="d-flex flex-column col-12 pl-0">
                                <div class="d-flex align-items-center mb-1" *ngFor="let option of getOptions('oak')">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.oak === option"
                                      [src]="getPlayerPicture()">
                                    {{ option | titlecase }}
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar [ngClass]="{'somm-progressbar': isSommSelection('oak', option)}"
                                      [type]="isSommSelection('oak', option) ? 'success' :
                                        isPlayerSelection('oak', option) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('oak', option)" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('oak', option) }}%</div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                  Flavor
                                </div>
                                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                              </div>

                              <div class="d-flex flex-column col-12  pl-0">
                                <div class="d-flex align-items-center mb-1" *ngFor="let option of getOptions('flavor')">
                                  <div class="pgb-title">
                                    <img class="pgb-image" *ngIf="selectedSequence.result.flavor === option"
                                      [src]="getPlayerPicture()">
                                    {{ option | titlecase }}
                                  </div>
                                  <div class="ngb-progress-bar-container pt-1">
                                    <ngb-progressbar [ngClass]="{'somm-progressbar': isSommSelection('flavor', option)}"
                                      [type]="isSommSelection('flavor', option) ? 'success' :
                                        isPlayerSelection('flavor', option) ? 'secondary' : 'dark'"
                                      [value]="getOptionPercentage('flavor', option)" [max]="100">
                                    </ngb-progressbar>
                                  </div>
                                  <div class="pgb-value">{{ getOptionPercentage('flavor', option) }}%</div>
                                </div>
                              </div>
                            </div>



                          </div>
                        </ng-container>

                        <ng-template #loadingStats>
                          <div class="col-12 mt-5 text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                            <p>Fetching updated stats...</p>
                          </div>

                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-shell-light>
<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
    <div class="nk-app-root">
        <div class="nk-main ">
            <div class="nk-wrap ">
                <div class="nk-header nk-header-fixed is-light">
                    <div class="container-fluid">
                        <app-header></app-header>
                    </div>
                </div>
                <app-side></app-side>
                <div class="nk-content ">

                    <div class="container-fluid">
                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <ng-container *ngIf="!loading; else loadingContainer">
                                    <div class="nk-block-head nk-block-head-sm">
                                        <div class="nk-block-between">
                                            <div class="nk-block-head-content">
                                                <h3 class="nk-block-title page-title">Enterprise Management</h3>
                                                <div class="nk-block-des text-soft">
                                                    <p>Manage your enterprise settings.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-gs">
                                        <div class="col-lg-12 col-xxl-12">
                                            <div class="card card-bordered h-100">
                                                <div class="card-inner">
                                                    <div class="row g-gs">
                                                        <div class="col-lg-12 col-xxl-12 merchant">
                                                            <div class="profile-picture">
                                                                <img *ngIf="company?.logo"
                                                                    src="{{env.cdn}}companies/{{company?.logo}}" />
                                                                <img *ngIf="!company?.logo"
                                                                    src="../../assets/temp/tamura.png" />
                                                            </div>
                                                            <div class="information">
                                                                <div class="name">{{ company?.name }}</div>
                                                                <div class="description">{{ company?.tagline }}</div>
                                                            </div>

                                                            <div class="action" *ngIf="false">
                                                                <div>
                                                                    <a routerLinkActive="router-link-active"
                                                                        class="btn btn-super follow">
                                                                        <em class="icon ni ni-plus"></em>
                                                                        <div>Follow</div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-xxl-12">
                                                    <div class="tabs">
                                                        <div class="tab"
                                                            [ngClass]="{'selected': selectedTab == 'about'}"
                                                            (click)="selectTab('about')">About</div>
                                                        <div class="tab"
                                                            [ngClass]="{'selected': selectedTab == 'games'}"
                                                            (click)="selectTab('games')">Games</div>
                                                        <div class="tab"
                                                            [ngClass]="{'selected': selectedTab == 'sommeliers'}"
                                                            (click)="selectTab('sommeliers')">Sommeliers</div>
                                                        <div class="tab"
                                                            [ngClass]="{'selected': selectedTab == 'producers'}"
                                                            (click)="selectTab('producers')">Producers</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row g-gs" *ngIf="selectedTab == 'about'">
                                        <div class="col-lg-5 col-xxl-5">
                                            <div class="card card-bordered">
                                                <div class="card-inner">
                                                    <div class="card-title d-flex justify-content-between">
                                                        <span>About</span>
                                                        <button type="button" class="btn btn-sm btn-super"
                                                            (click)="openEnterpriseEdit()">Edit</button>
                                                    </div>
                                                    <div class="about">

                                                        <div class="map">
                                                            <google-map #map [options]="options" [zoom]="zoom"
                                                                width="100%" height="200px">
                                                                <map-marker *ngIf="markerLoaded"
                                                                    [position]="markerPosition"
                                                                    [options]="markerOptions"></map-marker>
                                                            </google-map>
                                                        </div>
                                                        <div [froalaView]="company?.description" class="bio">
                                                        </div>

                                                        <div class="title">Contact</div>
                                                        <!-- <div *ngIf="company?.websiteUrl" class="info-link">
                                                        <a href="{{ company?.websiteUrl }}" target="_blank">
                                                            {{ company?.websiteUrl }}
                                                        </a>
                                                    </div> -->
                                                        <div class="info-link">{{ company?.email }}</div>
                                                        <div class="info-link">{{ company?.phone }}</div>

                                                        <div class="title">Social</div>
                                                        <div class="d-flex">
                                                            <div class="social-link {{ item.key }} mr-2"
                                                                *ngFor="let item of company?.social | keyvalue">
                                                                <a href="{{item.value}}" target="_blank">
                                                                    <img
                                                                        src="../../../assets/icons/social/{{item.key | lowercase}}.svg">
                                                                </a>
                                                            </div>
                                                            <div class=" info-link" *ngIf="!company?.social">
                                                                No social links provided
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class=" card card-bordered">
                                                <div class="card-inner">
                                                    <div class="card-title">Sommelier</div>
                                                    <table class="table is-compact">
                                                        <tbody>
                                                            <tr *ngIf="sommLoading">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        <div class="spinner-border spinner-border-sm"
                                                                            role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="!sommLoading && somms && somms.length < 1">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        No records found
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="cursor-pointer" *ngFor="let somm of somms">
                                                                <td class="col-avatar">
                                                                    <div class="user-avatar">
                                                                        <img class="rounded-circle user-image"
                                                                            src="{{env.cdn}}profiles/{{somm?.picture}}">
                                                                        <div class="status-circle">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="main-label">
                                                                        {{ somm?.firstName }}
                                                                        {{ somm?.lastName }}</div>
                                                                    <div class="secondary-label">
                                                                        {{ somm?.displayCertificationLevel ? somm?.displayCertificationLevel : '-' }}
                                                                    </div>
                                                                </td>
                                                                <td class="col-selector2rows">
                                                                    <ng-container *ngIf="somm?.isPublicProfile; else
                                                                          publicLockSection">
                                                                        <img class="cursor-pointer"
                                                                            title="Disable somm public profile access"
                                                                            (click)="disableSommPublicProfile(somm?.id)"
                                                                            src="../../assets/icons/icons8-lock-enabled.svg">
                                                                    </ng-container>
                                                                    <ng-template #publicLockSection>
                                                                        <img class="cursor-pointer"
                                                                            title="Enable somm public profile access"
                                                                            (click)="enableSommPublicProfile(somm?.id)"
                                                                            src="../../assets/icons/icons8-lock-disabled.svg">
                                                                    </ng-template>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="card card-bordered">
                                                <div class="card-inner">
                                                    <div class="card-title">Producers</div>
                                                    <table class="table is-compact">
                                                        <tbody>
                                                            <tr *ngIf="producersLoading">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        <div class="spinner-border spinner-border-sm"
                                                                            role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                *ngIf="!producersLoading && producers && producers.length < 1">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        No records found
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="cursor-pointer" *ngFor="let producer of producers | slice:
                                                                (aboutSectionProducersPage-1) * 10 :
                                                                (aboutSectionProducersPage-1) *
                                                                10 + 10">
                                                                <td class="col-avatar">
                                                                    <div class="user-avatar">
                                                                        <img class="rounded-circle user-image"
                                                                            *ngIf="producer?.picture"
                                                                            src="{{env.cdn}}companies/{{producer?.picture}}">
                                                                        <div class="status-circle">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="main-label">
                                                                        {{ producer?.name }}
                                                                    </div>
                                                                    <div class="secondary-label" *ngIf="producer?.city &&
                                                                    producer?.state">{{producer.city}},
                                                                        {{producer.state}}
                                                                    </div>
                                                                </td>
                                                                <td class="col-selector2rows">
                                                                    <img
                                                                        src="../../assets/icons/icons8-chevron-right.svg">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot *ngIf="!producersLoading">
                                                            <tr *ngIf="producers">
                                                                <td colspan="5" class="pt-4">
                                                                    <div class="d-flex justify-content-center">
                                                                        <ngb-pagination
                                                                            [collectionSize]="producers.length"
                                                                            [(page)]="aboutSectionProducersPage"
                                                                            [pageSize]="10" [rotate]="true"
                                                                            [ellipses]="false" [boundaryLinks]="true">
                                                                        </ngb-pagination>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-xxl-7">
                                            <div class="card card-bordered">
                                                <img src="..." class="card-img-top" alt="">
                                                <div class="card-inner ">
                                                    <div class="card-title">Games</div>
                                                    <table class="table is-compact">
                                                        <tbody>
                                                            <tr *ngIf="templateLoading">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        <div class="spinner-border spinner-border-sm"
                                                                            role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="!templateLoading && wines && wines.length < 1">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        No records found
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="cursor-pointer" *ngFor="let template of aboutSectionWines | slice:
                                                            (aboutSectionPage-1) * pageSize : (aboutSectionPage-1) *
                                                            pageSize + pageSize">
                                                                <td class="col-image3rows">
                                                                    <div>
                                                                        <img class="wine-image"
                                                                            src="{{env.cdn}}wines/{{template?.note?.wine?.image}}">
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex justify-content-between">
                                                                        <div class="game-detail">
                                                                            <div class="main-label">
                                                                                {{template?.note?.wine.name}}
                                                                                {{template?.note?.wine.vintage === 0 ? "" : template?.note?.wine.vintage}}
                                                                            </div>
                                                                            <div class="secondary-label">
                                                                                {{template?.note?.wine.variety}}
                                                                                {{ template?.instances }}
                                                                            </div>
                                                                            <div class="secondary-label">
                                                                                {{template?.note?.wine.appellation}},
                                                                                {{template?.note?.wine.region}}</div>
                                                                            <a [attr.href]="template.buyLink"
                                                                                target="_blank"
                                                                                *ngIf="template?.buyLink"
                                                                                class="btn btn-super">
                                                                                BUY
                                                                            </a>
                                                                        </div>
                                                                        <div class="align-self-center">
                                                                            <ng-container
                                                                                *ngIf="template?.isPrivate; else publicLockSection">
                                                                                <img class="cursor-pointer"
                                                                                    (click)="updatePrivacyToPublic(template?.id)"
                                                                                    src="../../assets/icons/icons8-lock-enabled.svg">
                                                                            </ng-container>
                                                                            <ng-template #publicLockSection>
                                                                                <img class="cursor-pointer"
                                                                                    (click)="updatePrivacyToPrivate(template?.id)"
                                                                                    src="../../assets/icons/icons8-lock-disabled.svg">
                                                                            </ng-template>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot *ngIf="!templateLoading">
                                                            <tr *ngIf="templates">
                                                                <td colspan="5" class="pt-4">
                                                                    <div class="d-flex justify-content-center">
                                                                        <ngb-pagination
                                                                            [collectionSize]="aboutSectionWines.length"
                                                                            [(page)]="aboutSectionPage" [pageSize]="10"
                                                                            [rotate]="true" [ellipses]="false"
                                                                            [boundaryLinks]="true">
                                                                        </ngb-pagination>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-gs" *ngIf="selectedTab == 'games'">
                                        <div class="col-lg-5 col-xxl-5">
                                            <div class="card card-bordered">
                                                <div class="card-inner">

                                                    <div class="card-title">Sort By</div>
                                                    <div class="sort-by">
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" id="customRadio2"
                                                                (change)="sortByReleaseDate = false; refreshGames()"
                                                                [checked]="!sortByReleaseDate" name="customRadio"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label" for="customRadio2">Most
                                                                popular</label>
                                                        </div>

                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" id="customRadio3"
                                                                (change)="sortByReleaseDate = true; refreshGames()"
                                                                [checked]="sortByReleaseDate" name="customRadio"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="customRadio3">Release
                                                                date</label>
                                                        </div>
                                                    </div>

                                                    <div class="card-title">Filter by type</div>
                                                    <div class="sort-type">
                                                        <div class="type-item" [ngClass]="{ 'filter-selected': appliedWineColors.indexOf(color) >
                                                            -1 }" (click)="toggleColorFilter(color)"
                                                            *ngFor="let color of filterWineColorOptions">
                                                            {{ color | titlecase }}</div>
                                                    </div>

                                                    <div class="card-title">Filter by region</div>
                                                    <div class="filter-region">
                                                        <div class="filter-selector" [ngClass]="{ 'filter-selected':
                                                            appliedRegions.indexOf(option?.region.toLowerCase()) >
                                                            -1 }" (click)="toggleRegionFilter(option?.region)"
                                                            *ngFor="let option of filterRegionOptions">
                                                            <img
                                                                src="../../assets/flags/{{getCountryFlagName(option?.country)}}.svg">
                                                            {{ option?.region }} ({{ option?.count }})
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-xxl-7">
                                            <div class="card card-bordered">
                                                <img src="..." class="card-img-top" alt="">
                                                <div class="card-inner ">
                                                    <div class="card-title">Games</div>
                                                    <table class="table is-compact">
                                                        <tbody>
                                                            <tr *ngIf="templateLoading">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        <div class="spinner-border spinner-border-sm"
                                                                            role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="!templateLoading && templates &&
                                                                templates.length < 1">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        No records found
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="cursor-pointer"
                                                                *ngFor="let template of gameSectionWines | slice:
                                                                (gameSectionPage-1) * pageSize : (gameSectionPage-1) * pageSize + pageSize">
                                                                <td class="col-image3rows">
                                                                    <div>
                                                                        <img class="wine-image"
                                                                            src="{{env.cdn}}wines/{{template?.note?.wine.image}}">
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex justify-content-between">
                                                                        <div class="game-detail">
                                                                            <div class="main-label">
                                                                                {{template?.note?.wine.name}}
                                                                                {{template?.note?.wine.vintage === 0 ? "" : template?.note?.wine.vintage}}
                                                                            </div>
                                                                            <div class="secondary-label">
                                                                                {{template?.note?.wine.variety}}
                                                                                {{ template?.instances }}
                                                                            </div>
                                                                            <div class="secondary-label">
                                                                                {{template?.note?.wine.appellation}},
                                                                                {{template?.note?.wine.region}}</div>
                                                                            <a [attr.href]="template.buyLink"
                                                                                target="_blank"
                                                                                *ngIf="template?.buyLink"
                                                                                class="btn btn-super">
                                                                                BUY
                                                                            </a>
                                                                        </div>
                                                                        <div class="align-self-center">
                                                                            <ng-container
                                                                                (click)="updatePrivacyToPublic(template?.id)"
                                                                                *ngIf="template?.isPrivate; else publicLockSection">
                                                                                <img class="cursor-pointer"
                                                                                    src="../../assets/icons/icons8-lock-enabled.svg">
                                                                            </ng-container>
                                                                            <ng-template #publicLockSection>
                                                                                <img class="cursor-pointer"
                                                                                    (click)="updatePrivacyToPrivate(template?.id)"
                                                                                    src="../../assets/icons/icons8-lock-disabled.svg">
                                                                            </ng-template>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot *ngIf="!templateLoading">
                                                            <tr *ngIf="templates">
                                                                <td colspan="5" class="pt-4">
                                                                    <div class="d-flex justify-content-center">
                                                                        <ngb-pagination
                                                                            [collectionSize]="gameSectionWines.length"
                                                                            [(page)]="gameSectionPage" [pageSize]="10"
                                                                            [rotate]="true" [ellipses]="false"
                                                                            [boundaryLinks]="true">
                                                                        </ngb-pagination>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-gs" *ngIf="selectedTab == 'sommeliers'">
                                        <div class="col-lg-12 col-xxl-12">
                                            <div class="card card-bordered h-100">
                                                <div class="card-inner">
                                                    <div class="row g-gs">
                                                        <div class="col-lg-12 col-xxl-12">
                                                            <table class="table table-tranx is-compact">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th><span>Name</span></th>
                                                                        <th><span>Certification</span></th>
                                                                        <th><span>Games</span></th>
                                                                        <th></th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngIf="sommLoading">
                                                                        <td colspan="5">
                                                                            <div class="text-center my-5">
                                                                                <div class="spinner-border spinner-border-sm"
                                                                                    role="status">
                                                                                    <span
                                                                                        class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        *ngIf="!sommLoading && somms && somms.length < 1">
                                                                        <td colspan="5">
                                                                            <div class="text-center my-5">
                                                                                No records found
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngFor="let somm of somms">
                                                                        <td class="col-avatar">
                                                                            <div class="user-avatar">
                                                                                <img class="rounded-circle user-image"
                                                                                    src="{{env.cdn}}profiles/{{somm?.picture}}">
                                                                                <div class="status-circle">
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{{ somm.firstName }}
                                                                            {{ somm.lastName }}</td>
                                                                        <td>{{ somm?.displayCertificationLevel ? somm?.displayCertificationLevel : '-' }}
                                                                        </td>
                                                                        <td>{{ somm?.games }}</td>
                                                                        <td class="selector">
                                                                            <ng-container *ngIf="somm?.isPublicProfile; else
                                                                          publicLockSection">
                                                                                <img class="cursor-pointer"
                                                                                    title="Dsiable somm public profile access"
                                                                                    (click)="disableSommPublicProfile(somm?.id)"
                                                                                    src="../../assets/icons/icons8-lock-enabled.svg">
                                                                            </ng-container>
                                                                            <ng-template #publicLockSection>
                                                                                <img class="cursor-pointer"
                                                                                    title="Enable somm public profile access"
                                                                                    (click)="enableSommPublicProfile(somm?.id)"
                                                                                    src="../../assets/icons/icons8-lock-disabled.svg">
                                                                            </ng-template>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-gs" *ngIf="selectedTab == 'producers'">
                                        <div class="col-lg-12 col-xxl-12">
                                            <div class="card card-bordered h-100">
                                                <div class="card-inner">
                                                    <div class="row g-gs">
                                                        <div class="col-lg-12 col-xxl-12">
                                                            <table class="table table-tranx is-compact">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th><span>Name</span></th>
                                                                        <th><span>Location</span></th>
                                                                        <th><span>Games</span></th>
                                                                        <th></th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngIf="producersLoading">
                                                                        <td colspan="5">
                                                                            <div class="text-center my-5">
                                                                                <div class="spinner-border spinner-border-sm"
                                                                                    role="status">
                                                                                    <span
                                                                                        class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        *ngIf="!producersLoading && producers && producers.length < 1">
                                                                        <td colspan="5">
                                                                            <div class="text-center my-5">
                                                                                No records found
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngFor="let producer of producers | slice:
                                                            (producersSectionPage-1) * 10 :
                                                            (producersSectionPage-1) *
                                                            10 + 10">
                                                                        <td class="col-avatar">
                                                                            <!-- <div class="user-avatar">
                                                                                <img class="rounded-circle user-image"
                                                                                    *ngIf="producer?.picture"
                                                                                    src="{{env.cdn}}companies/{{producer?.picture}}">
                                                                                <div class="status-circle">
                                                                                </div>
                                                                            </div> -->
                                                                            <img
                                                                                src="
                                                                            ../../assets/flags/{{getCountryFlagName(producer?.country)}}.svg">
                                                                        </td>
                                                                        <td>{{ producer?.name }}</td>
                                                                        <td class="location">
                                                                            <ng-container
                                                                                *ngIf="!producer?.country; else countrySection">
                                                                                <div class="d-flex ">
                                                                                    <div class="user-avatar">
                                                                                        <div class="status-circle">
                                                                                        </div>

                                                                                    </div>
                                                                                    <div class="pt-2 pl-1">
                                                                                        {{ getLocation(producer?.city, producer?.country) }}
                                                                                    </div>
                                                                                </div>

                                                                            </ng-container>
                                                                            <ng-template #countrySection>


                                                                                &nbsp;
                                                                                {{ getLocation(producer?.city, producer?.country) }}
                                                                            </ng-template>

                                                                        </td>
                                                                        <td>{{ producer?.totalGames }}</td>
                                                                        <td class="selector">
                                                                            <img
                                                                                src="../../assets/icons/icons8-chevron-right.svg">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot *ngIf="!producersLoading">
                                                                    <tr *ngIf="producers">
                                                                        <td colspan="5" class="pt-4">
                                                                            <div class="d-flex justify-content-center">
                                                                                <ngb-pagination
                                                                                    [collectionSize]="producers.length"
                                                                                    [(page)]="producersSectionPage"
                                                                                    [pageSize]="10" [rotate]="true"
                                                                                    [ellipses]="false"
                                                                                    [boundaryLinks]="true">
                                                                                </ngb-pagination>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #loadingContainer>
                                    <div class="text-center empty">
                                        <div class="spinner-border spinner-border-sm" role="status"> <span
                                                class="sr-only">Loading...</span></div>
                                    </div>
                                </ng-template>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Update Enterprise</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form #companyForm="ngForm" (ngSubmit)="companyForm.valid && update()">

            <div class="form-group text-center">
                <div class="w-100 text-center picture">
                    <img [src]="url" class="rounded-circle picture border" (click)="file.click()">
                    <span>Edit</span>
                </div>

                <input type="file" #file hidden (change)="onFileChange($event)" accept="image/png, image/jpeg" />
            </div>

            <div class="form-group">
                <label class="form-label">Name</label>
                <div class="form-control-wrap ">
                    <input type="text" #userFullName="ngModel" placeholder="Chuck Furuya" required
                        [(ngModel)]="model.fullName" class="form-control" name="userFullName">
                </div>
                <div *ngIf="companyForm.submitted && userFullName.invalid" class="invalid-feedback mt-1">
                    <div *ngIf="userFullName.errors.required">This field is required.</div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Display Certification</label>
                <div class="form-control-wrap ">
                    <input type="text" #displayCertificationLevelInp="ngModel" placeholder="Advanced Sommelier L3, CMS"
                        [(ngModel)]="model.displayCertificationLevel" class="form-control" name="displayCertification">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Bio</label>
                <div class="form-control-wrap">
                    <textarea [froalaEditor]="options" [(froalaModel)]="model.bio" name="description">
                        </textarea>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Website</label>
                <div class="form-control-wrap ">
                    <input type="text" placeholder="http://chuckfuruya.com" [(ngModel)]="model.websiteUrl"
                        class="form-control" name="websiteUrl">
                </div>
            </div>

            <!-- <div class="form-group">
                <label class="form-label">Address</label>
                <div class="form-control-wrap">
                    <input type="text" placeholder="3911 Fox St, Denver, CO 80216-4817" [(ngModel)]="model.address"
                        class="form-control" name="address">
                </div>
            </div> -->

            <div class="form-group">
                <label class="form-label">Email</label>
                <div class="form-control-wrap ">
                    <input type="text" placeholder="chuckfuruya@wine-merchant.com" [(ngModel)]="model.email"
                        class="form-control" name="email">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Phone number (Private)</label>
                <div class="form-control-wrap ">
                    <input type="text" #memberTitle="ngModel" placeholder="(303) 433-4020" [(ngModel)]="model.mobile"
                        class="form-control" name="phone">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">LinkedIn</label>
                <div class="form-control-wrap ">
                    <input type="text" placeholder="http://www.linkedin.com/chuckfuruya" [(ngModel)]="linkedIn"
                        class="form-control" name="tiktok">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Instagram</label>
                <div class="form-control-wrap ">
                    <input type="text" placeholder="http://www.instagram.com/chuckfuruya" [(ngModel)]="instagram"
                        class="form-control" name="instagram">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">Facebook</label>
                <div class="form-control-wrap ">
                    <input type="text" placeholder="http://www.facebook.com/chuckfuruya" [(ngModel)]="facebook"
                        class="form-control" name="facebook">
                </div>
            </div>

            <div class="form-group mt-3">
                <div class="col-12 px-0">
                    <a *ngIf="!isSaving" (click)="close()" class="btn btn-light float-left">Cancel</a>
                    <button type="submit" appButtonSpinner class="btn btn-super float-right" text="Send"
                        [loadingState]="isSaving"></button>
                </div>
            </div>


        </form>
    </div>
</div>
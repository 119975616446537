<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Extend your plan</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>
            <div class="gr mt-3">Add another 1,000 emails for this month for just $30</div>
            <div class="pt-3 pb-2">
                <div class="border">
                    <div>
                        <span class="mr-1"><img src="../../assets/icons/icons8-pro-check-box.svg"></span>
                        <b>Pro plan - Extension</b>
                        <span class="gr ml-1">$30/month</span>
                    </div>

                    <div class="mt-3">
                        <div class="gr">Automatically extend when emails run out?</div>
                        <div class="form-control pl-0 border-0">
                            <div class="custom-control custom-switch checked">
                                <input type="checkbox" name="allowEmailExtend" [(ngModel)]="allowEmailExtend"
                                    class="custom-control-input" id="allowEmailExtend">
                                <label class="custom-control-label" for="allowEmailExtend"> YES</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>
    <div class="modal-footer">
        <div class="col-12 px-0 d-flex justify-between">
            <a *ngIf="!isSaving" (click)="close()" class="col-5 btn-cancel btn cancel float-left">Cancel</a>

            <button type="button" (click)="update()" appButtonSpinner class="col-5 btn float-right btn-primary"
                [loadingState]="isSaving" text="Confirm"></button>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { CMSService } from '../_services/cms.service';

@Component({
  selector: 'app-privacye',
  templateUrl: './privacye.component.html',
  styleUrls: ['./privacye.component.scss']
})
export class PrivacyeComponent implements OnInit {

  content: string = '';

  constructor(
    private cms: CMSService
  ) { }

  ngOnInit() {
    this.cms.getPrivacy().subscribe((ret) => {
      this.content = ret.items[0].data.content.iv;
    });
  }

}

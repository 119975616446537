<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
  <div class="nk-app-root">
      <div class="nk-main ">
          <div class="nk-wrap ">
              <div class="nk-header nk-header-fixed is-light">
                  <div class="container-fluid">
                      <app-header></app-header>
                  </div>
              </div>
              <app-side></app-side>
              <ng-content></ng-content>
          </div>
      </div>
  </div>
</body>

import { SupportComponent } from './support/support.component';
import { FooterComponent } from './_components/footer/footer.component';
import { HeaderComponent } from './_components/header/header.component';
import { SideComponent } from './_components/side/side.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { ShellAnonComponent } from './_components/shell-anon/shell-anon.component';
import { ShellLightComponent } from './_components/shell-light/shell-light.component';
import { ShellComponent } from './_components/shell/shell.component';
import { GamesComponent } from './games/games.component';
import { SettingsComponent } from './settings/settings.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FaqComponent } from './faq/faq.component';
import { BillingComponent } from './billing/billing.component';
import { ProfileComponent } from './profile/profile.component';
import { GameComponent } from './game/game.component';
import { WinesComponent } from './wines/wines.component';
import { PlayersComponent } from './players/players.component';
import { InventoryComponent } from './inventory/inventory.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { GameCreateComponent } from './game-create/game-create.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './_interceptors/token.interceptor';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { DropzoneDirective } from './_components/_directives/dropzone.directive';
import { ButtonSpinnerDirective } from './_components/_directives/button-spinner.directive';
import { NumericDirective } from './_components/_directives/numeric.directive';
import { TeamComponent } from './team/team.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { EnterprisePublicComponent } from './enterprise-public/enterprise-public.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotFoundComponent } from './not-found/not-found.component';
import { GenerateQrCodeComponent } from './generate-qr-code/generate-qr-code.component';
import { InstanceCreateComponent } from './instance-create/instance-create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TermseComponent } from './termse/termse.component';
import { PrivacyeComponent } from './privacye/privacye.component';
import { EulaComponent } from './eula/eula.component';
import { EulaeComponent } from './eulae/eulae.component';
import { TeamMemberCreateComponent } from './team-member-create/team-member-create.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { EnterpriseSettingsUpdateComponent } from './enterprise-settings-update/enterprise-settings-update.component';
import { ProfileUpdateComponent } from './profile-update/profile-update.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ConfirmationDialogComponent } from './_components/confirmation-dialog/confirmation-dialog.component';
import { PlayerManagementComponent } from './player-management/player-management.component';
import { PlayerDetailsComponent } from './player-details/player-details.component';
import { ImportPlayersComponent } from './import-players/import-players.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { TagsInputComponent } from './_components/tags-input/tags-input.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WineManagementComponent } from './wines/wine-management/wine-management.component';
import { GlobalFiltersComponent } from './_components/global-filters/global-filters.component';
import { RecommendationRequestModelComponent } from './recommendation-request-model/recommendation-request-model.component';
import { PaymentComponent } from './payment/payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ProducerComponent } from './producer/producer.component';
import { EditProducerComponent } from './edit-producer/edit-producer.component';
import { AccountPlanComponent } from './account-plan/account-plan.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { AccountPlanProComponent } from './account-plan-pro/account-plan-pro.component';
import { AccountPlanEditComponent } from './account-plan-edit/account-plan-edit.component';
import { CardDetailsEditComponent } from './card-details-edit/card-details-edit.component';
import { AccountPlanExtendComponent } from './account-plan-extend/account-plan-extend.component';
import { DowngradePlanModalComponent } from './downgrade-plan-modal/downgrade-plan-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    PrivacyComponent,
    TermsComponent,
    ShellComponent,
    ShellLightComponent,
    ShellAnonComponent,
    SideComponent,
    HeaderComponent,
    FooterComponent,
    GamesComponent,
    SettingsComponent,
    DashboardComponent,
    FaqComponent,
    BillingComponent,
    ProfileComponent,
    GameComponent,
    WinesComponent,
    PlayersComponent,
    InventoryComponent,
    GameCreateComponent,
    OnboardingComponent,
    DropzoneDirective,
    ButtonSpinnerDirective,
    NumericDirective,
    TeamComponent,
    EnterpriseComponent,
    EnterprisePublicComponent,
    NotFoundComponent,
    GenerateQrCodeComponent,
    InstanceCreateComponent,
    TermseComponent,
    PrivacyeComponent,
    EulaComponent,
    EulaeComponent,
    TeamMemberCreateComponent,
    AcceptInvitationComponent,
    EnterpriseSettingsUpdateComponent,
    ProfileUpdateComponent,
    ConfirmationDialogComponent,
    SupportComponent,
    PlayerManagementComponent,
    PlayerDetailsComponent,
    ImportPlayersComponent,
    TagsInputComponent,
    WineManagementComponent,
    GlobalFiltersComponent,
    RecommendationRequestModelComponent,
    PaymentComponent,
    ProducerComponent,
    EditProducerComponent,
    AccountPlanComponent,
    CardDetailsComponent,
    AccountPlanProComponent,
    AccountPlanEditComponent,
    CardDetailsEditComponent,
    AccountPlanExtendComponent,
    DowngradePlanModalComponent,
  ],
  entryComponents: [
    GameCreateComponent,
    GenerateQrCodeComponent,
    InstanceCreateComponent,
    TeamMemberCreateComponent,
    EnterpriseSettingsUpdateComponent,
    ProfileUpdateComponent,
    ImportPlayersComponent,
    WineManagementComponent,
  ],
  imports: [
    BrowserModule,

    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    GoogleChartsModule.forRoot(),
    NgbModule,
    InfiniteScrollModule,
    NgSelectModule,
    GoogleMapsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxStripeModule.forRoot(environment.stripeKey),
    AngularFirestoreModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

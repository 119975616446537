import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BillingService } from 'src/app/_services/billing.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss'],
})
export class SideComponent implements OnInit, OnDestroy {
  currentRoute = '';

  showAdmin = false;
  showWine = false;
  showPro = false;
  loadingSubscription = false;

  $destroy = new Subject<any>();

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private billingService: BillingService
  ) {
    this.currentRoute = this.router.url;
    const user = this.authService.getUser();
    const team = this.authService.getDefaultCompany();

    if (team) {
      this.showPro = team.value === 0;
      this.sharedService.business = team;
    }

    this.showAdmin = user && user.email && user.email.includes('@sommsays.co');

    // CHECK THE ROUTE IS GO PRO AND IS OWNER
    if (this.currentRoute === '/account-plan' && !this.showPro) {
      this.router.navigate(['/dashboard']);
    }

    // SUBSCRIBE TO BUSINESS CHANGED
    this.authService
      .getUserCompany$()
      .pipe(takeUntil(this.$destroy))
      .subscribe((team) => {
        this.sharedService.business = team;

        if (team) {
          this.showPro = team.value === 0;
          if (
            !this.sharedService.activeSubscription ||
            this.sharedService.activeSubscription.companyId !== team.companyId
          ) {
            this.reloadSubscription();
          }
        } else {
          this.sharedService.activeSubscription = undefined;
          this.authService.changeSubscription$(
            this.sharedService.activeSubscription
          );
        }

        if (this.currentRoute === '/account-plan' && !this.showPro) {
          this.router.navigate(['/dashboard']);
        }
      });
  }

  async ngOnInit() {
    if (!this.sharedService.activeSubscription) {
      await this.reloadSubscription();
    }
  }

  async reloadSubscription() {
    if (this.loadingSubscription) return;

    this.loadingSubscription = true;

    const ret = await this.billingService
      .getActiveSubscription(this.sharedService.business.companyId)
      .toPromise();

    this.loadingSubscription = false;

    if (ret.success) {
      this.sharedService.activeSubscription = ret.data;
      this.authService.changeSubscription$(ret.data);
    } else {
      this.sharedService.activeSubscription = undefined;
      this.authService.changeSubscription$(undefined);
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next();
  }
}

<app-shell-light>
    <div class="nk-content ">
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <div class="nk-block-head nk-block-head-sm">
                        <div class="nk-block-between">
                            <div class="nk-block-head-content">
                                <h3 class="nk-block-title page-title">Admin / Wine Data</h3>
                                <div class="nk-block-des text-soft">
                                    <p>Here's whats happning today</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-4">
                            <div class="card card-bordered search-border">
                                <div class="card-inner search-container">
                                    <div class="row nk-block mt-2">

                                        <div class="col-12 mt-1 mt-sm-0 col-sm-3">
                                            <div class="form-control-wrap">
                                                <ng-select name="userTypes" [(ngModel)]="filter.producers"
                                                    placeholder="Search by producers" multiple="true">
                                                    <ng-option *ngFor="let item of filterOptions.producers"
                                                        [value]="item.id">{{ item.text }}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <div class="col-12 mt-1 mt-sm-0 col-sm-3">
                                            <div class="form-control-wrap">
                                                <ng-select name="regions" [(ngModel)]="filter.regions"
                                                    placeholder="Search by regions" multiple="true">
                                                    <ng-option *ngFor="let item of filterOptions.regions | keyvalue"
                                                        [value]="item.key">{{ item.value }}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <div class="col-12 mt-1 mt-sm-0 col-sm-3">
                                            <div class="form-control-wrap">
                                                <ng-select name="varietals" [(ngModel)]="filter.varietals"
                                                    placeholder="Search by varieties" multiple="true">
                                                    <ng-option *ngFor="let item of filterOptions.varietals | keyvalue"
                                                        [value]="item.key">{{ item.value }}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <div class="col-12 mt-1 mt-sm-0 col-sm-3">
                                            <div class="form-control-wrap">
                                                <ng-select name="somms" [(ngModel)]="filter.somms"
                                                    placeholder="Search by Somms" multiple="true">
                                                    <ng-option *ngFor="let item of filterOptions.somms | keyvalue"
                                                        [value]="item.key">{{ item.value }}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ng-block mt-sm-2 mt-1">
                                        <div class="col-12 col-sm-3">
                                            <div class="form-control-wrap">
                                                <ng-select name="ratings" [(ngModel)]="filter.ratings"
                                                    placeholder="Search by Rating">
                                                    <ng-option *ngFor="let item of filterOptions.ratings | keyvalue"
                                                        [value]="item.key">{{ item.value }}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-3">
                                            <div class="form-control-wrap">
                                                <input type="text" class="form-control" [(ngModel)]="filter.vintageFrom"
                                                    placeholder="Vintage From" />
                                            </div>
                                        </div>

                                        <div class="col-12 mt-1 mt-sm-0 col-sm-3">
                                            <div class="form-control-wrap">
                                                <input type="text" class="form-control" [(ngModel)]="filter.vintageTo"
                                                    placeholder="Vintage To" />
                                            </div>
                                        </div>

                                        <!-- <div class="col-3">
                                            <div class="form-control-wrap">
                                                <input type="text" class="form-control" [(ngModel)]="filter.username"
                                                    placeholder="Search with username">
                                            </div>
                                        </div> -->

                                        <div class="col-12 mt-1 mt-sm-0 col-sm-3">
                                            <div class="form-control-wrap">
                                                <button class="btn btn-outline-primary btn-dim"
                                                    (click)="search(false, false)">
                                                    <em class="icon ni ni-reload mr-1"></em>
                                                    Refresh</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-bordered card-full">
                        <div class="card-inner p-0">
                            <div class="nk-tb-list nk-tb-orders d-none d-md-table">
                                <div class="nk-tb-item nk-tb-head">
                                    <div class="nk-tb-col tb-col-md"><span class="ml-2">Wine</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Stores</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>States</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Somms</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Players</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Rating</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>GTP</span></div>
                                    <div class="nk-tb-col tb-col-sm"></div>
                                </div>

                                <div class="nk-tb-item selectable" *ngFor="let overview of wines; let index = index">
                                    <div class="nk-tb-col tb-col-md">
                                        <div class="user-card">
                                            <div class="wine-image mr-1">
                                                <ng-conatiner *ngIf="overview?.wine.image; else defaultWineImage">
                                                    <img [src]="getWineImageUrl(overview.wine?.image)" class="m-1">
                                                </ng-conatiner>
                                                <ng-template #defaultWineImage>
                                                    <img src="../../assets/temp/barrelX_riesling.jpg" class="m-1">
                                                </ng-template>

                                            </div>
                                            <div class="user-name">
                                                <span class="tb-lead wine-info">{{ overview.wine?.producer }}
                                                    - {{
                                                    overview?.wine?.vintage }}</span>
                                                <span class="tb-lead wine-name">{{ overview.wine?.name }}</span>
                                                <span class="tb-lead wine-meta">
                                                    <img [src]="getWineIcon(overview.wine?.color)" class="grape"> {{
                                                    overview.wine?.variety}}
                                                    <img src="../../assets/flags/{{ getCountryFlagName(overview.wine?.country.trim()) }}.svg"
                                                        class="flag">
                                                    {{ getWineOrigin(overview?.wine) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ overview?.stores }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ overview?.states }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm" (click)="open(index)">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ overview?.somms }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm" (click)="open(index)">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ overview?.players }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm" (click)="open(index)">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ overview?.rating }}%
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm" (click)="open(index)">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ overview?.gtp }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>

                                            <tr>
                                                <td class="center">
                                                    <button class="btn btn-sm btn-action" (click)="open(index)">
                                                        <div>View</div>
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>



                            <div class="text-center empty" *ngIf="wines && wines.length === 0 && loading === false">
                                You have no wines yet<br>
                            </div>

                            <div class="text-center empty" *ngIf="loading === true">
                                <div class="spinner-border spinner-border-sm" role="status"> <span
                                        class="sr-only">Loading...</span></div>
                            </div>


                            <div class="col-12 py-2">
                                <ngb-pagination *ngIf="!loading" [(page)]="page" [pageSize]="length" [rotate]="true"
                                    [ellipses]="true" [boundaryLinks]="true" [collectionSize]="totalCount"
                                    (pageChange)="loadPage($event)">
                                </ngb-pagination>
                            </div>
                        </div>
                        <!-- <div class="card-inner-sm border-top text-center d-sm-none">
                            <a href="#" class="btn btn-link btn-block">See History</a>
                        </div> -->
                    </div>


                </div>
            </div>
        </div>
    </div>
</app-shell-light>
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { PagingResponse, ServerResponse } from "../_models/server-response";
import { PlayerFilter } from "../_models/player-filter.model";
import { Player } from "../_models/player.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlayerManagementService {
  constructor(private apiService: ApiService, private httpClient: HttpClient) { }

  getTotalGamesPlayedCount(filter: PlayerFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `player-management/get-played-total-games`,
      filter
    );
  }

  getTotalConnections(filter: PlayerFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `player-management/get-total-connections`,
      filter
    );
  }

  getAverageScore(filter: PlayerFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `player-management/get-average-score`,
      filter
    );
  }

  getAverageRating(filter: PlayerFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `player-management/get-average-rating`,
      filter
    );
  }

  getPlayers(filter: PlayerFilter) {
    return this.apiService.getByParams<PagingResponse<Player>>(
      `player-management/search`,
      filter
    );
  }

  fetchImportUrl(companyId: string): Observable<ServerResponse<string>> {
    return this.apiService.get<ServerResponse<string>>(
      `endgrate/${companyId}/import`
    );
  }

  fetchExportUrl(companyId: string): Observable<ServerResponse<string>> {
    return this.apiService.get<ServerResponse<string>>(
      `endgrate/${companyId}/export`
    );
  }

  getPlayer(playerId: string, companyId: string) {
    return this.apiService.get<ServerResponse<Player>>(
      `player-management/player/${playerId}?companyId=${companyId}`
    );
  }

  getPlayerHistory(filter: PlayerFilter) {
    return this.apiService.getByParams<PagingResponse<any>>(
      `player-management/player-history`,
      filter
    );
  }

  getFilterOptions(companyId: string) {
    return this.apiService.get<ServerResponse<any>>(
      `player-management/${companyId}/get-filters`
    );
  }

  getWineGlobalStats(id: string) {
    return this.apiService.get<ServerResponse<any>>(
      `player-management/get-stats/${id}/instance`
    );
  }

  export(filter: any) {
    return this.httpClient.post<Blob>(
      `${environment.apiURL}/player-management/export`,
      filter,
      { responseType: "blob" as "json" }
    );
  }

  import(companyId: string, fd: any): Observable<any> {
    return this.apiService.postForm(
      `player-management/${companyId}/import`,
      fd
    );
  }
  getAttributeRating(filter: PlayerFilter): Observable<any> {

    return this.apiService.getByParams(`player-management/get-attribute-rating`, filter);
  }
  getCharacteristicRating(filter: PlayerFilter): Observable<any> {

    return this.apiService.getByParams(`player-management/get-characteristic-rating`, filter);
  }
}

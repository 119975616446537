import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SupportService } from '../_services/support.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  message = '';
  isSaving = false;

  constructor(
    private toastService: ToastService,
    private supportService: SupportService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {}

  async sendMessage() {
    this.message = this.message.trim();

    if (!this.message) {
      this.toastService.showError('Please provide message before sending');
      return;
    }

    if (this.isSaving) { return; }

    this.isSaving = true;
    const versionAndBuild = `${environment.version} - ${environment.build}`;
    this.supportService
      .post(this.message, this.detectDevice(), versionAndBuild)
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe(async () => {
        this.toastService.showSuccess('Your message has been sent');
        this.message = '';
      });
  }

  detectDevice() {
    let deviceInfo = 'Management: ';
    deviceInfo += 'Browser: ' + this.deviceService.browser;
    deviceInfo += ', Brower_Version: ' + this.deviceService.browser_version;
    deviceInfo += ', Device_Type: ' + this.deviceService.deviceType;
    deviceInfo += ', Device: ' + this.deviceService.device;
    deviceInfo += ', OS: ' + this.deviceService.os;
    deviceInfo += ', OS_Version: ' + this.deviceService.os_version;
    return deviceInfo;
  }
}

import { Wine } from "./wine.model";

export class Note {
  wineId: string;
  wine: Wine;
  version: number;
  body: string;
  aroma: string;
  acidity: string;
  sugar: string;
  tannins: string;
  oak: string;
  flavors: Array<Flavor>;
  description: string;
  createdOn: Date;
  sparkling: boolean;
  variety: string;
  appellation: string;
  qualityOfVariety: number;
  qualityOfAppellation: number;
  valueForPrice: number;
  rating: number;
  foodPairingLink: string;
  foodPairing: string;
}

export class Flavor {
  value: string;
  selected: boolean;
}

<body class="nk-body npc-default pg-auth">
    <div class="nk-app-root">
        <div class="nk-main ">
            <div class="nk-wrap nk-wrap-nosidebar">
                <div class="nk-content ">
                    <div class="nk-block nk-block-middle nk-auth-body wide-xs">
                        <div class="brand-logo pb-4 text-center">
                            <a [routerLink]="['/']" routerLinkActive="router-link-active" class="logo-link">
                                <img class="" src="../../assets/logo/logo.png" alt="logo">
                            </a>
                        </div>
                        <div class="card card-bordered" *ngIf="step == 1">
                            <div class="card-inner card-inner-lg">
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <h4 class="nk-block-title">Step 1</h4>
                                        <div class="nk-block-des">
                                            <p>Tell us about your business.</p>
                                        </div>
                                    </div>
                                </div>
                                <form #addressForm="ngForm" (ngSubmit)="addressForm.valid && submitBusiness()">
                                    <div class="form-group">
                                        <label class="form-label" for="name">Business Name</label>
                                        <input type="text" class="form-control form-control-lg" name="businessName"
                                            [(ngModel)]="businessName" #business="ngModel" placeholder="Enter your name"
                                            required>
                                        <div *ngIf="addressForm.submitted && business.invalid" class="invalid mt-1">
                                            <div *ngIf="business.errors.required">This field is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="address">Business Address</label>
                                        <div>
                                            <input type="text" name="businessAddress"
                                                class="form-control form-control-lg" id="address"
                                                [(ngModel)]="businessAddress" #address="ngModel"
                                                placeholder="Enter business address" required>
                                            <div class="valid-feedback feedback-icon">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </div>
                                        <div *ngIf="addressForm.submitted && address.invalid" class="invalid mt-1">
                                            <div *ngIf="address.errors.required">This field is required.</div>
                                        </div>
                                    </div>
                                    <label class="form-label" for="name">Business Logo</label>
                                    <div class="form-group">
                                        <div class="custom-dropzone" dropzone (hovered)="toggleHover($event)"
                                            (dropped)="onDrop('business', $event)" [class.hovering]="isHovering">
                                            <ng-conatiner *ngIf="businessLogo; else showUploader">
                                                <div class="img-wraps">
                                                    <span class="closes" (click)="onRemove('business')"
                                                        title="Remove">×</span>
                                                    <img class="img-responsive" [src]="businessLogoSrc">
                                                </div>
                                            </ng-conatiner>
                                            <ng-template #showUploader>
                                                <span class="fs-13px">Drag and Drop a File</span>
                                                <span class="fs-13px">OR</span>
                                                <input style="display: none" name="businessLogo" type="file"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    (change)="onFilePicked('business', $event)" required
                                                    #hiddenfileinput>
                                                <button type="button" (click)="hiddenfileinput.click()"
                                                    class="btn btn-primary">SELECT</button>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="addressForm.submitted && !businessLogo"
                                            class="invalid-feedback mt-1">
                                            Please select any business logo
                                        </div>
                                    </div>
                                    <div class="d-flex mt-4">
                                        <div class="bg-light"><button type="button"
                                                class="btn btn-lg btn-light btn-block"
                                                (click)="cancel()">Cancel</button>
                                        </div>
                                        <div class="ml-auto bg-light">
                                            <button type="submit" [disabled]="!(addressForm.valid && businessLogo)"
                                                class="btn btn-lg btn-super btn-block">Next</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div class="card card-bordered" *ngIf="step == 2">
                            <div class="card-inner card-inner-lg">
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <h4 class="nk-block-title"> {{ hasPermissions ? 'Step 1' : 'Step 2' }}</h4>
                                        <div class="nk-block-des">
                                            <p>Tell us about you.</p>
                                        </div>
                                    </div>
                                </div>
                                <form #userForm="ngForm" (ngSubmit)="userForm.valid && submitUserDetail()">
                                    <div class="form-group">
                                        <label class="form-label" for="firstName">First Name</label>
                                        <input type="text" name="firstName" [(ngModel)]="firstName" #firstname="ngModel"
                                            class="form-control form-control-lg" id="firstName"
                                            placeholder="Enter your first name" required>
                                        <div *ngIf="userForm.submitted && firstname.invalid" class="invalid mt-1">
                                            <div *ngIf="firstname.errors.required">This field is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="lastName">Last Name</label>
                                        <input type="text" name="lastName" [(ngModel)]="lastName" #lastname="ngModel"
                                            class="form-control form-control-lg" id="lastName"
                                            placeholder="Enter your last name" required>
                                        <div *ngIf="userForm.submitted && lastname.invalid" class="invalid mt-1">
                                            <div *ngIf="lastname.errors.required">This field is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="userName">Username</label>
                                        <div class="form-control-wrap">
                                            <div class="input-group">
                                                <!--USERNAME INPUT -->
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">@</span>
                                                </div><input name="userName" type="text"
                                                    class="form-control form-control-lg" [(ngModel)]="userName"
                                                    (ngModelChange)="checkUsernameAvailability()" #username="ngModel"
                                                    placeholder="Username" required maxlength="15"
                                                    [ngClass]="{ 'border-success': !username.invalid && !isCheckingAvailability && isUsernameChanged && isUsernameAvailable,
                                                    'border-danger':!isCheckingAvailability && isUsernameChanged && !isUsernameAvailable}">
                                                <!-- CHECKING AVAILABILITY SPINNER -->
                                                <div class="valid-feedback feedback-icon"
                                                    *ngIf="isCheckingAvailability">
                                                    <div class="spinner-border spinner-border-sm" role="status"> <span
                                                            class="sr-only">Loading...</span></div>
                                                </div>
                                                <!-- USERNAME AVAILABILITY RESULT ICONS -->
                                                <ng-container *ngIf="!username.invalid && !isCheckingAvailability">
                                                    <div class="valid-feedback feedback-icon"
                                                        *ngIf="isUsernameChanged && isUsernameAvailable">
                                                        <em class="icon ni ni-check-circle fs-20px"></em>
                                                    </div>
                                                    <div class="invalid-feedback feedback-icon"
                                                        *ngIf="isUsernameChanged && !isUsernameAvailable">
                                                        <em class="icon fs-20px ni ni-cross-circle"></em>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <!-- USERNAME AVAILABILITY ERRORS -->
                                            <div class="valid-feedback mt-1"
                                                *ngIf="!userForm.submitted && !username.invalid && !isCheckingAvailability && isUsernameAvailable"
                                                style="font-style: normal;">
                                                <div>Username is available</div>
                                            </div>
                                            <div class="invalid-feedback mt-1"
                                                *ngIf="!userForm.submitted && !username.invalid && !isCheckingAvailability && !isUsernameAvailable">
                                                <div>Username is not available</div>
                                            </div>
                                            <!-- USERNAME INPUT ERRORS -->
                                            <div class="invalid-feedback mt-1"
                                                *ngIf="username.dirty && username.invalid">
                                                <div *ngIf="username.errors.required">This field is required.</div>
                                                <div *ngIf="username.errors.maxlength">Username cannot be greater than
                                                    15</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="jobAndCompany">Job Title @ {{ businessName
                                            }}</label>
                                        <input type="text" name="jobAndCompany" class="form-control form-control-lg"
                                            id="name" placeholder="Job Title" [(ngModel)]="jobTitle">
                                    </div>
                                    <label class="form-label" for="name">Your profile picture</label>
                                    <div class="">
                                        <div class="custom-dropzone" dropzone (hovered)="toggleHover($event)"
                                            (dropped)="onDrop('profile', $event)" [class.hovering]="isHovering">
                                            <ng-conatiner *ngIf="profileImage; else showProfileUploader">
                                                <div class="img-wraps">
                                                    <span class="closes" (click)="onRemove('profile')"
                                                        title="Remove">×</span>
                                                    <img class="img-responsive" [src]="userImageSrc">
                                                </div>
                                            </ng-conatiner>
                                            <ng-template #showProfileUploader>
                                                <span class="fs-13px">Drag and Drop a File</span>
                                                <span class="fs-13px">OR</span>
                                                <input style="display: none" type="file"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    (change)="onFilePicked('profile', $event)" #hiddenfileinput>
                                                <button type="button" (click)="hiddenfileinput.click()"
                                                    class="btn btn-primary">SELECT</button>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="userForm.submitted && !profileImage" class="invalid-feedback mt-1">
                                            Please select any profile image
                                        </div>
                                    </div>
                                    <div class="d-flex mt-4">
                                        <div class="bg-light" *ngIf="!hasPermissions"><button
                                                class="btn btn-lg btn-light btn-block" (click)="next(1)">Back</button>
                                        </div>
                                        <div class="ml-auto bg-light">
                                            <button type="submit" [disabled]="!(userForm.valid && profileImage)"
                                                class="btn btn-lg btn-super btn-block">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="card card-bordered" *ngIf="step == 3">
                            <div class="card-inner card-inner-lg">
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <h4 class="nk-block-title">{{ hasPermissions ? 'Step 3' : 'Step 2' }}</h4>
                                        <div class="nk-block-des">
                                            <p> Sommelier certification (optional)</p>
                                        </div>
                                    </div>
                                </div>
                                <form #sommForm="ngForm" (ngSubmit)="sommForm.valid && onboard()">
                                    <div class="form-group">
                                        <label class="form-label">Sommelier Certification</label>
                                        <div class="form-control px-0 border-0 d-flex justify-content-between">
                                            <span>Do you have sommelier certification?</span>
                                            <label class="switch">
                                                <input type="checkbox" (change)="isCertified = $event.target.checked">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>

                                    </div>

                                    <div class="form-group" *ngIf="isCertified">
                                        <label class="form-label" for="name">What certification type?</label>
                                        <select class="form-control" name="certificationType"
                                            [(ngModel)]="certificationType">
                                            <option value="">Select one...</option>
                                            <option [value]="cert" *ngFor="let cert of certs">{{ cert }}</option>
                                        </select>
                                        <div *ngIf="sommForm.submitted && !certificationType"
                                            class="invalid-feedback mt-1">
                                            Please select any certification type
                                        </div>
                                    </div>
                                    <div *ngIf="isCertified">
                                        <label class="form-label" for="name">Upload your certification</label>
                                        <div class="">
                                            <div class="custom-dropzone" dropzone (hovered)="toggleHover($event)"
                                                (dropped)="onDrop('certification', $event)"
                                                [class.hovering]="isHovering">
                                                <ng-conatiner *ngIf="certificationFile; else showCertificateUploader">
                                                    <div class="img-wraps">
                                                        <span class="closes" (click)="onRemove('certification')"
                                                            title="Remove">×</span>
                                                        <img class="img-responsive" [src]="certificationSrc">
                                                    </div>
                                                </ng-conatiner>
                                                <ng-template #showCertificateUploader>
                                                    <span class="fs-13px">Drag and Drop a File</span>
                                                    <span class="fs-13px">OR</span>
                                                    <input style="display: none" type="file"
                                                        accept="image/png, image/jpg, image/jpeg"
                                                        (change)="onFilePicked('certification', $event)"
                                                        #hiddenfileinput>
                                                    <button type="button" (click)="hiddenfileinput.click()"
                                                        class="btn btn-primary">SELECT</button>
                                                </ng-template>
                                            </div>
                                            <div *ngIf="sommForm.submitted && !certificationFile"
                                                class="invalid-feedback mt-1">
                                                Please upload certification file
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-4">
                                        <div class="bg-light"><button class="btn btn-lg btn-light btn-block"
                                                [disabled]="isLoading" (click)="next(2)">Back</button></div>

                                        <div class="ml-auto bg-light">
                                            <button type="submit" appButtonSpinner
                                                class="btn btn-lg btn-super btn-block" text="Get Started"
                                                [loadingState]="isLoading">
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PagingResponse, ServerResponse } from '../_models/server-response';
import { Template } from '../_models/template.model';
import { GameFilter } from '../_models/game-filter.model';
import { Instance } from '../_models/instance.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  httpOptions = {};
  constructor(private apiService: ApiService, private httpClient: HttpClient) {}

  create(fd: any): Observable<any> {
    return this.apiService.postForm('game/create', fd);
  }

  update(templateId, fd: any): Observable<any> {
    return this.apiService.postForm('game/update/' + templateId, fd);
  }

  getTemplates(filter: GameFilter): Observable<PagingResponse<Template>> {
    return this.apiService.getByParams<PagingResponse<Template>>(
      `game/${filter.companyId}/get-templates`,
      filter
    );
  }

  getTemplatesV2(filter: GameFilter): Observable<PagingResponse<Template>> {
    return this.apiService.getByParams<PagingResponse<Template>>(
      `game-management/${filter.companyId}/get-games`,
      filter
    );
  }

  getTemplateById(templateId: string): Observable<any> {
    return this.apiService.get(`game/template/${templateId}/details`);
  }

  getSankeyChart(templateId: string, sankeyPreference): Observable<any> {
    return this.apiService.getByParams(
      `game/${templateId}/get-sankey-chart`,
      sankeyPreference
    );
  }

  getFlavorwave(templateId: string, sankeyPreference): Observable<any> {
    return this.apiService.getByParams(
      `game/${templateId}/get-flavor-preference`,
      sankeyPreference
    );
  }

  getWinePreference(templateId: string): Observable<any> {
    return this.apiService.get(`game/${templateId}/get-wine-preference`);
  }

  getTemplateSequencesCount(templateId: string): Observable<any> {
    return this.apiService.get(`game/${templateId}/get-games-played-count`);
  }

  getUniquePlayerCount(templateId: string): Observable<any> {
    return this.apiService.get(`game/${templateId}/get-unique-players-count`);
  }

  getAverageTime(templateId: string): Observable<any> {
    return this.apiService.get(`game/${templateId}/get-average-time`);
  }

  getCorrectAnswerAverage(templateId: string): Observable<any> {
    return this.apiService.get(
      `game/${templateId}/get-average-correct-percetage`
    );
  }

  getPlayers(templateId: string, page: number, count: number): Observable<any> {
    return this.apiService.get(
      `game/${templateId}/get-instances?page=${page}&count=${count}`
    );
  }

  downloadQRCode(code: string, type: string): Observable<any> {
    return this.apiService.get<Blob>(
      `game/generate-qr?code=${code}&type=${type}`,
      {
        responseType: 'blob',
      }
    );
  }

  createInstance(
    templateId: string,
    model: any
  ): Observable<ServerResponse<Instance>> {
    return this.apiService.put(`game/${templateId}/create-instance`, model);
  }

  updateInstance(
    instanceId: string,
    model: any
  ): Observable<ServerResponse<Instance>> {
    return this.apiService.put(`game/${instanceId}/update-instance`, model);
  }

  setPrivate(templateId: string): Observable<ServerResponse<boolean>> {
    return this.apiService.put(`game/${templateId}/set-private`);
  }

  setPublic(templateId: string): Observable<ServerResponse<boolean>> {
    return this.apiService.put(`game/${templateId}/set-public`);
  }

  findProducerNamesByText(text: string): Observable<any> {
    return this.apiService.get(`game/find-producer-names-by-text?text=${text}`);
  }

  findWineNamesByText(text: string): Observable<any> {
    return this.apiService.get(`game/find-wine-names-by-text?text=${text}`);
  }

  findVarietalsByText(text: string): Observable<any> {
    return this.apiService.get(`game/find-wine-varietals-by-text?text=${text}`);
  }

  findWineRegionsByText(text: string): Observable<any> {
    return this.apiService.get(`game/find-wine-region-by-text?text=${text}`);
  }

  findWineCountriesByText(text: string): Observable<any> {
    return this.apiService.get(`game/find-wine-countries-by-text?text=${text}`);
  }

  findWineAppellationByText(text: string): Observable<any> {
    return this.apiService.get(
      `game/find-wine-appellation-by-text?text=${text}`
    );
  }

  getTotalCompletedSequenceCountByDefaultCompany(): Observable<any> {
    return this.apiService.get(
      `game/get-total-completed-sequence-count-by-default-company`
    );
  }
  getPreferenceSummary(templateId: string): Observable<any> {
    return this.apiService.get(`game/get-preference-summary/${templateId}`);
  }

  getFeedbackSummary(templateId: string): Observable<any> {
    return this.apiService.get(`game/get-feedback-summary/${templateId}`);
  }
  getAverageRating(templateId: string): Observable<any> {
    return this.apiService.get(`game/${templateId}/get-average-rating`);
  }

  export(filter: GameFilter): Observable<any> {
    return this.apiService.post(
      `game-management/${filter.companyId}/export`,
      filter,
      {
        responseType: 'blob',
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ServerResponse } from '../_models/server-response';
import { User } from '../_models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private apiService: ApiService) { }

    get(): Observable<ServerResponse<User>> {
        return this.apiService.get<ServerResponse<User>>('user');
    }

    getSommeliers(): Observable<ServerResponse<User[]>> {
        return this.apiService.get<ServerResponse<User[]>>('user/get-sommeliers');
    }

    updateDefaultCompany(o: any): Observable<ServerResponse<boolean>> {
        return this.apiService.post(`user/update-default/${o}/company`);
    }
}

<div class="center payment-wrapper">
  <div>
    <h3>Plans</h3>
    <div *ngFor="let plan of plans">
      <input type="radio" id="html" name="plan" [value]="plan.id" [(ngModel)]="planId" />
      {{ plan.name }} - {{ plan.amount | currency:plan.currency:'symbol-narrow'}}
    </div>
  </div>

  <ngx-stripe-card class="stripe mt-4" [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
  <div class="mt-4">
    <button type="submit" class="mr-2 btn btn-dark" (click)="subscribe()"> Start Subscription </button>
    <button type="submit" class=" btn btn-dark" (click)="charge()"> Charge 5$</button>
  </div>

  <div class="mt-5">
    {{ result | json }}
  </div>
</div>
export class DashboardFilter {
    companyId: string;
    durationType: number;
    producers: string[];
    varietalComparer: number;
    varietal: string[];
    vintageComparer: number;
    vintageFrom: number;
    vintageTo: number;
    regionComparer: number;
    region: string[];
    usernameComparer: number;
    username: string;
}
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { BillingService } from '../_services/billing.service';
import { EventService } from '../_services/event.service';
import { SharedService } from '../_services/shared.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-account-plan-extend',
  templateUrl: './account-plan-extend.component.html',
  styleUrls: ['./account-plan-extend.component.scss'],
})
export class AccountPlanExtendComponent implements OnInit {
  model: any = {};
  isSaving = false;
  @Input() allowEmailExtend = false;

  constructor(
    private activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private billingService: BillingService,
    private toastService: ToastService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.activeModal.close(false);
  }

  update(): void {
    if (this.isSaving) return;

    this.isSaving = true;

    this.billingService
      .buyEmails(this.sharedService.business.companyId, this.allowEmailExtend)
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe((m) => {
        if (m.success) {
          this.eventService.dispatchEvent({ type: 'reload_account_plan' });
          this.activeModal.close(this.model);
          this.toastService.showSuccess(m.message);
        } else {
          this.toastService.showError(m.message);
        }
      });
  }
}

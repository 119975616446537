<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
    <div class="nk-app-root">
        <div class="nk-main ">
            <div class="nk-wrap ">
                <div class="nk-header nk-header-fixed is-light">
                    <div class="container-fluid">
                        <app-header></app-header>
                    </div>
                </div>
                <app-side></app-side>
                <div class="nk-content ">
                    <div class="container-fluid">

                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <div class="nk-block-head nk-block-head-sm">
                                    <div class="nk-block-between">
                                        <div class="nk-block-head-content">
                                            <h3 class="nk-block-title page-title">Team Management</h3>
                                            <div class="nk-block-des text-soft">
                                                <p>Manage your team.</p>
                                            </div>
                                        </div>
                                        <div class="nk-block-head-content">
                                            <div class="toggle-wrap nk-block-tools-toggle">
                                                <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1"
                                                    data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                                                <div class="toggle-expand-content" data-content="pageMenu">
                                                    <ul class="nk-block-tools g-3">
                                                        <li>
                                                            <a routerLinkActive="router-link-active" (click)="addUser()"
                                                                class="btn btn-super"><em class="icon ni ni-plus"></em>
                                                                <span>Add User</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row g-gs">
                            <div class="col-lg-8 col-xxl-8">

                                <div class="nk-block nk-block-lg">
                                    <div class="card card-bordered card-preview">
                                        <table class="table table-tranx is-compact">
                                            <thead>
                                                <tr class="tb-tnx-head">
                                                    <th></th>
                                                    <th><span>Name</span></th>
                                                    <th><span>Email</span></th>
                                                    <th><span>Level</span> </th>
                                                    <th><span>Title</span> </th>
                                            </thead>
                                            <tbody>
                                                <tr *ngIf="members && members.length === 0 && loading === false">
                                                    <td colspan="5">
                                                        <div class="text-center sub-text my-3">
                                                            You have no team members yet<br>
                                                            <a (click)="addUser()" routerLinkActive="router-link-active"
                                                                class="btn btn-super btn-sm mt-3">
                                                                <em class="icon ni ni-plus"></em> <span>
                                                                    Add User</span>
                                                            </a>
                                                        </div>

                                                    </td>
                                                </tr>

                                                <tr *ngIf="loading === true">
                                                    <td colspan="5">
                                                        <div class="text-center my-5">
                                                            <div class="spinner-border spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span></div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr (click)="selectMember(member)" class="tb-tnx-item cursor-pointer"
                                                    [ngClass]="{'selected-member': selectedMember && selectedMember.id
                                                    ===
                                                    member.id}" *ngFor="let member of members">
                                                    <td class="avatar-col">
                                                        <div class="user-avatar">
                                                            <ng-conatiner
                                                                *ngIf="member.user.picture; else avatarDefault">
                                                                <img class="rounded-circle"
                                                                    [src]="getUserProfileImage(member.user.picture)">
                                                            </ng-conatiner>
                                                            <ng-template #avatarDefault>
                                                                <span>{{ getInitials(member.user.fullName) }}</span>
                                                            </ng-template>
                                                            <div class="status-circle"
                                                                [ngClass]="{'bg-success': member.isActive, 'bg-danger': !member.isActive }">
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <span>{{ member.user.fullName }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ member.user.email }}</span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="member.value === 0"
                                                            class="badge badge-danger">Owner</span>
                                                        <span *ngIf="member.value === 1"
                                                            class="badge badge-primary">Admin</span>
                                                        <span *ngIf="member.value === 2"
                                                            class="badge badge-warning">Employee</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ member.title ? member.title : '-' }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-4 col-xxl-4">
                                <div class="card card-bordered" *ngIf="selectedMember">
                                    <img src="..." class="card-img-top" alt="">
                                    <div class="card-inner ">
                                        <div class="d-flex justify-content-center">
                                            <div class="drodown menu" *ngIf="selectedMember.value !== 0">
                                                <a href="#"
                                                    class="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 mr-n1"
                                                    data-toggle="dropdown" aria-expanded="true">
                                                    <em class="icon ni ni-more-v"></em></a>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <ul class="link-list-opt no-bdr">
                                                        <li>
                                                            <a (click)="deleteAccount(selectedMember.id)">
                                                                <em class="icon ni ni-trash"></em>
                                                                <span>Delete Account</span>
                                                            </a>
                                                        </li>
                                                        <li *ngIf="!selectedMember.hasAcceptedInvite">
                                                            <a (click)="resendInvite(selectedMember.id)">
                                                                <em class="icon ni ni-question"></em>
                                                                <span>Resend Invite</span>
                                                            </a>
                                                        </li>
                                                        <li
                                                            *ngIf="selectedMember.hasAcceptedInvite && selectedMember.isActive">
                                                            <a (click)="disableAccount(selectedMember.id)">
                                                                <em class="icon ni ni-lock-alt-fill"></em>
                                                                <span>Disable Account</span>
                                                            </a>
                                                        </li>
                                                        <li
                                                            *ngIf="selectedMember.hasAcceptedInvite && !selectedMember.isActive">
                                                            <a (click)="enableAccount(selectedMember.id)">
                                                                <em class="icon ni ni-unlock-fill"></em>
                                                                <span>Activate Account</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="user-avatar lg user-avatar-lg">
                                                <ng-conatiner *ngIf="selectedMember.user.picture; else avatarDefault">
                                                    <img class="rounded-circle"
                                                        [src]="getUserProfileImage(selectedMember.user.picture)">
                                                </ng-conatiner>
                                                <ng-template #avatarDefault>
                                                    <span>{{ getInitials(selectedMember.user.fullName) }}</span>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center mt-2">
                                            <h5 class="card-title">{{ selectedMember.user.fullName }}</h5>
                                        </div>
                                        <div class="d-flex justify-content-center mt-1 text-soft">
                                            {{ selectedMember.title }}
                                        </div>
                                        <div class="d-flex justify-content-center mt-2">
                                            <span *ngIf="selectedMember.value === 0"
                                                class="badge badge-danger">Owner</span>
                                            <span *ngIf="selectedMember.value === 1"
                                                class="badge badge-primary">Admin</span>
                                            <span *ngIf="selectedMember.value === 2"
                                                class="badge badge-warning">Employee</span>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-12 mt-2">
                                                <div>
                                                    <span class="sub-text-sm fw-bold text-dark">Email</span>
                                                    <span class="lead-text">{{ selectedMember.user.email }}</span>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="selectedMember.value === 0; else editForm">
                                                <div class="col-12 mt-2">
                                                    <div>
                                                        <span class="sub-text-sm fw-bold text-dark">Permissions</span>
                                                        <span class="lead-text">Owner</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-2">
                                                    <div>
                                                        <span class="sub-text-sm fw-bold text-dark">Create Game</span>
                                                        <span class="lead-text">Yes</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-2">
                                                    <div>
                                                        <span class="sub-text-sm fw-bold text-dark">Public
                                                            Profile</span>
                                                        <span class="lead-text">Yes</span>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #editForm>
                                                <div class="col-12 mt-2">
                                                    <div>
                                                        <span
                                                            class="sub-text-sm fw-bold text-dark">Permissions</span><br>
                                                        <ng-select name="permissionValue"
                                                            [(ngModel)]="selectedMemberEdit.value" [items]="permissions"
                                                            bindLabel="name" bindValue="id" placeholder="Select
                                                          one...">
                                                        </ng-select>
                                                        <!-- <span class="badge badge-primary">Admin</span> -->
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="sub-text-sm fw-bold text-dark">Create Games</span>
                                                        <div class="custom-control custom-switch checked">
                                                            <input type="checkbox" name="game_creation_allowed"
                                                                [(ngModel)]="selectedMemberEdit.isGameCreationAllowed"
                                                                class="custom-control-input" name="allowedCreateGames"
                                                                id="create-games">
                                                            <label class="custom-control-label"
                                                                for="create-games"></label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-12 mt-3">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="sub-text-sm fw-bold text-dark">Public
                                                            Profile</span>
                                                        <div class="custom-control custom-switch checked">
                                                            <input type="checkbox" name="public_profile"
                                                                [(ngModel)]="selectedMemberEdit.isPublicProfile"
                                                                class="custom-control-input" name="isPublicProfile"
                                                                id="public-profile">
                                                            <label class="custom-control-label"
                                                                for="public-profile"></label>
                                                        </div>

                                                    </div>
                                                </div>


                                                <div class="col-12 mt-5" *ngIf="selectedMemberPermissionChanged">
                                                    <button (click)="updateMember()" appButtonSpinner
                                                        class="btn btn-super btn-block float-right" text="Update"
                                                        [loadingState]="isUpdating"></button>
                                                </div>
                                            </ng-template>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
<div class="form-group">
    <label class="form-label" for="label" [ngClass]="{ 'mb-0': readOnly }">
        {{ label }} <small>{{ subLabel }}</small> </label>

    <div class="form-control-wrap" *ngIf="!readOnly">
        <input type="text" class="form-control" name="tags" [(ngModel)]="value" [placeholder]="placeholder"
            (keydown.enter)="$event.preventDefault();add($event);" />
    </div>

    <div class="values">
        <span class="badge mt-1 rounded-pill bg-primary text-white mt-1" (click)="remove(index)"
            *ngFor="let item of items; let index = index;">{{ item }}
            <em class="icon ni ni-cross ml-1 cursor-pointer" *ngIf="!readOnly"></em>
        </span>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { finalize } from 'rxjs/operators';
import { BillingService } from '../_services/billing.service';
import { SharedService } from '../_services/shared.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-card-details-edit',
  templateUrl: './card-details-edit.component.html',
  styleUrls: ['./card-details-edit.component.scss']
})
export class CardDetailsEditComponent implements OnInit {

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  name = '';
  isSaving = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#3e4f63',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        '::placeholder': {
          color: '#becde6',
        },
      },
    },
    
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private activeModal: NgbActiveModal,
    private stripeService: StripeService,
    private billingService: BillingService,
    private sharedService: SharedService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.activeModal.close(false);
  }

  onConfirm() {
  }

  // async createToken() {
  //   this.isSaving = true;
  //   const stripe = await this.stripeService
  //     .createToken(this.card.element, { name: this.name })
  //     .toPromise();
  //   if (stripe.token) {
  //     this.createCustomerId(stripe.token.id);
  //   }
  //   if (stripe.error) {
  //     this.isSaving = false;
  //     console.log(stripe.error.message);
  //   }
  // }

  // createCustomerId(stripeToken: any) {
  //   this.billingService
  //     .createCustomerId(
  //       this.sharedService.business.companyId,
  //       stripeToken as string
  //     )
  //     .pipe(finalize(() => (this.isSaving = false)))
  //     .subscribe((res) => {
  //       if (res && res.success) {
  //         this.activeModal.close(res);
  //         this.toastService.showSuccess('Payment details updated');
  //       } else {
  //         this.toastService.showError('You are not allowed for this action');
  //       }
  //     });
  // }


}

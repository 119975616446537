export class PlayerFilter {
  companyId: string;
  playerId: string;
  durationType: number;
  producers: string[];
  varietalComparer: number;
  varietal: string[];
  vintageComparer: number;
  vintageFrom: number;
  vintageTo: number;
  regionComparer: number;
  region: string[];
  sommeliers: string[];
  usernameComparer: number;
  username: string;
  FilterBy: string[];
  sortBy: number;
  isSortByAscending: boolean;
  page: number;
  count: number;
  rating: number;
  filterBy: string;
}

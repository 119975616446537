<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
    <div class="nk-app-root">
        <div class="nk-main ">
            <div class="nk-wrap ">
                <div class="nk-header nk-header-fixed is-light">
                    <div class="container-fluid">
                        <app-header></app-header>
                    </div>
                </div>
                <app-side></app-side>
                <div class="nk-content white">
                    <div class="container-fluid">
                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <ng-container *ngIf="showPlans; else loadingContainer">
                                    <div class="nk-block-head nk-block-head-sm">
                                        <div class="text-center mt-5">
                                            <div class="nk-block-head-content">
                                                <h3 class="nk-block-title title-1">Go pro</h3>
                                                <div class="nk-block-des text-soft mt-2">
                                                    <p class="gr">Get the very best digital engagement for your wine
                                                        customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-center mt-3">
                                            <!-- FREE PLAN -->
                                            <div class="col-md-5 plan mr-2" [ngClass]="{'current': current === 'free'}">
                                                <div class="m-2">
                                                    <div class="type">Free</div>
                                                    <div class="fee">$0<span class="val">per month</span></div>
                                                    <p class="gr">Our free tools are state of the art, allow you to
                                                        engage
                                                        customers,
                                                        and increase sales both on-prem and off-prem.</p>
                                                    <div>
                                                        <button type="button" class="btn btn-primary justify-center"
                                                            (click)="onDowngradePlan()" appButtonSpinner
                                                            [loadingState]="downgradeLoading">
                                                            <img *ngIf="current === 'free'"
                                                                src="../../assets/icons/icons8-tick-white.svg"
                                                                class="mr-1">
                                                            {{(current === 'free') ?
                                                         'Current Plan':'Downgrade'}}</button>
                                                    </div>
                                                    <div class="mt-1">
                                                        <button type="button"
                                                            class="btn btn-opaque justify-center">Email
                                                            Support</button>
                                                    </div>
                                                    <div class="divider"></div>
                                                    <div class="bl">FEATURES</div>
                                                    <div class="gr">Everything but the email...</div>

                                                    <div>
                                                        <ul>
                                                            <li *ngFor="let feature of freeFeatures" class="list">
                                                                <img class="mr-2"
                                                                    src="../../../assets/icons/icons8-check-circle.svg"><span>{{feature}}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRO PLAN -->
                                            <div class="col-md-5 plan" [ngClass]="{'current': current === 'pro'}">
                                                <div class="m-2">
                                                    <div class="type">Pro
                                                    </div>
                                                    <div class="fee">$99<span class="val">per
                                                            month</span>
                                                    </div>
                                                    <p class="gr">Upgrade your engagement to include email
                                                        personalization
                                                        and automation. Somm Says will ensure </p>
                                                    <div>
                                                        <button type="button" (click)="onUpgradePlan()" appButtonSpinner
                                                            [loadingState]="loading"
                                                            class="btn btn-primary justify-center">
                                                            <img *ngIf="subscription?.isActive && current === 'pro'"
                                                                src="../../assets/icons/icons8-tick-white.svg"
                                                                class="mr-1">
                                                            {{(subscription?.isActive && current ==='pro') ? 'Current
                                                        Plan':'Upgrade'}}</button>

                                                    </div>
                                                    <div class="mt-1">
                                                        <button type="button"
                                                            class="btn btn-opaque justify-center">Email
                                                            Support</button>
                                                    </div>
                                                    <div class="divider"></div>
                                                    <div class="bl">FEATURES</div>
                                                    <div class="gr">Adds 1,000 automated, personalized emails per
                                                        month....
                                                    </div>

                                                    <div>
                                                        <ul>
                                                            <li *ngFor="let feature of proFeatures" class="list">
                                                                <img class="mr-2"
                                                                    src="../../../assets/icons/icons8-check-circle.svg"><span>{{feature}}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #loadingContainer>
                                    <div class="text-center empty">
                                        <div class="spinner-border spinner-border-sm" role="status"> <span
                                                class="sr-only">Loading...</span></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>



                </div>

                <!-- FREQUENTLY ASKED QUSTIONS -->

                <div class="nk-content">
                    <div class="container-fluid">
                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <div class="nk-block-head nk-block-head-sm">
                                    <div class="text-center mt-5">
                                        <div class="nk-block-head-content">
                                            <h3 class="nk-block-title title-2">Frequently asked questions</h3>
                                            <div class="nk-block-des text-soft mt-2">
                                                <p class="gr more">Still need more </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-center mt-4">
                                        <div class="col-md-6">
                                            <ul>
                                                <li class="list-1" *ngFor="let fq of frequentQuestions">
                                                    <div class="d-flex justify-between">
                                                        <div class="bl" [innerHtml]="fq.q"></div>
                                                        <img [src]="fq.expanded ? '../../assets/icons/icons8-collapse.svg' :'../../assets/icons/icons8-expand.svg'"
                                                            (click)="fq.expanded = !fq.expanded">
                                                    </div>
                                                    <div class="gr mt-2" *ngIf="fq.expanded" [innerHtml]="fq.a">
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-center mt-3">
                                        <div class="col-md-10 footer p-4">
                                            <div class="d-flex justify-center mt-3">
                                                <img [ngClass]="{'merge-image':i === 1}" class="img"
                                                    *ngFor="let img of images;let i = index" [src]="img">
                                            </div>
                                            <div class="text-center mt-3 bl">Still have questions?</div>
                                            <div class="text-center mt-2 gr">Can’t find the answer you’re looking for?
                                                Please chat to our
                                                friendly team.</div>
                                            <div class="col-md-2 margin-auto">
                                                <button type="button" class="mt-3 btn btn-primary justify-center">Get in
                                                    touch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
<div class="nk-sidebar" data-content="sidebarMenu">
    <div class="nk-sidebar-inner" data-simplebar>
        <ul class="nk-menu nk-menu-md">

            <li class="nk-menu-item">
                <a [routerLink]="['/dashboard']" routerLinkActive="active" class="nk-menu-link">
                    <img [src]="currentRoute === '/dashboard' ? '../../../assets/icons/icons8-dashboard-selected.svg' : '../../../assets/icons/icons8-dashboard.svg'"
                        class="icon">
                    <span class="nk-menu-text">Dashboard</span>
                </a>
            </li>

            <li class="nk-menu-item">
                <a [routerLink]="['/games']" routerLinkActive="active" class="nk-menu-link">
                    <img [src]="currentRoute === '/games' ? '../../../assets/icons/icons8-hierarchy-selected.svg' : '../../../assets/icons/icons8-hierarchy.svg'"
                        class="icon">
                    <span class="nk-menu-text">Games</span>
                </a>
            </li>

            <li class="nk-menu-item">
                <a [routerLink]="['/player-management']" routerLinkActive="active" class="nk-menu-link">
                    <img [src]="currentRoute === '/player-management' ? '../../../assets/icons/icons8-contacts-selected.svg' : '../../../assets/icons/icons8-contacts.svg'"
                        class="icon">
                    <span class="nk-menu-text">Players</span>
                </a>
            </li>

            <li class="nk-menu-item">
                <a [routerLink]="['/team']" routerLinkActive="active" class="nk-menu-link">
                    <img [src]="currentRoute === '/team' ? '../../../assets/icons/icons8-teamwork-selected.svg' : '../../../assets/icons/icons8-teamwork.svg'"
                        class="icon">
                    <span class="nk-menu-text">Team</span>
                </a>
            </li>


            <li class="nk-menu-item">
                <a [routerLink]="['/enterprise']" routerLinkActive="active" class="nk-menu-link">
                    <img [src]="currentRoute === '/enterprise' ? '../../../assets/icons/icons8-new-company-selected.svg' : '../../../assets/icons/icons8-new-company.svg'"
                        class="icon">
                    <span class="nk-menu-text">Settings</span>
                </a>
            </li>

            <li class="nk-menu-item">
                <a [routerLink]="['/profile']" routerLinkActive="active" class="nk-menu-link">
                    <img [src]="currentRoute === '/profile' ? '../../../assets/icons/icons8-user-selected.svg' : '../../../assets/icons/icons8-user.svg'"
                        class="icon">
                    <span class="nk-menu-text">Profile</span>
                </a>
            </li>

            <li class="nk-menu-item has-sub" [hidden]="!showAdmin">
                <a href="javascript:;" class="nk-menu-link nk-menu-toggle"
                    [ngClass]="{ 'active': currentRoute === '/wines' || currentRoute === '/producer'}">
                    <span class="nk-menu-icon">
                        <em class="icon ni ni-signin"></em>
                    </span>
                    <span class="nk-menu-text">Admin</span>
                </a>
                <ul class="nk-menu-sub">
                    <li class="nk-menu-item">
                        <a [routerLink]="['/wines']" routerLinkActive="active" class="nk-menu-link"
                            data-bs-original-title="" title="">
                            <span class="nk-menu-text">Wine Data</span>
                        </a>
                    </li>
                    <li class="nk-menu-item">
                        <a [routerLink]="['/producer']" routerLinkActive="active" class="nk-menu-link">
                            <span class="nk-menu-text">Producer</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nk-menu-item" [hidden]="!showPro">
                <a [routerLink]="['/account-plan']" routerLinkActive="active" class="nk-menu-link">
                    <img [src]="currentRoute === '/account-plan' ? '../../../assets/icons/icons8-go-pro.svg' : '../../../assets/icons/icons8-go-pro.svg'"
                        class="icon">
                    <span class="nk-menu-text">Go Pro</span>
                </a>
            </li>

        </ul>
    </div>
</div>
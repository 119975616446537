import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SupportService {
  httpOptions = {};

  constructor(private http: HttpClient) {}

  post(message: string, platform: string, build: string) {
    const formData: FormData = new FormData();
    formData.append("message", message);
    formData.append("platform", platform);
    formData.append("build", build);
    return this.http.post(environment.apiURL + "/support", formData).pipe(
      map((response: any) => {
        const ret = response;
        if (ret) {
          return ret;
        } else {
          console.log("error");
        }
      })
    );
  }
}

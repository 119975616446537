import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PagingResponse, ServerResponse } from '../_models/server-response';
import { Company } from '../_models/company.model';
import { Team } from '../_models/team';

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    constructor(private apiService: ApiService) { }

    getMembers(companyId: string, page: number, count: number): Observable<PagingResponse<Team>> {
        return this.apiService.get<PagingResponse<Team>>(`teamManagement/team?page=${page}&count=${count}&companyId=${companyId}`);
    }

    sendInvite(model: any): Observable<ServerResponse<Team>> {
        return this.apiService.post(`teamManagement/team/send-invite`, model);
    }

    updatePermission(model): Observable<ServerResponse<any>> {
        return this.apiService.post(`teamManagement/team/update-permission`, model);
    }

    acceptInvitation(id, token): Observable<ServerResponse<any>> {
        return this.apiService.post(`teamManagement/team/accept-invite/${id}/token/${token}`);
    }

    resendInvite(id): Observable<ServerResponse<any>> {
        return this.apiService.post(`teamManagement/team/${id}/resent-invite`);
    }

    enablePermission(id): Observable<ServerResponse<any>> {
        return this.apiService.put(`teamManagement/permission/${id}/enable`);
    }

    disablePermission(id): Observable<ServerResponse<any>> {
        return this.apiService.put(`teamManagement/permission/${id}/disabled`);
    }

    enablePublicProfile(companyId, userId): Observable<ServerResponse<any>> {
        return this.apiService.put(`teamManagement/permission/${companyId}/enable-public-profile/${userId}`);
    }

    disablePublicProfile(companyId, userId): Observable<ServerResponse<any>> {
        return this.apiService.put(`teamManagement/permission/${companyId}/disable-public-profile/${userId}`);
    }

    deleteAccount(id): Observable<ServerResponse<any>> {
        return this.apiService.delete(`teamManagement/account/` + id);
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { SharedService } from 'src/app/_services/shared.service';


@Injectable({
    providedIn: 'root',
})
export class OnboardGuard implements CanActivate {
    constructor(
        private router: Router,
        private sharedService: SharedService
    ) { }

    async canActivate(): Promise<boolean> {
        if (this.sharedService.token) {
            if (this.isOnboarded()) {
                return true;
            }

            this.router.navigate(['/onboard']);
            return false;
        }

        const authToken = localStorage.getItem("token");
        if (authToken) {
            this.sharedService.token = authToken;
            if (this.isOnboarded()) {
                return true;
            }

            this.router.navigate(['/onboard']);
            return false;
        }

        this.router.navigate(['/signin']);
        return false;
    }

    isOnboarded(): boolean {
        var user = JSON.parse(localStorage.getItem("user")) as User;
        return user.isOnboarded;
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-public',
  templateUrl: './enterprise-public.component.html',
  styleUrls: ['./enterprise-public.component.scss']
})
export class EnterprisePublicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

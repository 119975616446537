<app-shell-light>
    <div class="nk-content ">
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <div class="nk-block">
                        <app-global-filters [title]="title" [subTitle]="subTitle" [filter]="filter"
                            (duration)="changeDuration($event)" (createGame)="createGame()"
                            [filterOptions]="filterOptions" (load)="load()" [showGames]="showGames">
                        </app-global-filters>
                        <div class="row g-gs mt-07">

                            <!-- QR Scans -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icon-recommendation.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">QR Scans</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left"
                                                    title="Total number of QR scans performed"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingTotalScansCount" class="spinner-border spinner-border-sm"
                                                role="status"> <span class="sr-only">Loading...</span></div>
                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingTotalScansCount">
                                                <span class="amount">{{ totalScans }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span class="{{ totalScansDifference < 0 ? 'text-danger' : 'text-success' }}
                                                            fw-medium">{{
                                                        totalScansDifference > -1 ? '+' : ''}}
                                                        {{ totalScansDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- People Played -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icons8-omnichannel.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">People Played</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left"
                                                    title="Total number of players have played"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingTotalConnectionsCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>

                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingTotalConnectionsCount">
                                                <span class="amount">{{ totalConnections }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span
                                                        class="{{ totalConnectionsDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                                                        totalConnectionsDifference > -1 ? '+' : ''}}
                                                        {{ totalConnectionsDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Buy Now Clicks -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icon-buylink.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Buy Now Clicks</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left" title="Total buy now clicks"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingBuyNowClickCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>

                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingBuyNowClickCount">
                                                <span class="amount">{{ totalBuyNowClicks }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span class="{{ totalBuyNowClicksDifference < 0 ? 'text-danger' : 'text-success' }}
                                                        fw-medium">{{
                                                        totalBuyNowClicksDifference > -1 ? '+' : ''}}
                                                        {{ totalBuyNowClicksDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Followers -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icons8-staff.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Followers</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left" title="Total number of followers"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingTotalFollowersCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                            <span *ngIf="!loadingTotalFollowersCount" class="amount">{{ totalFollowers
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Games Played -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icons8-champagne.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Games Played</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left"
                                                    title="Total number of games have been played"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingTotalGamesPlayedCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingTotalGamesPlayedCount">
                                                <span class="amount">{{ totalGamesPlayed }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span
                                                        class="{{ totalGamesPlayerDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                                                        totalGamesPlayerDifference > -1 ? '+' : ''}}
                                                        {{ totalGamesPlayerDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Recommendations Requested -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icon-recommendation.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Rec Requested</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left"
                                                    title="Total number of recommendation request received and responded"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingRecommendationsCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingRecommendationsCount">
                                                <span class="amount">{{ totalRecommendationReceived }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span class="{{ totalRecommendationReceivedDifference < 0 ? 'text-danger' : 'text-success' }}
                                                            fw-medium">{{
                                                        totalRecommendationReceivedDifference > -1 ? '+' : ''}}
                                                        {{ totalRecommendationReceivedDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Recommendations Fulfilled -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icon-recommendation.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Rec Fulfilled</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left"
                                                    title="Total number of recommendation request received and responded"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingRecommendationsRespondedCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingRecommendationsRespondedCount">
                                                <span class="amount">{{ totalRecommendationsResonded }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span class="{{ totalRecommendationReceivedDifference < 0 ? 'text-danger' : 'text-success' }}
                                                            fw-medium">{{
                                                        totalRecommendationsResondedDifference > -1 ? '+' : ''}}
                                                        {{ totalRecommendationsResondedDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Preference -->
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <!-- <img src="../../assets/icons/icon-recommendation.svg" class="icn"> -->
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Avg Preference</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left" title="Average perferences overall"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingAveragePreferences"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingAveragePreferences">
                                                <span class="amount">{{ averagePreferences }}%</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span class="{{ averagePreferencesDifference < 0 ? 'text-danger' : 'text-success' }}
                                                            fw-medium">{{
                                                        averagePreferencesDifference > -1 ? '+' : ''}}
                                                        {{ averagePreferencesDifference }}
                                                        %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12 col-xxl-12">
                                <div class="card card-bordered card-full">
                                    <div class="card-inner border-bottom card-sm-inner">
                                        <div class="card-title-group card-sm-heading">
                                            <div class="card-title">
                                                <h6 class="title">Top Wines</h6>
                                                <p>Check out your top wines for the period and sort by clicking on column headers.</p>
                                            </div>
                                            <div class="card-tools" *ngIf="false">
                                                <ul class="card-tools-nav">
                                                    <li class="active"><a href="#"><span>See all</span></a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="nk-tb-list nk-tb-orders mt-2 d-none d-md-table">
                                            <div class="nk-tb-item nk-tb-head">
                                                <div class="nk-tb-col">
                                                    <span class="ml-2">Name</span>
                                                </div>
                                                <div (click)="applyTopWineSort(1)" class="nk-tb-col">
                                                    <span>Games</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getTopWineDataTableSortClass(1)">
                                                    </em>
                                                </div>
                                                <div (click)="applyTopWineSort(2)" class="nk-tb-col">
                                                    <span>Players</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getTopWineDataTableSortClass(2)">
                                                    </em>
                                                </div>
                                                <div (click)="applyTopWineSort(3)" class="nk-tb-col"><span>Avg
                                                        Rating</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getTopWineDataTableSortClass(3)">
                                                    </em>
                                                </div>
                                                <div (click)="applyTopWineSort(4)" class="nk-tb-col"><span>GTP
                                                        Diff</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getTopWineDataTableSortClass(4)">
                                                    </em>
                                                </div>
                                                <div class="nk-tb-col tb-col-sm cursor-pointer">
                                                    <span>Rec Status</span>
                                                </div>
                                                <div (click)="applyTopWineSort(5)"
                                                    class="nk-tb-col tb-col-sm cursor-pointer">
                                                    <span>Rec fulfilled</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getTopWineDataTableSortClass(5)">
                                                    </em>
                                                </div>
                                                <div class="nk-tb-col"><span>Buy Clicks</span>
                                                    <em (click)="applyTopWineSort(6)" class="fs-14px float-left cursor-pointer icon ni
                                                        ni-arrow-long-up" [ngClass]="getTopWineDataTableSortClass(6)">
                                                    </em>
                                                </div>
                                            </div>
                                            <div class="nk-tb-item selectable"
                                                [routerLink]="['/game', topWine.templateId]"
                                                *ngFor="let topWine of topWines">
                                                <div class="nk-tb-col">
                                                    <div class="user-card">
                                                        <div class="wine-image">
                                                            <ng-conatiner
                                                                *ngIf="topWine.wine?.image; else defaultWineImage">
                                                                <img src="{{env.cdn}}wines/{{topWine.wine?.image}}">
                                                            </ng-conatiner>
                                                            <ng-template #defaultWineImage>
                                                                <img src="../../assets/temp/barrelX_riesling.jpg">
                                                            </ng-template>

                                                        </div>
                                                        <div class="user-name ml-2">
                                                            <span class="tb-lead wine-info">{{ topWine.wine.producer }}
                                                                - {{ topWine.wine.vintage }}</span>
                                                            <span class="tb-lead wine-name">{{ topWine.wine.name
                                                                }}</span>
                                                            <span class="tb-lead wine-meta">
                                                                <img [src]="getWineIcon(topWine.wine?.color)"
                                                                    class="grape">
                                                                {{ topWine.wine.variety}}
                                                                <img src="../../assets/flags/{{topWine.wine.country}}.svg"
                                                                    class="flag ml-0">
                                                                {{ getWineOrigin(topWine.wine) }}
                                                            </span>
                                                            <span class="tb-lead my-1 wine-meta">
                                                                <img [src]="getBuyItNowIcon(topWine.haveBuyLink)"
                                                                    class="wr">
                                                                Buy It Now
                                                                <img [src]="getFoodPairingIcon(topWine.haveFoodPairing)"
                                                                    class="wr ml-1">
                                                                Food Pairing
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="nk-tb-col tb-col-sm data-item-num-0">
                                                    <div class="d-flex flex-column">
                                                        <span>
                                                            {{ topWine.totalGames }}
                                                        </span>

                                                        <span *ngIf="filter.durationType !== 1" class="{{ topWine.differenceOfTotalGames < 0 ? 'text-danger' : 'text-success' }}
                                                             fw-medium
                                                             fs-12px">{{ topWine.differenceOfTotalGames > -1 ? '+' :
                                                            ''}}
                                                            {{ topWine.differenceOfTotalGames }}</span>
                                                    </div>
                                                    <!-- {{ topWine.totalGames }} -->
                                                </div>

                                                <div class="nk-tb-col tb-col-sm data-item-num-0">
                                                    {{ topWine.players }}
                                                </div>

                                                <div class="nk-tb-col tb-col-sm data-item-num-0">
                                                    <div class="d-flex flex-column">
                                                        <span>
                                                            {{ topWine.averagePreference }}%
                                                        </span>

                                                        <span *ngIf="filter.durationType !== 1"
                                                            class="{{ topWine.avgPreferenceDiffPercentage < 0 ? 'text-danger' : 'text-success' }} fw-medium fs-12px">{{
                                                            topWine.avgPreferenceDiffPercentage > -1 ? '+' : ''}}
                                                            {{ topWine.avgPreferenceDiffPercentage }} %</span>
                                                    </div>
                                                </div>

                                                <div class="nk-tb-col tb-col-sm data-item-num-0">
                                                    {{ topWine.gtp }}%
                                                </div>


                                                <div class="nk-tb-col data-item-num-0">
                                                    <div class="rec-status" *ngIf="topWine.recommendation?.completed">
                                                        <img src="../../assets/icons/icons8-waiter-green.svg">
                                                        <span>Complete({{topWine.recommendation?.completed}})</span>
                                                    </div>
                                                    <div class="rec-status default"
                                                        *ngIf="topWine.recommendation?.default">
                                                        <img src="../../assets/icons/icons8-waiter-green.svg">
                                                        <span>Default({{topWine.recommendation?.default}})</span>
                                                    </div>
                                                    <div class="rec-status pending"
                                                        *ngIf="topWine.recommendation?.pending">
                                                        <img src="../../assets/icons/icons8-waiter-grey.svg">
                                                        <span>Pending({{topWine.recommendation?.pending}})</span>
                                                    </div>

                                                </div>
                                                <div class="nk-tb-col data-item-num-0">
                                                    <div *ngIf="topWine.recommendation?.completed ||
                                                        topWine.recommendation?.pending">
                                                        <span class="red"
                                                            [ngClass]="{'green':topWine.recommendation?.completed+topWine.recommendation?.default
                                                            ===
                                                            topWine.recommendation?.completed+topWine.recommendation?.pending+topWine.recommendation?.default}">
                                                            {{topWine.recommendation?.completed+topWine.recommendation?.default}}/{{topWine.recommendation?.completed+topWine.recommendation?.pending+topWine.recommendation?.default}}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div class="nk-tb-col tb-col-sm data-item-num-0">
                                                    {{ topWine.buyClicks }}
                                                </div>

                                            </div>
                                        </div>

                                        <div class="nk-tb-list nk-tb-orders mt-2 d-block d-md-none">
                                            <div class="py-3 px-2 " [routerLink]="['/game', topWine.templateId]"
                                                *ngFor="let topWine of topWines; let odd = odd"
                                                [ngClass]="{'bg-grey': odd }">
                                                <div class="row">
                                                    <div class="col-4 pr-0">
                                                        <span>Name</span>
                                                    </div>
                                                    <div class="user-card col-8 pl-0">
                                                        <div class="wine-image">
                                                            <ng-conatiner
                                                                *ngIf="topWine.wine?.image; else defaultWineImage">
                                                                <img src="{{env.cdn}}wines/{{topWine.wine?.image}}">
                                                            </ng-conatiner>
                                                            <ng-template #defaultWineImage>
                                                                <img src="../../assets/temp/barrelX_riesling.jpg">
                                                            </ng-template>

                                                        </div>
                                                        <div class="user-name ml-2">
                                                            <span class="tb-lead wine-info">{{ topWine.wine.producer }}
                                                                - {{ topWine.wine.vintage }}</span>
                                                            <span class="tb-lead wine-name">{{ topWine.wine.name
                                                                }}</span>
                                                            <span class="tb-lead wine-meta">
                                                                <img [src]="getWineIcon(topWine.wine?.color)"
                                                                    class="grape">
                                                                {{ topWine.wine.variety}}
                                                                <img src="../../assets/flags/{{topWine.wine.country}}.svg"
                                                                    class="flag">
                                                                {{ getWineOrigin(topWine.wine) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-4 pr-0">
                                                        <span>Preference</span>
                                                    </div>
                                                    <div class="col-8 d-flex pl-0">
                                                        <div class="fs-14 fw-medium text-dark value">
                                                            {{ topWine.averagePreference }}%</div>
                                                        <div *ngIf="filter.durationType !== 1"
                                                            class="{{ topWine.avgPreferenceDiffPercentage < 0 ? 'text-danger' : 'text-success' }} fw-medium fs-11px sub-value">
                                                            {{ topWine.avgPreferenceDiffPercentage > -1 ? '+' : ''}}
                                                            {{ topWine.avgPreferenceDiffPercentage }} %</div>
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-4 pr-0">
                                                        <span>Games</span>
                                                    </div>
                                                    <div class="col-8 d-flex pl-0">
                                                        <div class="fs-14 fw-medium text-dark value">
                                                            {{ topWine.totalGames }}
                                                        </div>
                                                        <div *ngIf="filter.durationType !== 1" class="{{ topWine.differenceOfTotalGames < 0 ? 'text-danger' : 'text-success' }}
                                                            fw-medium fs-11px sub-value">
                                                            {{ topWine.differenceOfTotalGames > -1 ? '+' : ''}}
                                                            {{ topWine.differenceOfTotalGames }}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div class="w-100 py-4 text-center" *ngIf="loadingTopWines">
                                            <div class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                        </div>
                                        <div class="w-100 pt-3 text-center"
                                            *ngIf="!loadingTopWines && topWines.length < 1">
                                            <span>No records found</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-xxl-6">
                                <div class="card card-bordered card-full">
                                    <div class="card-inner border-bottom">
                                        <div class="card-title-group">
                                            <div class="card-title w-100">
                                                <h6 class="title">Top Players</h6>
                                                <p>See who is playing the most games.</p>
                                            </div>
                                            <div class="card-tools" *ngIf="false">
                                                <ul class="card-tools-nav">
                                                    <li class="active"><a href="#"><span>See all</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="nk-tb-list nk-tb-customers mt-2">
                                            <div class="nk-tb-item nk-tb-head">
                                                <div class="nk-tb-col"><span class="ml-2">Name</span></div>
                                                <div class="nk-tb-col">
                                                    <span class="d-none d-md-block">Games</span>
                                                    <span class="d-block d-md-none">Gms</span>
                                                </div>
                                                <div class="nk-tb-col">
                                                    <span class="d-none d-md-block">Preference</span>
                                                    <span class="d-block d-md-none">Pref</span>
                                                </div>
                                            </div>

                                            <div class="nk-tb-item selectable" *ngFor="let customer of topCustomers"
                                                [routerLink]="['/player-management/detail',customer.userId]">

                                                <div class="nk-tb-col pl-2">
                                                    <div class="user-card">
                                                        <div class="user-avatar user-avatar-xs bg-pink-dim">
                                                            <ng-conatiner *ngIf="customer.image; else avatarDefault">
                                                                <img class="rounded-circle"
                                                                    src="{{env.cdn}}profiles/{{customer.image}}">
                                                                <img *ngIf="customer.isKnownCustomer"
                                                                    class="known-customer"
                                                                    src="../../assets/icons/customer.svg">
                                                            </ng-conatiner>
                                                            <ng-template #avatarDefault>
                                                                <span>{{ getInitials(customer.name) }}</span>
                                                            </ng-template>
                                                        </div>
                                                        <div class="user-name">
                                                            <div class="fullname">{{ customer.name | titlecase}}</div>
                                                            <div class="username">{{ customer.username }} <span
                                                                    class="badge badge-light text-dark ml-3"
                                                                    *ngIf="customer.hasFollowed">Follows You</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- 
                                                <div class="nk-tb-col" (click)="selectTemplate()">
                                                    <div class="nk-activity-data">
                                                        <div class="label">{{ customer.name | titlecase }}</div>
                                                        <span class="time">{{ customer.username }}</span>
                                                    </div>
                                                </div> -->
                                                <div class="nk-tb-col data-item-num-0">
                                                    <div class="d-flex flex-column">
                                                        <span>
                                                            {{ customer.totalGamesPlayed }}
                                                        </span>

                                                        <span *ngIf="filter.durationType !== 1" class="{{ customer.differenceOfTotalGamesPlayed < 0 ? 'text-danger' : 'text-success' }}
                                                             fw-medium
                                                             fs-12px">{{ customer.differenceOfTotalGamesPlayed > -1 ?
                                                            '+' : ''}}
                                                            {{ customer.differenceOfTotalGamesPlayed }}</span>
                                                    </div>
                                                </div>
                                                <div class="nk-tb-col data-item-num-0">
                                                    <div class="d-flex flex-column">
                                                        <span>
                                                            {{ customer.averagePreference }}%
                                                        </span>

                                                        <span *ngIf="!loadingTopCustomers && filter.durationType !== 1"
                                                            class="{{  customer.avgPreferenceDiffPercentage < 0 ? 'text-danger' : 'text-success' }} fw-medium fs-12px">{{
                                                            customer.avgPreferenceDiffPercentage > -1 ? '+' : ''}}
                                                            {{ customer.avgPreferenceDiffPercentage }} %</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-100 py-4 text-center" *ngIf="loadingTopCustomers">
                                            <div class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                        </div>
                                        <div class="w-100 pt-3 text-center"
                                            *ngIf="!loadingTopCustomers && topCustomers.length < 1">
                                            <span>No records found</span>
                                        </div>
                                    </div>
                                    <!-- <div class="card-inner-sm border-top text-center d-sm-none">
                                        <a href="#" class="btn btn-link btn-block">See History</a>
                                    </div> -->
                                </div>
                            </div>

                            <div class="col-md-6 col-xxl-6">
                                <div class="card card-bordered card-full">
                                    <div class="card-inner border-bottom">
                                        <div class="card-title-group">
                                            <div class="card-title">
                                                <h6 class="title">Top Connectors</h6>
                                                <p>See who is sharing your games with the most people.</p>
                                            </div>
                                            <div class="card-tools" *ngIf="false">
                                                <ul class="card-tools-nav">
                                                    <li class="active"><a href="#"><span>See all</span></a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="nk-tb-list nk-tb-customers mt-2">
                                            <div class="nk-tb-item nk-tb-head">
                                                <div class="nk-tb-col"><span class="ml-2">Name</span></div>
                                                <div class="nk-tb-col">
                                                    <!-- <span>Connections</span> -->
                                                    <span class="d-none d-md-block">Connections</span>
                                                    <span class="d-block d-md-none">Cons</span>
                                                </div>
                                                <div class="nk-tb-col">
                                                    <span class="d-none d-md-block">Preference</span>
                                                    <span class="d-block d-md-none">Pref</span>
                                                </div>
                                            </div>
                                            <div class="nk-tb-item selectable" *ngFor="let connector of topConnectors"
                                                [routerLink]="['/player-management/detail',connector.userId]">

                                                <div class="nk-tb-col pl-2">
                                                    <div class="user-card">
                                                        <div class="user-avatar user-avatar-xs bg-pink-dim">
                                                            <ng-conatiner *ngIf="connector.image; else avatarDefault">
                                                                <img class="rounded-circle"
                                                                    src="{{env.cdn}}profiles/{{connector.image}}">
                                                                <img *ngIf="connector.isKnownCustomer"
                                                                    class="known-customer"
                                                                    src="../../assets/icons/customer.svg">
                                                            </ng-conatiner>
                                                            <ng-template #avatarDefault>
                                                                <span>{{ getInitials(connector.name) }}</span>
                                                            </ng-template>
                                                        </div>
                                                        <div class="user-name">
                                                            <div class="fullname">{{ connector.name | titlecase}}</div>
                                                            <div class="username">{{ connector.username }} <span
                                                                    class="badge badge-light text-dark ml-3"
                                                                    *ngIf="connector.hasFollowed">Follows You</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- <div class="nk-tb-col tb-col-sm data-item-num-0" (click)="selectTemplate()">{{ customer.averagePreference }}%</div> -->
                                                <div class="nk-tb-col data-item-num-0">
                                                    <div class="d-flex flex-column">
                                                        <span>
                                                            {{ connector.numberOfConnections }}
                                                        </span>
                                                        <span *ngIf="!loadingTopConnectors && filter.durationType !== 1"
                                                            class="{{  connector.numberOfConnectionsDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium fs-12px">{{
                                                            connector.numberOfConnectionsDifference > -1 ? '+' : ''}}
                                                            {{ connector.numberOfConnectionsDifference }}</span>
                                                    </div>
                                                </div>
                                                <div class="nk-tb-col data-item-num-0">
                                                    <div class="d-flex flex-column">
                                                        <span>
                                                            {{ connector.averagePreference }}%
                                                        </span>

                                                        <span *ngIf="!loadingTopConnectors && filter.durationType !== 1"
                                                            class="{{  connector.averagePreferenceDifference < 0 ? 'text-danger' : 'text-success' }}
                                                             fw-medium
                                                             fs-12px">{{ connector.averagePreferenceDifference > -1 ?
                                                            '+' : ''}}
                                                            {{ connector.averagePreferenceDifference }} %</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-100 py-4 text-center" *ngIf="loadingTopConnectors">
                                            <div class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                        </div>
                                        <div class="w-100 pt-3 text-center"
                                            *ngIf="!loadingTopConnectors && topConnectors.length < 1">
                                            <span>No records found</span>
                                        </div>
                                    </div>
                                    <!-- <div class="card-inner-sm border-top text-center d-sm-none">
                                        <a href="#" class="btn btn-link btn-block">See History</a>
                                    </div> -->
                                </div>
                            </div>

                            <div class="col-xl-12 col-xxl-12" *ngIf="false">
                                <div class="card card-bordered card-full">
                                    <div class="card-inner border-bottom">
                                        <div class="card-title-group">
                                            <div class="card-title w-100">
                                                <h6 class="title">Players
                                                    <button class="btn btn-sm btn-primary float-right"
                                                        *ngIf="userIsAdmin" (click)="export()">
                                                        <div class="spinner-border spinner-border-sm" *ngIf="exporting"
                                                            role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <span *ngIf="!exporting">
                                                            Export
                                                        </span>
                                                    </button>
                                                </h6>

                                                <!-- <p>Something should be written here</p> -->
                                            </div>
                                        </div>

                                        <div class="nk-tb-list nk-tb-customers">
                                            <div class="nk-tb-item nk-tb-head">
                                                <div class="nk-tb-col pl-2 cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(0)">
                                                    <span>Name</span>
                                                    <em class="fs-14px float-left icon ni" [ngClass]="sort !== 0 ? 'ni-arrow-long-up' : sort === 0 &&
                                                        !sortByAscending ? 'ni-arrow-long-up text-dark' :
                                                        'ni-arrow-long-down text-dark'"></em>
                                                </div>

                                                <div class="nk-tb-col tb-col-md cursor-pointer tb-col-sm"
                                                    (click)="applyPlayerAnalyticsSort(1)">
                                                    <span>Email</span>
                                                    <em class="fs-14px float-left icon ni" [ngClass]="sort !== 1 ? 'ni-arrow-long-up' : sort === 1 &&
                                                        !sortByAscending ? 'ni-arrow-long-up text-dark' :
                                                        'ni-arrow-long-down text-dark'"></em>
                                                </div>

                                                <div class="nk-tb-col sorting cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(2)">
                                                    <span class="d-none d-md-block"># of Games</span>
                                                    <span class="d-block d-md-none">Gms</span>
                                                    <em class="fs-14px float-left icon ni" [ngClass]="sort !== 2 ? 'ni-arrow-long-up' : sort === 2 &&
                                                        !sortByAscending ? 'ni-arrow-long-up text-dark' :
                                                        'ni-arrow-long-down text-dark'"></em>
                                                </div>

                                                <div class="nk-tb-col cursor-pointer tb-col-sm"
                                                    (click)="applyPlayerAnalyticsSort(3)">
                                                    <span>Connections</span>
                                                    <em class="fs-14px float-left icon ni" [ngClass]="sort !== 3 ? 'ni-arrow-long-up' : sort === 3 &&
                                                        !sortByAscending ? 'ni-arrow-long-up text-dark' :
                                                        'ni-arrow-long-down text-dark'"></em>
                                                </div>

                                                <div class="nk-tb-col cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(4)">
                                                    <span class="d-none d-md-block">Avg Preference</span>
                                                    <span class="d-block d-md-none">Pref</span>
                                                    <em class="fs-14px float-left icon ni" [ngClass]="sort !== 4 ? 'ni-arrow-long-up' : sort === 4 &&
                                                        !sortByAscending ? 'ni-arrow-long-up text-dark' :
                                                        'ni-arrow-long-down text-dark'"></em>
                                                </div>

                                                <div class="nk-tb-col tb-col-sm cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(5)">
                                                    <span>Avg Correct</span>
                                                    <em class="fs-14px float-left icon ni" [ngClass]="sort !== 5 ? 'ni-arrow-long-up' : sort === 5 &&
                                                        !sortByAscending ? 'ni-arrow-long-up text-dark' :
                                                        'ni-arrow-long-down text-dark'"></em>
                                                </div>
                                                <div class="nk-tb-col tb-col-sm"><span>&nbsp;</span></div>
                                            </div>
                                            <div class="nk-tb-item" *ngFor="let player of players">
                                                <div class="nk-tb-col pl-2">
                                                    <div class="user-card">
                                                        <div class="user-avatar user-avatar-xs bg-pink-dim">
                                                            <ng-conatiner *ngIf="player.image; else avatarDefault">
                                                                <img class="rounded-circle"
                                                                    src="{{env.cdn}}profiles/{{player.image}}">
                                                                <img *ngIf="player.isKnownCustomer"
                                                                    class="known-customer"
                                                                    src="../../assets/icons/customer.svg">
                                                            </ng-conatiner>
                                                            <ng-template #avatarDefault>
                                                                <span>{{ getInitials(player.name) }}</span>
                                                            </ng-template>
                                                        </div>
                                                        <div class="user-name">
                                                            <div class="fullname">{{ player.name | titlecase}}</div>
                                                            <div class="username">{{ player.username }} <span
                                                                    class="badge badge-light text-dark ml-3"
                                                                    *ngIf="player.hasFollowed">Follows You</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="nk-tb-col tb-col-sm">{{ player.email }}</div>
                                                <div class="nk-tb-col data-item-num-0">{{ player.numberOfGames }}</div>
                                                <div class="nk-tb-col data-item-num-0 tb-col-sm">
                                                    {{ player.numberOfConnections }}
                                                </div>
                                                <div class="nk-tb-col data-item-num-0">{{ player.averagePreference |
                                                    number:
                                                    '1.0-0' }}%</div>
                                                <div class="nk-tb-col data-item-num-0 tb-col-sm">
                                                    {{ player.averageCorrectAnswers |
                                                    number: '1.0-0' }}%</div>
                                                <div class="nk-tb-col nk-tb-col-action tb-col-sm">
                                                    <div class="dropdown">
                                                        <a class="text-soft dropdown-toggle btn btn-sm btn-icon btn-trigger"
                                                            data-toggle="dropdown"><em
                                                                class="icon ni ni-chevron-right"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                            <ul class="link-list-plain">
                                                                <li><a href="javascript:void(0);">View</a></li>
                                                                <!-- <li><a href="#">Invoice</a></li>
                                                            <li><a href="#">Print</a></li> -->
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="w-100 py-4 text-center" *ngIf="loadingTopPlayers">
                                            <div class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                        </div>
                                        <div class="w-100 py-3 text-center"
                                            *ngIf="!loadingTopPlayers && players.length < 1">
                                            <span>No records found</span>
                                        </div>
                                        <div class="search-results" class="search-results" infiniteScroll
                                            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                                            (scrolled)="onScroll()"></div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-shell-light>
<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Update payment details</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>
            <div class="gr mb-3">Update your card details</div>
            <ngx-stripe-card-group [elementsOptions]="elementsOptions">

                <form #cardDetaislForm="ngForm">

                    <div class="d-flex justify-between">
                        <div class="form-group col-8 p-0">
                            <label class="form-label">Name on card</label>
                            <div class="form-control-wrap">
                                <input type="text" placeholder="Olivia Rhye" [(ngModel)]="name" class="form-control"
                                    name="name">
                            </div>
                        </div>
                        <div class="form-group col-3 p-0">
                            <label class="form-label">Expiry</label>
                            <div class="form-control">
                                <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-between" *ngIf="false">
                        <div class="form-group col-8 p-0">
                            <label class="form-label">Card number</label>
                            <div class="form-control">
                                xxxx xxxx xxxx 1234
                            </div>
                        </div>
                        <div class="form-group col-3 p-0">
                            <label class="form-label">CVC</label>
                            <div class="form-control">
                                ***
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-between">
                        <div class="form-group col-8 p-0">
                            <label class="form-label">Card number</label>
                            <div class="form-control">
                                <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
                            </div>
                        </div>
                        <div class="form-group col-3 p-0">
                            <label class="form-label">CVC</label>
                            <div class="form-control">
                                <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
                            </div>
                        </div>
                    </div>

                </form>
            </ngx-stripe-card-group>
        </ng-container>
    </div>
    <div class="modal-footer">
        <div class="col-12 px-0 d-flex justify-between">
            <button [disabled]="isSaving" (click)="close()"
                class="col-5 btn-cancel btn cancel float-left">Cancel</button>
            <button type="button" (click)="onConfirm()" appButtonSpinner class="col-5 btn float-right btn-primary"
                [loadingState]="isSaving" text="Confirm"></button>
        </div>
    </div>
</div>
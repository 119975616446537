import { Injectable } from '@angular/core';
declare var NioApp: any;

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor() { }

    showError(message: string, duration: number = 3000, position: ToastLocation = ToastLocation.TopRight, showCloseButton: boolean = true) {
        NioApp.Toast(message, 'error', {
            position: position,
            closeButton: showCloseButton,
            newestOnTop: true,
            timeOut: duration,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
        });
    }

    showInfo(message: string, duration: number = 3000, position: ToastLocation = ToastLocation.TopRight, showCloseButton: boolean = true) {
        NioApp.Toast(message, 'info', {
            position: position,
            closeButton: showCloseButton,
            newestOnTop: true,
            timeOut: duration,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
        });
    }

    showSuccess(message: string, duration: number = 3000, position: ToastLocation = ToastLocation.TopRight, showCloseButton: boolean = true) {
        NioApp.Toast(message, 'success', {
            position: position,
            closeButton: showCloseButton,
            newestOnTop: true,
            timeOut: duration,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
        });
    }

    showWarning(message: string, duration: number = 3000, position: ToastLocation = ToastLocation.TopRight, showCloseButton: boolean = true) {
        NioApp.Toast(message, 'warning', {
            position: position,
            closeButton: showCloseButton,
            newestOnTop: true,
            timeOut: duration,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
        });
    }
}

export enum ToastLocation {
    Default = 'default',
    BottomCenter = 'bottom-center',
    BottomLeft = 'bottom-left',
    BottomRight = 'bottom-right',
    BottomFullWidth = 'bottom-full',
    TopCenter = 'top-center',
    TopLeft = 'top-left',
    TopRight = 'top-right',
    TopFullWidth = 'top-full',
}

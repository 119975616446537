import { Injectable } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ConfirmationDialogComponent } from "../_components/confirmation-dialog/confirmation-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    message: string,
    btnOkText: string = "OK",
    btnCancelText: string = "Cancel",
    hideCloseBtn = false,
    dialogSize: "sm" | "lg" = "sm"
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: dialogSize,
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.hideCloseBtn = hideCloseBtn;

    return modalRef.result;
  }
}

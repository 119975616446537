<div class="nk-header-wrap">
    <div class="nk-menu-trigger d-xl-none ml-n1">
        <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em
                class="icon ni ni-menu"></em></a>
    </div>
    <div class="nk-header-app-name">
        <div class="nk-header-app-info">
            <a [routerLink]="['/']" routerLinkActive="router-link-active">
                <img src="../../../assets/logo/logo.png" class="logo">
            </a>
        </div>
    </div>

    <div class="nk-header-tools">
        <ul class="nk-quick-nav">
            <li class="notification-dropdown user-dropdown">
                <a href="#" class="dropdown-toggle mr-n1" data-toggle="dropdown">
                    <div class="user-toggle">
                        <div class="avatar-header border sm">
                            <ng-container *ngIf="user && user.picture; else avatarText">
                                <img src="{{getAvatarUrl(user.picture)}}">
                            </ng-container>
                            <ng-template #avatarText>
                                <span>{{ getAvatarText() }}</span>
                            </ng-template>
                        </div>
                    </div>
                </a>
                <div class="dropdown-menu dropdown-menu-md dropdown-menu-right account-switch">

                    <div class="dropdown-inner user-card-wrap bg-lighter d-block position-relative">
                        <form>
                            <div class="user-card">
                                <div class="avatar-header border mr-1">
                                    <ng-container *ngIf="user && user.picture; else avatarText">
                                        <img src="{{getAvatarUrl(user.picture)}}">
                                    </ng-container>
                                    <ng-template #avatarText>
                                        <span>{{ getAvatarText() }}</span>
                                    </ng-template>
                                </div>
                                <div class="user-info">
                                    <span class="lead-text">{{ getName() }}</span>
                                    <span class="sub-text">{{ getEmail() }}</span>
                                </div>

                                <em (click)="$event.stopPropagation();isCollapsed = false" *ngIf="isCollapsed"
                                    class="selected-icon icon ni ni-chevron-down cursor-pointer toggle-icon"></em>
                                <em (click)="$event.stopPropagation();isCollapsed = true" *ngIf="!isCollapsed"
                                    class="selected-icon icon ni ni-chevron-up cursor-pointer toggle-icon"></em>
                            </div>
                        </form>
                    </div>

                    <div class="dropdown-inner p-0" *ngIf="!isCollapsed">
                        <ul class="p-0">
                            <li class="p-2 border-bottom position-relative pointer"
                                (click)="changeDefault(permission.companyId)"
                                [ngClass]="{'selected': permission.isDefault, 'border-bottom': !last }"
                                *ngFor="let permission of user.permissions; let last = last">
                                <div class="user-card">
                                    <div class="user-avatar border-radius-none"
                                        [ngClass]="{'company-logo': permission.company?.logo}">
                                        <!-- <span *ngIf="permission.company?.logo">
                                        </span> -->
                                        <ng-container *ngIf="permission.company?.logo; else companyAvatar">
                                            <img class="company-logo border-radius-none"
                                                src="{{env.cdn}}companies/{{permission.company?.logo}}"
                                                [alt]="getCompanyAvatar(permission.company?.name)">
                                        </ng-container>
                                        <ng-template #companyAvatar>
                                            <span> {{ getCompanyAvatar(permission.company?.name) }}</span>
                                        </ng-template>

                                    </div>
                                    <div class="user-info">
                                        <span class="lead-text">{{ permission?.company?.name }}</span>
                                        <span class="sub-text">{{ permission?.title }}</span>
                                    </div>
                                </div>
                                <em *ngIf="permission.isDefault" class="selected-icon icon ni ni-check"></em>
                            </li>

                        </ul>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><a [routerLink]="['/profile']" routerLinkActive="router-link-active"><img
                                        src="../../../assets/icons/icons8-admin-settings-male.svg"><span>View
                                        Profile</span></a></li>
                            <li><a [routerLink]="['/privacy']" routerLinkActive="router-link-active"><img
                                        src="../../../assets/icons/icons8-privacy-policy.svg"><span>Privacy
                                        policy</span></a></li>
                            <li><a [routerLink]="['/terms']" routerLinkActive="router-link-active"><img
                                        src="../../../assets/icons/icons8-terms-and-conditions.svg"><span>Terms of
                                        service</span></a></li>
                            <li><a [routerLink]="['/eula']" routerLinkActive="router-link-active"><img
                                        src="../../../assets/icons/icons8-terms-and-conditions.svg"><span>EULA</span></a>
                            </li>
                            <li><a [routerLink]="['/support']" routerLinkActive="router-link-active"><img
                                src="../../../assets/icons/icons8-ask-question.svg"><span>Support</span></a></li>
                        </ul>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><a (click)="signout()" routerLinkActive="router-link-active" class="pointer"><em
                                        class="icon ni ni-signout pointer"></em><span>Sign Out</span></a></li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { DowngradePlanModalComponent } from '../downgrade-plan-modal/downgrade-plan-modal.component';
import { Subscription } from '../_models/subscription.model';
import { BillingService } from '../_services/billing.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-account-plan-edit',
  templateUrl: './account-plan-edit.component.html',
  styleUrls: ['./account-plan-edit.component.scss']
})
export class AccountPlanEditComponent implements OnInit {
  @Input() current;
  model: any = {};
  isSaving = false;
  activePlan: string;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private billingService: BillingService,


  ) {
  }

  ngOnInit(): void {
    this.activePlan = this.current;
  }

  close(): void {
    this.activeModal.close(false);
  }

  onConfirm(): void {
    if (this.current === 'free') {
      this.updatePlan('free');
    }
    if (this.current === 'pro') {
      this.updatePlan(environment.stripeMonthlyPlan);
    }

  }

  updatePlan(identifier: string): void {
    if (this.activePlan === this.current) {
      this.activeModal.close();
      return;
    }
    this.isSaving = true;
    this.billingService.updatePlan(this.sharedService.business.companyId, identifier).subscribe(res => {
      this.isSaving = false;
      this.activeModal.close(this.current);
    });
  }


  openDowngradeModal(): void {
    if (this.activePlan === 'free') {
      this.current = 'free';
      return;
    }
    const modelRef = this.modalService.open(DowngradePlanModalComponent, {
      size: 'sm',
      backdrop: 'static'
    });
    modelRef.result.then((result) => {
      this.current = result ? 'free' : 'pro';
    });
  }


}

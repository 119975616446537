<body class="nk-body npc-default pg-auth">
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main ">
      <!-- wrap @s -->
      <div class="nk-wrap nk-wrap-nosidebar">
        <!-- content @s -->
        <div class="nk-content ">
          <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
            <div class="brand-logo pb-4 text-center">
              <a href="html/index.html" class="logo-link">
                <img class="" src="../../assets/logo/logo.png" alt="logo">
              </a>
            </div>
            <div class="card card-bordered">
              <div class="card-inner card-inner-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Sign In</h4>
                    <div class="nk-block-des">
                      <p>Access the Somm Says panel using your email and verification code.</p>
                    </div>
                  </div>
                </div>

                <form #loginForm="ngForm" (ngSubmit)="loginForm.valid && validate()" *ngIf="!confirmMode">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label" for="default-01">Email or Username</label>
                    </div>
                    <input type="text" name="username" [(ngModel)]="username" #emailAddress="ngModel"
                      class="form-control form-control-lg" id="default-01" placeholder="Enter your email address"
                      pattern="[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
                    <div *ngIf="loginForm.submitted && emailAddress.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="emailAddress.errors.required">This field is required.</div>
                      <div *ngIf="emailAddress.errors.pattern">Provided email address is invalid.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" appButtonSpinner class="btn btn-lg btn-super btn-block" text="Sign in"
                      [loadingState]="isLoading">
                    </button>
                  </div>
                </form>

                <form #confirmationForm="ngForm" (ngSubmit)="confirmationForm.valid && signin()" *ngIf="confirmMode">
                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label" for="password">Verification Code</label>
                      <a class="link link-primary link-sm cursor-pointer" (click)="resendCode()">Re-send code?</a>
                    </div>
                    <div class="form-control-wrap">
                      <a (click)="showPassword = !showPassword" class="form-icon form-icon-right passcode-switch"
                        data-target="password">
                        <em *ngIf="!showPassword" class="icon ni ni-eye"></em>
                        <em *ngIf="showPassword" class="icon ni ni-eye-off"></em>
                      </a>

                      <input [type]="showPassword ? 'text' : 'password'" class="form-control form-control-lg"
                        id="password" [(ngModel)]="code" #passcode="ngModel" name="passcode"
                        placeholder="Enter code you received" minlength="6" maxlength="6" required>
                    </div>
                    <div *ngIf="confirmationForm.submitted && passcode.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="passcode.errors.minlength">6 digits passcode is required</div>
                      <div *ngIf="passcode.errors.required">This field is required.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" appButtonSpinner class="btn btn-lg btn-super btn-block" text="Confirm"
                      [loadingState]="isLoading">
                    </button>
                    <button *ngIf="!isLoading" class="btn btn-lg btn-light btn-block" (click)="confirmMode = false">
                      Cancel
                    </button>
                  </div>
                </form>
                <div class="form-note-s2 text-center pt-4">New on our platform? <a [routerLink]="['/signup']">Create an
                    account</a>
                </div>
              </div>
            </div>
          </div>
          <div class="nk-footer nk-auth-footer-full">
            <div class="container wide-lg">
              <div class="row g-3">
                <div class="col-lg-6 order-lg-last">
                  <ul class="nav nav-sm justify-content-center justify-content-lg-end">
                    <li class="nav-item">
                      <a class="nav-link" [routerLink]="['/termse']">Terms & Condition</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [routerLink]="['/privacye']">Privacy Policy</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [routerLink]="['/eulae']">EULA</a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6">
                  <div class="nk-block-content text-center text-lg-left">
                    <p class="text-soft">&copy; 2021 SommSays. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
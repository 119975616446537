<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Update Enterprise</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!inviteSent; else successMessage;">
            <form #companyForm="ngForm" (ngSubmit)="companyForm.valid && update()">

                <div class="form-group text-center">
                    <div class="w-100 text-center logo">
                        <img [src]="url" class="rounded-circle logo" (click)="file.click()">
                        <span>Edit</span>
                    </div>

                    <input type="file" #file hidden (change)="onFileChange($event)" accept="image/png, image/jpeg" />
                </div>

                <div class="form-group">
                    <label class="form-label">Name</label>
                    <div class="form-control-wrap ">
                        <input type="text" #companyName="ngModel" placeholder="Chuck Furuya" required
                            [(ngModel)]="model.name" class="form-control" name="companyName">
                    </div>
                    <div *ngIf="companyForm.submitted && companyName.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="companyName.errors.required">This field is required.</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Tagline</label>
                    <div class="form-control-wrap ">
                        <input type="text" #taglineInp="ngModel" placeholder="Coolest wine shop" required
                            [(ngModel)]="model.tagline" class="form-control" name="tagline">
                    </div>
                    <div *ngIf="companyForm.submitted && taglineInp.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="taglineInp.errors.required">This field is required.</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Bio</label>
                    <div class="form-control-wrap">
                        <textarea [froalaEditor]="options" class="form-control" [(froalaModel)]="model.description"
                            name="description" placeholderText="Describe about yourself">
                        </textarea>
                        <!-- <textarea class="form-control" [(ngModel)]="model.description" name="description"
                            placeholder="More than your total wine store! Pure, real expressions of wine from around the world. We have a huge selection of local and imported beer, spirits and beverage tools. Owned by two lifetime beverage professional sommeliers.">
                        </textarea> -->
                        <!-- <input type="text" #memberEmail="ngModel" placeholder="chuckfuruyauncorked@gmail.com" required
                            [(ngModel)]="model.email" class="form-control" name="emailAddress"> -->
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Website (Display Url)</label>
                    <div class="form-control-wrap ">
                        <input type="text" placeholder="http://wine-merchant.com" [(ngModel)]="model.websiteUrl"
                            class="form-control" name="websiteUrl">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Address</label>
                    <div class="form-control-wrap">
                        <input type="text" placeholder="3911 Fox St, Denver, CO 80216-4817" [(ngModel)]="model.address"
                            class="form-control" name="address">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Email</label>
                    <div class="form-control-wrap ">
                        <input type="text" placeholder="business@wine-merchant.com" [(ngModel)]="model.email"
                            class="form-control" name="email">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Phone</label>
                    <div class="form-control-wrap ">
                        <input type="text" #memberTitle="ngModel" placeholder="(303) 433-4020" [(ngModel)]="model.phone"
                            class="form-control" name="phone">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Instagram</label>
                    <div class="form-control-wrap ">
                        <input type="text" placeholder="http://www.instagram.com/winemerchant" [(ngModel)]="instagram"
                            class="form-control" name="instagram">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Facebook</label>
                    <div class="form-control-wrap ">
                        <input type="text" placeholder="http://www.facebook.com/winemerchant" [(ngModel)]="facebook"
                            class="form-control" name="facebook">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Tik Tok</label>
                    <div class="form-control-wrap ">
                        <input type="text" placeholder="http://www.tiktok.com/winemerchant" [(ngModel)]="tiktok"
                            class="form-control" name="tiktok">
                    </div>
                </div>

                <div class="form-group mt-3">
                    <div class="col-12 px-0">
                        <a *ngIf="!isSaving" (click)="close()" class="btn btn-light float-left">Cancel</a>
                        <button type="submit" appButtonSpinner class="btn btn-super float-right" text="Send"
                            [loadingState]="isSaving"></button>
                    </div>
                </div>


            </form>

        </ng-container>
        <ng-template #successMessage>

            <div class="nk-modal text-center mt-5">
                <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                <h4 class="nk-modal-title mb-1">Success</h4>
                <div class="nk-modal-text">
                    <div class="caption-text">
                        An invitation to join has been sent to {{ model.email }}
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="mt-5">
                    <button (click)="close()" class="btn btn-super float-right">Done</button>
                </div>
            </div>
        </ng-template>
    </div>
</div>
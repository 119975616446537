import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PagingResponse, ServerResponse } from '../_models/server-response';
import { Company } from '../_models/company.model';
import { AuthenticationService } from './authentication.service';
import { DashboardFilter } from '../_models/dashboard-filter.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getTotalGamesPlayedCount(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `dashboard/get-played-games-count`,
      filter
    );
  }

  getRecommendationsCount(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<any>>(
      `dashboard/get-recommendations-count`,
      filter
    );
  }

  getRecommendationsRespondedCount(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<any>>(
      `dashboard/get-recommendations-responded-count`,
      filter
    );
  }

  getTotalInstancesCount(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `dashboard/get-instances-count`,
      filter
    );
  }

  getTotalBuyItNowClickCount(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `dashboard/get-buy-now-click-count`,
      filter
    );
  }

  getTotalConnectionsCount(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `dashboard/get-connections-count`,
      filter
    );
  }

  getTotalScans(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `dashboard/get-total-qr-scans`,
      filter
    );
  }

  getAveragePreferences(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `dashboard/get-average-preference`,
      filter
    );
  }

  getCompanyFollowersCount(filter: DashboardFilter): Observable<any> {
    return this.apiService.getByParams<ServerResponse<number>>(
      `dashboard/get-company-followers-count`,
      filter
    );
  }

  getTopWines(filter: DashboardFilter) {
    return this.apiService.getByParams<ServerResponse<any>>(
      `dashboard/get-top-wines`,
      filter
    );
  }

  getTopCustomers(filter: DashboardFilter) {
    return this.apiService.getByParams<ServerResponse<any>>(
      `dashboard/get-top-customers`,
      filter
    );
  }

  getTopConnectors(filter: DashboardFilter) {
    return this.apiService.getByParams<ServerResponse<any>>(
      `dashboard/get-top-connectors`,
      filter
    );
  }

  getStatsByPlayer(filter: any) {
    return this.apiService.getByParams<PagingResponse<any>>(
      `dashboard/get-all-player-analytics`,
      filter
    );
  }

  exportAllPlayerStats(filter: any) {
    return this.apiService.post(
      `dashboard/export-all-player-analytics`,
      filter,
      {
        responseType: 'blob',
      }
    );
  }

  getFilterOptions(companyId: string) {
    return this.apiService.get<ServerResponse<any>>(
      `dashboard/get-filters?companyId=${companyId}`
    );
  }
}

<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Edit</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>
            <form #producerForm="ngForm" (ngSubmit)="producerForm.valid && update()">

                <div class="form-group text-center">
                    <div class="w-100 text-center logo">
                        <img [src]="url" class="logo pointer" (click)="file.click()">
                    </div>

                    <input type="file" #file hidden (change)="onFileChange($event)" accept="image/png, image/jpeg" />
                </div>

                <div class="form-group">
                    <label class="form-label">Name</label>
                    <div class="form-control-wrap ">
                        <input type="text" #companyName="ngModel" placeholder="Chuck Furuya" required
                            [(ngModel)]="model.name" class="form-control" name="companyName">
                    </div>
                    <div *ngIf="producerForm.submitted && companyName.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="companyName.errors.required">This field is required.</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Address</label>
                    <div class="form-control-wrap">
                        <input type="text" placeholder="3911 Fox St, Denver, CO 80216-4817" [(ngModel)]="model.address"
                            class="form-control" name="address">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Email</label>
                    <div class="form-control-wrap ">
                        <input type="text" placeholder="business@wine-merchant.com" [(ngModel)]="model.email"
                            class="form-control" name="email">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Phone</label>
                    <div class="form-control-wrap ">
                        <input type="text" #memberTitle="ngModel" placeholder="(303) 433-4020" [(ngModel)]="model.phone"
                            class="form-control" name="phone">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">City</label>
                    <div class="form-control-wrap ">
                        <input type="text" #memberTitle="ngModel" placeholder="e.g Denver" [(ngModel)]="model.city"
                            class="form-control" name="city">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">State</label>
                    <div class="form-control-wrap ">
                        <input type="text" #memberTitle="ngModel" placeholder="e.g California" [(ngModel)]="model.state"
                            class="form-control" name="state">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Country</label>
                    <div class="form-control-wrap ">
                        <input type="text" #memberTitle="ngModel" placeholder="e.g United Kingdom"
                            [(ngModel)]="model.country" class="form-control" name="country">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Website</label>
                    <div class="form-control-wrap ">
                        <input type="text" placeholder="http://wine-merchant.com" [(ngModel)]="model.websiteUrl"
                            class="form-control" name="websiteUrl">
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label">Description</label>
                    <div class="form-control-wrap">
                        <textarea [froalaEditor]="options" class="form-control" [(froalaModel)]="model.description"
                            name="description" placeholderText="Describe about yourself">
                        </textarea>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Tagline</label>
                    <div class="form-control-wrap ">
                        <input type="text" #taglineInp="ngModel" placeholder="Coolest wine shop" required
                            [(ngModel)]="model.tagline" class="form-control" name="tagline">
                    </div>
                    <div *ngIf="producerForm.submitted && taglineInp.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="taglineInp.errors.required">This field is required.</div>
                    </div>
                </div>


                <div class="form-group">
                    <label class="form-label">Business Type</label>
                    <div class="form-control-wrap ">
                        <ng-select name="businessTypes" [(ngModel)]="model.businessTypes" placeholder="Business Type"
                            multiple="true">
                            <ng-option *ngFor="let item of businessTypes" [value]="item.value">{{
                                item.text }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Verified</label>
                    <div class="control">
                        <div class="form-control px-2 border-0 d-flex justify-content-between">
                            <span class="form-label">URL</span>
                            <div class="custom-control custom-switch checked">
                                <input type="checkbox" name="verifiedUrl" [(ngModel)]="model.verifiedUrl"
                                    class="custom-control-input" id="verifiedUrl">
                                <label class="custom-control-label" for="verifiedUrl"></label>
                            </div>
                        </div>
                        <div class="hr"></div>

                        <div class="form-control px-2 border-0 d-flex justify-content-between">
                            <span class="form-label">Business</span>
                            <div class="custom-control custom-switch checked">
                                <input type="checkbox" name="verifiedBusiness" [(ngModel)]="model.verifiedBusiness"
                                    class="custom-control-input" id="verifiedBusiness">
                                <label class="custom-control-label" for="verifiedBusiness"></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div>Social Media</div>
                <div class="separator"></div>

                <div class="flex" *ngFor="let social of social; let i =index">
                    <div class="col-4 col-md-4 pl-0">
                        <div class="form-group">
                            <label class="form-label">Type</label>
                            <select [(ngModel)]="social.type" [value]="social.type" class="form-control"
                                [ngModelOptions]="{standalone: true}" required>
                                <option [value]="'youtube'">YouTube</option>
                                <option [value]="'instagram'">Instagram</option>
                                <option [value]="'twitter'">Twitter</option>
                                <option [value]="'facebook'">Facebook</option>
                                <option [value]="'linkedin'">LinkedIn</option>
                                <option [value]="'pinterest'">Pinterest</option>
                                <option [value]="'tiktok'">Tik Tok</option>
                                <option [value]="'yelp'">Yelp</option>
                                <option [value]="'opentable'">Open Table</option>
                            </select>
                            <div class="invalid-feedback mt-1">
                                <div *ngIf="type?.errors?.required"></div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-7 col-md-7">
                        <label class="form-label">Link</label>
                        <div class="form-control-wrap ">
                            <input type="text" [(ngModel)]="social.link" [ngModelOptions]="{standalone: true}"
                                [value]="social.link" class="form-control">
                        </div>
                    </div>

                    <div class="align-close">
                        <img src="../../assets/icons/icons8-close.svg" (click)="cancelSelection(i)">
                    </div>
                </div>

                <div class="add-btn" (click)="addSocialLinks()">Add Social Link</div>

                <div class="form-group mt-3 modal-footer">
                    <div class="col-12 px-0">
                        <a *ngIf="!isSaving" (click)="close()" class="btn cancel float-left">Cancel</a>
                        <button type="button" (click)="update()" appButtonSpinner class="btn next-btn float-right"
                            [loadingState]="isSaving" text="Next"></button>
                    </div>
                </div>
            </form>

        </ng-container>
    </div>
</div>
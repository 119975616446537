import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, retry, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GameCreateComponent } from '../game-create/game-create.component';
import { RecommendationRequestModelComponent } from '../recommendation-request-model/recommendation-request-model.component';
import { GlobalConstants } from '../_constants/global.constants';
import { PlayerFilter } from '../_models/player-filter.model';
import { Player } from '../_models/player.model';
import { Team } from '../_models/team';
import { User } from '../_models/user.model';
import { AuthenticationService } from '../_services/authentication.service';
import { GameService } from '../_services/game.service';
import { PlayerManagementService } from '../_services/player-management.service';
import { SharedService } from '../_services/shared.service';
import { getIcon, getWineOrigin, WineService } from '../_services/wine.service';

@Component({
  selector: 'app-player-details',
  templateUrl: './player-details.component.html',
  styleUrls: ['./player-details.component.scss'],
})
export class PlayerDetailsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private playerManagementService: PlayerManagementService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private gameService: GameService,
    private wineService: WineService
  ) {}

  sequences = [];
  displaySequences = [];
  title = 'Player Detail';
  subTitle = "Here are Player's insights on Somm Says.";
  showGames = { duration: true };

  dataTablePageSize = 15;
  page = 1;
  length = 1000;
  totalSequencesCount = 0;
  selectedIndex = 0;
  selectedTab = 'history';
  type = '';
  characetristicRating: any;
  characetristicLoading = true;
  selectedCharacetristicRating = 'somm';
  selectedCharacetristicRatings: any = [];
  attributeFilter = ['Variety', 'Producer', 'Appellation'];
  attributeRating: any;
  attributeRatingLoading = true;

  loadingGamesPlayedCount = true;
  loadingAverageScore = true;
  loadingAverageRating = true;
  loadingTotalConnectionsCount = true;
  loadingPlayers = true;
  maxGames: number;
  maxBarLength: number;

  totalGamesPlayed = 0;
  totalGamesPlayerDifference = 0;

  totalConnections = 0;
  totalConnectionsDifference = 0;

  averageScore = 0;
  averageScoreDifference = 0;

  averageRating = 0;
  averageRatingDifference = 0;

  filter: PlayerFilter = new PlayerFilter();
  previousFilterPayload: any;

  recommendationStatus = 'All';

  filterOptions = {
    producers: [],
    varietals: [],
    regions: [],
    sommeliers: [],
  };

  team: Team = undefined;

  env;

  user: User;

  player: Player;

  selectedWineStats: any;
  selectedSequence: any;
  isLoadingStats = false;

  destroyed$ = new Subject<void>();
  destoryCompanySubs$ = new Subject<void>();
  destoryStats$ = new Subject<void>();

  companyWines = [];

  ngOnInit() {
    var playerRet = this.route.snapshot.data['player'];

    if (playerRet && playerRet.success) {
      this.player = playerRet.data;
    }

    this.env = environment;
    this.team = this.authService.getDefaultCompany();
    this.filter.companyId = this.team?.companyId;
    this.filter.durationType = 2;
    this.filter.sortBy = 1;
    this.filter.playerId = this.player.id;

    this.user = this.authService.getUser();
    this.init();

    this.authService
      .getUserCompany$()
      .pipe(takeUntil(this.destoryCompanySubs$))
      .subscribe(async (m) => {
        if (m) {
          this.destroyed$.next();
          this.team = m;
          this.filter.companyId = this.team?.companyId;
          this.getCompanyWines();
        }
      });
  }

  getAttributeRating(filter: PlayerFilter): void {
    this.attributeRatingLoading = true;
    this.playerManagementService
      .getAttributeRating(filter)
      .pipe(finalize(() => (this.attributeRatingLoading = false)))
      .subscribe((res) => {
        this.attributeRating = res.data;
        if (res && res.data) {
          this.maxGamesLegnth(res?.data);
        }
      });
  }
  getAttributeRatingFilterBY(filterBy: string): void {
    this.filter.filterBy = filterBy;
    this.attributeRatingLoading = true;
    this.playerManagementService
      .getAttributeRating(this.filter)
      .pipe(finalize(() => (this.attributeRatingLoading = false)))
      .subscribe((res) => {
        this.attributeRating = res.data;
        if (res && res.data) {
          this.maxGamesLegnth(res?.data);
        }
      });
  }
  getCharacteristicRating(filter: PlayerFilter): void {
    this.characetristicLoading = true;
    this.playerManagementService
      .getCharacteristicRating(filter)
      .pipe(finalize(() => (this.characetristicLoading = false)))
      .subscribe((res) => {
        if (res.item2 && res.item2.feels && res.item2.feels.length) {
          this.characetristicRating = res;
          this.selectedCharacetristicRatings =
            this.characetristicRating.item2.feels?.filter(
              (feel) => feel.feelName !== 'Flavor'
            );
          this.maxBarWidth(this.selectedCharacetristicRatings);
        }
      });
  }
  sortByAttributeChange(e: any): void {
    const sortBy = e.target.value;
    this.filter.sortBy = +sortBy;
    this.getAttributeRating(this.filter);
  }
  maxGamesLegnth(data: any[]): void {
    const gamesArr = [];
    data.map((g) => {
      return gamesArr.push(g.games);
    });
    this.maxGames = Math.max(...gamesArr);
  }
  createGame(): void {
    if (!this.team.isGameCreationAllowed) return;

    this.modalService
      .open(GameCreateComponent, {
        backdrop: 'static',
      })
      .result.then((result) => {});
  }

  getCompanyWines(): void {
    this.wineService
      .getCompanyWines(this.filter.companyId)
      .pipe(takeUntil(this.destoryCompanySubs$))
      .subscribe((res) => {
        this.companyWines = res?.data;
      });
  }

  openRecommendation(): void {
    const modelRef = this.modalService.open(
      RecommendationRequestModelComponent
    );
    modelRef.componentInstance.companyId = this.filter.companyId;
    modelRef.componentInstance.recommendationId =
      this.selectedSequence?.recommendationId;
    modelRef.componentInstance.requiredType = this.type;
    modelRef.componentInstance.picture = this.player.picture;
    modelRef.componentInstance.playerName = this.player.name;
    modelRef.componentInstance.wineTypes = [...this.companyWines];
    modelRef.componentInstance.filteredTypes = [...this.companyWines];
    modelRef.componentInstance.wineName = `${
      this.selectedSequence.note.wine.name
    } ${this.selectedSequence.note.wine.variety} ${
      this.selectedSequence.note.wine.vintage > 0
        ? this.selectedSequence.note.wine.vintage
        : 'NV'
    }`;
    modelRef.result.then((val) => {
      if (val === 'success') {
        this.getPlayerHistory();
      }
    });
  }

  maxBarWidth(data: any): void {
    const dataArr = [];
    data.map((feel) => {
      feel.property.map((cr) => {
        const sum = +cr.val1 + +cr.val2 + +cr.val3 + +cr.val4;
        dataArr.push(sum);
      });
    });
    this.maxBarLength = Math.max(...dataArr);
  }

  getInnerBarWidth(p: string): number {
    const val = +p;
    const width = 100 * (val / (this.maxBarLength ? this.maxBarLength : 1));
    return width;
  }

  init() {
    this.playerManagementService
      .getFilterOptions(this.team?.companyId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (r) => (this.filterOptions = r.data),
        (_) => {}
      );

    this.getCompanyWines();
    this.load();
  }
  selectTab(value: string) {
    this.selectedTab = value;
  }
  load(isDurationChanged: boolean = false) {
    const appliedFilters = JSON.parse(localStorage.getItem('filters'));
    if (appliedFilters) {
      this.filter = appliedFilters;
    }
    this.filter.playerId = this.player.id;
    this.getTotalGamesPlayedCount(isDurationChanged);
    this.getAverageScore(isDurationChanged);
    this.getAverageRating(isDurationChanged);
    this.getTotalConnections(isDurationChanged);
    this.getPlayerHistory(false, isDurationChanged);
    this.getAttributeRating(this.filter);
    this.getCharacteristicRating(this.filter);
  }

  changeDuration(value: number) {
    const isDurationChanged: boolean = this.filter.durationType !== value;
    this.filter.durationType = value;
    this.filter.playerId = this.player.id;
    localStorage.setItem('filters', JSON.stringify(this.filter));
    this.destroyed$.next();
    this.load(isDurationChanged);
  }

  getTotalGamesPlayedCount(isDurationChanged: boolean = false) {
    this.loadingGamesPlayedCount = true;
    this.playerManagementService
      .getTotalGamesPlayedCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingGamesPlayedCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalGamesPlayed = m.data;
        this.totalGamesPlayerDifference = 0;
        this.totalGamesPlayerDifference = m.percentage;
      });
  }

  getAverageScore(isDurationChanged: boolean = false) {
    this.loadingAverageScore = true;
    this.playerManagementService
      .getAverageScore(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingAverageScore = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.averageScore = m.data;
        this.averageScoreDifference = 0;
        this.averageScoreDifference = m.percentage;
      });
  }

  getAverageRating(isDurationChanged: boolean = false) {
    this.loadingAverageRating = true;
    this.playerManagementService
      .getAverageRating(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingAverageRating = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.averageRating = m.data;
        this.averageRatingDifference = 0;
        this.averageRatingDifference = m.percentage;
      });
  }

  getTotalConnections(isDurationChanged: boolean = false) {
    this.loadingTotalConnectionsCount = true;
    this.playerManagementService
      .getTotalConnections(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalConnectionsCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalConnections = m.data;
        this.totalConnectionsDifference = 0;
        this.totalConnectionsDifference = m.percentage;
      });
  }

  getPlayerHistory(append = false, isDurationChanged: boolean = false) {
    this.loadingPlayers = true;
    if (!append) {
      this.selectedSequence = undefined;
      this.selectedWineStats = undefined;
      this.sequences = [];
      this.displaySequences = [];
      this.page = 1;
    }

    const model = { ...this.generateFilterPayload(isDurationChanged) };
    model['page'] = this.page;
    model['count'] = this.length;

    this.playerManagementService
      .getPlayerHistory(model)
      .pipe(
        finalize(() => (this.loadingPlayers = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m: any) => {
        this.totalSequencesCount = m.totalCount;
        if (append) {
          this.sequences.push(...m.data);
          this.displaySequences.push(...m.data);
        } else {
          this.sequences = m.data;
          this.displaySequences = [...this.sequences];
          if (this.sequences.length > 0) {
            this.select(this.selectedIndex);
          }
        }
      });
  }

  generateFilterPayload(isDurationChanged: boolean) {
    if (isDurationChanged) {
      this.previousFilterPayload.durationType = this.filter.durationType;
      return this.previousFilterPayload;
    }

    this.previousFilterPayload = { ...this.filter };
    if (!this.previousFilterPayload.vintageFrom)
      this.previousFilterPayload.vintageFrom = 0;
    if (!this.previousFilterPayload.vintageTo)
      this.previousFilterPayload.vintageTo = 0;

    return this.previousFilterPayload;
  }

  fetchWineOrigin = (wine) => getWineOrigin(wine);

  getWineIcon = (color: string) => getIcon(color);

  select(index) {
    this.destoryStats$.next();
    this.isLoadingStats = true;
    this.selectedSequence = this.displaySequences[index];
    this.selectedIndex = index;
    this.playerManagementService
      .getWineGlobalStats(this.selectedSequence.instanceId)
      .pipe(
        takeUntil(this.destoryStats$),
        finalize(() => (this.isLoadingStats = false))
      )
      .subscribe((stats) => {
        this.selectedWineStats = stats;
      });
  }

  getOptions(key: string) {
    if (key === 'flavor') {
      return this.selectedSequence.note.flavors.map((x) => x.value);
    }
    return GlobalConstants[key];
  }
  getRequestValue(type: string, value: number): string {
    let ret = '';
    const name = type.charAt(0).toUpperCase() + type.slice(1);
    if (value === 3) {
      ret = `More ${name}`;
    }
    if (value === 2) {
      ret = `Same ${name}`;
    }
    if (value === 1) {
      ret = `Less ${name}`;
    }
    this.type = ret;
    return ret;
  }

  getOptionPercentage(key: string, option: string) {
    if (!this.selectedWineStats || !this.selectedWineStats[key]) {
      return 0;
    }

    const optionStat = this.selectedWineStats[key].find(
      (x) => x.name === option
    );

    return optionStat ? optionStat.percentage : 0;
  }

  getPlayerPicture() {
    return `${environment.cdn}profiles/${this.player.picture}`;
  }

  getAverageRatingBySelecttion() {
    var averagePercentage = 0;
    this.selectedWineStats.explicitPreference.forEach((x) => {
      averagePercentage += (parseInt(x.name) * x.percentage) / 100;
    });

    return Math.round(averagePercentage);
  }

  getExplicityPreference(percentage) {
    if (percentage === 100) {
      return 'Top 10%';
    } else if (percentage === 75) {
      return 'Top 25%';
    } else if (percentage === 50) {
      return 'Average';
    } else if (percentage === 25) {
      return 'Not for me';
    }
  }

  formatAverageTime(time) {
    if (!time) return '0';

    return `${time.days}:${time.hours}:${time.minutes}:${time.seconds}`;
  }

  isSommSelection(option, value) {
    if (option === 'explicitPreference') return false;
    else if (option === 'flavor') {
      const sommSelectedFlavor = this.selectedSequence.note.flavors.find(
        (x) => x.selected
      );
      return sommSelectedFlavor.value === value;
    }

    return this.selectedSequence.note[option] === value;
  }

  isPlayerSelection(option, value) {
    // if (option === "explicitPreference") return this.selectedSequence.result.explicitPreference === value;
    return this.selectedSequence.result[option] === value;
  }

  getCountryFlag = (country: string) => SharedService.flagName(country);

  onCharacteristicRatingChange(selectedRating: string): void {
    if (selectedRating === 'somm') {
      this.selectedCharacetristicRatings =
        this.characetristicRating.item2.feels?.filter(
          (feel) => feel.feelName !== 'Flavor'
        );
    } else if (selectedRating === 'player') {
      this.selectedCharacetristicRatings =
        this.characetristicRating.item1.feels?.filter(
          (feel) => feel.feelName !== 'Flavor'
        );
    }
    this.maxBarWidth(this.selectedCharacetristicRatings);
  }

  sortCharRatingChange(ratings: any[]): any[] {
    return this.sharedService.sortCharRatingChange(ratings);
  }

  filterByRecommendation(status) {
    this.recommendationStatus = status;
    this.page = 1;
    this.selectedSequence = undefined;
    this.selectedIndex = -1;

    if (status === 'All') {
      this.displaySequences = [...this.sequences];
      this.totalSequencesCount = this.displaySequences.length;
    } else if (status === 'Pending') {
      const filtered = this.sequences.filter(
        (x) =>
          x.recommendation !== null && x.recommendation.hasResponded === false
      );
      this.displaySequences = [...filtered];
      this.totalSequencesCount = this.displaySequences.length;
    } else if (status === 'Completed') {
      const filtered = this.sequences.filter(
        (x) =>
          x.recommendation !== null &&
          x.recommendation.hasResponded === true &&
          !x.recommendation.isDefault
      );
      this.displaySequences = [...filtered];
      this.totalSequencesCount = this.displaySequences.length;
    } else if (status === 'Default') {
      const filtered = this.sequences.filter(
        (x) =>
          x.recommendation !== null &&
          x.recommendation.hasResponded &&
          x.recommendation.isDefault
      );
      this.displaySequences = [...filtered];
      this.totalSequencesCount = this.displaySequences.length;
    }
  }
}

<div class="p-2">
    <div class="title d-flex justify-between bb">
        <span>{{title}}</span>
        <img (click)="activeModal.close()" src="../../assets/icons/icons8-close.svg">
    </div>
    <ng-container *ngIf="true">
        <div class="body mb-3">
            <img class="profile-image" src="{{env.cdn}}profiles/{{picture}}">
            <div>{{playerName}} requests <strong>{{requiredType}}</strong> based on <br> the
                <strong>{{wineName}}</strong> game
            </div>
        </div>
        <div class="recomm" *ngIf="showList">Recommendation</div>
        <div class="select" *ngIf="showList">
            <div class="form-control-wrap">
                <ng-select [(ngModel)]="name" (change)="setWineType($event)" (open)="onOpen()">
                    <ng-option *ngFor="let type of wineTypes" [value]="type">{{type?.name}} {{type?.variety}}
                        {{type?.vintage > 0 ? type?.vintage : 'NV'}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="showWine">

        <div class="title" *ngIf="isFinal">Make this the 30-day default recommendation
            for {{requiredType}} based on {{wineName}}?</div>
        <div class="d-flex align-center p-2">
            <img class="wine-image" src="{{env.cdn}}wines/{{wineType?.image}}">
            <div class=" d-flex flex-column">
                <div class="recomm-type">{{wineType?.name | titlecase}}
                    {{ wineType?.vintage > 0 ? wineType?.vintage : 'NV' }}</div>
                <div class="d-flex">
                    <img [src]="getWineIcon(wineType?.color)">
                    <span class="recomm-detail">{{wineType?.variety}}</span>
                </div>
                <div class="d-flex">
                    <img class="img" src="../../assets/flags/{{flagName(wineType?.country)}}.svg">
                    <span class="recomm-detail">{{wineType?.appellation}}, {{wineType?.region}},
                        {{flagName(wineType?.country)}}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="footer d-flex justify-between">

        <button class="cancel" *ngIf="!isFinal" (click)="activeModal.close()">Cancel</button>
        <button class="cancel" *ngIf="isFinal" (click)="submit(0)">No</button>
        <button class="submit confirm" [disabled]="!showWine" (click)="confirm()" *ngIf="isSubmit">Submit</button>
        <!-- <button class="submit confirm" (click)="confirm()" *ngIf="isConfirm && ">Confirm</button> -->
        <button class="submit confirm" *ngIf="isFinal" (click)="submit(1)">Make Default</button>

    </div>
</div>
<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
    <div class="nk-app-root">
        <div class="nk-main ">
            <div class="nk-wrap ">
                <div class="nk-header nk-header-fixed is-light">
                    <div class="container-fluid">
                        <app-header></app-header>
                    </div>
                </div>
                <app-side></app-side>
                <div class="nk-content ">

                    <div class="container-fluid">
                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <ng-container *ngIf="!loading; else loadingContainer">
                                    <div class="nk-block-head nk-block-head-sm">
                                        <div class="nk-block-between">
                                            <div class="nk-block-head-content">
                                                <h3 class="nk-block-title page-title">Profile Management</h3>
                                                <div class="nk-block-des text-soft">
                                                    <p>Manage your profile.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-gs">
                                        <div class="col-lg-12 col-xxl-12">
                                            <div class="card card-bordered h-100">
                                                <div class="card-inner">
                                                    <div class="row g-gs">
                                                        <div class="col-lg-12 col-xxl-12 merchant">
                                                            <div class="profile-picture">
                                                                <img *ngIf="user?.picture"
                                                                    src="{{env.cdn}}profiles/{{user?.picture}}" />
                                                                <img *ngIf="!user?.picture"
                                                                    src="../../assets/temp/tamura.png" />
                                                            </div>
                                                            <div class="information">
                                                                <div class="name">{{ user?.fullName }}</div>
                                                                <div class="description">
                                                                    {{ user?.displayCertificationLevel }}</div>
                                                            </div>

                                                            <div class="action" *ngIf="false">
                                                                <div>
                                                                    <a routerLinkActive="router-link-active"
                                                                        class="btn btn-super follow">
                                                                        <em class="icon ni ni-plus"></em>
                                                                        <div>Follow</div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-xxl-12">
                                                    <div class="tabs">
                                                        <div class="tab"
                                                            [ngClass]="{'selected': selectedTab == 'about'}"
                                                            (click)="selectTab('about')">About</div>
                                                        <div class="tab"
                                                            [ngClass]="{'selected': selectedTab == 'games'}"
                                                            (click)="selectTab('games')">Games</div>
                                                        <div class="tab"
                                                            [ngClass]="{'selected': selectedTab == 'enterprises'}"
                                                            (click)="selectTab('enterprises')">Enterprises</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row g-gs" *ngIf="selectedTab == 'about'">
                                        <div class="col-lg-5 col-xxl-5">
                                            <div class="card card-bordered">
                                                <div class="card-inner">
                                                    <div class="card-title d-flex justify-content-between">
                                                        <span>About</span>
                                                        <button type="button" class="btn btn-sm btn-super"
                                                            (click)="openProfileEdit()">Edit</button>
                                                    </div>
                                                    <div class="about">
                                                        <div [froalaView]="user?.bio" class="bio">
                                                        </div>

                                                        <div class="title">Contact</div>

                                                        <div class="info-link">{{ user?.email }}</div>
                                                        <div class="info-link">{{ user?.phone }}</div>

                                                        <div class="title">Social</div>
                                                        <div class="d-flex">
                                                            <div class="social-link {{ item.key }} mr-2"
                                                                *ngFor="let item of user?.social | keyvalue">
                                                                <a href="{{item.value}}" target="_blank">
                                                                    <img
                                                                        src="../../../assets/icons/social/{{item.key | lowercase}}.svg">
                                                                </a>
                                                            </div>
                                                            <div class=" info-link" *ngIf="!user?.social">
                                                                No social links provided
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                            <div class=" card card-bordered">
                                                <div class="card-inner">
                                                    <div class="card-title">Enterprises</div>
                                                    <table class="table is-compact">
                                                        <tbody>
                                                            <tr *ngIf="enterprisesLoading">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        <div class="spinner-border spinner-border-sm"
                                                                            role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                *ngIf="!enterprisesLoading && enterprises && enterprises.length < 1">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        No records found
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="cursor-pointer"
                                                                *ngFor="let producer of enterprises">
                                                                <td class="col-avatar">
                                                                    <ng-container
                                                                        *ngIf="producer?.logo; else emtpyContainer">
                                                                        <img class="rounded-circle user-image"
                                                                            *ngIf="producer?.logo"
                                                                            src="{{env.cdn}}companies/{{producer?.logo}}">
                                                                    </ng-container>
                                                                    <ng-template #emtpyContainer>
                                                                        <div class="user-avatar">
                                                                            <div class="status-circle">
                                                                            </div>
                                                                        </div>
                                                                    </ng-template>

                                                                </td>
                                                                <td>
                                                                    <div class="main-label">
                                                                        {{ producer?.name }}
                                                                    </div>
                                                                    <div class="secondary-label" *ngIf="producer?.city &&
                                                                    producer?.state">{{producer.city}},
                                                                        {{producer.state}}
                                                                    </div>
                                                                </td>
                                                                <td class="col-selector2rows">
                                                                    <img
                                                                        src="../../assets/icons/icons8-chevron-right.svg">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-xxl-7">
                                            <div class="card card-bordered">
                                                <img src="..." class="card-img-top" alt="">
                                                <div class="card-inner ">
                                                    <div class="card-title">Games</div>
                                                    <table class="table is-compact">
                                                        <tbody>
                                                            <tr *ngIf="templateLoading">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        <div class="spinner-border spinner-border-sm"
                                                                            role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="!templateLoading && wines && wines.length < 1">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        No records found
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="cursor-pointer" *ngFor="let template of templates | slice:
                                                            (aboutSectionPage-1) * pageSize : (aboutSectionPage-1) *
                                                            pageSize + pageSize">
                                                                <td class="col-image3rows">
                                                                    <div>
                                                                        <img class="wine-image"
                                                                            src="{{env.cdn}}wines/{{template.note.wine.image}}">
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="main-label">
                                                                        {{template.note.wine.name}}
                                                                        {{template.note.wine.vintage === 0 ? "" : template.note.wine.vintage}}
                                                                    </div>
                                                                    <div class="secondary-label">
                                                                        {{template.note.wine.variety}}
                                                                    </div>
                                                                    <div class="secondary-label">
                                                                        {{template.note.wine.appellation}},
                                                                        {{template.note.wine.region}}</div>
                                                                    <a [attr.href]="template.buyLink" target="_blank"
                                                                        *ngIf="template.buyLink" class="btn btn-super">
                                                                        BUY
                                                                    </a>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot *ngIf="!templateLoading">
                                                            <tr *ngIf="templates">
                                                                <td colspan="5" class="pt-4">
                                                                    <div class="d-flex justify-content-end">
                                                                        <ngb-pagination
                                                                            [collectionSize]="templates.length"
                                                                            [(page)]="aboutSectionPage" [pageSize]="10"
                                                                            [rotate]="true" [ellipses]="false"
                                                                            [boundaryLinks]="true">
                                                                        </ngb-pagination>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-gs" *ngIf="selectedTab == 'games'">
                                        <div class="col-lg-5 col-xxl-5">
                                            <div class="card card-bordered">
                                                <div class="card-inner">

                                                    <div class="card-title">Sort By</div>
                                                    <div class="sort-by">
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" id="customRadio2"
                                                                (change)="sortByReleaseDate = false; refreshGames()"
                                                                [checked]="!sortByReleaseDate" name="customRadio"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label" for="customRadio2">Most
                                                                popular</label>
                                                        </div>

                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" id="customRadio3"
                                                                (change)="sortByReleaseDate = true; refreshGames()"
                                                                [checked]="sortByReleaseDate" name="customRadio"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="customRadio3">Release
                                                                date</label>
                                                        </div>
                                                    </div>

                                                    <div class="card-title">Filter by type</div>
                                                    <div class="sort-type">
                                                        <div class="type-item" [ngClass]="{ 'filter-selected': appliedWineColors.indexOf(color) >
                                                            -1 }" (click)="toggleColorFilter(color)"
                                                            *ngFor="let color of filterWineColorOptions">
                                                            {{ color | titlecase }}</div>
                                                    </div>

                                                    <div class="card-title">Filter by region</div>
                                                    <div class="filter-region">
                                                        <div class="filter-selector" [ngClass]="{ 'filter-selected':
                                                            appliedRegions.indexOf(option?.region.toLowerCase()) >
                                                            -1 }" (click)="toggleRegionFilter(option?.region)"
                                                            *ngFor="let option of filterRegionOptions">
                                                            <img
                                                                src="../../assets/flags/{{getCountryFlagName(option?.country)}}.svg">
                                                            {{ option?.region }} ({{ option?.count }})
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-xxl-7">
                                            <div class="card card-bordered">
                                                <img src="..." class="card-img-top" alt="">
                                                <div class="card-inner ">
                                                    <div class="card-title">Games</div>
                                                    <table class="table is-compact">
                                                        <tbody>
                                                            <tr *ngIf="templateLoading">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        <div class="spinner-border spinner-border-sm"
                                                                            role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="!templateLoading && templates &&
                                                                templates.length < 1">
                                                                <td colspan="5">
                                                                    <div class="text-center my-5">
                                                                        No records found
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="cursor-pointer"
                                                                *ngFor="let template of gameSectionWines | slice:
                                                                (gameSectionPage-1) * pageSize : (gameSectionPage-1) * pageSize + pageSize">
                                                                <td class="col-image3rows">
                                                                    <div>
                                                                        <img class="wine-image"
                                                                            src="{{env.cdn}}wines/{{template?.note?.wine.image}}">
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="main-label">
                                                                        {{template?.note?.wine.name}}
                                                                        {{template?.note?.wine.vintage === 0 ? "" : template?.note?.wine.vintage}}
                                                                    </div>
                                                                    <div class="secondary-label">
                                                                        {{template?.note?.wine.variety}}
                                                                        {{ template?.instances }}
                                                                    </div>
                                                                    <div class="secondary-label">
                                                                        {{template?.note?.wine.appellation}},
                                                                        {{template?.note?.wine.region}}</div>
                                                                    <a [attr.href]="template?.buyLink" target="_blank"
                                                                        *ngIf="template?.buyLink" class="btn
                                                                        btn-super">
                                                                        BUY
                                                                    </a>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot *ngIf="!templateLoading">
                                                            <tr *ngIf="templates">
                                                                <td colspan="5" class="pt-4">
                                                                    <div class="d-flex justify-content-end">
                                                                        <ngb-pagination
                                                                            [collectionSize]="gameSectionWines.length"
                                                                            [(page)]="gameSectionPage" [pageSize]="10"
                                                                            [rotate]="true" [ellipses]="false"
                                                                            [boundaryLinks]="true">
                                                                        </ngb-pagination>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-gs" *ngIf="selectedTab == 'enterprises'">
                                        <div class="col-lg-12 col-xxl-12">
                                            <div class="card card-bordered h-100">
                                                <div class="card-inner">
                                                    <div class="row g-gs">
                                                        <div class="col-lg-12 col-xxl-12">
                                                            <table class="table table-tranx is-compact">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th><span>Name</span></th>
                                                                        <th><span>Location</span></th>
                                                                        <th><span>Games</span></th>
                                                                        <th></th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngIf="enterprisesLoading">
                                                                        <td colspan="5">
                                                                            <div class="text-center my-5">
                                                                                <div class="spinner-border spinner-border-sm"
                                                                                    role="status">
                                                                                    <span
                                                                                        class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        *ngIf="!enterprisesLoading && enterprises && enterprises.length < 1">
                                                                        <td colspan="5">
                                                                            <div class="text-center my-5">
                                                                                No records found
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngFor="let producer of enterprises">
                                                                        <td class="col-avatar">
                                                                            <ng-container
                                                                                *ngIf="producer?.logo; else emtpyContainer">
                                                                                <img class="rounded-circle user-image"
                                                                                    *ngIf="producer?.logo"
                                                                                    src="{{env.cdn}}companies/{{producer?.logo}}">
                                                                            </ng-container>
                                                                            <ng-template #emtpyContainer>
                                                                                <div class="user-avatar">
                                                                                    <div class="status-circle">
                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </td>
                                                                        <td>{{ producer?.name }}</td>
                                                                        <td class="location">
                                                                            <ng-container
                                                                                *ngIf="!producer?.country; else countrySection">
                                                                                <div class="d-flex ">
                                                                                    <div class="user-avatar">
                                                                                        <div class="status-circle">
                                                                                        </div>

                                                                                    </div>
                                                                                    <div class="pt-2 pl-1">
                                                                                        {{ getLocation(producer?.city, producer?.country) }}
                                                                                    </div>
                                                                                </div>

                                                                            </ng-container>
                                                                            <ng-template #countrySection>
                                                                                <img
                                                                                    src="
                                                                ../../assets/flags/{{getCountryFlagName(producer?.country)}}.svg">

                                                                                &nbsp;
                                                                                {{ getLocation(producer?.city, producer?.country) }}
                                                                            </ng-template>

                                                                        </td>
                                                                        <td>{{ producer?.totalGames }}</td>
                                                                        <td class="selector">
                                                                            <img
                                                                                src="../../assets/icons/icons8-chevron-right.svg">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #loadingContainer>
                                    <div class="text-center empty">
                                        <div class="spinner-border spinner-border-sm" role="status"> <span
                                                class="sr-only">Loading...</span></div>
                                    </div>
                                </ng-template>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
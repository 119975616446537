import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Attribute, Wine } from 'src/app/_models/wine.model';
import { CdnService } from 'src/app/_services/cdn.service';
import { ConfirmationDialogService } from 'src/app/_services/confirmation-dialog.service';
import { GameService } from 'src/app/_services/game.service';
import { ToastService } from 'src/app/_services/toast.service';
import { WineService } from 'src/app/_services/wine.service';
import countries from 'src/assets/data/countries.json';

@Component({
  selector: 'app-wine-management',
  templateUrl: './wine-management.component.html',
  styleUrls: ['./wine-management.component.scss'],
})
export class WineManagementComponent implements OnInit {
  wineImage: File;
  wineImageSource: any;
  isHovering = false;
  isFormModified = false;
  buyLink = '';
  isSaving = false;

  importers = [{ value: '' }];
  distributors = [{ value: '' }];
  foodPairing = [{ value: '' }];
  vineyards = [{ value: '' }];
  barrelMaterials = [{ value: '' }];
  harvestDates = [{ dateFrom: '', dateTo: '' }];

  @ViewChild('wineForm', { static: false }) wineForm: NgForm;

  searchingProducer = false;
  searchingWine = false;
  searchingVarietals = false;
  searchingWineRegions = false;
  searchingWineCountries = false;
  searchingAppellation = false;

  isWineFormValid = true;

  active = 1;

  attributes: Attribute[] = [
    { name: 'Certified or Practicing Organic', enabled: false, value: 'OG' },
    { name: 'Sustainable', enabled: false, value: 'SS' },
    { name: 'Vegan', enabled: false, value: 'V' },
    { name: 'Charitable Giving', enabled: false, value: 'CG' },
    { name: 'Biodynamic', enabled: false, value: 'BD' },
    { name: 'Natural Wine', enabled: false, value: 'NW' },
    { name: 'Woman Owned', enabled: false, value: 'WO' },
    { name: 'Female Winemaker', enabled: false, value: 'FW' },
    { name: 'BIPOC Owned', enabled: false, value: 'BO' },
    { name: 'BIPOC Winemaker', enabled: false, value: 'BW' },
    { name: 'LGBTQ+ Owned', enabled: false, value: 'LO' },
    { name: 'LGBTQ+ Winemaker', enabled: false, value: 'LW' },
  ];

  bottleSizes = [
    '187.5 ml',
    '375 ml',
    '750 ml',
    '1.5 L',
    '3.0 L',
    '4.5 L',
    '6.0 L',
    '9.0 L',
    '12.0 L',
    '15.0 L',
    '18.0 L',
  ];

  @Input() wineModel: Wine;

  wine: Wine;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  countries = countries;

  constructor(
    private wineService: WineService,
    private cdnService: CdnService,
    private gameService: GameService,
    private activeModel: NgbActiveModal,
    private confirmationDialogService: ConfirmationDialogService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.wine = { ...this.wineModel };

    if (this.wine?.distributors) {
      this.distributors = [];
      this.wine.distributors.forEach((m: string) => {
        this.distributors.push({ value: m });
      });
    } else {
      this.wine.distributors = [];
    }

    if (this.wine?.vineyards) {
      this.vineyards = [];
      this.wine.vineyards.forEach((m: string) => {
        this.vineyards.push({ value: m });
      });
    } else {
      this.wine.vineyards = [];
    }

    if (this.wine?.foodPairing) {
      this.foodPairing = [];
      this.wine.foodPairing.forEach((m: string) => {
        this.foodPairing.push({ value: m });
      });
    } else {
      this.wine.foodPairing = [];
    }

    if (this.wine?.importers) {
      this.importers = [];
      this.wine.importers.forEach((m: string) => {
        this.importers.push({ value: m });
      });
    } else {
      this.wine.importers = [];
    }

    if (this.wine?.barrelMaterials) {
      this.barrelMaterials = [];
      this.wine.barrelMaterials.forEach((m: string) => {
        this.barrelMaterials.push({ value: m });
      });
    } else {
      this.wine.barrelMaterials = [];
    }
    console.log(this.wine?.harvestDates.length > 0);
    if (this.wine?.harvestDates && this.wine?.harvestDates.length > 0) {
      this.harvestDates = [];
      this.wine.harvestDates.forEach((m: any) => {
        this.harvestDates.push({
          dateFrom: this.formatHarvestDate(m.dateFrom),
          dateTo: this.formatHarvestDate(m.dateTo),
        });
      });
    }

    if (this.wine.image) {
      this.wineImageSource = `${this.cdnService.wine()}/${this.wine.image}`;
    }

    this.setupElements();
  }

  toggleHover = (event: boolean) => (this.isHovering = event);

  onFilePicked($event) {
    if ($event.target.files[0]) {
      this.setWineImage($event.target.files[0]);
    }
  }

  onDrop(files: FileList) {
    if (files.length > 0) {
      const file: File = files[0];
      const supportedTypes: Array<string> = ['png', 'jpg', 'jpeg'];

      if (supportedTypes.indexOf(file.type.split('/')[1]) === -1) return;

      this.setWineImage(file);
    }
  }

  onRemove() {
    this.wineImage = undefined;
    this.wineImageSource = undefined;
  }

  setWineImage(file: File) {
    this.wineImage = file;
    const reader = new FileReader();
    reader.onload = (e) => (this.wineImageSource = reader.result);
    reader.readAsDataURL(this.wineImage);
  }

  formModified(value: boolean) {
    if (!this.isFormModified) {
      if (value) this.isFormModified = true;
    }

    this.isWineFormValid = this.wineForm && this.wineForm.valid;

    return true;
  }

  searchProducers: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingProducer = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findProducerNamesByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingProducer = false))
          );
        } else {
          return of([]).pipe(finalize(() => (this.searchingProducer = false)));
        }
      }),
      finalize(() => (this.searchingProducer = false)),
      catchError(() => {
        return [];
      })
    );

  searchWines: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingWine = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineNamesByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingWine = false))
          );
        } else {
          return of([]).pipe(finalize(() => (this.searchingWine = false)));
        }
      }),
      finalize(() => (this.searchingWine = false)),
      catchError(() => {
        return [];
      })
    );

  searchVarietals: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingVarietals = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findVarietalsByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingVarietals = false))
          );
        } else {
          return of([]).pipe(finalize(() => (this.searchingVarietals = false)));
        }
      }),
      finalize(() => (this.searchingVarietals = false)),
      catchError(() => {
        return [];
      })
    );

  searchWineRegions: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingWineRegions = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineRegionsByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingWineRegions = false))
          );
        } else {
          return of([]).pipe(
            finalize(() => (this.searchingWineRegions = false))
          );
        }
      }),
      finalize(() => (this.searchingWineRegions = false)),
      catchError(() => {
        return [];
      })
    );

  searchWineCountries: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingWineCountries = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineCountriesByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingWineCountries = false))
          );
        } else {
          return of([]).pipe(
            finalize(() => (this.searchingWineCountries = false))
          );
        }
      }),
      finalize(() => (this.searchingWineCountries = false)),
      catchError(() => {
        return [];
      })
    );

  searchAppellation: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap(() => (this.searchingAppellation = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.gameService.findWineAppellationByText(term).pipe(
            map((o) => o.data),
            finalize(() => (this.searchingAppellation = false))
          );
        } else {
          return of([]).pipe(
            finalize(() => (this.searchingAppellation = false))
          );
        }
      }),
      finalize(() => (this.searchingAppellation = false)),
      catchError(() => {
        return [];
      })
    );

  addElement(type: string) {
    switch (type) {
      case 'grape':
        if (!this.wine.grapes) this.wine.grapes = [];
        this.wine.grapes.push({ name: '', perc: undefined });
        break;
      case 'vineyard':
        this.vineyards.push({ value: '' });
        break;
      case 'vintner':
        if (!this.wine.vintners) this.wine.vintners = [];
        this.wine.vintners.push({ title: '', name: '' });
        break;
      case 'foodPairing':
        this.foodPairing.push({ value: '' });
        break;
      case 'importer':
        if (!this.wine.importers) this.wine.importers = [];
        this.importers.push({ value: '' });
        break;
      case 'distributor':
        this.distributors.push({ value: '' });
        break;
      case 'bottle':
        if (!this.wine.wineBottles) this.wine.wineBottles = [];
        this.wine.wineBottles.push({ size: undefined, qtyProduced: undefined });
        break;
      case 'barrelMaterial':
        if (!this.wine.barrelMaterials) this.wine.barrelMaterials = [];
        this.barrelMaterials.push({ value: '' });
        break;
      case 'harvestDate':
        if (!this.wine.harvestDates) this.wine.harvestDates = [];
        this.harvestDates.push({ dateFrom: '', dateTo: '' });
        break;
    }
  }

  removeElement(type: string, index: number) {
    switch (type) {
      case 'grape':
        this.wine.grapes.splice(index, 1);
        break;
      case 'vineyard':
        this.vineyards.splice(index, 1);
        break;
      case 'vintner':
        this.wine.vintners.splice(index, 1);
        break;
      case 'foodPairing':
        this.foodPairing.splice(index, 1);
        break;
      case 'importer':
        this.importers.splice(index, 1);
        break;
      case 'distributor':
        this.distributors.splice(index, 1);
        break;
      case 'bottle':
        this.wine.wineBottles.splice(index, 1);
        break;
      case 'barrelMaterial':
        this.barrelMaterials.splice(index, 1);
        break;
      case 'harvestDate':
        this.harvestDates.splice(index, 1);
        break;
    }
  }

  setupElements() {
    if (!this.wine.grapes || this.wine.grapes.length < 1) {
      this.wine.grapes = [];
      this.wine.grapes.push({ name: '', perc: undefined });
    }

    if (!this.wine.vintners || this.wine.vintners.length < 1) {
      this.wine.vintners = [];
      this.wine.vintners.push({ title: '', name: '' });
    }

    if (!this.wine.wineBottles || this.wine.wineBottles.length < 1) {
      this.wine.wineBottles = [];
      this.wine.wineBottles.push({ size: undefined, qtyProduced: undefined });
    }
  }

  save() {
    this.isSaving = true;

    let payload: any = { ...this.wine };
    payload.importers = this.populateStringArrays(this.importers);
    payload.distributors = this.populateStringArrays(this.distributors);
    payload.foodPairing = this.populateStringArrays(this.foodPairing);
    payload.vineyards = this.populateStringArrays(this.vineyards);
    payload.barrelMaterials = this.populateStringArrays(this.barrelMaterials);
    payload.harvestDates = this.harvestDates.map((m) => {
      return {
        dateFrom: m.dateFrom ? this.getHarvestStructDate(m.dateFrom) : null,
        dateTo: m.dateTo ? this.getHarvestStructDate(m.dateTo) : null,
      };
    });

    this.wineService
      .update(payload, this.wineImage)
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe((ret) => {
        if (ret && ret.success) {
          this.activeModel.close({ wine: ret.data });
        } else {
          this.toastService.showError('Unable to update wine details');
        }
      });
  }

  populateStringArrays(source: any[]) {
    return source.map((m) => m.value);
  }

  formatHarvestDate(date) {
    const datePart = date.split('T');
    const value = datePart[0].split('-');
    return value[0] + '-' + value[1] + '-' + value[2];
  }

  getHarvestStructDate(date) {
    return date + 'T00:00:00.0Z';
  }

  close = () => {
    if (this.isFormModified) {
      this.confirmationDialogService
        .confirm(
          'Confirmation',
          'You have unsaved changes, are you sure you want to cancel it?',
          'Yes',
          'No',
          true
        )
        .then((success) => {
          if (success) {
            this.activeModel.close('any type of data to be send to handler');
          }
        })
        .catch(() => {});
    } else {
      this.activeModel.close();
    }
  };
}

<div class="modal-body">

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
            <a ngbNavLink>General</a>
            <ng-template ngbNavContent>
                <form #wineForm="ngForm" class="w-100" (ngSubmit)="wineForm.valid &&  (wineImage || wineImageSource) && formModified(wineForm.dirty) &&
          nav.select(2)">
                    <div class="row gy-3">

                        <div class="col-12">
                            <h5>General Information</h5>
                            <!-- <p class="text-light">A sentence that simple explains this section.</p> -->
                        </div>

                        <div class="col-12">
                            <div class="border-bottom text-light">
                                <span class="fs-11px">Wine Details</span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="custom-dropzone" dropzone (hovered)="toggleHover($event)"
                                (dropped)="onDrop($event)" [class.hovering]="isHovering">
                                <ng-conatiner *ngIf="wineImage || wineImageSource; else showCertificateUploader">
                                    <div class="img-wraps">
                                        <span class="closes" (click)="onRemove()" title="Remove">×</span>
                                        <img class="img-responsive" [src]="wineImageSource">
                                    </div>
                                </ng-conatiner>
                                <ng-template #showCertificateUploader>
                                    <span class="fs-13px">Drag and Drop a File</span>
                                    <span class="fs-13px">OR</span>
                                    <input style="display: none" type="file" accept="image/png, image/jpg, image/jpeg"
                                        (change)="onFilePicked($event)" #hiddenfileinput>
                                    <button type="button" (click)="hiddenfileinput.click()"
                                        class="btn btn-primary">SELECT</button>
                                </ng-template>
                            </div>
                            <div *ngIf="wineForm.submitted && !wineImage && !wineImageSource"
                                class="invalid-feedback mt-1">
                                Please select any image
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label class="form-label" for="fw-first-name">Producer</label>
                                    <small class="form-text text-muted ml-1"
                                        *ngIf="searchingProducer">searching...</small>
                                </div>

                                <div class="form-control-wrap">
                                    <input id="typeahead-http" type="text" class="form-control" name="winery"
                                        [(ngModel)]="wine.producer" #winery="ngModel" required
                                        [ngbTypeahead]="searchProducers" placeholder="Eg. Decoy" />
                                    <div *ngIf="wineForm.submitted && winery.invalid" class="invalid-feedback mt-1">
                                        <div *ngIf="winery.errors.required">This field is required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">

                                <div class="d-flex">
                                    <label class="form-label" for="fw-mobile-number">Wine Name</label>
                                    <small class="form-text text-muted ml-1" *ngIf="searchingWine">searching...</small>
                                </div>

                                <div class="form-control-wrap">

                                    <input id="typeahead-http" type="text" class="form-control" name="wineName"
                                        [(ngModel)]="wine.name" #wineName="ngModel" required
                                        [class.is-invalid]="searchFailed" [ngbTypeahead]="searchWines"
                                        placeholder="Eg. Paul Hobbs Russin River Valley" />
                                    <!-- <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div> -->

                                    <div *ngIf="wineForm.submitted && wineName.invalid" class="invalid-feedback mt-1">
                                        <div *ngIf="wineName.errors.required">This field is required.</div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Color</label>
                                <select [(ngModel)]="wine.color" name="wineColor" #wineColor="ngModel"
                                    class="form-control" required>
                                    <option [ngValue]="undefined">Select</option>
                                    <option [ngValue]="'red'">Red</option>
                                    <option [ngValue]="'rose'">Rose</option>
                                    <option [ngValue]="'orange'">Orange</option>
                                    <option [ngValue]="'white'">White</option>
                                </select>
                                <div *ngIf="wineForm.submitted && wineColor.invalid" class="invalid-feedback mt-1">
                                    <div *ngIf="wineColor.errors.required">Please select wine color.</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label class="form-label" for="fw-varietal">Varietal</label>
                                    <small class="form-text text-muted ml-1"
                                        *ngIf="searchingVarietals">searching...</small>
                                </div>
                                <div class="form-control-wrap">

                                    <input id="typeahead-http" type="text" class="form-control" required name="variety"
                                        [(ngModel)]="wine.variety" #variety="ngModel" placeholder="Eg. Chardonnay"
                                        [ngbTypeahead]="searchVarietals" />

                                    <!-- <input type="text" class="form-control" required name="variety" [(ngModel)]="wine.variety"
                    #variety="ngModel" placeholder="Eg. Chardonnay"> -->
                                </div>
                                <div *ngIf="wineForm.submitted && variety.invalid" class="invalid-feedback mt-1">
                                    <div *ngIf="variety.errors.required">This field is required.</div>
                                </div>
                            </div>
                        </div>



                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label" for="fw-email-address">Vintage <small>(For non vintage use
                                        0)</small></label>
                                <div class="form-control-wrap">
                                    <input type="text" numeric class="form-control" #vintage="ngModel" required
                                        name="vintage" [(ngModel)]="wine.vintage" placeholder="Eg. 2018">
                                </div>
                                <div *ngIf="wineForm.submitted && vintage.invalid" class="invalid-feedback mt-1">
                                    <div *ngIf="vintage.errors.required">This field is required.</div>
                                </div>
                            </div>
                        </div>




                        <div class="col-md-6">

                            <div class="form-group">
                                <label class="form-label">Sparkling</label>

                                <div>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" name="sparkling" [(ngModel)]="wine.sparkling"
                                            class="custom-control-input" id="sparkling">
                                        <label class="custom-control-label" for="sparkling">
                                        </label>
                                    </div>
                                </div>


                            </div>

                        </div>


                        <div class="col-12 mt-3">
                            <div class="border-bottom text-light">
                                <span class="fs-11px">Region of Origin</span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="col-md-6 pl-0">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <label class="form-label" for="fw-mobile-number">Country</label>
                                        <!-- <small class="form-text text-muted ml-1"
                                            *ngIf="searchingWineCountries">searching...</small> -->
                                    </div>
                                    <div class="form-control-wrap">

                                        <ng-select name="country" [(ngModel)]="wine.country" #country="ngModel"
                                            placeholder="Select a country">
                                            <ng-option *ngFor="let item of countries" [value]="item.name">
                                                {{ item.name }}
                                            </ng-option>
                                        </ng-select>

                                        <!-- <input id="typeahead-http" type="text" class="form-control" #country="ngModel"
                                            required name="country" [(ngModel)]="wine.country"
                                            placeholder="Eg. United States" [ngbTypeahead]="searchWineCountries" /> -->

                                    </div>
                                    <div *ngIf="wineForm.submitted && country.invalid" class="invalid-feedback mt-1">
                                        <div *ngIf="country.errors.required">This field is required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label class="form-label" for="fw-appellation">Appellation</label>
                                    <small class="form-text text-muted ml-1"
                                        *ngIf="searchingAppellation">searching...</small>
                                </div>
                                <div class="form-control-wrap">
                                    <input id="typeahead-http" type="text" class="form-control" #appellation="ngModel"
                                        required name="appellationInput" [(ngModel)]="wine.appellation"
                                        placeholder="Eg. California" [ngbTypeahead]="searchAppellation" />
                                </div>
                                <div *ngIf="wineForm.submitted && appellation.invalid" class="invalid-feedback mt-1">
                                    <div *ngIf="appellation.errors.required">This field is required.</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label class="form-label" for="fw-region">Region</label>
                                    <small class="form-text text-muted ml-1"
                                        *ngIf="searchingWineRegions">searching...</small>
                                </div>
                                <div class="form-control-wrap">
                                    <input id="typeahead-http" type="text" class="form-control" #region="ngModel"
                                        required name="region" [(ngModel)]="wine.region" placeholder="Eg. Sonoma County"
                                        [ngbTypeahead]="searchWineRegions" />
                                </div>
                                <div *ngIf="wineForm.submitted && region.invalid" class="invalid-feedback mt-1">
                                    <div *ngIf="region.errors.required">This field is required.</div>
                                </div>
                            </div>
                        </div>


                        <div class="col-12 mt-3">
                            <div class="border-bottom text-light">
                                <span class="fs-11px">Grapes used</span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-8">
                                    <label class="form-label">Grape</label>
                                </div>
                                <div class="col-3">
                                    <label class="form-label optional">Optional</label>
                                </div>
                                <div class="col-1">
                                </div>
                            </div>
                            <div class="row mt-1" *ngFor="let grape of wine.grapes;let index = index">
                                <div class="col-8">
                                    <input type="text" class="form-control" name="grapeName{{index}}"
                                        [(ngModel)]="grape.name"
                                        placeholder="{{ index === 0 ? 'E.g Pinot Noir' : 'Enter Grape'}}" />
                                </div>
                                <div class="col-3">
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control" name="perce{{index}}"
                                            [(ngModel)]="grape.perc" placeholder="-" />
                                        <span class="ml-1 optional fs-14px">%</span>
                                    </div>

                                </div>
                                <div class="col-1 align-self-center pl-0">
                                    <em class="icon ni ni-trash fs-20px cursor-pointer"
                                        (click)="removeElement('grape', index)"></em>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <button class="btn btn-primary btn-sm" type="button" (click)="addElement('grape')">
                                        <em class="icon ni ni-plus-sm"></em>
                                        <span> Add Grape
                                        </span>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <div class="border-bottom text-light">
                                <span class="fs-11px">Wine-Searcher</span>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="fw-wine-link">Link</label>
                                <div class="form-control-wrap">
                                    <input type="text" class="form-control" name="link" [(ngModel)]=" wine.link"
                                        placeholder="https://www.winelink.com/">
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label" for="fw-email-address">Bottle Size<small>(ml)</small></label>
                                <div class="form-control-wrap">
                                    <input type="text" numeric class="form-control" #bottles="ngModel" required
                                        name="bottles" [(ngModel)]="wine.bottles" placeholder="750">
                                </div>
                                <div *ngIf="wineForm.submitted && bottles.invalid" class="invalid-feedback mt-1">
                                    <div *ngIf="bottles.errors.required">This field is required.</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label" for="fw-email-address">Bottle Amount Produced</label>
                                <div class="form-control-wrap">
                                    <input type="text" numeric class="form-control" #bottlesProduced="ngModel" required
                                        name="bottlesProduced" [(ngModel)]="wine.bottlesProduced" placeholder="150,000">
                                </div>
                                <div *ngIf="wineForm.submitted && bottlesProduced.invalid"
                                    class="invalid-feedback mt-1">
                                    <div *ngIf="bottlesProduced.errors.required">This field is required.</div>
                                </div>
                            </div>
                        </div> -->





                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="fw-wine-link">Attributes</label>
                                <div class="form-control-wrap">
                                    <ng-select name="attributes" [(ngModel)]="selectedAttributes"
                                        placeholder="Select attributes" multiple="true">
                                        <ng-option *ngFor="let item of wineAttributes" [value]="item.value">
                                            {{ item.name }}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->

                        <div class="card-inner w-100">
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-super-outline" type="button"
                                    (click)="formModified(wineForm.dirty);close()">Cancel</button>
                                <button class="btn btn-super" type="submit">Next</button>
                            </div>
                        </div>

                    </div>
                </form>
            </ng-template>
        </li>

        <li [ngbNavItem]="2" [disabled]="!isWineFormValid">
            <a ngbNavLink>Winemaking</a>
            <ng-template ngbNavContent>

                <div class="row gy-3">

                    <div class="col-12">
                        <h5>Winemaking Information</h5>
                        <!-- <p class="text-light">A sentence that simple explains this section.</p> -->
                    </div>


                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Viticulture</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-10">
                                <label class="form-label">Vineyards</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>

                        <div class="row mt-1" *ngFor="let yard of vineyards;let index = index">
                            <div class="col-10">
                                <input type="text" class="form-control" name="yard{{index}}" [(ngModel)]="yard.value"
                                    placeholder="{{ index === 0 ? 'E.g Duckhorn Vineyards' : 'Enter Vineyard' }}" />
                            </div>

                            <div class="col-2 align-self-center pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('vineyard', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button" (click)="addElement('vineyard')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Vineyard
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-10">
                                <label class="form-label">Harvest Date</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-5 fs-12px text-light">
                                Start date
                            </div>
                            <div class="col-5 fs-12px text-light">
                                End date
                            </div>
                            <div class="col-2 align-self-center pl-0">
                            </div>
                        </div>

                        <div class="row" *ngFor="let harvestDate of harvestDates;let index = index"
                            [ngClass]="{'mt-1': index > 0 }">
                            <div class="col-5">
                                <input type="date" class="form-control" [(ngModel)]="harvestDate.dateFrom" />
                            </div>

                            <div class="col-5">
                                <input type="date" class="form-control" [(ngModel)]="harvestDate.dateTo" />
                            </div>

                            <div class="col-2 apt-1 pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('harvestDate', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button"
                                    (click)="addElement('harvestDate')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Harvest Date
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>


                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Vintner</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label">Winemaker</label>
                            </div>
                            <div class="col-5">
                                <label class="form-label">Title</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>

                        <div class="row mt-1" *ngFor="let vintner of wine.vintners;let index = index">

                            <div class="col-5">
                                <input type="text" class="form-control" name="wineMaker{{index}}"
                                    [(ngModel)]="vintner.name"
                                    placeholder="{{ index === 0 ? 'E.g Danna Epperson' : 'Enter Winemaker Name' }}" />
                            </div>
                            <div class="col-5">
                                <input type="text" class="form-control" name="title{{index}}"
                                    [(ngModel)]="vintner.title"
                                    placeholder="{{ index === 0 ? 'E.g VP Of Marketing' : 'Enter Job Title' }}" />
                            </div>
                            <div class="col-2 align-self-center pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('vintner', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button" (click)="addElement('vintner')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Winemarker
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Aging</span>
                        </div>
                    </div>


                    <div class="col-md-12">
                        <div class="col-md-6 pl-0">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label class="form-label" for="fw-barrelTime">Barrel Time</label>
                                </div>
                                <div class="form-control-wrap">
                                    <input type="text" numeric class="form-control" #barrelTime="ngModel"
                                        name="barrelTime" [(ngModel)]="wine.barrelTime" placeholder="Eg. 10" />
                                </div>
                                <div class="text-light fs-11px">
                                    Enter number of months
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-10">
                                <label class="form-label">Barrel Materials</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>

                        <div class="row mt-1" *ngFor="let material of barrelMaterials;let index = index">
                            <div class="col-10">
                                <input type="text" class="form-control" name="material{{index}}"
                                    [(ngModel)]="material.value" placeholder="Eg. French Oak" />
                            </div>

                            <div class="col-2 align-self-center pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('barrelMaterial', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button"
                                    (click)="addElement('barrelMaterial')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Barrel Material
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>



                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Technical Data</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="col-md-6 pl-0">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label class="form-label" for="fw-phValue">pH Value</label>
                                </div>
                                <div class="form-control-wrap">
                                    <input type="text" class="form-control" #phValue="ngModel" name="phValue"
                                        [(ngModel)]="wine.phValue" decimals="2" placeholder="Eg. 3.56" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label" for="fw-email-address">Alcohol Percentage</label>
                            <div class="form-control-wrap">
                                <input type="text" numeric decimals="2" class="form-control" #alcoholPerc="ngModel"
                                    required name="alcoholPerc" [(ngModel)]="wine.alcoholPerc" placeholder="Eg. 14.2">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label" for="fw-taValue">TA Value</label>
                            <div class="form-control-wrap">
                                <input type="text" numeric class="form-control" #taValue="ngModel" name="taValue"
                                    decimals="2" [(ngModel)]="wine.taValue" placeholder="Eg. 5.8">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="col-md-6 pl-0">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label class="form-label" for="fw-phValue">Brix</label>
                                </div>
                                <div class="form-control-wrap">
                                    <input type="text" class="form-control" #brix="ngModel" name="brix"
                                        [(ngModel)]="wine.brix" decimals="2" placeholder="Eg. 0" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label" for="fw-sugarQuantity">Sugar Quantity</label>
                            <div class="form-control-wrap">
                                <input type="text" numeric class="form-control" #sugarQuantity="ngModel"
                                    name="sugarQuantity" decimals="2" [(ngModel)]="wine.sugarQuantity"
                                    placeholder="Eg. 0">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label" for="fw-sugarPerc">Sugar Percentage</label>
                            <div class="form-control-wrap">
                                <input type="text" numeric class="form-control" #taValue="ngModel" name="sugarPerc"
                                    decimals="2" [(ngModel)]="wine.sugarPerc" placeholder="Eg. 0">
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Bottles</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label">Bottle Size</label>
                            </div>
                            <div class="col-5">
                                <label class="form-label">Amount Produced</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>
                        <div class="row mt-1" *ngFor="let bottle of wine.wineBottles;let index = index">
                            <div class="col-5">
                                <ng-select name="bottleSize{{index}}" [(ngModel)]="bottle.size"
                                    placeholder="Select bottle size">
                                    <ng-option *ngFor="let item of  bottleSizes" [value]="item">
                                        {{ item }}</ng-option>
                                </ng-select>

                            </div>
                            <div class="col-5">
                                <input type="text" numeric class="form-control" name="bottleProduced{{index}}"
                                    [(ngModel)]="bottle.qtyProduced" placeholder="E.g 1,500,000" />
                            </div>
                            <div class="col-2 align-self-center pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('bottle', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button" (click)="addElement('bottle')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Bottle Size
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="card-inner w-100">
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-super-outline" type="button" (click)="nav.select(1)">Back</button>
                            <button class="btn btn-super" type="button" (click)="nav.select(3)">Next</button>
                        </div>
                    </div>

                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" [disabled]="!isWineFormValid">
            <a ngbNavLink>Additional</a>
            <ng-template ngbNavContent>
                <div class="row gy-3">

                    <div class="col-12">
                        <h5>Additional Information</h5>
                        <!-- <p class="text-light">A sentence that simple explains this section.</p> -->
                    </div>


                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Tasting Note</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <label class="form-label" for="fw-mobile-number">Description</label>
                        <div class="form-control-wrap">
                            <textarea name="description" [(ngModel)]="wine.description"
                                class="form-control form-control-simple no-resize text-area"
                                placeholder="Please provide description"></textarea>
                        </div>
                    </div>


                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Attrributes (select all that apply)</span>
                        </div>
                    </div>


                    <div class="col-md-12">
                        <div class="row" *ngFor="let attribute of wine.attributes;let index = index; let first = first"
                            [ngClass]="{ 'mt-2': !first }">
                            <div class="col-12">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" [(ngModel)]="attribute.enabled" class="custom-control-input"
                                        id="attribute{{index}}">
                                    <label class="custom-control-label" for="attribute{{index}}">
                                        {{ attribute.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Pairings</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-10">
                                <label class="form-label">Food Pairing</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>

                        <div class="row mt-1" *ngFor="let pairing of foodPairing;let index = index">
                            <div class="col-10">
                                <input type="text" class="form-control" name="pairing{{index}}"
                                    [(ngModel)]="pairing.value" placeholder="E.g Lamb, Pork etc" />
                            </div>

                            <div class="col-2 align-self-center pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('foodPairing', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button"
                                    (click)="addElement('foodPairing')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Food Pairing
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <div class="border-bottom text-light">
                            <span class="fs-11px">Economics</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-10">
                                <label class="form-label">Importers</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>

                        <div class="row mt-1" *ngFor="let importer of importers;let index = index">
                            <div class="col-10">
                                <input type="text" class="form-control" name="importer{{index}}"
                                    [(ngModel)]="importer.value" placeholder="E.g Importer" />
                            </div>

                            <div class="col-2 align-self-center pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('importer', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button" (click)="addElement('importer')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Importer
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-10">
                                <label class="form-label">Distributors</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>

                        <div class="row mt-1" *ngFor="let distributor of distributors;let index = index">
                            <div class="col-10">
                                <input type="text" class="form-control" name="distributor{{index}}"
                                    [(ngModel)]="distributor.value" placeholder="E.g Distributor" />
                            </div>

                            <div class="col-2 align-self-center pl-0">
                                <em class="icon ni ni-trash fs-20px cursor-pointer"
                                    (click)="removeElement('distributor', index)"></em>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm" type="button"
                                    (click)="addElement('distributor')">
                                    <em class="icon ni ni-plus-sm"></em>
                                    <span> Add Distributors
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label" for="fw-email-address">Retail Price (USD)</label>
                            <div class="form-control-wrap">
                                <input type="text" numeric class="form-control" #retailPriceUSD="ngModel" required
                                    name="retailPriceUSD" [(ngModel)]="wine.retailPriceUSD" placeholder="Eg.
                                          30">
                            </div>
                        </div>
                    </div>

                    <div class="card-inner w-100">
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-super-outline" type="button" (click)="nav.select(2)">Back</button>
                            <button type="button" (click)="save()" appButtonSpinner class="btn btn-super" text="Save"
                                [loadingState]="isSaving"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import saveAs from 'file-saver';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { GameCreateComponent } from '../game-create/game-create.component';
import { GenerateQrCodeComponent } from '../generate-qr-code/generate-qr-code.component';
import { InstanceCreateComponent } from '../instance-create/instance-create.component';
import { GameFilter } from '../_models/game-filter.model';
import { Team } from '../_models/team';
import { Template } from '../_models/template.model';
import { AuthenticationService } from '../_services/authentication.service';
import { CdnService } from '../_services/cdn.service';
import { DashboardService } from '../_services/dashboard.service';
import { GameService } from '../_services/game.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
})
export class GamesComponent implements OnInit, OnDestroy {
  templates = [];
  userCompany: Team;
  loading = false;
  totalCount = 0;
  title = 'Game Management';
  subTitle = '';
  showGames = { import: false, export: false, game: true, duration: true };

  filter: GameFilter = new GameFilter();

  filterOptions = {
    producers: [],
    varietals: [],
    regions: [],
    sommeliers: [],
  };

  destroyed$ = new Subject<void>();
  destoryCompanySubs$ = new Subject<void>();

  isPremium = false;
  exporting = false;

  loadingTotalGamesPlayedCount = true;
  loadingRecommendationsCount = true;
  loadingRecommendationsRespondedCount = true;
  loadingBuyNowClickCount = true;
  loadingTotalFollowersCount = true;
  loadingTotalConnectionsCount = true;

  totalRecommendationReceived = 0;
  totalRecommendationReceivedDifference = 0;

  totalRecommendationsResonded = 0;
  totalRecommendationsResondedDifference = 0;

  totalGamesPlayed = 0;
  totalGamesPlayerDifference = 0;

  totalConnections = 0;
  totalConnectionsDifference = 0;

  totalBuyNowClicks = 0;
  totalBuyNowClicksDifference = 0;

  totalFollowers = 0;
  previousFilterPayload: any;

  sort = 1; // by default sort by Average correct answer
  sortByAscending = false;

  constructor(
    private modalService: NgbModal,
    private gameService: GameService,
    private cdn: CdnService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private router: Router,
    private dashboardService: DashboardService
  ) {
    this.filter.length = 10;
    this.filter.page = 1;
    this.filter.durationType = 2;
  }

  async ngOnInit() {
    this.userCompany = this.authService.getDefaultCompany();
    this.init();

    this.authService
      .getUserCompany$()
      .pipe(takeUntil(this.destoryCompanySubs$))
      .subscribe((m) => {
        if (m) {
          this.destroyed$.next();
          this.userCompany = m;
          this.filter.companyId = m.companyId;
          this.filter.page = 1;
          this.totalCount = 0;
          this.load(false);
        }
      });

    this.isPremium = this.sharedService.activeSubscription
      ? !this.sharedService.activeSubscription.isFree
      : false;

    this.showGames.export = this.isPremium;

    this.authService._subscription$
      .pipe(takeUntil(this.destoryCompanySubs$))
      .subscribe((t) => {
        this.isPremium = t ? !t.isFree : false;
        this.showGames.export = this.isPremium;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destoryCompanySubs$.next();
  }

  init() {
    this.filter.companyId = this.userCompany?.companyId;
    this.dashboardService
      .getFilterOptions(this.filter.companyId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (r) => (this.filterOptions = r.data),
        (_) => {}
      );
    const appliedFilters = JSON.parse(localStorage.getItem('filters'));
    if (appliedFilters) {
      this.filter = appliedFilters;
    }

    this.load(false);
  }

  load(durationChanged: boolean) {
    this.getTotalGamesPlayedCount(durationChanged);
    this.getTotalBuyNowClickCount(durationChanged);
    this.getTotalFollowersCount(durationChanged);
    this.getTotalConnectionsCount(durationChanged);
    this.getRecommendationsCount(durationChanged);
    this.getRecommendationsRespondedCount(durationChanged);
    this.loadTemplates(durationChanged);
  }

  loadTemplates(durationChanged: boolean) {
    if (!this.loading) {
      this.loading = true;
      this.templates = [];

      const payload = this.generateFilterPayload(durationChanged);

      payload.sortBy = this.sort;
      payload.isSortByAscending = this.sortByAscending;

      this.gameService
        .getTemplatesV2(payload)
        .pipe(
          finalize(() => (this.loading = false)),
          takeUntil(this.destroyed$)
        )
        .subscribe((d) => {
          this.templates = d.data;
          this.totalCount = d.totalCount;
          this.subTitle = `You have ${this.totalCount} game templates.`;
        });
    }
  }

  getCountryFlag = (country: string) => SharedService.flagName(country);

  getBuyItNowIcon(value: boolean) {
    return `../../../assets/icons/${value ? 'correct' : 'wrong'}.svg`;
  }

  getFoodPairingIcon(value: boolean) {
    return `../../../assets/icons/${value ? 'correct' : 'wrong'}.svg`;
  }

  async selectTemplate(templateId: string) {
    if (!this.userCompany.isGameCreationAllowed) return;
    const templateRet = await this.gameService
      .getTemplateById(templateId)
      .toPromise();

    const template = { ...templateRet.data };

    const modalInstance = this.modalService.open(GameCreateComponent, {
      backdrop: 'static',
    });
    template.note.wine = { ...template.wine };
    template.sommelier = { ...template.user };
    modalInstance.componentInstance.note = template.note;

    modalInstance.componentInstance.companyId = template.companyId;
    modalInstance.componentInstance.sommId = template.sommelierId;
    modalInstance.componentInstance.isEditMode = true;
    modalInstance.componentInstance.template = template;
    modalInstance.componentInstance.buyLink = template.buyLink;

    modalInstance.result.then((result) => {});
  }

  generateQR(code) {
    const modalInstance = this.modalService.open(GenerateQrCodeComponent);
    modalInstance.componentInstance.code = code;
    modalInstance.result.then((result) => {});
  }

  createInstance(templateId: string) {
    if (!this.userCompany.isGameCreationAllowed) return;

    const model = this.modalService.open(InstanceCreateComponent);
    model.componentInstance.templateId = templateId;
    model.result.then(
      (r) => {
        if (r && r.generateQrCode) {
          const template = this.templates.find((x) => x.id == templateId);
          if (template) {
            this.generateQR(template.code);
          }
        }
      },
      (error) => console.log(error)
    );
  }

  open(templateId: string) {
    this.router.navigate(['/game', templateId]);
  }

  createGame() {
    if (!this.userCompany.isGameCreationAllowed) return;

    const modelRef = this.modalService.open(GameCreateComponent, {
      backdrop: 'static',
    });

    modelRef.result.then((result) => {
      if (result) {
        console.log('REFERESH TEMPLATES');
        this.loadTemplates(false);
      }
    });
  }

  getWineImageUrl = (name) => `${this.cdn.wine()}/${name}`;

  getWineOrigin(wine) {
    if (!wine) return '';

    let origin: string[] = [];

    if (wine.appellation && origin.indexOf(wine.appellation) < 0)
      origin.push(wine.appellation);
    if (wine.region && origin.indexOf(wine.region) < 0)
      origin.push(wine.region);
    if (wine.country && origin.indexOf(wine.country) < 0)
      origin.push(wine.country);

    return origin.join(', ');
  }

  getWineIcon(color: string) {
    if (!color) return '../../assets/icons/wine-colors/default.svg';

    color = color.trim().toLowerCase();

    if (color === 'rose') {
      return '../../assets/icons/wine-colors/rose-wine.svg';
    } else if (color === 'white') {
      return '../../assets/icons/wine-colors/white-wine.svg';
    } else if (color === 'red') {
      return '../../assets/icons/wine-colors/red-wine.svg';
    } else if (color === 'orange') {
      return '../../assets/icons/wine-colors/orange-wine.svg';
    }

    return '../../assets/icons/wine-colors/default.svg';
  }

  search() {
    const appliedFilters = JSON.parse(localStorage.getItem('filters'));
    if (appliedFilters) {
      this.filter = appliedFilters;
    }
    this.filter.page = 1;
    this.filter.length = 10;
    this.loadTemplates(false);
  }

  loadPage($event) {
    this.filter.page = $event ?? 1;
    this.filter.length = 10;
    localStorage.setItem('filters', JSON.stringify(this.filter));
    this.loadTemplates(false);
  }

  export() {
    if (this.exporting) return;
    const appliedFilters = JSON.parse(localStorage.getItem('filters'));
    if (appliedFilters) {
      this.filter = appliedFilters;
    }

    this.exporting = true;
    this.gameService
      .export(this.filter)
      .pipe(finalize(() => (this.exporting = false)))
      .subscribe(
        (blob) => {
          saveAs(blob, 'games.xlsx');
        },
        (error) => console.log(error)
      );
  }

  getTotalGamesPlayedCount(isDurationChanged: boolean = false) {
    this.loadingTotalGamesPlayedCount = true;
    this.dashboardService
      .getTotalGamesPlayedCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalGamesPlayedCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalGamesPlayed = m.data;
        this.totalGamesPlayerDifference = 0;
        this.totalGamesPlayerDifference = m.percentage;
      });
  }

  getRecommendationsCount(isDurationChanged: boolean = false) {
    this.loadingRecommendationsCount = true;
    this.dashboardService
      .getRecommendationsCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingRecommendationsCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalRecommendationReceived = m.data;
        this.totalRecommendationReceivedDifference = 0;
        this.totalRecommendationReceivedDifference = m.percentage;
      });
  }

  getRecommendationsRespondedCount(isDurationChanged: boolean = false) {
    this.loadingRecommendationsRespondedCount = true;
    this.dashboardService
      .getRecommendationsRespondedCount(
        this.generateFilterPayload(isDurationChanged)
      )
      .pipe(
        finalize(() => (this.loadingRecommendationsRespondedCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalRecommendationsResonded = m.data;
        this.totalRecommendationsResondedDifference = 0;
        this.totalRecommendationsResondedDifference = m.percentage;
      });
  }

  getTotalBuyNowClickCount(isDurationChanged: boolean = false) {
    this.loadingBuyNowClickCount = true;
    this.dashboardService
      .getTotalBuyItNowClickCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingBuyNowClickCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalBuyNowClicks = m.data;
        this.totalBuyNowClicksDifference = 0;
        this.totalBuyNowClicksDifference = m.percentage;
      });
  }

  getTotalFollowersCount(isDurationChanged: boolean = false) {
    this.loadingTotalFollowersCount = true;
    this.dashboardService
      .getCompanyFollowersCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalFollowersCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalFollowers = m.data;
      });
  }

  getTotalConnectionsCount(isDurationChanged: boolean = false) {
    this.loadingTotalConnectionsCount = true;
    this.dashboardService
      .getTotalConnectionsCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalConnectionsCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalConnections = m.data;
        this.totalConnectionsDifference = 0;
        this.totalConnectionsDifference = m.percentage;
      });
  }

  generateFilterPayload(isDurationChanged: boolean) {
    if (isDurationChanged) {
      this.previousFilterPayload.durationType = this.filter.durationType;
      return this.previousFilterPayload;
    }

    this.previousFilterPayload = { ...this.filter };
    if (!this.previousFilterPayload.vintageFrom)
      this.previousFilterPayload.vintageFrom = 0;
    if (!this.previousFilterPayload.vintageTo)
      this.previousFilterPayload.vintageTo = 0;

    return this.previousFilterPayload;
  }

  changeDuration(value: number) {
    const isDurationChanged: boolean = this.filter.durationType !== value;
    this.filter.durationType = value;
    localStorage.setItem('filters', JSON.stringify(this.filter));
    this.destroyed$.next();
    this.load(isDurationChanged);
  }

  applySort(sort) {
    if (this.sort === sort) {
      this.sortByAscending = !this.sortByAscending;
    } else {
      this.sort = sort;
      this.sortByAscending = false;
    }

    this.loadTemplates(false);
  }

  getSortClass(sortColumn: number) {
    if (this.sort !== sortColumn) {
      return 'ni-arrow-long-up';
    } else if (this.sort === sortColumn && !this.sortByAscending) {
      return 'ni-arrow-long-up text-dark';
    } else if (this.sort === sortColumn && this.sortByAscending) {
      return 'ni-arrow-long-down text-dark';
    }
  }
}

<div class="modal-header">
    <h4>Import Players</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button> -->
    <a class="close" *ngIf="!isUploading" (click)="close()"><em class="icon ni ni-cross-sm"></em></a>
</div>
<div class="modal-body pt-0">

    <ng-container *ngIf="!imported; else successState">
        <ng-container *ngIf="!isUploading; else uploading">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li ngbNavItem="redirect">
                    <a ngbNavLink>Endgrate</a>
                    <ng-template ngbNavContent>
                        <div class="text-center">
                            <button type="button" class="btn btn-primary" (click)="redirectToEndgrateImport()">Import with Endgrate</button>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="file">
                    <a ngbNavLink>Upload File</a>
                    <ng-template ngbNavContent>
                        <div class="row gy-3">
                            <div class="col-md-12">
                                <div class="custom-dropzone" dropzone (hovered)="toggleHover($event)"
                                    (dropped)="onDrop($event)" [class.hovering]="isHovering">
                                    <ng-conatiner *ngIf="file; else showCertificateUploader">
                                        <div class="file-wrap">
                                            <span class="closes" (click)="onRemove()" title="Remove">×</span>
                                            <div class="text-center">
                                                <em class="icon ni ni-file-text fs-60px"></em>
                                            </div>
                                            <span>{{ fileName }}</span>
                                        </div>
                                    </ng-conatiner>
                                    <ng-template #showCertificateUploader>
                                        <span class="fs-13px">Drag and Drop a File</span>
                                        <span class="fs-13px">OR</span>
                                        <input style="display: none" type="file" accept="text/csv"
                                            (change)="onFilePicked($event)" #hiddenfileinput>
                                        <button type="button" (click)="hiddenfileinput.click()"
                                            class="btn btn-primary">SELECT</button>
                                    </ng-template>
                                </div>
                                <!-- <div *ngIf="!file" class="invalid-feedback mt-1">
                            Please select any formatted CSV
                        </div> -->
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="content">
                    <a ngbNavLink>Paste CSV</a>
                    <ng-template ngbNavContent>
                        <textarea [(ngModel)]="content" class="form-control"></textarea>
                    </ng-template>
                </li>

            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </ng-container>
        <ng-template #uploading>
            <div class="uploading-container">
                <div class="d-flex justify-content-center flex-column h-100">
                    <div class="text-center">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <p class="text-center">Uploading</p>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-template #successState>
        <div class="text-center mt-5">
            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
            <div class="caption-text mt-1">
                Players have been imported successfully
            </div>
        </div>

    </ng-template>



</div>

<div class="modal-footer" *ngIf="!isUploading && !imported">
    <button type="button" class="btn btn-danger" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" (click)="upload()">
        <em class="icon ni ni-upload mr-1"></em>
        Upload
    </button>
</div>

<div class="modal-footer" *ngIf="imported">
    <button type="button" class="btn btn-danger" (click)="close()">Done</button>
</div>
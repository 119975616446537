<div class="nk-block nk-block-middle wide-md mx-auto">
    <div class="nk-block-content nk-error-ld text-center"><img class="nk-error-gfx" src="/assets/images/error-404.svg"
            alt="">
        <div class="wide-xs mx-auto">
            <h3 class="nk-error-title">Oops, Not Found!</h3>
            <p class="nk-error-text">We are very sorry for inconvenience. It looks like you’re try to access a
                page or record that either has been deleted or never existed.</p><a [routerLink]="['/']"
                class="btn btn-lg btn-primary mt-2">Go To Dashboard</a>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountPlanEditComponent } from '../account-plan-edit/account-plan-edit.component';
import { AccountPlanExtendComponent } from '../account-plan-extend/account-plan-extend.component';
import { CardDetailsEditComponent } from '../card-details-edit/card-details-edit.component';
import { Subscription } from '../_models/subscription.model';
import { BillingService } from '../_services/billing.service';
import { CMSService } from '../_services/cms.service';
import { EventService } from '../_services/event.service';
import { SharedService } from '../_services/shared.service';
import { ToastService } from '../_services/toast.service';
@Component({
  selector: 'app-account-plan-pro',
  templateUrl: './account-plan-pro.component.html',
  styleUrls: ['./account-plan-pro.component.scss'],
})
export class AccountPlanProComponent implements OnInit {
  title = 'Admin/Producer Data';
  subTitle = 'Commodo interdum quam.';
  frequentQuestions = [];

  images = [
    'assets/images/avatar/b-sm.jpg',
    'assets/images/avatar/c-sm.jpg',
    'assets/images/avatar/d-sm.jpg',
  ];

  invoices = [];
  invoicesCount = 0;
  subscription: Subscription;

  loading = false;
  cardInfoLoading = false;
  page = 1;
  count = 10;
  totalCount = 0;

  destroyed$ = new Subject<void>();
  cardInfo: any;
  env = environment;
  showGames = { game: true, duration: true };
  current: string;

  bucket: any;

  constructor(
    private modalService: NgbModal,
    private billingService: BillingService,
    private sharedService: SharedService,
    private toastService: ToastService,
    private eventService: EventService,
    private cmsService: CMSService
  ) {}

  ngOnInit(): void {
    this.setActiveSubscription();
    this.loadInvoices();
    this.getCardsInfo();
    this.getEmailBucket();

    this.eventService.events.subscribe((event) => {
      if (event.type === 'reload_account_plan') {
        this.getActiveSubscription();
        this.loadInvoices();
        this.getEmailBucket();
      }
    });

    this.cmsService.getSubscriptionFaq().subscribe((ret) => {
      if (ret) {
        ret.items.forEach((element) => {
          this.frequentQuestions.push({
            q: element.data.question.iv,
            a: element.data.answer.iv,
            expanded: false,
          });
        });
      }
    });
  }

  async setActiveSubscription() {
    this.subscription = this.sharedService.activeSubscription;
    if (!this.subscription) {
      await this.getActiveSubscription();
    }
    if (this.subscription?.isActive) {
      this.current = 'pro';
    } else {
      this.current = 'free';
    }
  }

  async getActiveSubscription() {
    const ret = await this.billingService
      .getActiveSubscription(this.sharedService.business.companyId)
      .toPromise();
    if (ret.success) {
      this.subscription = ret.data;
      this.sharedService.activeSubscription = this.subscription;
    }
  }

  async getEmailBucket() {
    this.bucket = undefined;

    const ret = await this.billingService
      .getEmailBucket(this.sharedService.business.companyId)
      .toPromise();

    if (ret.success) {
      this.bucket = ret.data;
    }
  }

  loadInvoices(): void {
    if (!this.loading) {
      this.loading = true;
      this.billingService
        .getInvoices(
          this.sharedService.business.companyId,
          this.page,
          this.count
        )
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((r: any) => {
          if (r.success) {
            this.invoices = [...r.data];
            this.totalCount = r.totalCount;
          }
        });
    }
  }

  getCardsInfo() {
    this.cardInfoLoading = true;
    this.billingService
      .getCards(this.sharedService.business.companyId)
      .pipe(finalize(() => (this.cardInfoLoading = false)))
      .subscribe((res: any) => {
        if (res?.data) {
          this.cardInfo = res.data[0];
        }
      });
  }

  onEditAccountPlan(): void {
    const modelRef = this.modalService.open(AccountPlanEditComponent, {
      backdrop: 'static',
    });
    modelRef.componentInstance.current = this.current;

    modelRef.result.then((result) => {
      if (result) {
        this.current = result;
        this.getEmailBucket();
        this.toastService.showSuccess('Plan updated.');
      }
    });
  }

  opneEditCardDetailsModal(): void {
    const modelRef = this.modalService.open(CardDetailsEditComponent, {
      backdrop: 'static',
    });
    modelRef.result.then((result) => {
      if (result) {
      }
    });
  }

  extendAccountPlan(): void {
    const modelRef = this.modalService.open(AccountPlanExtendComponent, {
      backdrop: 'static',
    });
    modelRef.result.then((result) => {
      if (result) {
      }
    });
  }

  loadPage(ev: number): void {
    if (ev) {
      this.page = ev;
      this.loadInvoices();
    }
  }

  onDownloadAll(): void {
    this.invoices?.forEach((invoice) => {
      if (invoice?.stripeInvoiceURL) {
        FileSaver(invoice?.stripeInvoiceURL);
      }
    });
  }

  getEmailConsumedPercent() {
    if (!this.bucket) return 0;

    const value = this.bucket.consumed / this.bucket.totalPurchased;
    return value * 100;
  }

  // getReceiptUrl(invoiceUrl) {
  //   https://invoicedata.stripe.com/invoice_receipt_file_url/
  // }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { SharedService } from 'src/app/_services/shared.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {

    constructor(private sharedService: SharedService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ setHeaders: { 'x-platform': 'management' } });

        if (this.sharedService.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.sharedService.token}`
                }
            });
        }
        return next.handle(request);
    }
}

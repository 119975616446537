import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { debounce, debounceTime, finalize } from "rxjs/operators";
import { ServerResponse } from "../_models/server-response";
import { User } from "../_models/user.model";
import { AuthenticationService } from "../_services/authentication.service";
import { ConfirmationDialogService } from "../_services/confirmation-dialog.service";
import { ToastService } from "../_services/toast.service";
import { UserService } from "../_services/user.service";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
})
export class OnboardingComponent implements OnInit {
  step = 0;
  isHovering: boolean;
  isLoading: boolean = false;
  timeout = undefined;

  // Business details
  businessName = "";
  businessAddress = "";
  businessLogo: File = undefined;
  businessLogoSrc: any;

  // User details
  firstName = "";
  lastName = "";
  userName = "";
  jobTitle = "";
  profileImage: File = undefined;
  userImageSrc: any;
  isUsernameAvailable = true;
  isUsernameChanged = false;
  isCheckingAvailability = false;

  user: User = undefined;

  // Sommelier details
  certs = [
    "WSET - Award in Wines(Level 1)",
    "WSET - Award in Wines and Spirits(Level 2)",
    "WSET - Award in Wines and Spirits(Level 3)",
    "WSET - Diploma in Wines and Spirits(Level 4)",
    "WSET - Master of Wine(Level 5)",
    "NWS - Foundation Certificate(Level 1)",
    "NWS - Foundation Certificate(Level 2)",
    "NWS - Intermediate Certificate(Level 3)",
    "NWS - Advanced Sommelier Certificate(Level 4)",
    "NWS - Master Diploma(Level 5)",
    "CMS - Introductory Sommelier Certificate(Level 1)",
    "CMS - Certified Sommelier(Level 2)",
    "CMS - Advanced Sommelier(Level 3)",
    "CMS - Master Sommelier Diploma(Level 4)",
    "SWE - Certified Specialist of Wine(Level 1)",
    "SWE - Certified Wine Educator(Level 2)",
    "NASA - Master Wine Taster(Level 1)",
    "NASA - Master of Service(Level 1)",
    "NASA - Sommelier Certification(Level 2)",
    "NASA - Italian Wine Specialist(Level 2)",
    "NASA - American Wine Specialist(Level 2)",
    "NASA - Master of Terroir(Level 3)",
    "NASA - Master of Sangiovese(Level 3)",
    "ISG - Wine Fundamentals I(Level 1)",
    "ISG - Wine Fundamentals II(Level 2)",
    "ISG - Sommelier Diploma(Level 3)",
    "CIA - Wine and Beverage Certificate(Level 1)",
    "CIA - Certified Wine Professional(Level 2)",
    "IWG - Level I(Level 1)",
    "IWG - Level II(Level 2)",
    "IWG - Level III(Level 3)",
    "IWG - Certified Wine Judge(Level 3)",
    "IWG - Certified Wine Instructor(Level 3)",
    "Wine Influencer",
    "Wine Brand Ambassador",
    "Wine Journalist",
    "Winemaker",
  ];

  isCertified = false;
  certificationType = "";
  certificationFile: File = undefined;
  certificationSrc: any;

  hasPermissions = false;

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private toastSerice: ToastService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    this.userService
      .get()
      .pipe(
        finalize(() => {
          if (this.user.isOnboarded) {
            this.router.navigate(["dashboard"]);
          }

          this.firstName = this.user.firstName;
          this.lastName = this.user.lastName;
          this.userName = this.user.username;
          this.hasPermissions =
            this.user.permissions.length > 0 &&
            this.user.permissions.findIndex((x) => x.value !== 0) > -1;

          if (this.hasPermissions) {
            this.step = 2;
          } else {
            this.step = 1;
            if (this.user.businessName) {
              this.businessName = this.user.businessName;
            } else {
              const company = this.user.permissions.find(
                (x) => x.company.ownerId === this.user.id
              );
              if (company) {
                this.businessName = company.company?.name;
                this.businessAddress = company.company?.address;
              }
            }
          }
        })
      )
      .subscribe((x) => {
        if (x.success) {
          this.user = x.data;
          this.authService.setUserDetails$(this.user);
        } else {
          this.user = this.authService.getUser();
        }
      });
  }

  next(step) {
    this.step = step;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onFilePicked(type, $event) {
    if ($event.target.files[0]) {
      if (type === "business") {
        this.businessLogo = $event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => (this.businessLogoSrc = reader.result);
        reader.readAsDataURL(this.businessLogo);
      } else if (type === "certification") {
        this.certificationFile = $event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => (this.certificationSrc = reader.result);
        reader.readAsDataURL(this.certificationFile);
      } else {
        this.profileImage = $event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => (this.userImageSrc = reader.result);
        reader.readAsDataURL(this.profileImage);
      }
    }
  }

  onDrop(type, files: FileList) {
    if (files.length > 0) {
      const file: File = files[0];
      const supportedTypes: Array<string> = ["png", "jpg", "jpeg"];

      if (supportedTypes.indexOf(file.type.split("/")[1]) === -1) return;

      if (type === "business") {
        this.businessLogo = file;
        const reader = new FileReader();
        reader.onload = (e) => (this.businessLogoSrc = reader.result);
        reader.readAsDataURL(this.businessLogo);
      } else if (type === "certification") {
        this.certificationFile = file;
        const reader = new FileReader();
        reader.onload = (e) => (this.certificationSrc = reader.result);
        reader.readAsDataURL(this.certificationFile);
      } else {
        this.profileImage = file;
        const reader = new FileReader();
        reader.onload = (e) => (this.userImageSrc = reader.result);
        reader.readAsDataURL(this.profileImage);
      }
    }
  }

  onRemove(type) {
    if (type === "business") {
      this.businessLogo = undefined;
      this.businessLogoSrc = "";
    } else if (type === "certification") {
      this.certificationFile = undefined;
    } else {
      this.profileImage = undefined;
      this.userImageSrc = "";
    }
  }

  submit() {
    if (!this.isLoading) {
      this.isLoading = true;
      const model = {
        businessName: this.businessName,
        businessAddress: this.businessAddress,
        businessLogo: this.businessLogo,
        firstName: this.firstName,
        lastName: this.lastName,
        username: this.userName,
        profileImage: this.profileImage,
        isCertified: this.isCertified,
        certification: this.certificationType,
        certificationFile: this.certificationFile,
        jobTitle: this.jobTitle,
      };

      this.authService
        .onboard(model)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((m) => {
          if (m.success) {
            this.authService.setUserDetails$(m.data);
            this.router.navigate(["games"]);
          } else {
            this.toastSerice.showError(m.message);
          }
        });
    }
  }

  submitBusiness() {
    if (this.businessLogo) {
      this.next(2);
    }
  }

  submitUserDetail() {
    if (this.profileImage) {
      this.next(3);
    }
  }

  onboard() {
    if (this.isCertified) {
      if (this.certificationType && this.certificationFile) {
        this.submit();
      }
    } else {
      this.certificationType = "";
      this.certificationFile = null;
      this.submit();
    }
  }

  checkUsernameAvailability() {
    this.isCheckingAvailability = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (!this.isUsernameChanged) {
      this.isUsernameChanged = true;
    }

    if (this.userName) {
      this.timeout = setTimeout(() => {
        if (this.userName) {
          this.authService
            .findUsernameAvailability(this.userName)
            .pipe(finalize(() => (this.isCheckingAvailability = false)))
            .subscribe((ret: ServerResponse<boolean>) => {
              this.isUsernameAvailable = ret.data;
            });
        }
      }, 1000);
    } else {
      this.isCheckingAvailability = false;
    }
  }

  cancel() {
    this.confirmationDialogService
      .confirm(
        "Confirmation",
        "You will be logged out, do you wish to continue?",
        "Yes",
        "No",
        true
      )
      .then((success) => {
        if (success) {
          this.authService.logout(true);
          window.location.href = "https://sommsays.co/";
        }
      })
      .catch(() => {});
  }
}

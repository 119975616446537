import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastLocation, ToastService } from '../_services/toast.service';
import * as LogRocket from 'logrocket';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  step = 0;
  isLoading = false;

  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  privacyAndTermsAccepted = false;
  pa: string;
  formDisabled = false;

  resendCodeRequest = false;

  code: string = '';
  showPasscode = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.businessName = params['business'];
      this.email = params['email'];
      this.firstName = params['firstname'];
      this.lastName = params['lastname'];
      this.pa = params['pa'];
      this.privacyAndTermsAccepted = true;
    });
  }

  ngOnInit() {
    if (this.pa === '1') {
      this.formDisabled = true;
      this.signup();
    }
  }

  signup() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.formDisabled = false;

      const model = {
        email: this.email,
        businessName: this.businessName,
        firstName: this.firstName,
        lastName: this.lastName,
      };

      this.authService
        .signup(model)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((m) => {
          if (!m.success) {
            this.toastService.showError(
              m.message,
              5000,
              ToastLocation.TopCenter
            );
          } else {
            this.step = 1;
            this.formDisabled = false;
          }
        });
    }
  }

  resendCode() {
    if (this.resendCodeRequest) return;

    this.resendCodeRequest = true;
    this.authService
      .validate(this.email, 'email')
      .pipe(finalize(() => (this.resendCodeRequest = false)))
      .subscribe((m) => {
        if (m.success) {
          this.toastService.showSuccess(
            'Code has been re-sent check yuor inbox'
          );
        } else {
          this.toastService.showError(m.message, 5000, ToastLocation.TopCenter);
        }
      });
  }

  confirm() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.authService.signin(this.email, this.code, 'email').subscribe((m) => {
        if (m.success) {
          this.authService.setUserDetails$(m.data);
          localStorage.setItem('token', m.data.token);
          this.router.navigate(['dashboard']);
        } else {
          this.toastService.showError(m.message, 5000, ToastLocation.TopCenter);
        }
        this.isLoading = false;
      });
    }
  }
}

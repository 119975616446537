import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingResponse, ServerResponse } from '../_models/server-response';
import { Wine } from '../_models/wine.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private apiService: ApiService) {}

  getCustomerId(stripeToken: string) {
    return this.apiService.get(
      `api/Payment/get-customer-id?stripeToken=${stripeToken}`
    );
  }

  getStripeCustomerId() {
    return this.apiService.get(`api/Payment/get-stripe-customer-id`);
  }

  getPlans() {
    return this.apiService.get(`api/Payment/get-plans`);
  }

  subscribe(model: any) {
    let fd = this.apiService.jsonToFormData(model);
    return this.apiService.postAsForm('api/Payment/subscribe', fd);
  }

  charge(model) {
    let fd = this.apiService.jsonToFormData(model);
    return this.apiService.postAsForm('api/Payment/charge', fd);
  }
}

<app-shell-light>
    <div class="nk-content ">
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <app-global-filters [title]="title" [subTitle]="subTitle" [filter]="filter"
                        (createGame)="createGame()" [filterOptions]="filterOptions" [showGames]="showGames"
                        (load)="search()">
                    </app-global-filters>
                    <div class="card card-bordered card-full mt-4">
                        <div class="card-inner p-0">
                            <div class="nk-tb-list nk-tb-orders d-none d-md-table">
                                <div class="nk-tb-item nk-tb-head f-16">
                                    <div class="nk-tb-col tb-col-md"><span>Producers</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Wines</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>States</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Somms</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Players</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Rating</span></div>
                                    <div class="nk-tb-col tb-col-sm"><span>Gms Played</span></div>
                                    <div class="nk-tb-col tb-col-sm"></div>
                                </div>

                                <div class="nk-tb-item selectable" *ngFor="let producer of producers">
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    <div class="flex-row align-center">
                                                        <img class="img" src="{{env.cdn}}companies/{{producer?.logo}}">
                                                        <div class="text-start">
                                                            <div class="f-14">{{producer?.name}}</div>
                                                            <div class="f-12">
                                                                <span *ngIf="producer?.city">{{producer?.city +
                                                                    ','}}</span>
                                                                <span
                                                                    *ngIf="producer?.country">{{producer?.country}}</span>
                                                            </div>
                                                            <div class="f-11">
                                                                <span class="mr-5" *ngIf="producer?.verifiedUrl">
                                                                    <img class="mr-5"
                                                                        src="../../assets/icons/icons8-tick.svg">Verified
                                                                    Url</span>
                                                                <span class="mr-5" *ngIf="producer?.verifiedBusiness">
                                                                    <img class="mr-5"
                                                                        src="../../assets/icons/icons8-tick.svg">Verified
                                                                    Business</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    <span> {{ producer?.props?.wineCount }}</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    <span> {{ producer?.props?.statesCount }}</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ producer?.props?.sommsCount }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ producer?.props?.playersCount }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ producer?.props?.rating }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col tb-col-sm">
                                        <table>
                                            <tr>
                                                <td class="center">
                                                    {{ producer?.props?.gamesCount }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="nk-tb-col nk-tb-col-action tb-col-sm">
                                        <table class="ml-auto">
                                            <tr>
                                                <td>
                                                    <button class="edit-btn"
                                                        (click)="editProducer(producer)">Edit</button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center empty" *ngIf="loading === true">
                                <div class="spinner-border spinner-border-sm" role="status"> <span
                                        class="sr-only">Loading...</span></div>
                            </div>

                            <div class="col-12 py-2">
                                <ngb-pagination *ngIf="!loading" [(page)]="page" [(pageSize)]="count" [rotate]="true"
                                    [ellipses]="true" [boundaryLinks]="true" [collectionSize]="totalCount"
                                    (pageChange)="loadPage($event)">
                                </ngb-pagination>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</app-shell-light>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CardDetailsComponent } from '../card-details/card-details.component';
import { DowngradePlanModalComponent } from '../downgrade-plan-modal/downgrade-plan-modal.component';
import { Subscription } from '../_models/subscription.model';
import { AuthenticationService } from '../_services/authentication.service';
import { BillingService } from '../_services/billing.service';
import { CMSService } from '../_services/cms.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-account-plan',
  templateUrl: './account-plan.component.html',
  styleUrls: ['./account-plan.component.scss'],
})
export class AccountPlanComponent implements OnInit, OnDestroy {
  current = '';
  loading = false;
  showPlans = false;
  downgradeLoading = false;

  freeFeatures = [
    'Digital wine tasting game',
    'Digital wine tech sheets',
    'Wine merchandising data',
    'Push and In-platform notifications',
    'Direct “Buy Now” links ',
  ];

  proFeatures = [
    'Weekly “New Game” emails to followers and known customers',
    'Sync customer emails with Somm Says data to optimize relationships',
    'Automated, personalized reminder emails arrive 2-3 days after a postive player experience for all players',
    'Add 1,000 more emails for just $30',
  ];

  frequentQuestions = [];

  images = [
    'assets/images/avatar/b-sm.jpg',
    'assets/images/avatar/c-sm.jpg',
    'assets/images/avatar/d-sm.jpg',
  ];

  stripePlan = environment.stripeMonthlyPlan;
  subscription: Subscription;

  $destroy = new Subject();

  faqs: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private billingService: BillingService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private cmsService: CMSService
  ) {}

  async ngOnInit() {
    this.subscription = this.sharedService.activeSubscription;

    if (this.subscription) {
      this.showPlans = true;
      this.current = this.subscription?.isActive ? 'pro' : 'free';
    } else {
      const ret = await this.billingService
        .getActiveSubscription(this.sharedService.business.companyId)
        .toPromise();

      if (ret.success) {
        this.sharedService.activeSubscription = ret.data;
        this.subscription = ret.data;
        this.current = this.subscription?.isActive ? 'pro' : 'free';
        this.showPlans = true;
      }
    }

    this.authService._subscription$
      .pipe(takeUntil(this.$destroy))
      .subscribe((t) => {
        this.subscription = t;
        this.current = this.subscription?.isFree ? 'free' : 'pro';
      });

    this.cmsService.getSubscriptionFaq().subscribe((ret) => {
      if (ret) {
        ret.items.forEach((element) => {
          this.frequentQuestions.push({
            q: element.data.question.iv,
            a: element.data.answer.iv,
            expanded: false,
          });
        });
      }
    });
  }

  onUpgradePlan(): void {
    if (this.current === 'pro') {
      this.router.navigate(['/account-plan/current-plan']);
    } else {
      this.loading = true;
      this.getStripeCustomerId();
    }
  }

  async getStripeCustomerId() {
    const stripeCustomerIdRet = await this.billingService
      .getStripCustomerId(this.sharedService.business.companyId)
      .toPromise();

    if (stripeCustomerIdRet.success) {
      this.loading = false;
      const subscribed = await this.billingService
        .subscribe(stripeCustomerIdRet.data, this.stripePlan)
        .toPromise();
      if (subscribed.success) {
        this.subscription = subscribed.data;
        if (this.subscription?.isActive) {
          this.current = 'pro';
        } else {
          this.current = 'free';
        }
        this.sharedService.activeSubscription = this.subscription;
        this.router.navigate(['/account-plan/current-plan']);
      }
    } else {
      this.loading = false;
      this.opneCardDetailsModal();
    }
  }

  opneCardDetailsModal(): void {
    const modelRef = this.modalService.open(CardDetailsComponent, {
      backdrop: 'static',
    });
    modelRef.result.then(async (result) => {
      if (result) {
        const subscribed = !this.subscription?.id
          ? await this.billingService
              .subscribe(result.data, this.stripePlan)
              .toPromise()
          : await this.billingService
              .updatePlan(
                this.sharedService.business.companyId,
                this.stripePlan
              )
              .toPromise();

        if (subscribed.success) {
          this.subscription = subscribed.data;
          if (this.subscription?.isActive) {
            this.current = 'pro';
          } else {
            this.current = 'free';
          }
          this.router.navigate(['/account-plan/current-plan']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
  }

  onDowngradePlan(): void {
    if (this.current === 'free') {
      this.router.navigate(['/account-plan/current-plan']);
      return;
    }
    const modelRef = this.modalService.open(DowngradePlanModalComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.result.then((result) => {
      if (result) {
        this.downgradeLoading = true;
        this.billingService
          .updatePlan(this.sharedService.business.companyId, 'free')
          .subscribe((res) => {
            if (res?.data) {
              this.current = 'free';
              this.downgradeLoading = false;
              this.router.navigate(['/account-plan/current-plan']);
            }
          });
      }
    });
  }
}

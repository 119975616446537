import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { User } from '../_models/user.model';
import { ApiService } from './api.service';
import jwt_decode from 'jwt-decode';
import { SharedService } from './shared.service';
import { Team } from '../_models/team';
import { Subscription } from '../_models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  _userDetails$: Subject<any> = new Subject();
  _defaultCompany$: Subject<Team> = new Subject();
  _subscription$: Subject<Subscription> = new Subject();

  token: any = '';
  user: User = null;
  private roles: string[] = [];

  constructor(
    private apiService: ApiService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  validate(value: string, mode: string): Observable<any> {
    return this.apiService.postForm('authentication/validate', { value, mode });
  }

  signin(value: string, code: string, mode: string): Observable<any> {
    return this.apiService.postForm('authentication/signin', {
      value,
      code,
      mode,
    });
  }

  signup(o: any): Observable<any> {
    return this.apiService.post('authentication/signup', o);
  }

  onboard(o: any): Observable<any> {
    return this.apiService.postForm('authentication/onboard', o);
  }

  findUsernameAvailability(username: string) {
    return this.apiService.postForm(
      'authentication/check-username-availability',
      { username }
    );
  }

  isSignedIn() {
    const token = localStorage.getItem('token');
    return !!token;
  }

  logout(isExternalUrl = false) {
    localStorage.clear();
    this.sharedService.token = null;
    this.setUserDetails$(null);
    if (!isExternalUrl) this.router.navigate(['/signin']);
  }

  setUserDetails$(user) {
    localStorage.setItem('user', JSON.stringify(user));
    this.user = user;
    this._userDetails$.next(user);
    this._defaultCompany$.next(
      this.user?.permissions?.find((x) => x.isDefault)
    );
  }

  getUserDetails$() {
    if (this.user == null) {
      const user = this.getUser();
      if (user) {
        this._userDetails$.next(user);
      }
      return this._userDetails$.asObservable();
    } else {
      this._userDetails$.next(this.user);
      return this._userDetails$.asObservable();
    }
  }

  changeSubscription$(sb: Subscription) {
    this._subscription$.next(sb);
  }

  getSubscription$(): Observable<Subscription> {
    return this._subscription$.asObservable();
  }

  setDefaultCompany$(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.user = user;
    this._defaultCompany$.next(user?.permissions?.find((x) => x.isDefault));
  }

  getUserCompany$(): Observable<Team> {
    return this._defaultCompany$.asObservable();
  }

  getDefaultCompany(): Team {
    const user = this.getUser();
    const permissions = user?.permissions;
    const permission = permissions.find((x) => x.isDefault);

    if (permission) {
      return permission;
    }

    return permissions[0];
  }

  getUser(): User {
    const userJSON = localStorage.getItem('user');

    if (userJSON) {
      this.user = JSON.parse(userJSON) as User;
      return this.user;
    } else {
      return null;
    }
  }

  isOnboarded(): boolean {
    const user = this.getUser();
    return user && user.isOnboarded;
  }

  getToken = () => localStorage.getItem('token');

  getRoles(): string[] {
    if (this.roles.length > 0) return this.roles;

    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded: any = jwt_decode(token);
        if (Array.isArray(decoded.role)) {
          this.roles = [...decoded.role];
        } else {
          this.roles = [decoded.role];
        }
      } catch (Error) {}
    }

    return this.roles;
  }

  setLogRocket() {
    localStorage.setItem('logRocket', 'started');
  }

  getLockRocket() {
    return localStorage.getItem('logRocket');
  }

  isAdmin() {
    return this.getRoles().indexOf('admin') > -1;
  }

  isSommelier() {
    return this.getRoles().indexOf('sommelier') > -1;
  }

  isPlayer() {
    return this.getRoles().indexOf('player') > -1;
  }
}

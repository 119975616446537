import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shell-light',
  templateUrl: './shell-light.component.html',
  styleUrls: ['./shell-light.component.scss']
})
export class ShellLightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

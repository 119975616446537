<body class="nk-body npc-default pg-auth">
  <div class="nk-app-root">
    <div class="nk-main ">
      <div class="nk-wrap nk-wrap-nosidebar">
        <div class="nk-content ">
          <div class="nk-block nk-block-middle nk-auth-body wide-xs">
            <div class="brand-logo pb-4 text-center">
              <a [routerLink]="['/']" routerLinkActive="router-link-active" class="logo-link">
                <img class="" src="../../assets/logo/logo.png" alt="logo">
              </a>
            </div>


            <div class="card card-bordered" *ngIf="step == 0">
              <div class="card-inner card-inner-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Sign Up</h4>
                    <div class="nk-block-des">
                      <p>Ready to reach more customers? Let's get started.</p>
                    </div>
                  </div>
                </div>
                <form disabled #signupForm="ngForm"
                  (ngSubmit)="signupForm.valid && privacyAndTermsAccepted && signup()">
                  <div class="form-group">
                    <label class="form-label" for="name">Business Name</label>
                    <input type="text" class="form-control form-control-lg" [(ngModel)]="businessName"
                      #business="ngModel" name="businessName" id="businessName" placeholder="Enter business name"
                      [disabled]="formDisabled" required>
                    <div *ngIf="signupForm.submitted && business.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="business.errors.required">This field is required.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="name">First Name</label>
                    <input type="text" class="form-control form-control-lg" [(ngModel)]="firstName" #firstname="ngModel"
                      name="firstName" id="fisrtName" placeholder="Enter your first name" [disabled]="formDisabled"
                      required>
                    <div *ngIf="signupForm.submitted && firstname.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="firstname.errors.required">This field is required.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="name">Last Name</label>
                    <input type="text" class="form-control form-control-lg" name="lastName" id="lastName"
                      placeholder="Enter your last name" required [(ngModel)]="lastName" #lastname="ngModel"
                      [disabled]="formDisabled">
                    <div *ngIf="signupForm.submitted && lastname.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="lastname.errors.required">This field is required.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="email">Email</label>
                    <input type="email" name="emailAddress" [(ngModel)]="email" #emailAddress="ngModel"
                      class="form-control form-control-lg" id="email" placeholder="Enter your email address"
                      [disabled]="formDisabled" pattern="[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
                    <div *ngIf="signupForm.submitted && emailAddress.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="emailAddress.errors.required">This field is required.</div>
                      <div *ngIf="emailAddress.errors.pattern">Provided email address is invalid.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-control-xs custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="checkbox"
                        (change)="privacyAndTermsAccepted = $event.target.checked" [checked]="privacyAndTermsAccepted">
                      <label class="custom-control-label" for="checkbox">I agree to Somm Says
                        <a [routerLink]="['/privacy']">Privacy Policy</a> &amp; <a [routerLink]="['/terms']">
                          Terms. </a></label>
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" appButtonSpinner class="btn btn-lg btn-super btn-block" text="Continue"
                      [loadingState]="isLoading">
                    </button>
                  </div>
                </form>
                <div class="form-note-s2 text-center pt-1"> Already have an account? <a
                    [routerLink]="['/signin']"><strong>Sign In</strong></a>
                </div>
              </div>
            </div>

            <div class="card card-bordered" *ngIf="step == 1">
              <div class="card-inner card-inner-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Confirm Verification Code</h4>
                    <div class="nk-block-des">
                      <p>Please enter the code sent to your email.</p>
                    </div>
                  </div>
                </div>
                <form #confirmationForm="ngForm" (ngSubmit)="confirmationForm.valid && confirm()">

                  <div class="form-group">
                    <div class="form-label-group">
                      <label class="form-label" for="password">Verification Code</label>
                      <a class="link link-primary link-sm cursor-pointer" (click)="resendCode()">Re-send code?</a>
                    </div>
                    <div class="form-control-wrap">
                      <a (click)="showPasscode = !showPasscode" class="form-icon form-icon-right passcode-switch"
                        data-target="password">
                        <em *ngIf="!showPasscode" class="icon ni ni-eye"></em>
                        <em *ngIf="showPasscode" class="icon ni ni-eye-off"></em>
                      </a>

                      <input [type]="showPasscode ? 'text' : 'password'" class="form-control form-control-lg"
                        id="password" [(ngModel)]="code" #passcode="ngModel" name="passcode"
                        placeholder="Enter your passcode" minlength="6" maxlength="6" required>
                    </div>
                    <div *ngIf="confirmationForm.submitted && passcode.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="passcode.errors.minlength">6 digits passcode is required</div>
                      <div *ngIf="passcode.errors.required">This field is required.</div>
                    </div>
                  </div>

                  <!-- <div class="form-group">
                    <label class="form-label" for="name">Verification Code</label>
                    <input type="text" class="form-control form-control-lg" [(ngModel)]="code" #passcode="ngModel"
                      name="passcode" placeholder="Enter code" minlength="6" maxlength="6" required>
                    <div *ngIf="confirmationForm.submitted && passcode.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="passcode.errors.minlength">6 digits passcode is required</div>
                      <div *ngIf="passcode.errors.required">This field is required.</div>
                    </div>
                  </div> -->
                  <div class="form-group">
                    <button type="submit" appButtonSpinner class="btn btn-lg btn-super btn-block" text="Confirm"
                      [loadingState]="isLoading">
                    </button>
                  </div>
                </form>
                <div class="form-note-s2 text-center pt-1"> Already have an account? <a
                    [routerLink]="['/signin']"><strong>Sign in
                      instead</strong></a>
                </div>
              </div>
            </div>

          </div>
          <div class="nk-footer nk-auth-footer-full">
            <div class="container wide-lg">
              <div class="row g-3">
                <div class="col-lg-6 order-lg-last">
                  <ul class="nav nav-sm justify-content-center justify-content-lg-end">
                    <li class="nav-item">
                      <a class="nav-link" [routerLink]="['/termse']">Terms & Condition</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [routerLink]="['/privacye']">Privacy Policy</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [routerLink]="['/eulae']">EULA</a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6">
                  <div class="nk-block-content text-center text-lg-left">
                    <p class="text-soft">&copy; 2021 SommSays. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
import { Injectable } from "@angular/core";
import { FirebaseEvent } from "../_models/firebaseEventModel";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventService {
  protected eventSubject = new Subject<FirebaseEvent<any>>();
  public events = this.eventSubject.asObservable();

  dispatchEvent(event: FirebaseEvent<any>) {
    this.eventSubject.next(event);
  }
}

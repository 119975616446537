import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Team } from '../_models/team';
import { AuthenticationService } from '../_services/authentication.service';
import { TeamService } from '../_services/team.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-team-member-create',
  templateUrl: './team-member-create.component.html',
  styleUrls: ['./team-member-create.component.scss']
})
export class TeamMemberCreateComponent implements OnInit {

  team: Team = new Team();

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  permissionValue = undefined;

  isSaving = false;
  inviteSent = false;

  constructor(private modal: NgbActiveModal, private authService: AuthenticationService, private teamService: TeamService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.team.companyId = this.authService.getDefaultCompany().companyId;
  }

  close = () => this.modal.dismiss();

  sendInvite() {
    this.isSaving = true;
    this.team.value = Number.parseInt(this.permissionValue);
    this.teamService.sendInvite(this.team)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe(m => {
        if (m.success) {
          this.passEntry.emit(m.data);
          this.inviteSent = true;
        } else {
          this.toastService.showError(m.message, 5000);
        }
      });
  }

}

<div class="row g-gs d-flex justify-between align-center">
    <div class="information col-sm-12 col-md-4">
        <div class="name">{{title}}</div>
        <div class="description">{{subTitle}}</div>
        <!-- <div class="description">
            {{ player.username }}
            <span class="badge badge-light text-dark ml-3" *ngIf="player.hasFollowed">Follows You</span>
          </div> -->
    </div>
    <div class="col-sm-12 col-md-8 p-0 end">
        <button type="button" [ngClass]="{'active': isFilter}" class="btn filter-by" (click)="isFilter = !isFilter">
            {{
            selectedFilterCount>0?
            (selectedFilterCount>1?selectedFilterCount+' Filters Applied'
            :selectedFilterCount+'
            Filter Applied')
            :'Filter By'}}
            <img src="../../assets/icons/icons8-select.svg" class="ml-1">
        </button>
        <div class="btn-group duration-filter" *ngIf="showGames?.duration">
            <button type="button" class="btn btn-light" (click)="changeDuration(2)"
                [ngClass]="{'selected': filter.durationType === 2}">Last 7 days</button>
            <div class="btn-separater"></div>
            <button type="button" class="btn btn-light" (click)="changeDuration(3)"
                [ngClass]="{'selected': filter.durationType === 3}">Last 30 Days</button>
            <div class="btn-separater"></div>
            <button type="button" class="btn btn-light" (click)="changeDuration(1)"
                [ngClass]="{'selected': filter.durationType === 1}">All
                Time</button>
        </div>
        <button type="button" class="btn create-game" (click)="game()" *ngIf="showGames?.game">Create Game</button>
        <button type="button" class="btn create-game" (click)="importHandler()"
            *ngIf="showGames?.import">Import</button>
        <button type="button" class="btn create-game" (click)="exportHandler()"
            *ngIf="showGames?.export">Export</button>
    </div>

</div>
<div class="row" *ngIf="isFilter">
    <div class="col-12 mb-1">
        <div class="card card-bordered search-border">
            <div class="card-inner search-container">
                <div class="row mt-2">
                    <div class="col-12 col-sm-4">
                        <div class="label">Producer</div>
                        <div class="form-control-wrap">
                            <ng-select name="userTypes" [(ngModel)]="filter.producers"
                                placeholder="E.g. Decoy, Kim Crawford, or Tenuta San Guido" multiple="true">
                                <ng-option *ngFor="let item of filterOptions.producers" [value]="item.text">{{
                                    item.text }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mt-1 mt-sm-0">
                        <div class="label">Region or Appellation</div>
                        <div class="form-control-wrap">
                            <ng-select name="regions" [(ngModel)]="filter.region"
                                placeholder="E.g. Argentina, Oregon, or Napa Valley" multiple="true">
                                <ng-option *ngFor="let item of filterOptions.regions | keyvalue" [value]="item.key">
                                    {{ item.value }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mt-1 mt-sm-0">
                        <div class="label">Variety</div>
                        <div class="form-control-wrap">
                            <ng-select name="varietals" [(ngModel)]="filter.varietal"
                                placeholder="E.g. Bordeaux, Chardonnay, or Sangiovese" multiple="true">
                                <ng-option *ngFor="let item of filterOptions.varietals | keyvalue" [value]="item.key">
                                    {{ item.value }}</ng-option>
                            </ng-select>

                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12 col-sm-4">
                        <div class="label">Color</div>
                        <div class="form-control-wrap">
                            <ng-select name="colors" [(ngModel)]="filter.colors"
                                placeholder="E.g. Red, White, Rose or Orange" multiple="true">
                                <ng-option *ngFor="let item of colors" [value]="item.value">{{item.name}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 mt-1 mt-sm-0 col-sm-2">
                        <div class="label">Vintage From</div>
                        <div class="form-control-wrap">
                            <input type="text" class="form-control" [(ngModel)]="filter.vintageFrom"
                                placeholder="E.g. 2018" />
                        </div>
                    </div>
                    <div class="col-12 mt-1 mt-sm-0 col-sm-2">
                        <div class="label">Vintage to</div>
                        <div class="form-control-wrap">
                            <input type="text" class="form-control" [(ngModel)]="filter.vintageTo"
                                placeholder="E.g. 2022" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 mt-1 mt-sm-0">
                        <div class="label">Sommelier</div>
                        <div class="form-control-wrap">
                            <ng-select name="varietals" [(ngModel)]="filter.sommeliers"
                                placeholder="E.g. Steven Washuta" multiple="true">
                                <ng-option *ngFor="let item of filterOptions.sommeliers | keyvalue" [value]="item.key">
                                    {{ item.value }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <!-- <div class="col-12 col-sm-4 mt-1 mt-sm-0">
                        <div class="label">State</div>
                        <div class="form-control-wrap">
                            <input type="text" class="form-control" name="varietals" [(ngModel)]="filter.state"
                                placeholder="E.g. California, Colorado, Washington">
                        </div>
                    </div> -->
                </div>
                <!--<div class="row ng-block mt-2">
                    <div class="col-12 mt-1 mt-sm-0 col-sm-4">
                        <div class="label">Rating</div>
                        <div class="form-control-wrap">
                            <ng-select name="rating" [(ngModel)]="filter.rating"
                                placeholder="E.g. Top 10% or Top 25%" multiple="true">
                                <ng-option *ngFor="let item of rating" [value]="item.rating">{{item.key}}
                                </ng-option>
                        </div>
                    </div>

                </div>-->
                <div class="row mt-2 pl-16">
                    <div>
                        <button (click)="applyFilter()" class="btn refresh mr-8">Apply Filters</button>
                    </div>

                    <div>
                        <button (click)="clearFilters()" class="btn clear"> Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
export const environment = {
  production: true,
  version: '1.137',
  build: '1036',
  mode: 'prod',
  apiURL: 'https://apiqa.sommsays.co',
  cdn: 'https://sommsays.azureedge.net/',
  pwaUrl: 'https://pwaqa.sommsays.co',
  froalaKey:
    'te1C2sD7D7E6B4E3I4E3kuxtjA-9C2mpmlqbA-9vH4dguD3E2D1F1C4F1D4F1C11C6==',
  stripeKey:
    'pk_test_51LAyL0I4cl7i6DkBaMLB9n8YbfCJAmuG7gWu6sHPmBKd3oBCICggC3EIx59ALPIg4xKoUQ2hJPEoWi9R89m3UueE008yRSYrWe',
  stripeMonthlyPlan: 'price_1LmkzqI4cl7i6DkBIVeqEM81',
  firebaseConfig: {
    apiKey: 'AIzaSyAywQEnKYXTLAPS5FBn4o3HzJNYcAqxhpY',
    authDomain: 'sommsays-ce6ac.firebaseapp.com',
    databaseURL: 'https://sommsays-ce6ac.firebaseio.com',
    projectId: 'sommsays-ce6ac',
    storageBucket: 'sommsays-ce6ac.appspot.com',
    messagingSenderId: '591303308434',
    appId: '1:591303308434:web:237994059722df1596e24b',
    measurementId: 'G-B80B0HBC9L',
  },
};

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ServerResponse } from '../_models/server-response';
import { User } from '../_models/user.model';
import { Company } from '../_models/company.model';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(private apiService: ApiService) { }

    getTemplatesByCompany(companyId): Observable<ServerResponse<any>> {
        return this.apiService.get<ServerResponse<any>>(`game/get-templates-by-company/${companyId}`);
    }

    getProducers(companyId): Observable<ServerResponse<any>> {
        return this.apiService.get<ServerResponse<any>>(`game/get-producers/${companyId}`);
    }

    getSomms(companyId): Observable<ServerResponse<any>> {
        return this.apiService.get<ServerResponse<any>>(`game/get-somms/${companyId}`);
    }

    getCompany(companyId: string): Observable<any> {
        return this.apiService.get<ServerResponse<Company>>(`game/get-company/${companyId}`);
    }

    updateCompanyDetails(model: any, file: any): Observable<any> {
        const fd = this.apiService.jsonToFormData(model);

        if (file) {
            fd.append('file', file);
        }

        return this.apiService.postForm(`company/update-enterprise`, fd, {}, true);
    }

    updateUserProfile(model: any): Observable<any> {
        return this.apiService.post(`user/update`, model);
    }

    updateUserPicture(model: any): Observable<any> {
        return this.apiService.postForm(`user/upload-picture`, model);
    }

    getTemplatesByUser(): Observable<ServerResponse<any>> {
        return this.apiService.get<ServerResponse<any>>(`user/get-templates`);
    }

    getUserEnterprises(): Observable<ServerResponse<any>> {
        return this.apiService.get<ServerResponse<any>>(`user/get-enterprises`);
    }
}
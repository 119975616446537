import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { textChangeRangeIsUnchanged } from 'typescript';
import { GameService } from '../_services/game.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-instance-create',
  templateUrl: './instance-create.component.html',
  styleUrls: ['./instance-create.component.scss']
})
export class InstanceCreateComponent implements OnInit {

  @Input() templateId: string = '';
  instanceId: string = '';

  @Input() set instance(instanceVal) {
    this.instanceId = instanceVal?.id;
    if (instanceVal?.expiresOn.indexOf('0001') === 0) {
      this.willExpire = false;
    } else if (instanceVal?.expiresOn.indexOf('0001') < 0) {
      this.willExpire = true;
      const date = new Date(instanceVal.expiresOn);
      const dateBreakdown = date.toISOString().split('T');
      const timeBreakdown = date.toLocaleTimeString('en-US').split(':');
      this.endDate = dateBreakdown[0];
      this.endTime = timeBreakdown[0] + ':' + timeBreakdown[1];
    }

    if (instanceVal?.startDateTime.indexOf('0001') === 0) {
      this.isStartNow = true;
    } else if (instanceVal?.startDateTime.indexOf('0001') < 0) {
      this.isStartNow = false;
      const date = new Date(instanceVal.startDateTime);
      const dateBreakdown = date.toISOString().split('T');
      const timeBreakdown = date.toLocaleTimeString('en-US').split(':');
      this.startDate = dateBreakdown[0];
      this.startTime = timeBreakdown[0] + ':' + timeBreakdown[1];
    }

    this.isUnlimitedPlayers = instanceVal?.maxParticipants === 0;
    if (!this.isUnlimitedPlayers) {
      this.maxParticipants = instanceVal?.maxParticipants;
    }
  }

  isStartNow = true;
  willExpire = false;
  isUnlimitedPlayers = true;
  code = '';

  startDate: any;
  startTime: any;

  endDate: any;
  endTime: any;

  maxParticipants: number;

  isInstanceProcessed = false;
  isSaving = false;

  index = 0;
  blobs = [];
  loading = false;
  isDownloaded = false;

  horizontalQRHref = '';
  squareQRHref = '';
  digitalQRHref = '';
  instance_url = '';

  constructor(private gameService: GameService, private modal: NgbActiveModal, private toastService: ToastService, private domeSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  save() {
    this.isSaving = true;
    const payload = {
      isStartingNow: this.isStartNow,
      noExpiration: !this.willExpire,
      isUnlimitedPeopleAllowed: this.isUnlimitedPlayers,
      maxParticipant: this.maxParticipants
    };

    if (!this.isStartNow) {
      const startDate = this.startDate.split('-');
      const startTime = this.startTime.split(':');

      const startDateTime = new Date(startDate[0], startDate[1], startDate[2], startTime[0], startTime[1]);
      payload['startDateTime'] = startDateTime;
    }

    if (this.willExpire) {
      const endDate = this.endDate.split('-');
      const endTime = this.endTime.split(':');

      const expiration = new Date(endDate[0], Number.parseInt(endDate[1]) - 1, endDate[2], endTime[0], endTime[1]);
      payload['expirationDateTime'] = expiration;
    }


    if (this.instanceId) {
      this.gameService.updateInstance(this.instanceId, payload)
        .pipe(finalize(() => this.isSaving = false))
        .subscribe(m => {
          if (m.success) {
            this.isInstanceProcessed = true;
            this.code = m.data.code;
            this.modal.close({ refresh: true, data: m.data });
          }
        });
    } else {
      this.gameService.createInstance(this.templateId, payload)
        .pipe(finalize(() => this.isSaving = false))
        .subscribe(m => {
          if (m.success) {
            this.isInstanceProcessed = true;
            this.code = m.data.code;
            this.instance_url = environment.pwaUrl + '/scan/' + this.code;
            this.generateQR(m.data.code);
          }
        });
    }
  }

  generateQR(code: string) {
    this.loading = true;
    var horizontalQRRequest = this.gameService.downloadQRCode(code, "1");
    var squareQRRequest = this.gameService.downloadQRCode(code, "0");
    var digitalQRRequest = this.gameService.downloadQRCode(code, "2");

    forkJoin([horizontalQRRequest, squareQRRequest, digitalQRRequest])
      .pipe(finalize(() => this.loading = false))
      .subscribe(results => {
        results.forEach(result => this.blobs.push(result));
        this.horizontalQRHref = window.URL.createObjectURL(this.blobs[0]);
        this.squareQRHref = window.URL.createObjectURL(this.blobs[1]);
        this.digitalQRHref = window.URL.createObjectURL(this.blobs[2]);
      });
  }

  download() {
    this.isDownloaded = true;
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(this.blobs[this.index]);
    downloadLink.setAttribute('download', `QR-${this.code}`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  closeModal = () => this.modal.close();

  copyToClipboard() {
    window.navigator.clipboard.writeText(this.instance_url);
    this.toastService.showSuccess('Link has been copied to clipboard');
  }
}

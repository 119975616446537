import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { pipe, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GameCreateComponent } from '../game-create/game-create.component';
import { DashboardFilter as DashboardFilter } from '../_models/dashboard-filter.model';
import { Team } from '../_models/team';
import { User } from '../_models/user.model';
import { AuthenticationService } from '../_services/authentication.service';
import { DashboardService } from '../_services/dashboard.service';
import { SharedService } from '../_services/shared.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  title = '';
  subTitle = '';
  showGames = { game: true, duration: true };
  loadingTotalGamesPlayedCount = true;
  loadingRecommendationsCount = true;
  loadingRecommendationsRespondedCount = true;
  loadingBuyNowClickCount = true;
  loadingTotalFollowersCount = true;
  loadingTotalConnectionsCount = true;
  loadingTotalScansCount = true; //
  loadingAveragePreferences = true; //
  loadingTopPlayers = true;
  loadingTopConnectors = true;
  loadingTopCustomers = true;
  loadingTopWines = true;

  totalRecommendationReceived = 0;
  totalRecommendationReceivedDifference = 0;

  totalRecommendationsResonded = 0;
  totalRecommendationsResondedDifference = 0;

  totalGamesPlayed = 0;
  totalGamesPlayerDifference = 0;

  totalConnections = 0;
  totalConnectionsDifference = 0;

  totalScans = 0;
  totalScansDifference = 0;

  averagePreferences = 0;
  averagePreferencesDifference = 0;

  totalBuyNowClicks = 0;
  totalBuyNowClicksDifference = 0;

  totalFollowers = 0;

  topWines: any = [];
  topCustomers: any = [];
  topConnectors: any = [];
  players: any = [];

  playersPage = 1;
  playersLength = 10;
  totalPlayersCount = 0;

  env;

  user: User;
  userIsAdmin = false;

  sort = 5; // by default sort by Average correct answer
  sortByAscending = false;

  sortTopWineColumn = 1;
  sortTopWineByAscending = false;

  filter: DashboardFilter = new DashboardFilter();

  filterOptions = {
    producers: [],
    varietals: [],
    regions: [],
  };

  destroyed$ = new Subject<void>();
  destoryCompanySubs$ = new Subject<void>();

  team: Team = undefined;

  previousFilterPayload: any;
  exporting = false;

  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private dashboardService: DashboardService
  ) {}

  async ngOnInit() {
    this.env = environment;
    this.team = this.authService.getDefaultCompany();
    this.filter.companyId = this.team?.companyId;
    this.filter.durationType = 2; // durationType -- AllTime = 1, LastWeek = 2, Last30Days = 3
    this.init();

    this.authService
      .getUserCompany$()
      .pipe(takeUntil(this.destoryCompanySubs$))
      .subscribe(async (m) => {
        if (m) {
          this.destroyed$.next();
          this.team = m;
          this.filter.companyId = this.team?.companyId;
        }

        this.user = this.authService.getUser();
        if (this.user) {
          this.title = `Welcome, ${this.user.firstName} ${this.user.lastName}`;
          this.subTitle = `Welcome to ${this.team.company.name}`;
          this.userIsAdmin = this.authService.isAdmin();
        }
      });
  }

  init() {
    this.dashboardService
      .getFilterOptions(this.team?.companyId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (r) => (this.filterOptions = r.data),
        (_) => {}
      );

    this.load();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destoryCompanySubs$.next();
  }

  load(isDurationChanged: boolean = false) {
    const appliedFilters = JSON.parse(localStorage.getItem('filters'));
    if (appliedFilters) {
      this.filter = appliedFilters;
    }
    this.getTotalGamesPlayedCount(isDurationChanged);
    this.getTotalBuyNowClickCount(isDurationChanged);
    this.getTotalFollowersCount(isDurationChanged);
    this.getTotalConnectionsCount(isDurationChanged);
    this.getTotalScans(isDurationChanged);
    this.getAveragePreference(isDurationChanged);
    this.getTopWines(isDurationChanged);
    this.getTopCustomers(isDurationChanged);
    this.getTopConnectors(isDurationChanged);
    this.getRecommendationsCount(isDurationChanged);
    this.getRecommendationsRespondedCount(isDurationChanged);
    this.getStatsByPlayer(false, isDurationChanged);
  }

  getTotalGamesPlayedCount(isDurationChanged: boolean = false) {
    this.loadingTotalGamesPlayedCount = true;
    this.dashboardService
      .getTotalGamesPlayedCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalGamesPlayedCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalGamesPlayed = m.data;
        this.totalGamesPlayerDifference = 0;
        this.totalGamesPlayerDifference = m.percentage;
      });
  }

  getRecommendationsCount(isDurationChanged: boolean = false) {
    this.loadingRecommendationsCount = true;
    this.dashboardService
      .getRecommendationsCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingRecommendationsCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalRecommendationReceived = m.data;
        this.totalRecommendationReceivedDifference = 0;
        this.totalRecommendationReceivedDifference = m.percentage;
      });
  }

  getRecommendationsRespondedCount(isDurationChanged: boolean = false) {
    this.loadingRecommendationsRespondedCount = true;
    this.dashboardService
      .getRecommendationsRespondedCount(
        this.generateFilterPayload(isDurationChanged)
      )
      .pipe(
        finalize(() => (this.loadingRecommendationsRespondedCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalRecommendationsResonded = m.data;
        this.totalRecommendationsResondedDifference = 0;
        this.totalRecommendationsResondedDifference = m.percentage;
      });
  }

  getTotalBuyNowClickCount(isDurationChanged: boolean = false) {
    this.loadingBuyNowClickCount = true;
    this.dashboardService
      .getTotalBuyItNowClickCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingBuyNowClickCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalBuyNowClicks = m.data;
        this.totalBuyNowClicksDifference = 0;
        this.totalBuyNowClicksDifference = m.percentage;
      });
  }

  getTotalFollowersCount(isDurationChanged: boolean = false) {
    this.loadingTotalFollowersCount = true;
    this.dashboardService
      .getCompanyFollowersCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalFollowersCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalFollowers = m.data;
      });
  }

  getTotalConnectionsCount(isDurationChanged: boolean = false) {
    this.loadingTotalConnectionsCount = true;
    this.dashboardService
      .getTotalConnectionsCount(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalConnectionsCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalConnections = m.data;
        this.totalConnectionsDifference = 0;
        this.totalConnectionsDifference = m.percentage;
      });
  }

  getTotalScans(isDurationChanged: boolean = false) {
    this.loadingTotalScansCount = true;
    this.dashboardService
      .getTotalScans(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTotalScansCount = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.totalScans = m.data;
        this.totalScansDifference = 0;
        this.totalScansDifference = m.percentage;
      });
  }

  getAveragePreference(isDurationChanged: boolean = false) {
    this.loadingAveragePreferences = true;
    this.dashboardService
      .getAveragePreferences(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingAveragePreferences = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.averagePreferences = m.data;
        this.averagePreferencesDifference = 0;
        this.averagePreferencesDifference = m.percentage;
      });
  }

  getTopWines(isDurationChanged: boolean = false) {
    this.loadingTopWines = true;
    this.topWines = [];

    const payload = this.generateFilterPayload(isDurationChanged);

    payload.sortBy = this.sortTopWineColumn;
    payload.isSortByAscending = this.sortTopWineByAscending;

    this.dashboardService
      .getTopWines(payload)
      .pipe(
        finalize(() => (this.loadingTopWines = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => {
        this.topWines = m.data;
        if (this.topWines) {
          this.topWines.forEach(
            (m) => (m.wine.country = SharedService.flagName(m.wine.country))
          );
        }
      });
  }

  getTopCustomers(isDurationChanged: boolean = false) {
    this.loadingTopCustomers = true;
    this.topCustomers = [];
    this.dashboardService
      .getTopCustomers(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTopCustomers = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => (this.topCustomers = m.data));
  }

  getTopConnectors(isDurationChanged: boolean = false) {
    this.loadingTopConnectors = true;
    this.topConnectors = [];
    this.dashboardService
      .getTopConnectors(this.generateFilterPayload(isDurationChanged))
      .pipe(
        finalize(() => (this.loadingTopConnectors = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m) => (this.topConnectors = m.data));
  }

  getStatsByPlayer(append = false, isDurationChanged: boolean = false) {
    this.loadingTopPlayers = true;
    if (!append) {
      this.players = [];
      this.playersPage = 1;
    }

    const model = { ...this.generateFilterPayload(isDurationChanged) };
    model['page'] = this.playersPage;
    model['count'] = this.playersLength;
    model['sortBy'] = this.sort;
    model['isSortByAscending'] = this.sortByAscending;

    this.dashboardService
      .getStatsByPlayer(model)
      .pipe(
        finalize(() => (this.loadingTopPlayers = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m: any) => {
        this.totalPlayersCount = m.totalCount;
        if (append) {
          this.players.push(...m.data);
        } else {
          this.players = m.data;
        }
      });
  }

  createGame() {
    if (!this.team.isGameCreationAllowed) return;

    this.modalService
      .open(GameCreateComponent, {
        backdrop: 'static',
      })
      .result.then((result) => {});
  }

  getInitials(fullName: string) {
    if (!fullName) return '';

    const names = fullName.split(' ');
    let intials = names[0][0].toUpperCase();

    if (names.length > 1) {
      intials += names[1][0].toUpperCase();
    }

    return intials;
  }

  onScroll() {
    if (
      !this.loadingTopPlayers &&
      this.totalPlayersCount !== this.players.length
    ) {
      this.playersPage++;
      this.getStatsByPlayer(true, true); // Changed duration to true as it provides the previous selected duration
    }
  }

  getWineOrigin(wine) {
    if (!wine) return '';

    let origin: string[] = [];

    if (wine.appellation && origin.indexOf(wine.appellation) < 0)
      origin.push(wine.appellation);
    if (wine.region && origin.indexOf(wine.region) < 0)
      origin.push(wine.region);
    if (wine.country && origin.indexOf(wine.country) < 0)
      origin.push(wine.country);

    return origin.join(', ');
  }

  changeDuration(value: number) {
    const isDurationChanged: boolean = this.filter.durationType !== value;
    this.filter.durationType = value;
    localStorage.setItem('filters', JSON.stringify(this.filter));
    this.destroyed$.next();
    this.load(isDurationChanged);
  }

  getWineIcon(color: string) {
    if (!color) return '../../assets/icons/wine-colors/default.svg';

    color = color.trim().toLowerCase();

    if (color === 'rose') {
      return '../../assets/icons/wine-colors/rose-wine.svg';
    } else if (color === 'white') {
      return '../../assets/icons/wine-colors/white-wine.svg';
    } else if (color === 'red') {
      return '../../assets/icons/wine-colors/red-wine.svg';
    } else if (color === 'orange') {
      return '../../assets/icons/wine-colors/orange-wine.svg';
    }

    return '../../assets/icons/wine-colors/default.svg';
  }

  generateFilterPayload(isDurationChanged: boolean) {
    if (isDurationChanged) {
      this.previousFilterPayload.durationType = this.filter.durationType;
      return this.previousFilterPayload;
    }

    this.previousFilterPayload = { ...this.filter };
    if (!this.previousFilterPayload.vintageFrom)
      this.previousFilterPayload.vintageFrom = 0;
    if (!this.previousFilterPayload.vintageTo)
      this.previousFilterPayload.vintageTo = 0;

    return this.previousFilterPayload;
  }

  applyPlayerAnalyticsSort(sort) {
    if (this.sort === sort) {
      this.sortByAscending = !this.sortByAscending;
    } else {
      this.sort = sort;
      this.sortByAscending = false;
    }
    this.getStatsByPlayer();
  }

  export() {
    if (this.exporting) return;

    this.exporting = true;
    this.dashboardService
      .exportAllPlayerStats(this.filter)
      .pipe(finalize(() => (this.exporting = false)))
      .subscribe(
        (blob) => {
          saveAs(blob, 'players.xlsx');
        },
        (error) => console.log(error)
      );
  }

  getBuyItNowIcon(value: boolean) {
    return `../../../assets/icons/${value ? 'correct' : 'wrong'}.svg`;
  }

  getFoodPairingIcon(value: boolean) {
    return `../../../assets/icons/${value ? 'correct' : 'wrong'}.svg`;
  }

  getTopWineDataTableSortClass(sortColumn: number) {
    if (this.sortTopWineColumn !== sortColumn) {
      return 'ni-arrow-long-up';
    } else if (
      this.sortTopWineColumn === sortColumn &&
      !this.sortTopWineByAscending
    ) {
      return 'ni-arrow-long-up text-dark';
    } else if (
      this.sortTopWineColumn === sortColumn &&
      this.sortTopWineByAscending
    ) {
      return 'ni-arrow-long-down text-dark';
    }
  }

  applyTopWineSort(sort) {
    if (this.sortTopWineColumn === sort) {
      this.sortTopWineByAscending = !this.sortTopWineByAscending;
    } else {
      this.sortTopWineColumn = sort;
      this.sortTopWineByAscending = false;
    }

    this.getTopWines();
  }
}

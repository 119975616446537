<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
  <div class="nk-app-root">
      <div class="nk-main ">
          <div class="nk-wrap ">
              <div class="nk-header nk-header-fixed is-light">
                  <div class="container-fluid">
                      <app-header></app-header>
                  </div>
              </div>
              <app-side></app-side>
              <div class="nk-content ">
                  <div class="container-fluid">
                      <div class="nk-content-inner">
                          <div class="nk-content-body">




                            
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</body>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Team } from 'src/app/_models/team';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-global-filters',
  templateUrl: './global-filters.component.html',
  styleUrls: ['./global-filters.component.scss'],
})
export class GlobalFiltersComponent implements OnInit {
  @Input() filter: any;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() filterOptions: any;
  @Input() showGames: any;
  @Output() duration = new EventEmitter<number>();
  @Output() createGame = new EventEmitter<any>();
  @Output() import = new EventEmitter<any>();
  @Output() export = new EventEmitter<any>();
  @Output() load = new EventEmitter<any>();
  isFilter = false;
  selectedFilterCount: number;
  team: Team;
  colors = [
    { name: 'Red', value: 'red' },
    { name: 'Rose', value: 'rose' },
    { name: 'Orange', value: 'orange' },
    { name: 'White', value: 'white' },
  ];
  constructor(private authService: AuthenticationService) {}

  ngOnInit(): void {
    const storedFilters = JSON.parse(localStorage.getItem('filters'));
    if (storedFilters) {
      this.filter = storedFilters;
    }
    this.selectedFilterCount = Object.keys(this.filter).length - 5;
    this.authService.getUserCompany$().subscribe(async (m) => {
      if (m) {
        this.team = m;
        this.filter.companyId = this.team?.companyId;
        this.applyFilter();
      }
    });
  }

  changeDuration(duration: number): void {
    this.duration.emit(duration);
  }

  game(): void {
    this.createGame.emit();
  }

  applyFilter(): void {
    const filter = this.cleanObject(this.filter);
    localStorage.setItem('filters', JSON.stringify(filter));
    this.selectedFilterCount = Object.keys(this.filter).length - 5;
    this.load.emit(filter);
  }

  importHandler(): void {
    this.import.emit();
  }

  exportHandler(): void {
    this.export.emit();
  }

  clearFilters(): void {
    localStorage.removeItem('filters');
    delete this.filter.producers;
    delete this.filter.region;
    delete this.filter.varietal;
    delete this.filter.colors;
    delete this.filter.state;
    delete this.filter.vintageFrom;
    delete this.filter.vintageTo;
    delete this.filter.rating;
    delete this.filter.sommeliers;
  }

  cleanObject(obj): any {
    for (const propName in obj) {
      if (
        obj[propName] == null ||
        obj[propName] == '' ||
        obj[propName] === undefined
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }
}

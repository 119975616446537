<div class="modal-body">

  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Wine Details</a>
      <ng-template ngbNavContent>
        <form #wineForm="ngForm" class="w-100" (ngSubmit)="wineForm.valid && (isEditMode === true || wineImage) && formModified(wineForm.dirty) &&
          nav.select(2)">
          <div class="row gy-3">

            <div class="col-md-12">
              <div class="custom-dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)"
                [class.hovering]="isHovering">
                <ng-conatiner *ngIf="wineImage || wineImageSource; else showCertificateUploader">
                  <div class="img-wraps">
                    <span class="closes" (click)="onRemove()" title="Remove">×</span>
                    <img class="img-responsive" [src]="wineImageSource">
                  </div>
                </ng-conatiner>
                <ng-template #showCertificateUploader>
                  <span class="fs-13px">Drag and Drop a File</span>
                  <span class="fs-13px">OR</span>
                  <input style="display: none" type="file" accept="image/png, image/jpg, image/jpeg"
                    (change)="onFilePicked($event)" #hiddenfileinput>
                  <button type="button" (click)="hiddenfileinput.click()" class="btn btn-primary">SELECT</button>
                </ng-template>
              </div>
              <div *ngIf="wineForm.submitted &&  isEditMode === false && !wineImage" class="invalid-feedback mt-1">
                Please select any image
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label class="form-label" for="fw-first-name">Winery</label>
                  <small class="form-text text-muted ml-1" *ngIf="searchingProducer">searching...</small>
                </div>

                <div class="form-control-wrap">

                  <input id="typeahead-http" type="text" class="form-control" name="winery"
                    [(ngModel)]="note.wine.producer" #winery="ngModel" required [ngbTypeahead]="searchProducers"
                    placeholder="Eg. Decoy" />



                  <!-- <input type="text" class="form-control" name="winery" [(ngModel)]="note.wine.producer"
                    #winery="ngModel" required placeholder="Eg. Decoy"> -->
                  <div *ngIf="wineForm.submitted && winery.invalid" class="invalid-feedback mt-1">
                    <div *ngIf="winery.errors.required">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">

                <div class="d-flex">
                  <label class="form-label" for="fw-mobile-number">Country</label>
                  <small class="form-text text-muted ml-1" *ngIf="searchingWineCountries">searching...</small>
                </div>
                <div class="form-control-wrap">

                  <ng-select #country="ngModel" name="countryName" [(ngModel)]="note.wine.country"
                    placeholder="Select a country">
                    <ng-option *ngFor="let item of countries" [value]="item.name">
                      {{ item.name }}
                    </ng-option>
                  </ng-select>

                  <!-- <input id="typeahead-http" type="text" class="form-control" #country="ngModel" required name="country"
                    [(ngModel)]="note.wine.country" placeholder="Eg. United States"
                    [ngbTypeahead]="searchWineCountries" /> -->

                  <!-- <input type="text" class="form-control" #country="ngModel" required name="country"
                    [(ngModel)]="note.wine.country" placeholder="Eg. United States"> -->
                </div>
                <div *ngIf="wineForm.submitted && country.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="country.errors.required">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">

                <div class="d-flex">
                  <label class="form-label" for="fw-mobile-number">Wine Name</label>
                  <small class="form-text text-muted ml-1" *ngIf="searchingWine">searching...</small>
                </div>

                <div class="form-control-wrap">
                  <!-- <input type="text" class="form-control" name="wineName" [(ngModel)]="note.wine.name" #wine="ngModel"
                    required placeholder="Eg. Paul Hobbs Russin River Valley"> -->
                  <input id="typeahead-http" type="text" class="form-control" name="wineName"
                    [(ngModel)]="note.wine.name" #wine="ngModel" required [class.is-invalid]="searchFailed"
                    [ngbTypeahead]="searchWines" placeholder="Eg. Paul Hobbs Russin River Valley" />
                  <!-- <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div> -->

                  <div *ngIf="wineForm.submitted && wine.invalid" class="invalid-feedback mt-1">
                    <div *ngIf="wine.errors.required">This field is required.</div>
                  </div>

                </div>

              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-4 col-md-5">
                  <div class="form-group">
                    <label class="form-label">Sparkling</label>
                    <div class="checkbox-container pt-1">
                      <div class="custom-control custom-control-sm custom-checkbox">
                        <input type="checkbox" [disabled]="isEditMode" class="custom-control-input" id="checkbox"
                          (change)="note.wine.sparkling = $event.target.checked" [checked]="note.wine.sparkling">
                        <label class="custom-control-label" for="checkbox">&nbsp;</label>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-8 col-md-7 pl-0">
                  <div class="form-group">
                    <label class="form-label">Color</label>
                    <select [disabled]="isEditMode" [(ngModel)]="note.wine.color" name="wineColor" #wineColor="ngModel"
                      class="form-control" required>
                      <option [ngValue]="undefined">Select</option>
                      <option [ngValue]="'red'">Red</option>
                      <option [ngValue]="'rose'">Rose</option>
                      <option [ngValue]="'orange'">Orange</option>
                      <option [ngValue]="'white'">White</option>
                    </select>
                    <div *ngIf="wineForm.submitted && wineColor.invalid" class="invalid-feedback mt-1">
                      <div *ngIf="wineColor.errors.required">Please select wine color.</div>
                    </div>
                  </div>
                </div>
              </div>

              <a [href]="mailToContent" *ngIf="isEditMode" class="support-text">Contact Support to Change</a>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label class="form-label" for="fw-varietal">Varietal</label>
                  <small class="form-text text-muted ml-1" *ngIf="searchingVarietals">searching...</small>
                </div>
                <div class="form-control-wrap">

                  <input id="typeahead-http" type="text" class="form-control" required name="variety"
                    [(ngModel)]="note.wine.variety" #variety="ngModel" placeholder="Eg. Chardonnay"
                    [ngbTypeahead]="searchVarietals" />

                  <!-- <input type="text" class="form-control" required name="variety" [(ngModel)]="note.wine.variety"
                    #variety="ngModel" placeholder="Eg. Chardonnay"> -->
                </div>
                <div *ngIf="wineForm.submitted && variety.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="variety.errors.required">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="fw-email-address">Vintage <small>(For non vintage use 0)</small></label>
                <div class="form-control-wrap">
                  <input type="text" numeric class="form-control" #vintage="ngModel" required name="vintage"
                    [(ngModel)]="note.wine.vintage" placeholder="Eg. 2018">
                </div>
                <div *ngIf="wineForm.submitted && vintage.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="vintage.errors.required">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label class="form-label" for="fw-appellation">Appellation</label>
                  <small class="form-text text-muted ml-1" *ngIf="searchingAppellation">searching...</small>
                </div>
                <div class="form-control-wrap">
                  <input id="typeahead-http" type="text" class="form-control" #appellation="ngModel" required
                    name="appellationInput" [(ngModel)]="note.wine.appellation" placeholder="Eg. Napa Valley AVA"
                    [ngbTypeahead]="searchAppellation" />
                </div>
                <div *ngIf="wineForm.submitted && appellation.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="appellation.errors.required">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label class="form-label" for="fw-region">Production Region</label>
                  <small class="form-text text-muted ml-1" *ngIf="searchingWineRegions">searching...</small>
                </div>
                <div class="form-control-wrap">
                  <input id="typeahead-http" type="text" class="form-control" #region="ngModel" required name="region"
                    [(ngModel)]="note.wine.region" placeholder="Eg. California" [ngbTypeahead]="searchWineRegions" />
                </div>
                <div *ngIf="wineForm.submitted && region.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="region.errors.required">This field is required.</div>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="fw-email-address">Bottle Size<small>(ml)</small></label>
                <div class="form-control-wrap">
                  <input type="text" numeric class="form-control" #bottles="ngModel" required name="bottles"
                    [(ngModel)]="note.wine.bottles" placeholder="750">
                </div>
                <div *ngIf="wineForm.submitted && bottles.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="bottles.errors.required">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="fw-email-address">Bottle Amount Produced</label>
                <div class="form-control-wrap">
                  <input type="text" numeric class="form-control" #bottlesProduced="ngModel" required
                    name="bottlesProduced" [(ngModel)]="note.wine.bottlesProduced" placeholder="150,000">
                </div>
                <div *ngIf="wineForm.submitted && bottlesProduced.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="bottlesProduced.errors.required">This field is required.</div>
                </div>
              </div>
            </div> -->



            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label" for="fw-wine-link">Buy It Now Link</label>
                <div class="form-control-wrap">
                  <input type="text" class="form-control" name="wineLink" [(ngModel)]="buyLink"
                    placeholder="https://www.winelink.com/">
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label" for="fw-wine-link">Attributes</label>
                <div class="form-control-wrap">
                  <ng-select name="attributes" [(ngModel)]="selectedAttributes" placeholder="Select attributes"
                    multiple="true">
                    <ng-option *ngFor="let item of wineAttributes" [value]="item.value">{{ item.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="card-inner w-100">
              <div class="d-flex justify-content-between">
                <button class="btn btn-super-outline" type="button"
                  (click)="formModified(wineForm.dirty);close()">Cancel</button>
                <button class="btn btn-super" type="submit">Next</button>
              </div>
            </div>

          </div>
        </form>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" [disabled]="!isWineFormValid">
      <a ngbNavLink>Game Notes</a>
      <ng-template ngbNavContent>
        <form #tastingForm="ngForm"
          (ngSubmit)="tastingForm.valid && validate() && formModified(tastingForm.dirty) && nav.select(3)">
          <div class="row gy-3">
            <!-- <div class="row col-sm-12 mb-0">
            <div class="form-group col-12 col-sm-6">
              <label class="form-label">Company</label>
              <select [(ngModel)]="companyId" name="company" #company="ngModel" class="form-control" required>
                <option value="">Select one</option>
                <option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</option>
              </select>
              <div *ngIf="tastingForm.submitted && company.invalid" class="invalid-feedback mt-1">
                <div *ngIf="company.errors.required">This field is required.</div>
              </div>
            </div>
            <div class="form-group col-12 col-sm-6" *ngIf="authService.isAdmin()">
              <label class="form-label">Sommelier</label>
              <select [(ngModel)]="sommId" name="sommelier" #somm="ngModel" class="form-control" required>
                <option value="">Select one</option>
                <option *ngFor="let somm of somms" [value]="somm.id">{{ somm.firstName }} {{ somm.lastName }}</option>
              </select>
              <div *ngIf="tastingForm.submitted && somm.invalid" class="invalid-feedback mt-1">
                <div *ngIf="somm.errors.required">This field is required.</div>
              </div>
            </div>

          </div> -->

            <div class="col-sm-12">
              <div class="section-heading">
                <p>Game Notes</p>
                <p>These notes are to be used in the game</p>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-label">Aroma</div>
              <div class="btn-group d-none d-md-block">
                <button type="button" *ngFor="let o of aroma" (click)="changeSelection('aroma', o)"
                  [ngClass]="getSelectionBtnClass('aroma', o)">
                  {{ capitalizeFirstLetter(o) }}
                </button>
              </div>

              <div class="d-flex d-md-none flex-column aroma-mobile-version">
                <button type="button" *ngFor="let o of aroma" (click)="changeSelection('aroma', o)"
                  [ngClass]="getSelectionBtnClass('aroma', o)">
                  {{ capitalizeFirstLetter(o) }}
                </button>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-label">Body</div>
              <div class="btn-group">
                <button type="button" *ngFor="let o of body" (click)="changeSelection('body', o)"
                  [ngClass]="getSelectionBtnClass('body', o)">
                  {{ capitalizeFirstLetter(o) }}
                </button>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-label">Acidity</div>
              <div class="btn-group">
                <button type="button" *ngFor="let o of acidity" (click)="changeSelection('acidity', o)"
                  [ngClass]="getSelectionBtnClass('acidity', o)">
                  {{ capitalizeFirstLetter(o) }}
                </button>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-label">
                <div class="col row justify-content-start"> <span [ngClass]="{'text-gray' : isOak}">Tannins</span>
                  <div class="ml-2 custom-control custom-switch checked"><input type="checkbox" name="reg-public"
                      id="site-off" [(ngModel)]="isOak" class="custom-control-input"><label for="site-off"
                      class="custom-control-label fw-normal" [ngClass]="{'text-gray' : !isOak}">Oak</label></div>
                </div>
              </div>
              <div class="btn-group">
                <ng-container *ngIf="!isOak; else oakSection">
                  <button type="button" *ngFor="let o of tannins" (click)="changeSelection('tannins', o)"
                    [ngClass]="getSelectionBtnClass('tannins', o)">
                    {{ capitalizeFirstLetter(o) }}
                  </button>
                </ng-container>
                <ng-template #oakSection>
                  <button type="button" *ngFor="let o of oak" (click)="changeSelection('oak', o)"
                    [ngClass]="getSelectionBtnClass('oak', o)">
                    {{ capitalizeFirstLetter(o) }}
                  </button>
                </ng-template>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-label">Sweetness</div>
              <div class="btn-group">
                <button type="button" *ngFor="let o of sugar" (click)="changeSelection('sugar', o)"
                  [ngClass]="getSelectionBtnClass('sugar', o)">
                  {{ capitalizeFirstLetter(o) }}
                </button>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-label">Flavor</div>
              <p>Enter four answer options and select the correct answer.</p>
              <div class="form-control-wrap" [ngClass]="{'mt-1': i !== 0}"
                *ngFor="let flavor of note.flavors; let i = index">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text"> <input type="radio" name="flavorSelected{{i}}"
                        (click)="onFlavorChecked($event, i)" [checked]="flavor.selected"></div>
                  </div> <input type="text" name="flavor{{i}}" [(ngModel)]="flavor.value" class="form-control"
                    placeholder="{{flavorPlaceholders[i]}}">
                </div>
              </div>
              <div *ngIf="tastingForm.submitted && (isflavorInputError || isflavorSelectError)"
                class="invalid-feedback mt-1">
                <div *ngIf="isflavorInputError">Please provide answers for flavor</div>
                <div *ngIf="isflavorSelectError">Please select your answer from above</div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="section-heading">
                <p>Food Pairing</p>
                <p>Add your personal food pairing suggestion with a link to a recipe or restaurant.</p>
              </div>
            </div>


            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label" for="fw-wine-link">Food Pairings <small>optional</small></label>
                <div class="form-control-wrap">
                  <input type="text" class="form-control" name="wineLink" [(ngModel)]="note.foodPairing"
                    placeholder="Add food pairing">
                </div>
              </div>
            </div>

            <!-- <div class="col-md-12">
              <app-tags-input placeholder="Add food pairing" [items]="note.foodPairing" label="Food Pairings"
                (onAdd)="onFoodPairingAdd($event)" (onRemove)="onFoodPairingRemove($event)" subLabel="optional">
              </app-tags-input>
            </div> -->

            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label" for="fw-wine-link">Food Pairing Link <small>optional</small></label>
                <div class="form-control-wrap">
                  <input type="text" class="form-control" name="wineRecipeLink" [(ngModel)]="note.foodPairingLink"
                    placeholder="https://recipelink.com">
                </div>
              </div>
            </div>


            <div class="card-inner w-100">
              <div class="d-flex justify-content-between">
                <a (click)="formModified(tastingForm.dirty);nav.select(1)" class="btn  btn-light">Back</a>
                <button type="submit" class="btn btn-super">Next</button>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" [disabled]="!isTastingFormValid">
      <a ngbNavLink>My Notes</a>
      <ng-template ngbNavContent>
        <form #notesForm="ngForm"
          (ngSubmit)="notesForm.valid && validate() && formModified(notesForm.dirty) && nav.select(4)">
          <div class="row gy-3">
            <div class="col-sm-12">
              <div class="section-heading">
                <p>My Notes</p>
                <p>These are your private notes. This will help you keep track of your tastings.</p>
              </div>
            </div>

            <div class="col-sm-12 my-1  section-subheading border-bottom">
              <p>Uniqueness</p>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <label class="form-label">Variety <small>(optional)</small></label>
                <select [(ngModel)]="note.variety" name="noteVariety" #noteVarietyInput="ngModel" class="form-control">
                  <option default [ngValue]="undefined">Select</option>
                  <option [ngValue]="'Unique'">Unique</option>
                  <option [ngValue]="'Representative'">Representative</option>
                  <option [ngValue]="'Unknown'">N/A</option>
                </select>
                <div *ngIf="notesForm.submitted && noteVarietyInput.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="noteVarietyInput.errors.required">Please select any variety.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <label class="form-label">Appellation <small>(optional)</small></label>
                <select [(ngModel)]="note.appellation" name="noteApellation" #noteApellationInput="ngModel"
                  class="form-control">
                  <option [ngValue]="undefined">Select</option>
                  <option [ngValue]="'Unique'">Unique</option>
                  <option [ngValue]="'Representative'">Representative</option>
                  <option [ngValue]="'Unknown'">N/A</option>
                </select>
                <div *ngIf="notesForm.submitted && noteApellationInput.invalid" class="invalid-feedback mt-1">
                  <div *ngIf="noteApellationInput.errors.required">Please select any appellation.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 my-1 section-subheading border-bottom">
              <p>Quality</p>
            </div>

            <div class="col-sm-12">
              <div class="form-label">Quality Of Variety</div>
              <div class="btn-group d-none d-md-block">
                <button type="button" *ngFor="let o of sommNoteOptions"
                  (click)="changeSelection('qualityOfVariety', o.value)"
                  [ngClass]="getSelectionBtnClass('qualityOfVariety', o.value)">
                  {{ o.text }}
                </button>
              </div>
              <div class="d-flex d-md-none flex-column aroma-mobile-version">
                <button type="button" *ngFor="let o of sommNoteOptions"
                  (click)="changeSelection('qualityOfVariety', o.value)"
                  [ngClass]="getSelectionBtnClass('qualityOfVariety', o.value)">
                  {{ o.text }}
                </button>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-label">Quality Of Appellation</div>
              <div class="btn-group d-none d-md-block">
                <button type="button" *ngFor="let o of sommNoteOptions"
                  (click)="changeSelection('qualityOfAppellation', o.value)"
                  [ngClass]="getSelectionBtnClass('qualityOfAppellation', o.value)">
                  {{ o.text }}
                </button>
              </div>
              <div class="d-flex d-md-none flex-column aroma-mobile-version">
                <button type="button" *ngFor="let o of sommNoteOptions"
                  (click)="changeSelection('qualityOfAppellation', o.value)"
                  [ngClass]="getSelectionBtnClass('qualityOfAppellation', o.value)">
                  {{ o.text }}
                </button>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-label">Value For Price</div>
              <div class="btn-group d-none d-md-block">
                <button type="button" *ngFor="let o of sommNoteOptions"
                  (click)="changeSelection('valueForPrice', o.value)"
                  [ngClass]="getSelectionBtnClass('valueForPrice', o.value)">
                  {{ o.text }}
                </button>
              </div>
              <div class="d-flex d-md-none flex-column aroma-mobile-version">
                <button type="button" *ngFor="let o of sommNoteOptions"
                  (click)="changeSelection('valueForPrice', o.value)"
                  [ngClass]="getSelectionBtnClass('valueForPrice', o.value)">
                  {{ o.text }}
                </button>
              </div>
            </div>

            <div class="col-sm-12 my-1 section-subheading border-bottom">
              <p>Overall</p>
            </div>

            <div class="col-sm-12">
              <div class="form-label">Personal Rating</div>
              <div class="btn-group d-none d-md-block">
                <button type="button" *ngFor="let o of sommNoteOptions" (click)="changeSelection('rating', o.value)"
                  [ngClass]="getSelectionBtnClass('rating', o.value)">
                  {{ o.text }}
                </button>
              </div>
              <div class="d-flex d-md-none flex-column aroma-mobile-version">
                <button type="button" *ngFor="let o of sommNoteOptions" (click)="changeSelection('rating', o.value)"
                  [ngClass]="getSelectionBtnClass('rating', o.value)">
                  {{ o.text }}
                </button>
              </div>
            </div>

            <div class="col-md-12">
              <label class="form-label" for="fw-mobile-number">Notes</label>
              <div class="form-control-wrap">
                <textarea name="description" [(ngModel)]="note.description"
                  class="form-control form-control-simple no-resize text-area"
                  placeholder="Please provide description"></textarea>
              </div>
            </div>


            <div class="card-inner w-100">
              <div class="d-flex justify-content-between">
                <a (click)="nav.select(2)" *ngIf="!isLoading" class="btn btn-light">Back</a>
                <button class="btn btn-super" type="submit">Next</button>
              </div>
            </div>



          </div>
        </form>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" [disabled]="!areFormsValid">
      <a ngbNavLink>Review & Publish</a>
      <ng-template ngbNavContent>
        <div class="row gy-3">
          <div class="col-12">
            <div class="user-card">
              <div class="wine-image">
                <ng-conatiner *ngIf="wineImageSource; else defaultWineImage">
                  <img [src]="wineImageSource">
                </ng-conatiner>
                <ng-template #defaultWineImage>
                  <img src="../../assets/temp/barrelX_riesling.jpg">
                </ng-template>

              </div>
              <div class="user-name ml-2">
                <span class="tb-lead wine-info">{{ note.wine.producer }} Winery - {{ note.wine.vintage }}</span>
                <span class="tb-lead wine-name">{{ note.wine.name }}</span>
                <span class="tb-lead wine-meta">
                  <img src="../../assets/icons/icons8-grapes.svg" class="grape"> {{ note.wine.variety}}
                  <img src="../../assets/flags/english.png" class="flag"> {{ note.wine.country }}</span>
              </div>
            </div>
          </div>

          <div class="col-sm-12 mt-2">
            <div class="section-heading">
              <p>Game Notes</p>
              <p>These notes are to be used in the game</p>
            </div>
          </div>


          <!-- <div class="row col-sm-12 mb-0">
          <div class="form-group col-12 col-sm-12">
            <label class="form-label">Company</label>
            <select [(ngModel)]="companyId" disabled class="form-control">
              <option value="">Select one</option>
              <option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</option>
            </select>
          </div>
          <div class="form-group col-12 col-sm-6" *ngIf="authService.isAdmin()">
            <label class="form-label">Sommelier</label>
            <select [(ngModel)]="sommId" disabled class="form-control">
              <option value="">Select one</option>
              <option *ngFor="let somm of somms" [value]="somm.id">{{ somm.firstName }} {{ somm.lastName }}</option>
            </select>
          </div>

        </div> -->

          <div class="col-sm-12">
            <div class="form-label">Aroma</div>
            <div class="btn-group d-none  d-md-block">
              <button type="button" *ngFor="let o of aroma" [ngClass]="getSelectionBtnClass('aroma', o)">
                {{ capitalizeFirstLetter(o) }}
              </button>
            </div>
            <div class="d-flex d-md-none flex-column aroma-mobile-version">
              <button type="button" *ngFor="let o of aroma" [ngClass]="getSelectionBtnClass('aroma', o)">
                {{ capitalizeFirstLetter(o) }}
              </button>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-label">Body</div>
            <div class="btn-group">
              <button type="button" *ngFor="let o of body" [ngClass]="getSelectionBtnClass('body', o)">
                {{ capitalizeFirstLetter(o) }}
              </button>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-label">Acidity</div>
            <div class="btn-group">
              <button type="button" *ngFor="let o of acidity" [ngClass]="getSelectionBtnClass('acidity', o)">
                {{ capitalizeFirstLetter(o) }}
              </button>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-label">
              <div class="col row justify-content-start">
                <span *ngIf="!isOak">Tannins</span>
                <span *ngIf="isOak">Oak</span>
              </div>
            </div>
            <div class="btn-group">
              <ng-container *ngIf="!isOak; else oakSection">
                <button type="button" *ngFor="let o of tannins" [ngClass]="getSelectionBtnClass('tannins', o)">
                  {{ capitalizeFirstLetter(o) }}
                </button>
              </ng-container>
              <ng-template #oakSection>
                <button type="button" *ngFor="let o of oak" [ngClass]="getSelectionBtnClass('oak', o)">
                  {{ capitalizeFirstLetter(o) }}
                </button>
              </ng-template>
              <!-- <button type="button" *ngFor="let o of tannins" [ngClass]="getSelectionBtnClass('tannins', o)">
              {{ capitalizeFirstLetter(o) }}
            </button> -->
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-label">Sweetness</div>
            <div class="btn-group">
              <button type="button" *ngFor="let o of sugar" [ngClass]="getSelectionBtnClass('sugar', o)">
                {{ capitalizeFirstLetter(o) }}
              </button>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-label">Flavor</div>
            <div class="form-control-wrap" [ngClass]="{'mt-1': !start}"
              *ngFor="let flavor of note.flavors; let index = index; let start = start">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"> <input type="radio" [disabled]="!flavor.selected"
                      [checked]="flavor.selected"> </div>
                </div> <input type="text" readonly [value]="flavor.value" class="form-control" placeholder="Option">
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="section-heading">
              <p>Food Pairing</p>
              <p>Add your personal food pairing suggestion with a link to a recipe or restaurant.</p>
            </div>
          </div>

          <!-- <div class="col-md-12">
            <app-tags-input [items]="note.wine.foodPairing" label="Food Pairing Recommendation" readOnly="true">
            </app-tags-input>
          </div> -->

          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label" for="fw-wine-link">Food Pairings</label>
              <div class="form-control-wrap">
                <input type="text" class="form-control" name="foodPairing" [(ngModel)]="note.foodPairing" disabled>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label" for="fw-wine-link">Food Pairing Link <small>optional</small></label>
              <div class="form-control-wrap">
                <input type="text" class="form-control" name="recipeLink" [(ngModel)]="note.foodPairingLink" disabled>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="section-heading">
              <p>My Notes</p>
              <p>These are your private notes. This will help you keep track of your tastings.</p>
            </div>
          </div>

          <div class="col-sm-12 section-subheading border-bottom">
            <p>Uniqueness</p>
          </div>

          <div class="col-sm-12">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Variety</label>
                  <p>{{ note.variety }}</p>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Appellation</label>
                  <p>{{ note.appellation }}</p>
                </div>
              </div>
            </div>

          </div>


          <div class="col-sm-12 section-subheading border-bottom">
            <p>Quality</p>
          </div>

          <div class="col-sm-12">
            <div class="form-label">Quality Of Variety</div>

            <div class="btn-group d-none  d-md-block">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('qualityOfVariety', o.value)">
                {{ o.text }}
              </button>
            </div>
            <div class="d-flex d-md-none flex-column aroma-mobile-version">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('qualityOfVariety', o.value)">
                {{ o.text }}
              </button>
            </div>

          </div>

          <div class="col-sm-12">
            <div class="form-label">Quality Of Appellation</div>

            <div class="btn-group d-none  d-md-block">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('qualityOfAppellation', o.value)">
                {{ o.text }}
              </button>
            </div>
            <div class="d-flex d-md-none flex-column aroma-mobile-version">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('qualityOfAppellation', o.value)">
                {{ o.text }}
              </button>
            </div>

          </div>

          <div class="col-sm-12">
            <div class="form-label">Value For Price</div>

            <div class="btn-group d-none  d-md-block">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('valueForPrice', o.value)">
                {{ o.text }}
              </button>
            </div>
            <div class="d-flex d-md-none flex-column aroma-mobile-version">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('valueForPrice', o.value)">
                {{ o.text }}
              </button>
            </div>
          </div>

          <div class="col-sm-12 section-subheading border-bottom">
            <p>Overall</p>
          </div>

          <div class="col-sm-12">
            <div class="form-label">Personal Rating</div>
            <div class="btn-group d-none  d-md-block">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('rating', o.value)">
                {{ o.text }}
              </button>
            </div>
            <div class="d-flex d-md-none flex-column aroma-mobile-version">
              <button type="button" *ngFor="let o of sommNoteOptions"
                [ngClass]="getSelectionBtnClass('rating', o.value)">
                {{ o.text }}
              </button>
            </div>
          </div>

          <div class="col-md-12">
            <label class="form-label" for="fw-mobile-number">Notes</label>
            <div class="form-control-wrap">
              <textarea name="description" readonly [(ngModel)]="note.description"
                class="form-control form-control-simple no-resize text-area"
                placeholder="Please provide description"></textarea>
            </div>
          </div>


          <div class="card-inner w-100">
            <div class="d-flex justify-content-between">
              <a (click)="nav.select(3)" *ngIf="!isLoading" class="btn btn-light">Back</a>
              <a (click)="createGame()" appButtonSpinner class="btn btn-super" text="Save"
                [loadingState]="isLoading"></a>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>

</div>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from '../_models/subscription.model';
import { Team } from '../_models/team';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public token: string;
  public business: Team;
  public activeSubscription: Subscription;

  public static flagName(country: string): string {
    if (!country) return '';

    let name = this.covertToTitleCase(country.trim());
    if (
      name.toLowerCase() === 'usa' ||
      name.toLowerCase() === 'united states' ||
      name.toLowerCase() === 'united states of america'
    ) {
      name = 'USA';
    }
    return name;
  }

  public static covertToTitleCase(m) {
    const arr = m.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
    }

    return arr.join(' ');
  }

  public static isExternalLink = (text) =>
    text.indexOf('http://') == 0 || text.indexOf('https://') == 0;

  public static getUserProfileImage(src) {
    if (src) {
      if (this.isExternalLink(src)) {
        return src;
      } else {
        return `${environment.cdn}profiles/${src}`;
      }
    }

    return undefined;
  }

  public static getInitials(fullName: string) {
    if (!fullName) return '';

    const names = fullName.split(' ');
    let intials = names[0][0].toUpperCase();

    if (names.length > 1) {
      intials += names[1][0].toUpperCase();
    }

    return intials;
  }
  sortCharRatingChange(ratings: any[]): any[] {
    const data = [];
    ratings.forEach((d) => {
      if (d.feelName === 'Aroma') {
        data[0] = d;
      } else if (d.feelName === 'Body') {
        data[1] = d;
      } else if (d.feelName === 'Acidity') {
        data[2] = d;
      } else if (d.feelName === 'Sugar') {
        data[3] = d;
      } else if (d.feelName === 'Tannins') {
        data[4] = d;
      } else if (d.feelName === 'Oak') {
        data[5] = d;
      } else if (d.feelName === 'Flavor') {
        data[6] = d;
      } else {
        return;
      }
    });
    return data;
  }
}

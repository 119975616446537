import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BillingService } from 'src/app/_services/billing.service';
import { SharedService } from 'src/app/_services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private sharedService: SharedService) {}

  async canActivate(): Promise<boolean> {
    if (this.sharedService.token) {
      return true;
    }

    const authToken = localStorage.getItem('token');
    if (authToken) {
      this.sharedService.token = authToken;

      return true;
    }

    this.router.navigate(['/signin']);
    return false;
  }
}

<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
  <div class="nk-app-root">
    <div class="nk-main ">
      <div class="nk-wrap ">
        <div class="nk-header nk-header-fixed is-light">
          <div class="container-fluid">
            <app-header></app-header>
          </div>
        </div>
        <app-side></app-side>
        <div class="nk-content ">
          <div class="container-fluid">
            <div class="nk-content-inner">
              <div class="nk-content-body">
                <div class="nk-block-head nk-block-head-sm">
                  <div class="nk-block-between">
                    <div class="nk-block-head-content">
                      <h3 class="nk-block-title page-title">Support</h3>
                      <div class="nk-block-des text-soft">
                        <p>We're here to help you have fun. Let us know in case you have an issue.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-gs">
                  <div class="col-lg-12 col-xxl-12">
                    <div class="card card-bordered h-100">
                      <div class="card-inner">
                        <div class="row g-gs">


                          <div class="col-lg-12 col-xxl-12 merchant">
                            <textarea autocapitalize="none" [(ngModel)]="message" class="form-control"
                              placeholder="How can we help?" spellcheck="false" rows="6"></textarea>
                          </div>
                          <div class="col-12 px-0">

                            <button [disabled]="isSaving" (click)="message = ''" class="btn btn-light float-left">
                              Cancel
                            </button>
                            <button type="button" (click)="sendMessage()" appButtonSpinner
                              class="btn btn-super float-right" text="Send" [loadingState]="isSaving"></button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingResponse, ServerResponse } from '../_models/server-response';
import { Wine } from '../_models/wine.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class WineService {
  constructor(private apiService: ApiService) {}

  getFilterOptions(companyId: string) {
    return this.apiService.get<ServerResponse<any>>(
      `wine-management/${companyId}/get-filters`
    );
  }
  getCompanyWines(companyId: string) {
    return this.apiService.get<ServerResponse<any>>(
      `Recommendation/get-company-wines/${companyId}`
    );
  }

  respondRecommendation(
    recommendationId: string,
    wineId: string,
    isDefault: number
  ) {
    return this.apiService.post(
      `Recommendation/${recommendationId}/respond/${wineId}/default/${isDefault}`
    );
  }
  search(filterModel: any): Observable<PagingResponse<Wine>> {
    return this.apiService.post('wine-management/search', filterModel);
  }

  update(model: any, file: any) {
    let fd = this.apiService.jsonToFormData(model);
    if (file) fd.append('picture', file);
    return this.apiService.postAsForm('wine-management/update', fd);
  }
}

export const getWineOrigin = (wine: Wine) => {
  if (!wine) return '';

  let origin: string[] = [];

  if (wine.appellation && origin.indexOf(wine.appellation) < 0)
    origin.push(wine.appellation);
  if (wine.region && origin.indexOf(wine.region) < 0) origin.push(wine.region);
  if (wine.country && origin.indexOf(wine.country) < 0)
    origin.push(wine.country);

  return origin.join(', ');
};

export const getIcon = (color: string) => {
  if (!color) return '../../assets/icons/wine-colors/default.svg';

  color = color.trim().toLowerCase();

  if (color === 'rose') {
    return '../../assets/icons/wine-colors/rose-wine.svg';
  } else if (color === 'white') {
    return '../../assets/icons/wine-colors/white-wine.svg';
  } else if (color === 'red') {
    return '../../assets/icons/wine-colors/red-wine.svg';
  } else if (color === 'orange') {
    return '../../assets/icons/wine-colors/orange-wine.svg';
  }

  return '../../assets/icons/wine-colors/default.svg';
};

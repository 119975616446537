import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Company } from '../_models/company.model';
import { ProfileService } from '../_services/profile.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.scss']
})
export class ProfileUpdateComponent implements OnInit {

  model: any = {};

  instagram = '';
  facebook = '';
  linkedIn = '';

  file: File;
  url = '../../assets/temp/tamura.png';

  isSaving = false;

  public options: Object = {
    placeholderText: 'Describe about your self',
    charCounterCount: false,
    key: environment.froalaKey
  }


  @Input() set user(value) {
    this.model = { ...value };

    if (this.model.social) {
      this.instagram = this.model?.social['instagram'];
      this.facebook = this.model?.social['facebook'];
      this.linkedIn = this.model?.social['linkedIn'] || this.model?.social['linkedin'];
    }

    if (this.model?.picture) {
      this.url = environment.cdn + 'profiles/' + this.model?.picture;
    }

  };

  @Output() updated = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal, private toastService: ToastService, private profileService: ProfileService) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close({});
  }

  update() {

    if (this.isSaving) return;

    this.isSaving = true;

    this.model.social = {};

    if (this.instagram) { this.model.social['instagram'] = this.instagram; }
    if (this.facebook) { this.model.social['facebook'] = this.facebook; }
    if (this.linkedIn) { this.model.social['linkedin'] = this.linkedIn; }

    this.profileService.updateUserProfile(this.model)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe((d) => {
        if (d.success) {
          if (this.file) {
            this.profileService.updateUserPicture({ file: this.file }).subscribe((r) => {
              this.url = environment.cdn + 'profiles/' + r.data;
              d.data.picture = r.data;
              this.toastService.showSuccess('Profile has been updated successfuly');
              this.updated.emit({ ...d.data });
            })
          } else {
            this.toastService.showSuccess('Profile has been updated successfuly');
            this.updated.emit({ ...d.data });
          }
        } else {
          this.toastService.showError(d.message);
        }
      });
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.url = reader.result as string;
      };

    }
  }

}

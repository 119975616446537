import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { SharedService } from '../_services/shared.service';
import { TeamService } from '../_services/team.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {

  isInvalidParams = false;
  isAccepted = false;
  isUpdating = false;

  isLoggedIn = false;

  constructor(private route: ActivatedRoute, private sharedService: SharedService, private authService: AuthenticationService, private toastService: ToastService, private teamService: TeamService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isSignedIn();

    if (this.isLoggedIn) {
      this.sharedService.token = this.authService.getToken();
    }

    this.route.queryParams.subscribe(queryParam => {
      if (queryParam.identifier && queryParam.token) {
        this.isUpdating = true;
        this.teamService.acceptInvitation(queryParam.identifier, queryParam.token)
          .pipe(finalize(() => this.isUpdating = false))
          .subscribe(m => {
            this.isAccepted = m.data;
          });
      } else {
        this.toastService.showError('Invalid parameters provided', 5000);
        this.isInvalidParams = true;
      }
    });

  }

}

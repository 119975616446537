import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { BillingService } from '../_services/billing.service';
import { SharedService } from '../_services/shared.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent implements OnInit {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  name = '';
  isSaving = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#3e4f63',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        '::placeholder': {
          color: '#becde6',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  errorMessage = '';

  constructor(
    private activeModal: NgbActiveModal,
    private stripeService: StripeService,
    private billingService: BillingService,
    private sharedService: SharedService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.activeModal.close(false);
  }

  async onConfirm() {
    this.isSaving = true;
    this.errorMessage = '';
    const stripe = await this.stripeService
      .createToken(this.card.element, { name: this.name })
      .toPromise();
    if (stripe.token) {
      this.createCustomerId(stripe.token.id);
    }
    if (stripe.error) {
      this.isSaving = false;
      this.errorMessage = stripe.error?.message;
      console.log(stripe.error.message);
    }
  }

  createCustomerId(stripeToken: any) {
    this.billingService
      .createCustomerId(
        this.sharedService.business.companyId,
        stripeToken as string
      )
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe((res) => {
        if (res && res.success) {
          this.activeModal.close(res);
        } else {
          this.toastService.showError('You are not allowed for this action');
        }
      });
  }
}

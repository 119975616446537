import { Component, OnInit } from "@angular/core";
import { User } from "src/app/_models/user.model";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { ToastService } from "src/app/_services/toast.service";
import { UserService } from "src/app/_services/user.service";
import { environment } from "src/environments/environment";
import * as LogRocket from "logrocket";
import { SharedService } from "src/app/_services/shared.service";

declare function externalInitialize(): any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  env = environment;

  isCollapsed = true;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  user: User;

  async ngOnInit() {
    externalInitialize();

    this.initialize(this.authService.getUser());
    this.authService.getUserDetails$().subscribe((m) => this.initialize(m));

    const updatedUser = await this.userService.get().toPromise();
    this.authService.setUserDetails$(updatedUser.data);
  }

  initialize(user) {
    this.user = user;
    const isLogRocketInitialized = this.authService.getLockRocket();

    if (this.user && !isLogRocketInitialized) {
      LogRocket.identify(this.user.email, {
        name: this.user.firstName + " " + this.user.lastName,
        email: this.user.email,
        certification: this.user.displayCertificationLevel || "",
        jobTitle: this.user.jobTitle || "",
      });
      this.authService.setLogRocket();
    }
  }

  signout = () => this.authService.logout();

  getName = () => `${this.user.firstName} ${this.user.lastName}`;

  getEmail = () => this.user.email;

  getAvatarText() {
    let avatarText = `${this.user.firstName.substr(0, 1).toUpperCase()}`;

    if (this.user.lastName) {
      avatarText += this.user.lastName.substr(0, 1).toUpperCase();
    }

    return avatarText;
  }

  getAvatarUrl(url) {
    return SharedService.getUserProfileImage(url);
  }

  changeDefault(companyId) {
    this.isCollapsed = true;
    this.userService.updateDefaultCompany(companyId).subscribe((r) => {
      if (r.success) {
        this.user.permissions.forEach((x) => {
          x.isDefault = x.companyId === companyId;
        });
        this.authService.setUserDetails$(this.user);
      } else {
        this.toastService.showError(r.message, 5000);
      }
    });
  }

  getCompanyAvatar(name) {
    let avatarText = "";
    if (name) {
      const names = name.split(" ");
      avatarText = `${names[0].substr(0, 1).toUpperCase()}`;

      if (names.length > 1) {
        avatarText += names[1].substr(0, 1).toUpperCase();
      }
    }

    return avatarText;
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-downgrade-plan-modal',
  templateUrl: './downgrade-plan-modal.component.html',
  styleUrls: ['./downgrade-plan-modal.component.scss']
})
export class DowngradePlanModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  close(): void {
    this.activeModal.close(false);
  }

  onConfirm(): void {
    this.activeModal.close(true);
  }
}

<div class="modal-body">
    <div class="text-right">
        <img class="pointer" (click)="close()" src="../../assets/icons/icons8-close.svg">
    </div>
    <div class="text-center">
        <img src="../../assets/icons/icons8-delete-red.svg">
    </div>
    <div class="title text-center mt-3">
        Downgrade?
    </div>
    <div class="gr text-center mt-2">
        Are you sure you want to downgrade? Your subscription will continue until the end of the month.
    </div>
</div>
<div>
    <div class="col-12 d-flex pb-2 justify-between">
        <a (click)="close()" class="col-5 btn-cancel btn cancel">Cancel</a>
        <button type="button" (click)="onConfirm()" class="col-5 btn-red btn">Yes</button>
    </div>

</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { TeamMemberCreateComponent } from '../team-member-create/team-member-create.component';
import { Team } from '../_models/team';
import { AuthenticationService } from '../_services/authentication.service';
import { SharedService } from '../_services/shared.service';
import { TeamService } from '../_services/team.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy {

  companyId: string = '';

  members: Team[] = [];
  totalMembersCount = 0;

  selectedMember = undefined;
  isUpdating = false;

  permissions = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Employee' }
  ]

  selectedMemberEdit = {
    id: undefined,
    value: undefined,
    isGameCreationAllowed: false,
    isPublicProfile: false
  }

  loading = false;

  destroyed$ = new Subject<void>();
  destoryCompanySubs$ = new Subject<void>();


  constructor(private authService: AuthenticationService, private toastService: ToastService, private modalService: NgbModal, private teamService: TeamService) { }

  ngOnInit() {
    this.companyId = this.authService.getDefaultCompany()?.companyId;

    this.authService.getUserCompany$()
      .pipe(takeUntil(this.destoryCompanySubs$)).subscribe(m => {
        if (m) {
          this.destroyed$.next();
          this.companyId = m.companyId;
          this.members = [];
          this.loadTeamMembers();
        }
      });

    this.loadTeamMembers();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destoryCompanySubs$.next();
  }

  loadTeamMembers() {
    this.loading = true;
    this.teamService.getMembers(this.companyId, 1, 50)
      .pipe(finalize(() => this.loading = false),
        takeUntil(this.destroyed$))
      .subscribe(m => {
        this.members = m.data;
        this.totalMembersCount = m.totalCount;
      });
  }

  getUserProfileImage = (url) => SharedService.getUserProfileImage(url);

  getInitials = (value) => SharedService.getInitials(value);

  addUser() {
    const modalRef = this.modalService.open(TeamMemberCreateComponent);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.members.push(receivedEntry);
    });
  }


  selectMember(member: Team) {
    this.selectedMember = { ...member };
    this.selectedMemberEdit.isGameCreationAllowed = member.isGameCreationAllowed;
    this.selectedMemberEdit.isPublicProfile = member.isPublicProfile;
    this.selectedMemberEdit.value = member.value;
    this.selectedMemberEdit.id = member.id;
  }

  updateMember() {
    if (!this.isUpdating) {
      this.isUpdating = true;
      const member = this.members.find(x => x.id == this.selectedMemberEdit.id);

      const payload = { ...member };

      payload.isGameCreationAllowed = this.selectedMemberEdit.isGameCreationAllowed;
      payload.isPublicProfile = this.selectedMemberEdit.isPublicProfile;
      payload.value = this.selectedMemberEdit.value;
      payload.name = member.user.fullName;
      payload.email = member.user.email;

      this.teamService.updatePermission(payload)
        .pipe(finalize(() => this.isUpdating = false),
          takeUntil(this.destroyed$))
        .subscribe(r => {
          if (r.success) {

            member.value = payload.value;
            member.isGameCreationAllowed = payload.isGameCreationAllowed;
            member.isPublicProfile = payload.isPublicProfile;

            this.selectedMember.value = payload.value;
            this.selectedMember.isGameCreationAllowed = payload.isGameCreationAllowed;
            this.selectedMember.isPublicProfile = payload.isPublicProfile;

            this.toastService.showSuccess(`Member ${member.user.fullName} details has been updated successfully`);
          } else {
            this.toastService.showError(r.message, 5000);
          }
        });
    }
  }

  get selectedMemberPermissionChanged() {
    return this.selectedMember.value !== this.selectedMemberEdit.value || this.selectedMember.isPublicProfile !== this.selectedMemberEdit.isPublicProfile || this.selectedMember.isGameCreationAllowed !== this.selectedMemberEdit.isGameCreationAllowed;
  }

  enableAccount(id) {
    this.teamService.enablePermission(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(m => {
        if (m.success) {
          this.members.find(x => x.id === id).isActive = true;
          if (this.selectedMember.id === id) {
            this.selectedMember.isActive = true;
          }
          this.toastService.showSuccess('Account has been activated');
        } else {
          this.toastService.showError(m.message, 5000);
        }
      })
  }

  disableAccount(id) {
    this.teamService.disablePermission(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(m => {
        if (m.success) {
          this.members.find(x => x.id === id).isActive = false;
          if (this.selectedMember.id === id) {
            this.selectedMember.isActive = false;
          }
          this.toastService.showInfo('Account has been disabled');
        } else {
          this.toastService.showError(m.message, 5000);
        }
      })
  }

  deleteAccount(id) {
    this.teamService.deleteAccount(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(m => {
        if (m.success) {
          const index = this.members.findIndex(x => x.id === id);
          this.members.splice(index, 1);
          if (this.selectedMember.id === id) {
            this.selectedMember = undefined;
          }
          this.toastService.showSuccess('Account has been deleted permanently');
        } else {
          this.toastService.showError(m.message, 5000);
        }
      })
  }

  resendInvite(id) {
    this.teamService.resendInvite(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(m => {
        if (m.success) {
          this.toastService.showSuccess('Invite has been sent successfully');
        } else {
          this.toastService.showError(m.message, 5000);
        }
      })
  }

}

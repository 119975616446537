<div class="nk-wrap nk-wrap-nosidebar">
    <div class="nk-content ">
        <div class="nk-block nk-block-middle wide-md mx-auto">
            <div class="nk-block-content nk-error-ld text-center">
                <ng-container *ngIf="isUpdating; else content">
                    <div class="text-center my-5">
                        <div class="spinner-border spinner-border-lg" role="status">
                            <span class="sr-only">Loading...</span></div>
                    </div>
                </ng-container>

                <!-- <img class="nk-error-gfx" src="/demo3/images/gfx/error-404.svg" alt=""> -->
                <ng-template #content>
                    <em class="icon text-success ni ni-check-circle" *ngIf="isAccepted"></em>
                    <em class="icon text-danger ni ni-cross-circle" *ngIf="!isAccepted || isInvalidParams"></em>
                    <div class="wide-xs mx-auto" *ngIf="isInvalidParams">
                        <h3 class="nk-error-title">Oops! Why you’re here?</h3>
                        <p class="nk-error-text">We are very sorry for inconvenience. It looks like you’re try to access
                            invalid page.</p>
                        <a [routerLink]="['/signin']" *ngIf="!isLoggedIn" class="btn btn-lg btn-primary mt-2">Login</a>
                        <a [routerLink]="['/dashboard']" *ngIf="isLoggedIn" class="btn btn-lg btn-primary mt-2">Back To
                            Dashboard</a>
                    </div>

                    <div class="wide-xs mx-auto" *ngIf="!isAccepted && !isInvalidParams">
                        <h3 class="nk-error-title">Oops! failed to accept invite</h3>
                        <p class="nk-error-text">We are very sorry for inconvenience. It looks like you’re try to access
                            invitation link that is expired or invalid.</p>
                        <a [routerLink]="['/signin']" *ngIf="!isLoggedIn" class="btn btn-lg btn-primary mt-2">Login</a>
                        <a [routerLink]="['/dashboard']" *ngIf="isLoggedIn" class="btn btn-lg btn-primary mt-2">Back To
                            Dashboard</a>
                    </div>

                    <div class="wide-xs mx-auto" *ngIf="isAccepted && !isInvalidParams">
                        <h3 class="nk-error-title">Congrats! Invitiation Accepted</h3>
                        <p class="nk-error-text">You have successfully accepted invitation</p>
                        <a [routerLink]="['/signin']" *ngIf="!isLoggedIn" class="btn btn-lg btn-primary mt-2">Login</a>
                        <a [routerLink]="['/dashboard']" *ngIf="isLoggedIn" class="btn btn-lg btn-primary mt-2">Back To
                            Dashboard</a>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CMSService {
  constructor(private http: HttpClient) {}

  getGame(): Observable<any> {
    const url = 'https://cloud.squidex.io/api/content/sommsays/game';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getSupport(): Observable<any> {
    const url =
      'https://cloud.squidex.io/api/content/sommsays/support?$orderby=created asc';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getFaq(): Observable<any> {
    const url =
      'https://cloud.squidex.io/api/content/sommsays/faq?$orderby=created asc';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getSubscriptionFaq(): Observable<any> {
    const url =
      'https://cloud.squidex.io/api/content/sommsays/faq-subscription?$orderby=created asc';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getPrivacy(): Observable<any> {
    const url = 'https://cloud.squidex.io/api/content/sommsays/privacy';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getTerms(): Observable<any> {
    const url = 'https://cloud.squidex.io/api/content/sommsays/terms/';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getHelp(): Observable<any> {
    const url = 'https://cloud.squidex.io/api/content/sommsays/help';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getWhy(): Observable<any> {
    const url = 'https://cloud.squidex.io/api/content/sommsays/why';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }

  getEULA(): Observable<any> {
    const url = 'https://cloud.squidex.io/api/content/sommsays/eula';

    return this.http.get<string>(url).pipe(map((response: any) => response));
  }
}

<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
    <div class="nk-app-root">
        <div class="nk-main ">
            <div class="nk-wrap ">
                <div class="nk-header nk-header-fixed is-light">
                    <div class="container-fluid">
                        <app-header></app-header>
                    </div>
                </div>
                <app-side></app-side>
                <div class="nk-content white">
                    <div class="container-fluid">
                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <div class="nk-block-head nk-block-head-sm">
                                    <div class="text-center mt-5">
                                        <div class="nk-block-head-content">
                                            <h3 class="nk-block-title bl">Account Plan</h3>
                                            <div class="nk-block-des text-soft mt-2">
                                                <!-- <p class="gr">Arcu adipiscing lacus, cursus massa.</p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-center mt-3">
                                        <div class="col-md-5 plan mr-2 pb-4">
                                            <div class="m-2">
                                                <div class="d-flex justify-between">
                                                    <div>
                                                        <div class="type bl">{{current|uppercase}}</div>
                                                        <p class="gr">
                                                            {{current=='free'?'Everything but the email':'Our most popular plan for small teams.'}}
                                                        </p>
                                                    </div>
                                                    <div class="fee gr" *ngIf="current=='pro'">
                                                        <!-- {{current=='free'?'$0':'$99'}} -->
                                                        $99
                                                        <span class="val">per month</span>
                                                    </div>
                                                </div>
                                                <div class="mt-2 bl">
                                                    <span *ngIf="current==='free'">No Emails (Upgrade to Pro and get
                                                        1000 emails)</span>

                                                    <div *ngIf="current==='pro' && bucket">
                                                        <ng-container
                                                            *ngIf="bucket.isEmpty || bucket.isExpired; else emaillimits">
                                                            <span>No emails available</span>
                                                        </ng-container>

                                                        <ng-template #emaillimits>
                                                            <span>
                                                                {{ bucket?.consumed }} of {{ bucket?.totalPurchased }}
                                                                emails</span>
                                                            <span *ngIf="bucket.hasLimitReached"
                                                                class="email-limit">Email
                                                                Limit
                                                                Reached</span>
                                                        </ng-template>
                                                    </div>

                                                </div>
                                                <div class="bar mt-2" *ngIf="current=='pro'">
                                                    <div class="inner-bar" [style.width.%]="getEmailConsumedPercent()">
                                                    </div>
                                                </div>
                                                <div class="d-flex col-12 p-0 gap">
                                                    <div class="col-3 p-0 mt-2">
                                                        <button (click)="onEditAccountPlan()" type="button"
                                                            class="btn btn-light justify-center">Edit
                                                            Plan</button>
                                                    </div>
                                                    <div class="col-5 p-0 mt-2"
                                                        *ngIf="false && bucket && (bucket.isEmpty || bucket.isExpired || bucket.hasLimitReached)">
                                                        <button (click)="extendAccountPlan()" type="button"
                                                            class="btn btn-super justify-center">Need more
                                                            emails?</button>
                                                    </div>

                                                    <!-- <div class="col-5 p-0 mt-2">
                                                        <button (click)="extendAccountPlan()" type="button"
                                                            class="btn btn-super justify-center">Need more
                                                            emails?</button>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PRO PLAN -->
                                        <div class="col-md-5" [ngClass]="{'plan': cardInfo}">
                                            <div class="m-2" *ngIf="cardInfo">
                                                <div class="type bl">Payment method</div>
                                                <p class="gr" *ngIf="false">Change how you pay for your plan.</p>
                                                <div class="plan p-2 h-100">
                                                    <div class="w-100 py-4" *ngIf="cardInfoLoading">
                                                        <div class="spinner-border spinner-border-sm" role="status">
                                                            <span class="sr-only">Loading...</span></div>
                                                    </div>
                                                    <div class="d-flex align-start gap" *ngIf="!cardInfoLoading">
                                                        <img src="../../assets/icons/icons8-visa-card.svg">
                                                        <div class="flex-grow-1 gr">
                                                            <div>{{cardInfo?.brand}} ending in {{cardInfo?.last4}}</div>
                                                            <div>Expiry {{cardInfo?.expMonth < 10 ? '0'
                                                                    +cardInfo?.expMonth : cardInfo?.expMonth
                                                                    }}/{{cardInfo?.expYear}}</div>
                                                            <div><img class="mr-1"
                                                                    src="../../assets/icons/icons8-email.svg">{{cardInfo?.name}}
                                                            </div>
                                                        </div>
                                                        <div *ngIf="false" class="col-2 p-0"
                                                            (click)="opneEditCardDetailsModal()">
                                                            <button class="btn btn-light">Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- INVOICE PORTION -->
                                    <div class="mt-5">
                                        <div class="mt-5 col-10 margin-auto p-0">
                                            <div class="col-12 d-flex p-0">
                                                <div>
                                                    <div class="bl f-18">Billing and invoicing</div>
                                                    <div class="gr">Take a look at where your money is going.</div>
                                                </div>
                                                <div class="flex-grow-1"></div>
                                                <div class="d-flex">
                                                    <button class="btn btn-gray" (click)="onDownloadAll()"><img
                                                            class="mr-1"
                                                            src="../../assets/icons/icons8-download.svg">Download
                                                        all</button>
                                                    <img class=" dots"
                                                        src="../../assets/icons/icons8-dots-vertical.svg">
                                                </div>

                                            </div>
                                            <div class="divider"></div>

                                            <!-- Billing Table -->

                                            <div class="nk-tb-list nk-tb-orders d-none d-md-table gr plan">
                                                <div class="nk-tb-item nk-tb-head f-16">
                                                    <div class="nk-tb-col tb-col-md">
                                                        <input class="mr-1" type="checkbox">
                                                        <span>Invoice</span>
                                                    </div>
                                                    <div class="nk-tb-col tb-col-sm"><span>Billing date</span></div>
                                                    <div class="nk-tb-col tb-col-sm"><span>Plan</span></div>
                                                    <div class="nk-tb-col tb-col-sm" *ngIf="false"><span>User
                                                            emails</span></div>
                                                    <div class="nk-tb-col tb-col-sm"><span>Remaining</span></div>
                                                    <div class="nk-tb-col tb-col-sm"></div>
                                                </div>

                                                <div class="nk-tb-item selectable" *ngFor="let invoice of invoices">
                                                    <div class="nk-tb-col tb-col-sm">
                                                        <table>
                                                            <tr>
                                                                <td class="center">
                                                                    <input type="checkbox" class="mr-1">
                                                                    <img src="../../assets/icons/icons8-file.svg">
                                                                    <span class="ml-1 bl"> {{
                                                                            'invoice #'+
                                                                            invoice?.stripeInvoiceNumber}}</span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                    <div class="nk-tb-col tb-col-sm">
                                                        <table>
                                                            <tr>
                                                                <td class="center">
                                                                    <span> {{ invoice?.originalDateTime |
                                                                            date:'mediumDate'}}</span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="nk-tb-col tb-col-sm">
                                                        <table>
                                                            <tr>
                                                                <td class="center">
                                                                    <span> {{ invoice?.plan }}</span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="nk-tb-col tb-col-sm" *ngIf="false">
                                                        <table>
                                                            <tr>
                                                                <td class="center">
                                                                    {{ invoice?.price }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="nk-tb-col tb-col-sm">
                                                        <table>
                                                            <tr>
                                                                <td class="center">
                                                                    <ng-container
                                                                        *ngIf="invoice.emailBucket; else emptyEmails">
                                                                        {{ invoice?.emailBucket?.consumed }}/{{ invoice?.emailBucket?.totalPurchased }}
                                                                    </ng-container>
                                                                    <ng-template #emptyEmails>
                                                                        -
                                                                    </ng-template>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="nk-tb-col nk-tb-col-action tb-col-sm">
                                                        <table class="ml-auto">
                                                            <tr>
                                                                <td>
                                                                    <a [href]="invoice?.stripeInvoiceURL"
                                                                        class="download-btn">Download</a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="w-100 py-4 text-center" *ngIf="loading">
                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                        <span class="sr-only">Loading...</span></div>
                                                </div>
                                                <div class="w-100 py-3 text-center"
                                                    *ngIf="!loading && invoices.length < 1">
                                                    <span>No records found</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 py-2">
                                            <ngb-pagination *ngIf="!loading" [(page)]="page" [(pageSize)]="count"
                                                [rotate]="true" [ellipses]="true" [boundaryLinks]="true"
                                                [collectionSize]="totalCount" (pageChange)="loadPage($event)">
                                            </ngb-pagination>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FREQUENTLY ASKED QUSTIONS -->

                <div class="nk-content">
                    <div class="container-fluid">
                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <div class="nk-block-head nk-block-head-sm">
                                    <div class="text-center mt-5">
                                        <div class="nk-block-head-content">
                                            <h3 class="nk-block-title bl">Frequently asked questions</h3>
                                            <div class="nk-block-des text-soft mt-2">
                                                <p class="gr more">Still need more </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-center mt-4">
                                        <div class="col-md-6">
                                            <ul>
                                                <li class="list-1" *ngFor="let fq of frequentQuestions">
                                                    <div class="d-flex justify-between">
                                                        <div class="bl" [innerHtml]="fq.q"></div>
                                                        <img [src]="fq.expanded ? '../../assets/icons/icons8-collapse.svg' :'../../assets/icons/icons8-expand.svg'"
                                                            (click)="fq.expanded = !fq.expanded">
                                                    </div>
                                                    <div class="gr mt-2" *ngIf="fq.expanded" [innerHtml]="fq.a">
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-center mt-3">
                                        <div class="col-md-10 footer p-4">
                                            <div class="d-flex justify-center mt-3">
                                                <img [ngClass]="{'merge-image':i === 1}" class="img"
                                                    *ngFor="let img of images;let i = index" [src]="img">
                                            </div>
                                            <div class="text-center mt-3 bl">Still have questions?</div>
                                            <div class="text-center mt-2 gr">Can’t find the answer you’re looking
                                                for?
                                                Please chat to our
                                                friendly team.</div>
                                            <div class="col-md-2 margin-auto">
                                                <button type="button" class="mt-3 btn btn-primary justify-center">Get in
                                                    touch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-tags-input",
  templateUrl: "./tags-input.component.html",
  styleUrls: ["./tags-input.component.scss"],
})
export class TagsInputComponent implements OnInit {
  @Input() items: string[];
  @Input() value: string;
  @Input() label: string;
  @Input() readOnly = false;
  @Input() subLabel = "";
  @Input() placeholder = "";

  @Output() onAdd = new EventEmitter<string>();
  @Output() onRemove = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  add($event) {
    console.log($event);
    this.value = this.value.trim();

    if (this.value) {
      if (this.items.indexOf(this.value) < 0) {
        this.onAdd.emit(this.value);
      }

      this.value = "";
    }
  }

  remove(index: number) {
    this.onRemove.emit(index);
  }
}

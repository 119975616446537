import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { PlayerManagementService } from "../_services/player-management.service";

@Injectable({
  providedIn: "root",
})
export class PlayerResolverService implements Resolve<any> {
  constructor(
    private managementService: PlayerManagementService,
    private authService: AuthenticationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params["id"];
    const companyId = this.authService.getDefaultCompany()?.companyId;
    return this.managementService.getPlayer(id, companyId);
  }
}

<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Add User</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!inviteSent; else successMessage;">
            <form #instanceForm="ngForm" (ngSubmit)="instanceForm.valid && sendInvite()">

                <div class="form-group">
                    <label class="form-label ">Name</label>
                    <div class="form-control-wrap ">
                        <input type="text" #userName="ngModel" placeholder="Chuck Furuya" required
                            [(ngModel)]="team.name" class="form-control" name="name">
                    </div>
                    <div *ngIf="instanceForm.submitted && userName.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="userName.errors.required">This field is required.</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label ">Title</label>
                    <div class="form-control-wrap ">
                        <input type="text" #memberTitle="ngModel" placeholder="Sommelier" required
                            [(ngModel)]="team.title" class="form-control" name="title">
                    </div>
                    <div *ngIf="instanceForm.submitted && memberTitle.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="memberTitle.errors.required">This field is required.</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label ">Email</label>
                    <div class="form-control-wrap ">
                        <input type="text" #memberEmail="ngModel" placeholder="chuckfuruyauncorked@gmail.com" required
                            [(ngModel)]="team.email" class="form-control" name="emailAddress">
                    </div>
                    <div *ngIf="instanceForm.submitted && memberEmail.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="memberEmail.errors.required">This field is required.</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Permission</label>
                    <div class="form-control-wrap ">
                        <ng-select name="permissionValue" #permissionVal="ngModel" [(ngModel)]="permissionValue"
                            placeholder="Select one..." required>
                            <ng-option value="1">Admin</ng-option>
                            <ng-option value="2">Employee</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="instanceForm.submitted && permissionVal.invalid" class="invalid-feedback mt-1">
                        <div *ngIf="permissionVal.errors.required">This field is required.</div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-control-wrap d-flex justify-content-between">
                        <label class="form-label">Create Games</label>
                        <div class="custom-control custom-switch checked">
                            <input type="checkbox" name="IsSizeSquareMeter" [(ngModel)]="team.isGameCreationAllowed"
                                class="custom-control-input" name="allowedCreateGames" id="user-create-games">
                            <label class="custom-control-label" for="user-create-games"></label>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-control-wrap d-flex justify-content-between">
                        <label class="form-label">Public Profile</label>
                        <div class="custom-control custom-switch checked">
                            <input type="checkbox" name="IsSizeSquareMeter" [(ngModel)]="team.isPublicProfile"
                                class="custom-control-input" name="user-publicProfile" id="user-public-profile">
                            <label class="custom-control-label" for="user-public-profile"></label>
                        </div>
                    </div>
                </div>


                <div class="form-group mt-3">
                    <div class="col-12 px-0">
                        <a *ngIf="!isSaving" (click)="close()" class="btn btn-light float-left">Cancel</a>
                        <button type="submit" appButtonSpinner class="btn btn-super float-right" text="Send"
                            [loadingState]="isSaving"></button>
                    </div>
                </div>


            </form>

        </ng-container>
        <ng-template #successMessage>

            <div class="nk-modal text-center mt-5">
                <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                <h4 class="nk-modal-title mb-1">Success</h4>
                <div class="nk-modal-text">
                    <div class="caption-text">
                        An invitation to join has been sent to {{ team.email }}
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="mt-5">
                    <button (click)="close()" class="btn btn-super float-right">Done</button>
                </div>
            </div>
        </ng-template>
    </div>
</div>
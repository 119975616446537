<app-shell-anon>
  <div class="nk-content ">
    <div class="container-fluid">
      <div class="nk-content-inner">
        <div class="nk-content-body">
          <div class="content-page wide-md m-auto">

            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Privacy Policy</h3>
                  <div class="nk-block-des text-soft">
                    <p>Somm Says - Privacy Policy</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-inner">
                  <div class="entry">
                    <div class="content" [innerHTML]="content"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</app-shell-anon>
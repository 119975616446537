import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { ServerResponse } from '../_models/server-response';
import { User } from '../_models/user.model';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastLocation, ToastService } from '../_services/toast.service';
import * as LogRocket from 'logrocket';
import { GameService } from '../_services/game.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  username: string;
  code: string;
  confirmMode = false;
  isLoading = false;
  resendCodeRequest = false;

  showPassword = false;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private router: Router,
    private gameService: GameService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {}

  validate() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.authService.validate(this.username, 'email').subscribe((m) => {
        if (m.success) {
          this.code = '';
          this.confirmMode = true;
        } else {
          this.toastService.showError(m.message, 5000, ToastLocation.TopCenter);
        }

        this.isLoading = false;
      });
    }
  }

  resendCode() {
    if (this.resendCodeRequest) return;

    this.resendCodeRequest = true;
    this.authService
      .validate(this.username, 'email')
      .pipe(finalize(() => (this.resendCodeRequest = false)))
      .subscribe((m) => {
        if (m.success) {
          this.toastService.showSuccess(
            'Code has been re-sent check yuor inbox'
          );
        } else {
          this.toastService.showError(m.message, 5000, ToastLocation.TopCenter);
        }
      });
  }

  signin() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.authService.signin(this.username, this.code, 'email').subscribe(
        async (m) => {
          if (m.success) {
            this.authService.setUserDetails$(m.data);
            localStorage.setItem('token', m.data.token);
            this.sharedService.token = m.data.token;
            const sequenceCount = await this.gameService
              .getTotalCompletedSequenceCountByDefaultCompany()
              .toPromise();
            this.router.navigate([
              !(sequenceCount.data >= 10) ? 'dashboard' : 'games',
            ]);
            this.isLoading = false;
          } else {
            this.toastService.showError(
              m.message,
              5000,
              ToastLocation.TopCenter
            );
            this.isLoading = false;
          }
        },
        (error) => (this.isLoading = false)
      );
    }
  }
}

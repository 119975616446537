import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
import { AuthenticationService } from "../_services/authentication.service";
import { PlayerManagementService } from "../_services/player-management.service";
import { ToastService } from "../_services/toast.service";
import { Team } from '../_models/team';

@Component({
  selector: "app-import-players",
  templateUrl: "./import-players.component.html",
  styleUrls: ["./import-players.component.scss"],
})
export class ImportPlayersComponent implements OnInit {
  isUploading = false;
  isHovering = false;

  file: File;
  fileName = "";
  content = "";
  active = "redirect";
  imported = false;

  team: Team;

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private playerManagemenetService: PlayerManagementService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.team = this.authService.getDefaultCompany();
  }

  onFilePicked($event) {
    if ($event.target.files[0]) {
      this.setFile($event.target.files[0]);
    }
  }

  onDrop(files: FileList) {
    if (files.length > 0) {
      const file: File = files[0];
      const supportedTypes: Array<string> = ["csv"];

      if (supportedTypes.indexOf(file.type.split("/")[1]) === -1) return;

      this.setFile(file);
    }
  }

  setFile(file: File) {
    this.file = file;
    this.fileName = this.file.name;
  }

  toggleHover = (event: boolean) => (this.isHovering = event);

  onRemove() {
    this.file = undefined;
    this.fileName = undefined;
  }

  close() {
    this.activeModal.dismiss({});
  }

  upload() {
    this.imported = false;
    if (this.isUploading) return;

    if (this.active === "file" && !this.file) {
      this.toastService.showError("Please select a csv file to upload");
      return;
    } else if (this.active === "content" && !this.content) {
      this.toastService.showError("Please provide CSV content");
      return;
    }

    this.isUploading = true;
    const payload = {
      file: this.file,
      type: this.active,
      content: this.content,
    };

    const companyId = this.authService.getDefaultCompany()?.companyId;
    this.playerManagemenetService
      .import(companyId, payload)
      .pipe(finalize(() => (this.isUploading = false)))
      .subscribe((ret) => {
        this.imported = ret.data;
        if (!ret.success) {
          this.toastService.showError(ret.message);
        }
      });
  }

  redirectToEndgrateImport() {
    const companyId = this.team?.companyId;
      if (companyId) {
          this.playerManagemenetService.fetchImportUrl(companyId).subscribe({
              next: (response) => {
                  // Check if the response includes the URL
                  if (response && response.data) {
                      // Use the URL from the response for redirection
                      window.location.href = response.data;
                  } else {
                      // Handle the case where the URL is not returned
                      this.toastService.showError('No import URL returned from the server.');
                  }
              },
              error: (error) => {
                  // Handle any errors, such as network issues or server errors
                  this.toastService.showError('Error fetching import URL.');
                  console.error('Error fetching import URL:', error);
              }
          });
      } else {
          this.toastService.showError('Company ID is undefined.');
          console.error('Company ID is undefined.');
      }
  }
}

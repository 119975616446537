export class GameFilter {
  companyId: string;
  gameCode: string;
  producers: string[];
  varietalComparer: number;
  varietal: string[];
  vintageComparer: number;
  vintageFrom: number;
  vintageTo: number;
  regionComparer: number;
  region: string[];
  usernameComparer: number;
  username: string;
  page: number;
  length: number;
  durationType: number;
}

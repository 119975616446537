import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/_models/user.model';
import { WineFilter } from 'src/app/_models/wine-filter.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CdnService } from 'src/app/_services/cdn.service';
import { WineService } from 'src/app/_services/wine.service';
import { environment } from 'src/environments/environment';
import { WineManagementComponent } from './wine-management/wine-management.component';

@Component({
  selector: 'app-wines',
  templateUrl: './wines.component.html',
  styleUrls: ['./wines.component.scss'],
})
export class WinesComponent implements OnInit {
  filterOptions = {
    producers: [],
    varietals: [],
    regions: [],
    somms: [],
    ratings: [],
  };

  filter: WineFilter = new WineFilter();
  env;
  user: User;

  sort = 5; // by default sort by Average correct answer
  sortByAscending = false;

  loading = true;

  wines: any = [];
  page = 1;
  length = 20;
  totalCount = 0;

  destroyed$ = new Subject<void>();
  destoryCompanySubs$ = new Subject<void>();

  previousFilterPayload: any;

  constructor(
    private wineService: WineService,
    private cdnService: CdnService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    // const wine = localStorage.getItem('wine');
    // if (wine) {
    //   this.openModal(JSON.parse(wine));
    // }

    this.env = environment;
    const team = this.authService.getDefaultCompany();
    this.filter.companyId = team?.companyId;
    this.filter.durationType = 2;

    this.user = this.authService.getUser();

    this.authService
      .getUserCompany$()
      .pipe(takeUntil(this.destoryCompanySubs$))
      .subscribe(async (m) => {
        if (team) {
          console.log(team);
          this.destroyed$.next();
          this.filter.companyId = team?.companyId;
          this.init();
        }
      });
  }

  init() {
    this.wineService
      .getFilterOptions(this.filter.companyId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (r) => (this.filterOptions = r.data),
        (_) => {}
      );

    this.search(false, false);
  }

  search(append = false, isDurationChanged: boolean = false) {
    this.loading = true;
    if (!append) {
      this.wines = [];
      this.page = 1;
    }

    const model = { ...this.generateFilterPayload(isDurationChanged) };
    model['page'] = this.page;
    model['count'] = this.length;
    model['sortBy'] = this.sort;
    model['isSortByAscending'] = this.sortByAscending;

    this.wineService
      .search(model)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m: any) => {
        this.totalCount = m.totalCount;
        if (append) {
          this.wines.push(...m.data);
        } else {
          this.wines = m.data;
        }
      });
  }

  generateFilterPayload(isDurationChanged: boolean) {
    if (isDurationChanged) {
      this.previousFilterPayload.durationType = this.filter.durationType;
      return this.previousFilterPayload;
    }

    this.previousFilterPayload = { ...this.filter };
    if (!this.previousFilterPayload.vintageFrom)
      this.previousFilterPayload.vintageFrom = undefined;
    if (!this.previousFilterPayload.vintageTo)
      this.previousFilterPayload.vintageTo = undefined;

    return this.previousFilterPayload;
  }

  getWineImageUrl = (name) => `${this.cdnService.wine()}/${name.trim()}`;

  getWineOrigin(wine) {
    if (!wine) return '';

    let origin: string[] = [];

    if (wine.appellation && origin.indexOf(wine.appellation) < 0)
      origin.push(wine.appellation);
    if (wine.region && origin.indexOf(wine.region) < 0)
      origin.push(wine.region);
    if (wine.country && origin.indexOf(wine.country) < 0)
      origin.push(wine.country);

    return origin.join(', ');
  }

  getWineIcon(color: string) {
    if (!color) return '../../assets/icons/wine-colors/default.svg';

    color = color.trim().toLowerCase();

    if (color === 'rose') {
      return '../../assets/icons/wine-colors/rose-wine.svg';
    } else if (color === 'white') {
      return '../../assets/icons/wine-colors/white-wine.svg';
    } else if (color === 'red') {
      return '../../assets/icons/wine-colors/red-wine.svg';
    } else if (color === 'orange') {
      return '../../assets/icons/wine-colors/orange-wine.svg';
    }

    return '../../assets/icons/wine-colors/default.svg';
  }

  open(index: string) {
    const wine = this.wines[index].wine;
    this.openModal(wine);
  }

  openModal(wine) {
    const modalInstance = this.modalService.open(WineManagementComponent, {
      backdrop: 'static',
    });
    console.log(wine);
    modalInstance.componentInstance.wineModel = wine;
    modalInstance.result.then((result) => {
      if (result?.wine) {
        this.search(false, false);
      }
    });
  }

  loadPage(page: number) {
    this.loading = true;
    this.wines = [];
    const model = { ...this.generateFilterPayload(false) };
    model['page'] = this.page;
    model['count'] = this.length;
    model['sortBy'] = this.sort;
    model['isSortByAscending'] = this.sortByAscending;

    this.wineService
      .search(model)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((m: any) => {
        this.totalCount = m.totalCount;
        this.wines = m.data;
      });
  }

  getCountryFlagName(countryName: string) {
    if (
      (countryName && countryName.toLowerCase() === 'united states') ||
      countryName === 'united states of america' ||
      countryName === 'usa'
    )
      return 'USA';

    return countryName?.trim();
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnterpriseSettingsUpdateComponent } from '../enterprise-settings-update/enterprise-settings-update.component';
import { AuthenticationService } from '../_services/authentication.service';
import { GameService } from '../_services/game.service';
import { GeoService } from '../_services/geo.service';
import { ProfileService } from '../_services/profile.service';
import { TeamService } from '../_services/team.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss']
})
export class EnterpriseComponent implements OnInit {

  @ViewChild(GoogleMap) map: GoogleMap;

  loading = false;
  sommLoading = false;
  producersLoading = false;
  templateLoading = false;

  selectedTab = 'about';
  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  zoom = 14;
  markerPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  markerLoaded = false;
  apiLoaded: Observable<boolean>;
  somms: any;

  companyId: string = '';

  options: google.maps.MapOptions = {
    center: this.center,
    disableDefaultUI: true,
    gestureHandling: 'cooperative',
  };

  env = environment;

  markerOptions: google.maps.MarkerOptions = { draggable: false };
  producers: any;
  templates: any;
  company: any;

  aboutSectionPage = 1;
  aboutSectionWines = [];
  aboutSectionProducersPage = 1;

  gameSectionWines: any;
  gameSectionPage = 1;

  producersSectionPage = 1;

  filterWineColorOptions = [];
  filterRegionOptions = [];

  appliedWineColors = [];
  appliedRegions = [];

  sortByReleaseDate = true;
  pageSize = 8;

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private geoService: GeoService,
    private gameService: GameService,
    private teamService: TeamService,
    private toastService: ToastService
  ) {
    this.apiLoaded = http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBXn0cf9uDoWJI1YhKprMl59fOYGBbYOCs&libraries=places', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  ngOnInit() {
    this.companyId = this.authService.getDefaultCompany()?.companyId;
    this.authService.getUserCompany$().subscribe(m => {
      if (m) {
        this.companyId = m.companyId;
        this.loadData();
      }
    });

    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.sommLoading = true;
    this.producersLoading = true;
    this.templateLoading = true;

    this.somms = [];
    this.templates = [];
    this.producers = [];

    this.profileService.getCompany(this.companyId)
      .pipe(finalize(() => this.loading = false))
      .subscribe((d) => {
        this.company = d.data;
        if (this.company?.social) {
          Object.keys(this.company.social).forEach((k) => this.company.social[k] == null && delete this.company.social[k]);
        }

        if (this.company.address) {
          this.loadMap(this.company.address, true);
        }
      });

    this.profileService.getProducers(this.companyId)
      .pipe(finalize(() => this.producersLoading = false))
      .subscribe((d) => {
        this.producers = d.data;
      });

    this.profileService.getSomms(this.companyId)
      .pipe(finalize(() => this.sommLoading = false))
      .subscribe((d) => { this.somms = d.data });

    this.profileService.getTemplatesByCompany(this.companyId)
      .pipe(finalize(() => this.templateLoading = false))
      .subscribe((d) => {
        this.templates = d.data;
        const wines = this.templates.map(x => x.note.wine);
        this.gameSectionWines = [...this.templates];
        this.aboutSectionWines = [...this.templates];

        wines.map(x => {
          const color = x.color.toLowerCase();
          if (this.filterWineColorOptions.indexOf(color) < 0) {
            this.filterWineColorOptions.push(color);
          }

          if (x?.region && this.filterRegionOptions.findIndex(a => a.region.toLowerCase() === x?.region?.toLowerCase()) < 0) {
            const country = x?.country;
            const count = wines.filter(k => k.region === x.region).length;
            this.filterRegionOptions.push({
              region: x.region,
              country: country,
              count: count
            });
          }

          return '';
        })
      });
  }

  selectTab(value: string) {
    this.selectedTab = value;
  }

  getWineOrigin(wine) {
    if (!wine) return '';

    let origin: string[] = [];

    if (wine.appellation && origin.indexOf(wine.appellation) < 0) origin.push(wine.appellation);
    if (wine.region && origin.indexOf(wine.region) < 0) origin.push(wine.region);
    if (wine.country && origin.indexOf(wine.country) < 0) origin.push(wine.country);

    return origin.join(", ");
  }

  getCountryFlagName(countryName: string) {
    if (countryName && countryName.toLowerCase() === 'united states' || countryName === 'united states of america' || countryName === 'usa') return 'USA';

    return countryName?.trim();
  }

  getLocation(city, country) {
    if (city && country) {
      return `${city}, ${country}`;
    } else if (city) {
      return `${city}`;
    } else if (country) {
      return `${country}`;
    } else {
      return 'Not available';
    }
  }

  openEnterpriseEdit() {
    const modalInstance = this.modalService.open(EnterpriseSettingsUpdateComponent);
    modalInstance.componentInstance.company = this.company;
    modalInstance.result.then((result) => {
      if (result?.data) {
        this.company = { ...result.data };
      }
    });
  }

  loadMap(address: string, enableMarker: boolean) {
    this.geoService.get(address).subscribe((ret) => {
      if (ret) {
        this.center.lat = ret.data.latitude;
        this.center.lng = ret.data.longitude;
        this.map.panTo(this.center);

        this.markerPosition.lat = ret.data.latitude;
        this.markerPosition.lng = ret.data.longitude;

        if (enableMarker) {
          this.markerLoaded = true;
        }
      }
    });
  }

  toggleColorFilter(color: string) {
    const index = this.appliedWineColors.indexOf(color.toLowerCase());
    if (index > -1) {
      this.appliedWineColors.splice(index, 1);
    } else {
      this.appliedWineColors.push(color.toLowerCase());
    }

    this.refreshGames();
  }


  toggleRegionFilter(value: string) {
    const index = this.appliedRegions.indexOf(value.toLowerCase());

    if (index > -1) {
      this.appliedRegions.splice(index, 1);
    } else {
      this.appliedRegions.push(value.toLowerCase());
    }

    this.refreshGames();
  }

  refreshGames() {
    this.gameSectionWines = this.templates.filter(x => (this.appliedWineColors.length < 1 || this.appliedWineColors.indexOf(x?.note?.wine?.color?.toLowerCase()) > -1) &&
      (this.appliedRegions.length < 1 || this.appliedRegions.indexOf(x?.note?.wine?.region?.toLowerCase()) > -1));

    this.gameSectionWines = [...this.gameSectionWines.sort((first: any, second: any) => {
      if (this.sortByReleaseDate) {
        return new Date(second.createdOn).getTime() - new Date(first.createdOn).getTime();
      } else {
        return first?.instances > second?.instances ? -1 : 1;
      }
    })];
  }

  updatePrivacyToPrivate(templateId) {
    this.gameService.setPrivate(templateId).toPromise().then(m => {
      const template = this.templates.find(x => x.id === templateId);
      template.isPrivate = true;
    });
  }

  updatePrivacyToPublic(templateId) {
    this.gameService.setPublic(templateId).toPromise().then(m => {
      const template = this.templates.find(x => x.id === templateId);
      template.isPrivate = false;
    });
  }

  enableSommPublicProfile(sommId) {
    this.teamService.enablePublicProfile(this.companyId, sommId).toPromise().then(m => {
      if (m.success) {
        const somm = this.somms.find(x => x.id === sommId);
        somm.isPublicProfile = true;
      } else {
        this.toastService.showError(m.message);
      }
    });
  }

  disableSommPublicProfile(sommId) {
    this.teamService.disablePublicProfile(this.companyId, sommId).toPromise().then(m => {
      if (m.success) {
        const somm = this.somms.find(x => x.id === sommId);
        somm.isPublicProfile = false;
      } else {
        this.toastService.showError(m.message);
      }
    });
  }
}

export class Wine {
  id: string;
  name: string;
  image: string;
  producer: string;
  vintage: number;
  description: string;
  color: string;
  variety: string;
  region: string;
  country: string;
  alcoholPerc: number;
  foodPairing: Array<string>;
  averageValueUSD: number;
  retailPriceUSD: number;
  retai: number;
  link: string;
  appellation: string;
  attributes: Attribute[];
  vintners: Vintner[];
  vineyards: string[];
  grapes: Grape[];
  bottles: number;
  bottlesProduced: number;
  importers: string[];
  distributors: string[];
  barrelTime: number;
  barrelMaterials: string[];
  wineBottles: WineBottle[];
  harvestDates: any;
}

export class Attribute {
  value: string;
  name: string;
  enabled: boolean;
}

export class Vintner {
  name: string;
  title: string;
}

export class Grape {
  name: string;
  perc: number;
}

export class WineBottle {
  size: string;
  qtyProduced: number;
}

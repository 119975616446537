import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProducerService } from '../_services/producer.service';
import { ToastService } from '../_services/toast.service';

@Component({
  selector: 'app-edit-producer',
  templateUrl: './edit-producer.component.html',
  styleUrls: ['./edit-producer.component.scss'],
})
export class EditProducerComponent implements OnInit {
  model: any = {};
  social: any[] = [];
  file: File;
  url = '../../assets/temp/tamura.png';

  isSaving = false;

  businessTypes = [
    { value: 'PRODUCER', text: 'Producer' },
    { value: 'RETAIL', text: 'Retail' },
    { value: 'BAR', text: 'Bar' },
    { value: 'EVENTS', text: 'Events' },
    { value: 'DISTRIBUTOR', text: 'Distributor' },
    { value: 'IMPORTER', text: 'Importer' },
    { value: 'SOMMELIER', text: 'Sommelier' },
  ];

  public options = {
    placeholderText:
      'More than your total wine store! Pure, real expressions of wine from around the world. We have a huge selection of local and imported beer, spirits and beverage tools. Owned by two lifetime beverage professional sommeliers.',
    charCounterCount: false,
    key: environment.froalaKey,
  };
  @Input() set producer(value) {
    this.model = { ...value };
    if (this.model.social) {
      Object.keys(this.model.social).map((key) => {
        const obj = { type: key, link: this.model.social[key] };
        this.social.push(obj);
      });
    }
    if (this.model.logo) {
      this.url = environment.cdn + 'companies/' + this.model.logo;
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private producerService: ProducerService
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.activeModal.close(false);
  }

  update(): void {
    this.parseSocialModel();
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.producerService
      .updateProducerProfile(this.model)
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe((d) => {
        if (d.success) {
          this.activeModal.close({ data: d.data });
        } else {
          this.toastService.showError(d.message);
        }
      });
  }

  addSocialLinks(): void {
    const obj = { type: '', link: '' };
    const lastIndex = this.social.length - 1;
    if (
      lastIndex &&
      this.social.length > 1 &&
      this.social[lastIndex]?.type.length &&
      this.social[lastIndex]?.link.length
    ) {
      this.social.push(obj);
    } else if (this.social.length === 1 || this.social.length === 0) {
      this.social.push(obj);
    }
    this.parseSocialModel();
  }

  parseSocialModel(): void {
    const model = {};
    this.social.forEach((d) => {
      if (d.type.length > 1) {
        model[d.type] = d.link;
      }
    });
    this.model.social = model;
  }

  cancelSelection(i: number): void {
    if (i > -1) {
      this.social.splice(i, 1);
    }
  }

  onFileChange(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.model.file = this.file;
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.url = reader.result as string;
      };
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GeoService {
    httpOptions = {};

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ) { }

    get(address: string): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('address', address);
        return this.http.post(environment.apiURL + '/geo/get', formData, this.httpOptions).pipe(
            map((response: any) => {
                const ret = response;
                if (ret) {
                    return ret;
                } else {
                    console.log('error');
                }
            })
        );
    }
}

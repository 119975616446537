<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Change your plan</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>

            <div class="pt-3 pb-3">
                <div class="border" (click)="openDowngradeModal()" [ngClass]="{'current': current === 'free'}">
                    <div class="d-flex justify-between">
                        <div class="flex-grow-1 d-flex">
                            <img src="../../assets/icons/icons8-two-stack.svg">
                            <div class="ml-1">
                                <div><b>Free</b> 0$/month</div>
                                <div>0 emails</div>
                            </div>
                        </div>
                        <img
                            [src]="current === 'free'?'../../assets/icons/icons8-checked-circle.svg':'../../assets/icons/icons8-empty-circle.svg'">
                    </div>
                </div>
            </div>

            <div class="pt-3 pb-3">
                <div class="border" (click)="current = 'pro'" [ngClass]="{'current': current === 'pro'}">
                    <div class="d-flex justify-between">
                        <div class="flex-grow-1 d-flex">
                            <img src="../../assets/icons/icons8-three-stack.svg">
                            <div class="ml-1">
                                <div><b>Pro</b> $99/month</div>
                                <div>Up to 1000 emails</div>
                            </div>
                        </div>
                        <img
                            [src]="current === 'pro'?'../../assets/icons/icons8-checked-circle.svg':'../../assets/icons/icons8-empty-circle.svg'">
                    </div>
                </div>
            </div>

            <div class="mt-3" *ngIf="false">
                <div>Automatically add 1,000 more emails for $30 when you run out?</div>
                <div class="form-control pl-0 border-0">
                    <div class="custom-control custom-switch checked">
                        <input type="checkbox" name="verifiedUrl" [checked]="true" class="custom-control-input"
                            id="verifiedUrl">
                        <label class="custom-control-label" for="verifiedUrl"> YES</label>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>


    <div class="modal-footer">
        <div class="col-12 px-0 d-flex justify-between">
            <a (click)="close()" class="col-5 btn-cancel btn cancel float-left">Cancel</a>
            <button type="button" (click)="onConfirm()" appButtonSpinner class="col-5 btn float-right btn-primary"
                [loadingState]="isSaving" text="Confirm"></button>
        </div>
    </div>

</div>
<body class="nk-body npc-default has-apps-sidebar has-sidebar ">
  <div class="nk-app-root">
      <div class="nk-main ">
          <div class="nk-wrap ">
              <div class="nk-header nk-header-fixed is-light">
                  <div class="container-fluid">
                      <app-header></app-header>
                  </div>
              </div>
              <app-side></app-side>
              <div class="nk-content">
                  <div class="container-fluid">
                      <div class="nk-content-inner">
                          <div class="nk-content-body">
                              <div class="nk-block-head nk-block-head-sm">
                                  <div class="nk-block-between">
                                      <div class="nk-block-head-content">
                                          <h3 class="nk-block-title page-title" *ngIf="title">{{title}}</h3>
                                          <div class="nk-block-des text-soft" *ngIf="description">
                                              <p>{{description}}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ng-content></ng-content>
              </div>
          </div>
      </div>
  </div>
</body>

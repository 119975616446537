<div class="modal-content">
    <div class="modal-body">
        <h4 class="title">Generate QR Code</h4>
        <ng-conatiner *ngIf="!loading; else showLoadingSection">
            <ul *ngIf="!isDownloaded">
                <li>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="horizontalQR" (click)="index = 0" checked name="customRadio"
                            class="custom-control-input">
                        <label class="custom-control-label" for="horizontalQR">Horizontal Print</label>
                    </div>
                    <div class="horizontalQR-viewer" *ngIf="index == 0">
                        <img [src]="domeSanitizer.bypassSecurityTrustResourceUrl(horizontalQRHref)" />
                    </div>
                </li>

                <li>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="verticalQR" (click)="index = 3" name="customRadio"
                            class="custom-control-input">
                        <label class="custom-control-label" for="verticalQR">Vertical Print</label>
                    </div>
                    <div class="verticalQR-viewer" *ngIf="index == 3">
                        <img [src]="domeSanitizer.bypassSecurityTrustResourceUrl(verticalQRHref)" />
                    </div>
                </li>

                <li>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="squareQR" (click)="index = 1" name="customRadio"
                            class="custom-control-input">
                        <label class="custom-control-label" for="squareQR">Square Print</label>
                    </div>
                    <div class="horizontalQR-viewer" *ngIf="index == 1">
                        <img [src]="domeSanitizer.bypassSecurityTrustResourceUrl(squareQRHref)" />
                    </div>
                </li>

                <!-- <li>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="digitalQR" (click)="index = 2" name="customRadio"
                            class="custom-control-input">
                        <label class="custom-control-label" for="digitalQR">Digital Print</label>
                    </div>
                    <div class="digitalQR-viewer" *ngIf="index == 2">
                        <img [src]="domeSanitizer.bypassSecurityTrustResourceUrl(digitalQRHref)" />
                    </div>
                </li> -->

                <li class="mt-3">
                    <div class="form-control-wrap">
                        <label class="form-label">Link</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [value]="template_url" readonly>
                            <div class="input-group-append">
                                <button (click)="copyToClipboard()" class="btn btn-outline-primary btn-dim">
                                    <em class="icon ni ni-copy-fill"></em>
                                </button>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>
            <div *ngIf="isDownloaded">
                <div class="nk-modal text-center mt-5">
                    <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                    <h4 class="nk-modal-title mb-1">Success</h4>
                    <div class="nk-modal-text">
                        <div class="caption-text">
                            Your image is downloaded
                        </div>
                    </div>
                </div>
            </div>

        </ng-conatiner>

        <ng-template #showLoadingSection>
            <div class="text-center empty">
                <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="modal-footer justify-content-center" *ngIf="!loading">
        <ng-container *ngIf="!isDownloaded">
            <button (click)="close()" class="btn btn-lg btn-outline-dark mr-2">Cancel</button>
            <button (click)="download()" class="btn btn-lg btn-primary">Download</button>
        </ng-container>
        <button *ngIf="isDownloaded" (click)="close()" class="btn btn-lg btn-primary mr-2">Done</button>
    </div>
</div>
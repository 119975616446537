import { PaymentComponent } from './payment/payment.component';
import { PlayerDetailsComponent } from './player-details/player-details.component';
import { SupportComponent } from './support/support.component';
import { EulaeComponent } from './eulae/eulae.component';
import { EulaComponent } from './eula/eula.component';
import { PrivacyeComponent } from './privacye/privacye.component';
import { TermseComponent } from './termse/termse.component';
import { InventoryComponent } from './inventory/inventory.component';
import { PlayersComponent } from './players/players.component';
import { WinesComponent } from './wines/wines.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';
import { FaqComponent } from './faq/faq.component';
import { BillingComponent } from './billing/billing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GamesComponent } from './games/games.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy/privacy.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { TermsComponent } from './terms/terms.component';
import { GameComponent } from './game/game.component';
import { GameCreateComponent } from './game-create/game-create.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AuthGuard } from './_components/_guards/auth.guard';
import { OnboardGuard } from './_components/_guards/onboard.guard';
import { TeamComponent } from './team/team.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { EnterprisePublicComponent } from './enterprise-public/enterprise-public.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { PlayerManagementComponent } from './player-management/player-management.component';
import { PlayerResolverService } from './resolvers/player-resolver.service';
import { WineManagementComponent } from './wines/wine-management/wine-management.component';
import { ProducerComponent } from './producer/producer.component';
import { AccountPlanComponent } from './account-plan/account-plan.component';
import { AccountPlanProComponent } from './account-plan-pro/account-plan-pro.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'signin', component: SigninComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'eula', component: EulaComponent },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'termse', component: TermseComponent },
  { path: 'privacye', component: PrivacyeComponent },
  { path: 'eulae', component: EulaeComponent },
  { path: 'support', component: SupportComponent, canActivate: [AuthGuard] },
  { path: 'games', component: GamesComponent, canActivate: [AuthGuard] },
  {
    path: 'game/:id',
    component: GameComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'game-create',
    component: GameCreateComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  { path: 'faq', component: FaqComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'producer',
    component: ProducerComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'account-plan',
    component: AccountPlanComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'account-plan/current-plan',
    component: AccountPlanProComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'wines',
    component: WinesComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  { path: 'players', component: PlayersComponent },
  { path: 'inventory', component: InventoryComponent },
  { path: 'onboard', component: OnboardingComponent, canActivate: [AuthGuard] },
  {
    path: 'team',
    component: TeamComponent,
    canActivate: [AuthGuard, OnboardGuard],
  },
  {
    path: 'enterprise',
    component: EnterpriseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'enterprise-public',
    component: EnterprisePublicComponent,
    canActivate: [AuthGuard],
  },
  { path: 'team/accept-invite', component: AcceptInvitationComponent },
  {
    path: 'player-management',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PlayerManagementComponent,
      },
      {
        path: 'detail/:id',
        component: PlayerDetailsComponent,
        resolve: {
          player: PlayerResolverService,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export class GlobalConstants {
  public static aroma = [
    "floral",
    "fruity",
    "herbal",
    "spicy",
    "savory",
    "earthy",
  ];
  public static body = ["light", "medium", "full"];
  public static acidity = ["creamy", "crisp", "tart"];
  public static tannins = ["silky", "fine grain", "grainy"];
  public static sugar = ["dry", "med dry", "med sweet", "sweet"];
  public static oak = ["none", "light", "moderate", "heavy"];
  public static wineAttributes = [
    {
      name: "Practicing Organic",
      value: "OG",
      enabled: false,
    },
    {
      name: "Sustainable",
      value: "SS",
      enabled: false,
    },
    {
      name: "Vegan",
      value: "V",
      enabled: false,
    },
    {
      name: "Charitable Giving",
      value: "CG",
      enabled: false,
    },
    {
      name: "Biodynamic",
      value: "BD",
      enabled: false,
    },
    {
      name: "Natural Wine",
      value: "NW",
      enabled: false,
    },
    {
      name: "Woman Owned",
      value: "WO",
      enabled: false,
    },
    {
      name: "Female Winemaker",
      value: "FW",
      enabled: false,
    },
    {
      name: "BIPOC Owned",
      value: "BO",
      enabled: false,
    },
    {
      name: "BIPOC Winemaker",
      value: "BW",
      enabled: false,
    },
    {
      name: "LGBTQ+ Owned",
      value: "LO",
      enabled: false,
    },
    {
      name: "LGBTQ+ Winemaker",
      value: "LW",
      enabled: false,
    },
  ];
}

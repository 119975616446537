import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingResponse } from '../_models/server-response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProducerService {
  constructor(private apiService: ApiService) { }

  getProducers(
    page?: number,
    count?: number,
    search?: any
  ): Observable<PagingResponse<any>> {
    let params = new HttpParams();
    params = params.append('page', JSON.stringify(page));
    params = params.append('count', JSON.stringify(count));
    return this.apiService.get<PagingResponse<any>>(`Admin/get-producers`, {
      params,
    });
  }
  updateProducerProfile(model: any): Observable<any> {
    return this.apiService.postForm(`Admin/update-producer`, model);
  }
}
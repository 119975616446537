import { ViewportScroller } from "@angular/common";
import {
  animate,
  animateChild,
  animation,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleChartComponent } from 'angular-google-charts';
import { forkJoin, of, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GenerateQrCodeComponent } from '../generate-qr-code/generate-qr-code.component';
import { InstanceCreateComponent } from '../instance-create/instance-create.component';
import { GlobalConstants } from '../_constants/global.constants';
import { Template } from '../_models/template.model';
import { AuthenticationService } from '../_services/authentication.service';
import { EventService } from '../_services/event.service';
import { FirebaseService } from '../_services/firebase.service';
import { GameService } from '../_services/game.service';
import { PlayerManagementService } from '../_services/player-management.service';
import { SharedService } from '../_services/shared.service';
import { ToastService } from '../_services/toast.service';
import { getIcon, getWineOrigin } from '../_services/wine.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter, :leave', [query('@*', animateChild())]),
    ]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class GameComponent implements OnInit, OnDestroy {
  // templateId = '604bfbdca621f9512a5eeacb';
  templateId = '';

  @ViewChild('chart') chart: GoogleChartComponent;

  env = environment;
  cdn = environment.cdn;
  title = "";
  type = "Sankey";
  data: any = [];
  selectedTab = 'summary';
  max = 0;
  public isCollapsed = {
    aroma: false,
    body: false,
    acidity: false,
    sugar: false,
    tannins: false,
    oak: false,
    flavor: false,
    guessPrice: false
  };

  template: Template;
  correctAverageAnswer: number;
  uniquePlayerCount: number;
  totalGamesCount: number;
  instances: any;
  displayInstances: any;
  isTemplateLoading = false;
  winePreferences: any;
  feedbackSummary: any[] = [];
  preferenceSummary: any[] = [];
  averageRating: number;

  sankeyPreference = [];
  flavorwave = [];
  flaverTypes = ['Aroma', 'Body', 'Acidity', 'Sugar', 'Oak', 'Flavor', 'Tannins', 'Guess the price'];
  feedBackColor = [
    { color: '#723147', background: '#F9DAE2', icon: 'fs-body.svg' },
    { color: '#636118', background: '#F9F2AB', icon: 'fs-acidity.svg' },
    { color: '#742869', background: ' #FAD8F2', icon: 'fs-sugar.svg' },
    { color: '#7A581C', background: '#FDDDB2', icon: 'fs-tannins.svg' },
    { color: '#602E89', background: '#EEDCF9', icon: 'fs-oak.svg' },
  ]
  isSankeyEmpty = false;

  userCompany: any;

  isLoadingFlavorWave = false;

  destroy$ = new Subject<any>();
  destroyFirebase$ = new Subject<any>();

  page = 1;
  dataTablePageSize = 20;
  destoryStats$ = new Subject<void>();
  selectedInstance = undefined;
  isLoadingStats = false;
  selectedIndex = -1;
  selectedWineStats: any;

  remainingVisibleState = {
    aroma: false,
    acidity: false,
    body: false,
    flavor: false,
    sugar: false,
    tannins: false,
    oak: false,
  };

  playerVerticalUIState = {
    aroma: false,
    acidity: false,
    body: false,
    flavor: false,
    sugar: false,
    tannins: false,
    oak: false,
    guessPrice: false,
  };

  colors = [
    '#023FA5',
    '#7D87B9',
    '#BEC1D4',
    '#D6BCC0',
    '#BB7784',
    '#8E063B',
    '#4A6FE3',
    '#8595E1',
    '#B5BBE3',
    '#E6AFB9',
    '#E07B91',
    '#D33F6A',
    '#11C638',
    '#8DD593',
    '#C6DEC7',
    '#EAD3C6',
    '#F0B98D',
    '#EF9708',
    '#0FCFC0',
    '#9CDED6',
    '#D5EAE7',
    '#F3E1EB',
    '#F6C4E1',
    '#F79CD4',
  ];

  options = {
    height: 500,
    sankey: {
      node: {
        label: {
          fontSize: 15,
          bold: false,
          color: '#000',
        },
        colors: this.colors,
      },
      link: {
        colorMode: 'gradient',
        colors: this.colors,
      },
    },
  };

  height = 500;

  instancesPage = 1;
  instancesLength = 10;
  totalInstancesCount = 0;
  loadingInstances = false;

  generatingQrCode = false;

  averageTime = 0;

  constructor(
    private gameService: GameService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private playerManagementService: PlayerManagementService,
    private firebaseService: FirebaseService,
    private eventService: EventService,
    private sharedService: SharedService,
  ) { }

  async ngOnInit() {
    this.isTemplateLoading = true;
    await this.firebaseService.authenticate();
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.userCompany = this.authService.getDefaultCompany();
        this.templateId = params.id;
        this.isTemplateLoading = true;
        this.gameService
          .getTemplateById(this.templateId)
          .pipe(finalize(() => (this.isTemplateLoading = false)))
          .subscribe((m) => {
            if (m.success) {
              this.template = m.data;
              // WATCH STATS
              this.firebaseService.watchStats(this.template.wine.id);
              this.firebaseService.watchWineGuessPriceChange(
                this.template.wine.id
              );
              this.sankeyPreference = [100, 75, 50, 25];
              this.load();
            }
          });
      }
    });

    this.eventService.events
      .pipe(takeUntil(this.destroyFirebase$))
      .subscribe((x) => {
        if (
          (x.type === 'STATS_UPDATED' || x.type === 'WINE_PRICE_CHANGED') &&
          x.payload &&
          this.selectedInstance &&
          !this.isLoadingStats
        ) {
          this.loadUpdatedStats();
        }
      });
    // this.getMaxBarLength();
  }

  ngOnDestroy() {
    this.firebaseService.unWatch();
    this.destroyFirebase$.next();
    this.destroyFirebase$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  load() {
    this.refreshSankey();
    this.gameService
      .getWinePreference(this.templateId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((m) => {
        this.winePreferences = m;
      });
    this.gameService
      .getCorrectAnswerAverage(this.templateId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((m) => {
        this.correctAverageAnswer = m.data;
      });
    this.gameService
      .getUniquePlayerCount(this.templateId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((m) => {
        this.uniquePlayerCount = m.data;
      });
    this.gameService
      .getTemplateSequencesCount(this.templateId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((m) => {
        this.totalGamesCount = m.data;
      });
    this.gameService
      .getTemplateSequencesCount(this.templateId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((m) => {
        this.totalGamesCount = m.data;
      });
    this.gameService
      .getAverageTime(this.templateId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((m) => {
        this.averageTime = m.data;
      });
    this.getInstances();
    this.getFeedbackSummary();
    this.getPreferenceSummary();
    this.getAverageRating();
  }

  updateInstance(instance: any) {
    if (!this.userCompany.isGameCreationAllowed) {
      return;
    }

    const model = this.modalService.open(InstanceCreateComponent);
    model.componentInstance.instance = instance;

    model.result.then(
      (r) => {
        if (r && r.refresh) {
          const temp = this.instances.find((x) => x.id === r.data.id);
          temp.expiresOn = r.data?.expiresOn;
          temp.startDateTime = r.data?.startDateTime;
          temp.maxParticipants = r.data?.maxParticipants;
          this.toastService.showInfo(
            'Game instance have been updated successfully'
          );
        }
      },
      (error) => console.log(error)
    );
  }
  jumpTo(f: any) {
    const el = document.getElementById(f);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

  }

  getFeedbackSummary() {
    this.gameService.getFeedbackSummary(this.templateId).subscribe(res => {
      if (res && res.data) {
        this.feedbackSummary = res.data.feedback;

      }
    });
  }
  getPreferenceSummary() {
    this.gameService.getPreferenceSummary(this.templateId).subscribe(res => {
      if (res && res.data) {
        this.preferenceSummary = res.data.feels;
        this.getMaxBarLength(res.data.feels);
      }
    });
  }
  getAverageRating() {
    this.gameService.getAverageRating(this.templateId).subscribe(res => {
      this.averageRating = res.data;

    });
  }
  refreshSankey(id = undefined) {
    if (id) {
      const index = this.sankeyPreference.indexOf(id);

      if (index > -1) {
        this.sankeyPreference.splice(index, 1);
      } else {
        this.sankeyPreference.push(id);
      }

      const winePreferences = [];
      this.sankeyPreference.forEach((k) => {
        if (k === 100) {
          winePreferences.push(this.winePreferences.topTen);
        }
        if (k === 75) {
          winePreferences.push(this.winePreferences.topTwentyFive);
        }
        if (k === 50) {
          winePreferences.push(this.winePreferences.topAverage);
        }
        if (k === 25) {
          winePreferences.push(this.winePreferences.notForMe);
        }
      });

      this.isSankeyEmpty = winePreferences.every((x) => x === 0);
    }

    if (!this.isSankeyEmpty) {
      const requests = [
        this.gameService.getSankeyChart(this.templateId, {
          preference: this.sankeyPreference,
        }),
        this.gameService.getFlavorwave(this.templateId, {
          preference: this.sankeyPreference,
        }),
      ];

      this.isLoadingFlavorWave = true;

      forkJoin(requests)
        .pipe(finalize(() => (this.isLoadingFlavorWave = false)))
        .subscribe((res) => {
          this.data = res[0];
          this.flavorwave = res[1];
        });
    }
  }

  getInstances(append = false) {
    this.loadingInstances = true;
    if (!append) {
      this.instances = [];
      this.displayInstances = [];
    }
    this.gameService
      .getPlayers(this.templateId, this.instancesPage, this.instancesLength)
      .pipe(
        finalize(() => (this.loadingInstances = false)),
        takeUntil(this.destroy$)
      )
      .subscribe((m: any) => {
        this.totalInstancesCount = m.totalCount;
        if (append) {
          this.instances.push(...m.data);
        } else {
          this.instances = m.data;
        }

        this.displayInstances = [...this.instances];
      });
  }

  exportPlayers(append = false) {
    this.gameService
      .getPlayers(this.templateId, this.instancesPage, this.instancesLength)
      .pipe(
        finalize(() => (this.loadingInstances = false)),
        takeUntil(this.destroy$)
      )
      .subscribe((m: any) => {
        this.totalInstancesCount = m.totalCount;
        if (append) {
          this.instances.push(...m.data);
        } else {
          this.instances = m.data;
        }

        this.displayInstances = [...this.instances];
      });
  }

  generateQR(code) {
    if (this.generatingQrCode) {
      return;
    }

    this.generatingQrCode = true;

    const modalInstance = this.modalService.open(GenerateQrCodeComponent);
    modalInstance.componentInstance.code = code;
    modalInstance.result.then(
      (result) => {
        this.generatingQrCode = false;
      },
      (err) => {
        this.generatingQrCode = false;
      }
    );
  }

  onScroll() {
    if (
      !this.loadingInstances &&
      this.totalInstancesCount !== this.instances.length
    ) {
      this.instancesPage++;
      this.getInstances(true);
    }
  }

  sankeyDataExist(type): boolean {
    if (!this.data) {
      return false;
    }
    let found = false;
    if (type === 'aroma') {
      for (let i = 0; i < GlobalConstants.aroma.length; i++) {
        if (
          this.data.some(
            (x) =>
              x.indexOf(this.covertToTitleCase(GlobalConstants.aroma[i])) > -1
          )
        ) {
          found = true;
          break;
        }
      }
    } else if (type === 'acidity') {
      for (let i = 0; i < GlobalConstants.acidity.length; i++) {
        if (
          this.data.some(
            (x) =>
              x.indexOf(this.covertToTitleCase(GlobalConstants.acidity[i])) > -1
          )
        ) {
          found = true;
          break;
        }
      }
    } else if (type === 'body') {
      for (let i = 0; i < GlobalConstants.body.length; i++) {
        if (
          this.data.some(
            (x) =>
              x.indexOf(this.covertToTitleCase(GlobalConstants.body[i])) > -1
          )
        ) {
          found = true;
          break;
        }
      }
    } else if (type === 'tannins') {
      for (let i = 0; i < GlobalConstants.tannins.length; i++) {
        if (
          this.data.some(
            (x) =>
              x.indexOf(this.covertToTitleCase(GlobalConstants.tannins[i])) > -1
          )
        ) {
          found = true;
          break;
        }
      }
    } else if (type === 'oak') {
      for (let i = 0; i < GlobalConstants.oak.length; i++) {
        if (
          this.data.some(
            (x) =>
              x.indexOf(this.covertToTitleCase(GlobalConstants.oak[i])) > -1
          )
        ) {
          found = true;
          break;
        }
      }
    } else if (type === 'sugar') {
      for (let i = 0; i < GlobalConstants.sugar.length; i++) {
        if (
          this.data.some(
            (x) =>
              x.indexOf(this.covertToTitleCase(GlobalConstants.sugar[i])) > -1
          )
        ) {
          found = true;
          break;
        }
      }
    }

    return found;
  }

  covertToTitleCase(m) {
    const arr = m.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(' ');
  }

  formatAverageTime(time) {
    if (!time) {
      return '0';
    }

    return `${time.days}:${time.hours}:${time.minutes}:${time.seconds}`;
  }

  getWineOrigin(wine) {
    if (!wine) {
      return '';
    }

    const origin: string[] = [];

    if (wine.appellation && origin.indexOf(wine.appellation) < 0) {
      origin.push(wine.appellation);
    }
    if (wine.region && origin.indexOf(wine.region) < 0) {
      origin.push(wine.region);
    }
    if (wine.country && origin.indexOf(wine.country) < 0) {
      origin.push(wine.country);
    }

    return origin.join(', ');
  }

  isSommSelectedFlavor(flavor) {
    const selected = this.template.note.flavors.find((x) => x.selected);

    if (selected) {
      return selected.value === flavor;
    }

    return false;
  }

  flagName(name) {
    return SharedService.flagName(name);
  }

  getMaxBarLength(feels: any[]) {
    const data = [];
    feels.map((d) => {
      d.property.map(c => {
        const sum = +c.val1 + +c.val2 + +c.val3;
        data.push(sum);
      });
    });
    this.max = Math.max(...data);
  }
  getInnerBarWidth(prop: string, p: any): string {
    const val = +prop;
    const sum = +p.val1 + +p.val2 + +p.val3;
    const width = 100 * (val / (this.max ? this.max : 1));
    return width + '%';

  }
  hidePlayerInstances($event) {
    this.loadingInstances = true;
    if ($event.target.checked) {
      this.displayInstances = [...this.instances.filter((x) => x.ownerCreated)];
    } else {
      this.displayInstances = [...this.instances];
    }
    this.loadingInstances = false;
  }

  selectTab(value: string) {
    this.selectedTab = value;
  }

  fetchWineOrigin = (wine) => getWineOrigin(wine);

  getWineIcon = (color: string) => getIcon(color);

  getCountryFlag = (country: string) => SharedService.flagName(country);

  getPercentageText(percentage) {
    if (percentage === 100) {
      return 'Top 10%';
    } else if (percentage === 75) {
      return 'Top 25%';
    } else if (percentage === 50) {
      return 'Average';
    } else if (percentage === 25) {
      return 'Not for me';
    } else {
      return 'Unknown';
    }
  }

  select(index) {
    this.selectedInstance = this.instances[index];
    this.selectedIndex = index;
    this.remainingVisibleState = {
      aroma: false,
      acidity: false,
      body: false,
      flavor: false,
      sugar: false,
      tannins: false,
      oak: false,
    };

    this.playerVerticalUIState = {
      aroma: false,
      acidity: false,
      body: false,
      flavor: false,
      sugar: false,
      tannins: false,
      oak: false,
      guessPrice: false,
    };
    this.loadUpdatedStats();
  }

  loadUpdatedStats() {
    this.destoryStats$.next();
    this.isLoadingStats = true;
    this.playerManagementService
      .getWineGlobalStats(this.selectedInstance.id)
      .pipe(
        takeUntil(this.destoryStats$),
        finalize(() => (this.isLoadingStats = false))
      )
      .subscribe((stats) => {
        this.selectedWineStats = stats;
      });
  }

  isSommSelection(option, value) {
    if (option === 'explicitPreference') {
      return false;
    } else if (option === 'flavor') {
      const sommSelectedFlavor = this.selectedInstance.note.flavors.find(
        (x) => x.selected
      );
      return sommSelectedFlavor.value === value;
    }

    return this.selectedInstance.note[option] === value;
  }

  isPlayerSelection(option, value) {
    // if (option === "explicitPreference") return this.selectedSequence.result.explicitPreference === value;
    return this.selectedWineStats[option] === value;
  }

  getOptionPercentage(key: string, option: string) {
    if (!this.selectedWineStats || !this.selectedWineStats[key]) {
      return 0;
    }

    const optionStat = this.selectedWineStats[key].find(
      (x) => x.name === option
    );

    return optionStat ? optionStat.percentage : 0;
  }

  getOptionUsers(key, option) {
    return this.selectedWineStats[key].find((x) => x.name === option)?.users;
  }

  getAverageRatingBySelecttion() {
    let averagePercentage = 0;
    this.selectedWineStats.explicitPreference.forEach((x) => {
      averagePercentage += (parseInt(x.name) * x.percentage) / 100;
    });

    return Math.round(averagePercentage);
  }

  getOptions(key: string) {
    if (key === 'flavor') {
      return this.selectedInstance.note.flavors.map((x) => x.value);
    }
    return GlobalConstants[key];
  }

  getPlayerPicture(image: string) {
    return `${environment.cdn}profiles/${image}`;
  }

  getActiveAttributes(attributes) {
    return attributes ? attributes.filter((x) => x.enabled) : [];
  }

  getHostName(url: string): string {
    const domain = (new URL(url));
    return domain.hostname;
  }
  sortCharRatingChange(ratings: any[]): any[] {
    return this.sharedService.sortCharRatingChange(ratings);

  }

}

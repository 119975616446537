<app-shell-light>
    <div class="nk-content" *ngIf="user">
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <app-global-filters [title]="title" [subTitle]="subTitle" [filter]="filter"
                        (duration)="changeDuration($event)" (createGame)="createGame()" [filterOptions]="filterOptions"
                        (load)="load()" [showGames]="showGames" (export)="export()" (import)="importPlayers()">
                    </app-global-filters>
                    <div class="nk-block mt-4">
                        <div class="row g-gs">
                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <img src="../../assets/icons/icons8-omnichannel.svg" class="icn">
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Connections</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left" title="Total Deposited"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingTotalConnectionsCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>

                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingTotalConnectionsCount">
                                                <span class="amount">{{ totalConnections }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span
                                                        class="{{ totalConnectionsDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                                                        totalConnectionsDifference > -1 ? '+' : ''}}
                                                        {{ totalConnectionsDifference }}
                                                        %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <img src="../../assets/icons/icons8-champagne.svg" class="icn">
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Games</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left" title="Total Deposited"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingGamesPlayedCount"
                                                class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingGamesPlayedCount">
                                                <span class="amount">{{ totalGamesPlayed }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span
                                                        class="{{ totalGamesPlayerDifference < 0 ? 'text-danger' : 'text-success' }} fw-medium">{{
                                                        totalGamesPlayerDifference > -1 ? '+' : ''}}
                                                        {{ totalGamesPlayerDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <img src="../../assets/icons/grape.svg" class="icn">
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Avg. Score</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left" title="Total Deposited"></em>
                                            </div>
                                        </div>
                                        <div class="card-amount">
                                            <div *ngIf="loadingAverageScore" class="spinner-border spinner-border-sm"
                                                role="status"> <span class="sr-only">Loading...</span></div>

                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingAverageScore">
                                                <span class="amount">{{ averageScore }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span class="{{ averageScoreDifference < 0 ? 'text-danger' : 'text-success' }}
                                                        fw-medium">{{ averageScoreDifference > -1 ? '+' : ''}}
                                                        {{ averageScoreDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card card-bordered card-full top-card">
                                    <div class="card-inner">
                                        <img src="../../assets/icons/star-rating.svg" class="icn">
                                        <div class="card-title-group align-start mb-0">
                                            <div class="card-title">
                                                <h6 class="subtitle">Avg. Rating</h6>
                                            </div>
                                            <div class="card-tools">
                                                <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip"
                                                    data-placement="left" title="Total Deposited"></em>
                                            </div>
                                        </div>

                                        <div class="card-amount">
                                            <div *ngIf="loadingAverageRating" class="spinner-border spinner-border-sm"
                                                role="status"> <span class="sr-only">Loading...</span></div>

                                            <div class="w-100 d-flex justify-content-between"
                                                *ngIf="!loadingAverageRating">
                                                <span class="amount">{{ averageRating }}</span>
                                                <div *ngIf="filter.durationType !== 1" class="d-flex flex-column">
                                                    <span class="{{ averageRatingDifference < 0 ? 'text-danger' : 'text-success' }}
                                                        fw-medium">{{ averageRatingDifference > -1 ? '+' : ''}}
                                                        {{ averageRatingDifference }} %</span>
                                                    <small>{{ filter.durationType === 2 ? 'Last 7 days' : 'Last 30 days'
                                                        }}</small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-xxl-12">
                                <div class="card card-bordered card-full">
                                    <div class="card-inner border-bottom">
                                        <div class="nk-tb-list nk-tb-customers">
                                            <div class="nk-tb-item nk-tb-head">
                                                <div class="nk-tb-col pl-2 cursor-pointer">
                                                    <span>Name</span>
                                                    <!-- <em class="fs-14px float-left icon ni" [ngClass]="sort !== 0 ? 'ni-arrow-long-up' : sort === 0 &&
                                                        !sortByAscending ? 'ni-arrow-long-up text-dark' :
                                                        'ni-arrow-long-down text-dark'"></em> -->
                                                </div>

                                                <div class="nk-tb-col tb-col-md cursor-pointer tb-col-sm"
                                                    (click)="applyPlayerAnalyticsSort(1)">
                                                    <span>Connections</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getSortingClass(1)">
                                                    </em>
                                                </div>

                                                <div class="nk-tb-col sorting cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(2)">
                                                    <span>Games</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getSortingClass(2)">
                                                    </em>
                                                </div>

                                                <div class="nk-tb-col cursor-pointer tb-col-sm"
                                                    (click)="applyPlayerAnalyticsSort(3)">
                                                    <span>Avg Score</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getSortingClass(3)">
                                                    </em>
                                                </div>

                                                <div class="nk-tb-col cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(4)">
                                                    <span class="d-none d-md-block">A. Rating</span>
                                                    <span class="d-block d-md-none">Pref</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getSortingClass(4)">
                                                    </em>
                                                </div>
                                                <div class="nk-tb-col tb-col-sm cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(5)">
                                                    <span>GTP Diff</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getSortingClass(5)">
                                                    </em>
                                                </div>
                                                <div class="nk-tb-col tb-col-sm cursor-pointer">
                                                    <span>Rec Status</span>
                                                </div>
                                                <div (click)="applyPlayerAnalyticsSort(6)"
                                                    class="nk-tb-col tb-col-sm cursor-pointer">
                                                    <span>Rec fulfilled</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getSortingClass(6)">
                                                    </em>
                                                </div>
                                                <div class="nk-tb-col tb-col-sm cursor-pointer"
                                                    (click)="applyPlayerAnalyticsSort(7)">
                                                    <span>Buy Clicks</span>
                                                    <em class="fs-14px float-left cursor-pointer icon ni ni-arrow-long-up"
                                                        [ngClass]="getSortingClass(7)">
                                                    </em>
                                                </div>
                                                <div class="nk-tb-col tb-col-sm cursor-pointer">
                                                    <span>Actions</span>
                                                </div>

                                                <div class="nk-tb-col cursor-pointer">
                                                    <span class="d-block d-md-none">View</span>
                                                </div>
                                            </div>
                                            <div class="nk-tb-item" *ngFor="let player of players">
                                                <div class="nk-tb-col pl-2">
                                                    <div class="user-card cursor-pointer"
                                                        [routerLink]="['detail', player.id]">
                                                        <div class="user-avatar user-avatar-xs bg-pink-dim">
                                                            <ng-conatiner *ngIf="player.picture; else avatarDefault">
                                                                <img class="rounded-circle"
                                                                    src="{{env.cdn}}profiles/{{player.picture}}">
                                                                <img *ngIf="player.isKnownCustomer"
                                                                    class="known-customer"
                                                                    src="../../assets/icons/customer.svg">
                                                            </ng-conatiner>
                                                            <ng-template #avatarDefault>
                                                                <span>{{ getInitials(player.name) }}</span>
                                                            </ng-template>
                                                        </div>
                                                        <div class="user-name ">
                                                            <div class="fullname">
                                                                {{ player.name | titlecase}}
                                                            </div>
                                                            <div class="username">{{ player.username }} <span
                                                                    class="badge badge-light text-dark ml-3"
                                                                    *ngIf="player.hasFollowed">Follows You</span>
                                                            </div>
                                                            <div class="username">{{ player.email }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="nk-tb-col data-item-num-0">{{ player.connections }}
                                                </div>
                                                <div class="nk-tb-col data-item-num-0 tb-col-sm">
                                                    {{ player.totalGames }}
                                                </div>
                                                <div class="nk-tb-col data-item-num-0">{{ player.averageScore |
                                                    number:
                                                    '1.0-0' }}</div>
                                                <div class="nk-tb-col data-item-num-0 tb-col-sm">
                                                    {{ player.averageRating |
                                                    number: '1.0-0' }}%</div>

                                                <div class="nk-tb-col data-item-num-0 tb-col-sm">
                                                    {{ player.gtp |
                                                    number: '1.0-0' }}%</div>
                                                <div class="nk-tb-col data-item-num-0">
                                                    <div class="rec-status" *ngIf="player.recommendation?.completed">
                                                        <img src="../../assets/icons/icons8-waiter-green.svg">
                                                        <span>Complete({{player.recommendation?.completed}})</span>
                                                    </div>
                                                    <div class="rec-status default"
                                                        *ngIf="player.recommendation?.default">
                                                        <img src="../../assets/icons/icons8-waiter-green.svg">
                                                        <span>Default({{player.recommendation?.default}})</span>
                                                    </div>
                                                    <div class="rec-status pending"
                                                        *ngIf="player.recommendation?.pending">
                                                        <img src="../../assets/icons/icons8-waiter-grey.svg">
                                                        <span>Pending({{player.recommendation?.pending}})</span>
                                                    </div>

                                                </div>
                                                <div class="nk-tb-col data-item-num-0">
                                                    <div
                                                        *ngIf="player.recommendation?.completed || player.recommendation?.pending">
                                                        <span class="red"
                                                            [ngClass]="{'green':player.recommendation?.completed+player.recommendation?.default === player.recommendation?.completed+player.recommendation?.pending+player.recommendation?.default}">
                                                            {{player.recommendation?.completed+player.recommendation?.default}}/{{player.recommendation?.completed+player.recommendation?.pending+player.recommendation?.default}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="nk-tb-col data-item-num-0 tb-col-sm">
                                                    {{ player.buyClicks }}</div>
                                                <div class="nk-tb-col nk-tb-col-action tb-col-sm">
                                                    <button class="btn btn-sm btn-super"
                                                        [routerLink]="['detail', player.id]">
                                                        View Player
                                                    </button>
                                                </div>

                                                <div class="nk-tb-col data-item-num-0 d-block d-md-none">
                                                    <button class="btn btn-sm btn-super"
                                                        [routerLink]="['detail', player.id]">
                                                        V
                                                    </button>


                                                </div>
                                            </div>


                                        </div>
                                        <div class="w-100 py-4 text-center" *ngIf="loadingPlayers">
                                            <div class="spinner-border spinner-border-sm" role="status"> <span
                                                    class="sr-only">Loading...</span></div>
                                        </div>
                                        <div class="w-100 py-3 text-center"
                                            *ngIf="!loadingPlayers && players.length < 1">
                                            <span>No records found</span>
                                        </div>
                                        <div class="search-results" class="search-results" infiniteScroll
                                            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                                            (scrolled)="onScroll()"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-shell-light>